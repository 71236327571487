import { connect } from "react-redux";
import ViewStyles from "./components/viewStyles";
import React, { useEffect, useRef } from "react";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import {
  fetchUnitDefns,
  ToggleLoader,
  setStyleTableCols,
  setUnitDefns,
  setShowStylesTable,
  setUnitDefnFilterValues,
} from "./product-unit-definition-service";
import { getColumns, getColumnsAg } from "../../actions/tableColumnActions";
import { setFilterConfiguration } from "actions/filterAction";
import { isEmpty } from "lodash";

const UnitDefinition = (props) => {
  const onFilterDependency = useRef([]);
  const stylesDashboardTableRef = useRef(null);

  useEffect(() => {
    //Load the API for table
    const fetchData = async () => {
      try {
        props.ToggleLoader(true);
        const data = await fetchFilterFieldValues("product unit definition");

        if (isEmpty(props.filterDashboardConfiguration)) {
          const filterConfigData = [
            {
              filterDashboardData: data,
              expectedFilterDimensions: ["product"],
              screenDimension: "product",
              isCrossDimensionFilter: false,
            },
          ];
          const filterConfig = formattedFilterConfiguration(
            "productUnitDefFilterConfiguration",
            filterConfigData,
            "Product Unit Definition"
          );
          props.setFilterConfiguration(filterConfig);
        } else {
          onFilterDependency.current =
            props.filterDashboardConfiguration.appliedFilterData.dependencyData;
        }

        props.setUnitDefnFilterValues(data);
        if (props.columns.length === 0) {
          const rescols = await props.getColumnsAg(
            "table_name=product_unit_definition"
          );
          props.setStyleTableCols(rescols);
        }
        props.ToggleLoader(false);
      } catch (error) {
        //Error Handling
        props.ToggleLoader(false);
      }
    };
    fetchData();
    return () => {
      props.setShowStylesTable(false);
    };
  }, []);

  const onClickFilter = () => {
    if (stylesDashboardTableRef.current) {
      stylesDashboardTableRef.current.api?.refreshServerSideStore({
        purge: true,
      });
    }
  };

  const onFilterDashboardClick = (dependencyData) => {
    onFilterDependency.current = dependencyData;
    onClickFilter();
  };

  return (
    <CoreComponentScreen
      pageLabel={"Product Unit Definition"}
      showPageRoute={true}
      showPageHeader={true}
      location={props.location}
      // Filter dashboard props
      showFilterDashboard={true}
      filterConfigKey={"productUnitDefFilterConfiguration"}
      onApplyFilter={onFilterDashboardClick}
    >
      <ViewStyles
        ref={{
          stylesDashboardTableRef: stylesDashboardTableRef,
          filterDependencyRef: onFilterDependency,
        }}
        id="productUnitDefnStylesComp"
      />
    </CoreComponentScreen>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.UnitDefnReducer.isLoading,
    filters: state.UnitDefnReducer.filtersData,
    columns: state.UnitDefnReducer.stylesTableCols,
    filterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "productUnitDefFilterConfiguration"
      ],
    planningLevelHierarchy:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer
        .planningLevelHierarchy,
    userAccessList:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer.userAccessList,
  };
};

const mapActionsToProps = {
  fetchUnitDefns,
  setUnitDefns,
  getColumns,
  ToggleLoader,
  setStyleTableCols,
  setShowStylesTable,
  setUnitDefnFilterValues,
  getColumnsAg,
  setFilterConfiguration,
};

export default connect(mapStateToProps, mapActionsToProps)(UnitDefinition);
