import React from "react";
import Configuration from "./configuration";
import HeaderBreadCrumbs from "../../../Utils/HeaderBreadCrumbs";

/**
 * Heading Component for the filter configuration screen
 */
const NavigationCrumbs = (props) => {
  return (
    <HeaderBreadCrumbs
      options={[
        {
          label: "Filters Configuration",
          id: 1,
          action: () => {
            props.history.push("/filters");
          },
        },
      ]}
    ></HeaderBreadCrumbs>
  );
};

/**
 * Starting point of the Filters screen
 * @param {*} props
 */
const Navigation = () => {
  return (
    <>
      <NavigationCrumbs />
      <Configuration />
    </>
  );
};

export default Navigation;
