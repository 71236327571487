import React, { useState, useEffect, useRef } from "react";
import makeStyles from "@mui/styles/makeStyles";
import globalStyles from "Styles/globalStyles";
import { Container, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { isEmpty } from "lodash";
import { getColumnsAg } from "actions/tableColumnActions";
import AgGridComponent from "Utils/agGrid";
import CellRenderers from "Utils/agGrid/cellRenderer";
import { common } from "modules/react-demo/constants/stringContants";
import AccessibleHierarchyModal from "./accessible-hierarchy-modal";
import AssignRole from "../assignRole/assign-role";
import { useHistory } from "react-router-dom";
import { userManagementRoutes } from "config/routes";
import { useStyles as sharedStyles } from "../styles-tenant-user-mgmt";
import ConfirmPrompt from "commonComponents/confirmPrompt";

const useStyles = makeStyles((theme) => ({
  contentBody: {
    minHeight: "30rem",
  },
}));

const ManageUserRoleDashboardTable = (props) => {
  const [productViewTableColumns, setProductViewTableColumns] = useState([]);
  const [showAccessibleDept, setShowAccessibleDept] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [productViewTableData, setProductViewTableData] = useState([]);
  const [mappedDepartmentsData, setMappedDepartmentsData] = useState([]);
  const [toEditRowData, setToEditRowData] = useState([]);
  const [productViewTableSelectedRowIDs, setProductViewTableSelectedRowIDs] =
    useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteInstance, setDeleteInstance] = useState([]);
  const [
    closeAssignRoleConfirmationDialog,
    setCloseAssignRoleConfirmationDialog,
  ] = useState(false);
  const userRoleDashboardTableInstance = useRef(null);
  //  api data
  const { roleTableData, setUserRoleMgmtLoader } = props;
  const history = useHistory();
  const classes = useStyles();
  const globalClasses = globalStyles();
  const sharedClasses = sharedStyles();

  useEffect(() => {
    const getInitialData = async () => {
      setUserRoleMgmtLoader(true);
      let cols = await getColumnsAg("table_name=user_app_role")();
      cols = cols.map((item) => {
        if (item.column_name === "hierarchies") {
          item.type = "link";
          item.cellRenderer = (cellProps) => {
            if (
              cellProps.data.role_name === "superuser" ||
              cellProps.data.role_name === "admin"
            ) {
              return "-";
            } else
              return (
                <CellRenderers
                  cellData={cellProps}
                  column={item}
                ></CellRenderers>
              );
          };
          item.onClick = (tableInfo) => {
            setMappedDepartmentsData(tableInfo.cellData.data.department_name);
            setShowAccessibleDept(true);
          };
        }
        return item;
      });
      let body = {
        meta: {
          search: [],
        },
        filters: [],
      };
      try {
        let response = await props.getRolesMappedToUserData(body);
        props.setRolesMappedToUserData(response.data.data);
      } catch (err) {
        setUserRoleMgmtLoader(false);
        props.addSnack({
          message: "Something went wrong",
          options: {
            variant: "error",
          },
        });
      }
      setProductViewTableColumns(cols);
    };
    getInitialData();
  }, []);

  useEffect(() => {
    if (roleTableData.length > 0) {
      let newTableRecord = roleTableData.map((item) => {
        return {
          ...item,
          hierarchies: "view",
          department_name: item.access_hierarchy,
        };
      });
      setUserRoleMgmtLoader(false);
      setProductViewTableData(newTableRecord);
    } else {
      setProductViewTableData([]);
    }
  }, [roleTableData]);

  // Note - To remove old selected values
  useEffect(() => {
    userRoleDashboardTableInstance.current &&
      userRoleDashboardTableInstance.current.api.refreshInfiniteCache();
  }, [userRoleDashboardTableInstance]);

  const openEditModal = (rowDataObj) => {
    setShowEditModal(true);
    setToEditRowData(rowDataObj);
  };

  const openEditModalToSetAll = () => {
    if (
      isEmpty(productViewTableSelectedRowIDs) ||
      productViewTableSelectedRowIDs.length === 1
    ) {
      props.addSnack({
        message: "Select at-least two rows",
        options: {
          variant: "info",
        },
      });
    } else {
      setShowEditModal(true);
      setToEditRowData(productViewTableSelectedRowIDs);
    }
  };

  const onCancel = () => {
    setShowAccessibleDept(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setCloseAssignRoleConfirmationDialog(false);
    props.clearTableHierarchyOnAssignRole();
  };

  const onDeleteClick = (rowData) => {
    setDeleteInstance(rowData);
    setShowDeleteDialog(true);
  };

  const confirmDeleteAssignedRoles = () => {
    props.setUserRoleMgmtLoader(true);
    const callDelete = async () => {
      try {
        let body = {
          meta: {
            search: [],
          },
          filters: [],
        };
        setShowDeleteDialog(false);
        await props.deleteRolesAssigned(deleteInstance.hierarchy_id);
        displaySnackMessages("Successfully deleted roles", "success");
        let response = await props.getRolesMappedToUserData(body);
        props.setRolesMappedToUserData(response.data.data);
      } catch (err) {
        props.setUserRoleMgmtLoader(false);
        displaySnackMessages("Something went wrong on delete", "error");
      }
    };
    callDelete();
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const openEditUserInfo = () => {
    return (
      <ConfirmPrompt
        showModal={true}
        message=""
        title="Edit"
        ariaLabeledBy="assign-role-edit-dialog"
        showCloseIcon={true}
        hideActionFooter={true}
        setConfirm={closeEditModal}
        size="lg"
      >
        <div className={classes.contentBody}>
          <AssignRole
            hideIconButtonsOnEdit={true}
            formDataOnEdit={toEditRowData}
            onCancel={closeEditModal}
          />
        </div>
      </ConfirmPrompt>
    );
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows();
    setProductViewTableSelectedRowIDs(selections);
  };

  const loadAgGridInstance = (val) => {
    userRoleDashboardTableInstance.current = val;
  };

  return (
    <Container maxWidth={false}>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
      >
        <Typography
          variant="h3"
          component="h3"
          className={`${globalClasses.pageHeader}`}
        >
          User and Role View
        </Typography>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.verticalAlignCenter} ${globalClasses.gap}`}
        >
          <Button
            variant="contained"
            color="primary"
            id="createStoreBtn"
            onClick={() => history.push(userManagementRoutes.role)}
            startIcon={<AddIcon />}
          >
            Assign Role To User
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="storeSetAllBtn"
            onClick={() => openEditModalToSetAll()}
          >
            Set All
          </Button>
        </div>
      </div>
      {productViewTableColumns.length > 0 && (
        <AgGridComponent
          rowdata={productViewTableData}
          columns={productViewTableColumns}
          onEditClick={(tableInfo) => openEditModal(tableInfo.data)}
          callDeleteApi={(tableInfo) => onDeleteClick(tableInfo.data)}
          selectAllHeaderComponent={true}
          onSelectionChanged={onSelectionChanged}
          uniqueRowId={"hierarchy_id"}
          rowSelection={"multiple"}
          loadTableInstance={loadAgGridInstance}
          sizeColumnsToFitFlag={true}
          // for FE sort do not pass manualcallBack prop
        />
      )}
      {showAccessibleDept && (
        <AccessibleHierarchyModal
          closeModal={onCancel}
          tableData={mappedDepartmentsData}
          getTableConfig={props.getTableConfig}
        />
      )}
      {showEditModal && openEditUserInfo()}
      <ConfirmPrompt
        showModal={showDeleteDialog}
        title="Delete Assigned Roles"
        message="Are you sure you want to delete the roles assigned to this user?"
        ariaLabeledBy="delete-roles-dialog"
        primaryBtnText={common.__ConfirmBtnText}
        secondaryBtnText={common.__RejectBtnText}
        showCloseIcon={true}
        setConfirm={setShowDeleteDialog}
        confirmCallback={(val) => {
          if (val) {
            confirmDeleteAssignedRoles();
          }
        }}
      />
      <ConfirmPrompt
        showModal={closeAssignRoleConfirmationDialog}
        title="Assign Roles"
        message="Your changes will be discarded if you proceed. Are you sure you want to close?"
        ariaLabeledBy="assign-roles-dialog"
        primaryBtnText={common.__ConfirmBtnText}
        secondaryBtnText={common.__RejectBtnText}
        showCloseIcon={true}
        setConfirm={setCloseAssignRoleConfirmationDialog}
        confirmCallback={(val) => {
          if (val) {
            closeEditModal();
          }
        }}
      />
    </Container>
  );
};

export default ManageUserRoleDashboardTable;
