import { combineReducers } from "redux";
import planDashboardReducer from "./PlanSmart-Dashboard/plansmart-dashboard-services";
import planCreateNewPlanReducer from "./CreateNewPlan/create-new-plan-service";
import planBudgetTableReducer from "./BudgetPlanTable/budget-plan-table-service";
import masterPlanReducer from "./Master-Plan/master-plan-services";
import comparePlanReducer from "./ComparePlan/compare-plan-service";
import reportReducer from "./Report/report-services";
import editableMetricTenantReducer from "./EditableMetricTenant";
export const plansmartReducer = combineReducers({
  planDashboardReducer,
  planCreateNewPlanReducer,
  planBudgetTableReducer,
  masterPlanReducer,
  comparePlanReducer,
  reportReducer,
  editableMetricTenantReducer,
});
