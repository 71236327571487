import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";

import globalStyles from "Styles/globalStyles";
import Charts from "Utils/charts";
import { isEmpty } from "lodash";

const LostSalesOpportunityComponent = (props) => {
  const [lostSalesBarGraph, setLostSalesBarGraph] = useState({});
  const [toRenderGraph, setToRenderGraph] = useState(false);
  const globalClasses = globalStyles();

  useEffect(() => {
    if (!isEmpty(props.lostSalesGraph)) {
      setLostSalesBarGraph(props.lostSalesGraph);
      setToRenderGraph(true);
    }
  }, [props.lostSalesGraph]);

  const buildLostSalesGraphComponent = () => {
    return {
      type: "column",
      chartType: "barChart",
      chartTitle: "",
      axisLegends: {
        xaxis: {
          categories: lostSalesBarGraph.map((item) => item.fiscal_week),
          title: "",
        },
        yaxis: { title: "Lost Sales units" },
      },
      series: [
        {
          name: "Weeks",
          data: lostSalesBarGraph.map((item) => item.lost_units),
        },
      ],
      // to customize later the format to be displayed on hover
      tooltip: {},
      isPercentLabel: false,
    };
  };

  return (
    <div>
      <Typography variant="h5" className={globalClasses.paddingVertical}>
        Lost Sales Opportunity
      </Typography>
      {toRenderGraph && <Charts options={buildLostSalesGraphComponent()} />}
    </div>
  );
};

export default LostSalesOpportunityComponent;
