import GroupType from "./groupType";
import PageRouteTitles from "../PageRouteTitles";
import { useHistory } from "react-router-dom";
import { Container, Paper } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import GroupTypeFilters from "./grpTypeFilters";
import FilteredProducts from "./productFilters";
import LoadingOverlay from "../../../../Utils/Loader/loader";
import { connect } from "react-redux";
import globalStyles from "Styles/globalStyles";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { useRef } from "react";

const CreateProductGroup = (props) => {
  const history = useHistory();
  const globalClasses = globalStyles();
  const productGrpTypeOptions = [
    {
      label: "Manual",
      value: "manual",
    },
    // {
    //   label: "Objective based",
    //   value: "objective",
    // },
    // {
    //   label: "Custom",
    //   value: "custom",
    // },
  ];
  const routeOptions = [
    {
      id: "product_grping_scr",
      label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
      action: () => history.push("/product-grouping"),
    },
    {
      id: "create_new_product_grp",
      label: "Create New Group",
      action: () => null,
    },
  ];

  const manualProductsTableRef = useRef(null);
  const manualProductGroupsTableRef = useRef(null);
  const manualStyleLvlTableRef = useRef(null);
  return (
    <>
      <PageRouteTitles options={routeOptions} />
      <Container maxWidth={false}>
        <LoadingOverlay loader={props.isLoading} spinner>
          <div className={`${globalClasses.flexRow} ${globalClasses.verticalAlignCenter}`}>
            <FormLabel
              classes={{ root: globalClasses.marginRight }}
              component="legend"
            >
              GroupType
            </FormLabel>
            <GroupType
              ref={{
                productLvlRef: manualProductsTableRef,
                styleLvlRef: manualStyleLvlTableRef,
                productGroupLvlRef: manualProductGroupsTableRef,
              }}
              type="product"
              options={productGrpTypeOptions}
            />
          </div>
        </LoadingOverlay>
        <GroupTypeFilters
          ref={{
            productLvlRef: manualProductsTableRef,
            styleLvlRef: manualStyleLvlTableRef,
            productGroupLvlRef: manualProductGroupsTableRef,
          }}
        />
        <LoadingOverlay loader={props.isLoading} spinner>
          <FilteredProducts
            ref={{
              productLvlRef: manualProductsTableRef,
              styleLvlRef: manualStyleLvlTableRef,
              productGroupLvlRef: manualProductGroupsTableRef,
            }}
          />
        </LoadingOverlay>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.productGroupReducer.isLoading,
  };
};
export default connect(mapStateToProps, null)(CreateProductGroup);
