import React from "react";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import TabsComponent from "commonComponents/tabs";

function TabsComponentScreen(props) {
  return (
    <CoreComponentScreen
      pageLabel={props.pageLabel}
      showPageRoute={true}
      showPageHeader={true}
      routeAction={props.routeAction}
    >
      <TabsComponent
        tabPannelStyle={{ padding: "0px" }}
        tabContainerstyle={{ padding: "0px" }}
        tabHeadingStyle={{ padding: "0 2rem" }}
        tabsData={props.tabsComponentList}
      />
    </CoreComponentScreen>
  );
}

export default TabsComponentScreen;
