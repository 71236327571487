import Definition from "./Definition";
import { useHistory, Prompt } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  fetchGroupDefinitionById,
  ToggleLoader,
} from "pages/product-grouping/product-grouping-service";
import { connect } from "react-redux";
import LoadingOverlay from "../../../../Utils/Loader/loader";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { getPromptStatus } from "./common-functions";
const EditDefinition = (props) => {
  const history = useHistory();
  const [definitionObj, setdefinitionObj] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const getPrevScr = () => {
    if (history.location.pathname.includes("create-group")) {
      return "/product-grouping/create-group/group-definitions";
    } else if (history.location.pathname.includes("modify")) {
      return `/product-grouping/modify/${props.match.params.group_id}/group-definitions`;
    } else {
      return "/product-grouping/group-definitions";
    }
  };
  const prevScr = getPrevScr();
  let routeOptions = [];
  if (prevScr.includes("create-group")) {
    routeOptions = [
      {
        id: "product_grouping_home_scr",
        label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
        action: () => history.push("/product-grouping"),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "Create New Group",
        action: () => history.push(prevScr),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "View Definitions",
        action: () =>
          history.push({
            pathname: "/product-grouping/create-group/group-definitions",
          }),
      },
      {
        id: "product_grouping_definitions_edit_definition",
        label: "Edit definition",
        action: () => null,
      },
    ];
  } else if (prevScr.includes("modify")) {
    routeOptions = [
      {
        id: "product_grouping_home_scr",
        label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
        action: () => history.push("/product-grouping"),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "View Group",
        action: () =>
          history.push(`/product-grouping/view/${props.match.params.group_id}`),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "Modify Group",
        action: () =>
          history.push(
            `/product-grouping/modify/${props.match.params.group_id}`
          ),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "View Definitions",
        action: () =>
          history.push({
            pathname: prevScr,
          }),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "Edit definition",
        action: () => null,
      },
    ];
  } else {
    routeOptions = [
      {
        id: "product_grouping_home_scr",
        label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
        action: () => history.push("/product-grouping"),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "Grouping Definitions",
        action: () => history.push("/product-grouping/group-definitions"),
      },
      {
        id: "product_grouping_definitions_edit_definition",
        label: "edit definition",
        action: () => null,
      },
    ];
  }

  useEffect(() => {
    const fetchDefinitionData = async () => {
      try {
        props.ToggleLoader(true);
        const res = await props.fetchGroupDefinitionById(props.match.params.id);
        setdefinitionObj(res.data.data);
        props.ToggleLoader(false);
      } catch (error) {
        props.ToggleLoader(false);
        //error handling
      }
    };
    fetchDefinitionData();
  }, []);
  return (
    <>
      <Prompt
        message={(location) => {
          const condition =
            !location.pathname.includes("edit-definitions") && isEdited;
          return getPromptStatus(condition, location);
        }}
      />
      <LoadingOverlay loader={props.isLoading} spinner>
        <Definition
          type="edit"
          definition={definitionObj}
          routeOptions={routeOptions}
          prevScr={prevScr}
          setIsEdited={setIsEdited}
        />
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.productGroupReducer.isLoading,
  };
};
const mapActionsToProps = {
  fetchGroupDefinitionById,
  ToggleLoader,
};
export default connect(mapStateToProps, mapActionsToProps)(EditDefinition);
