import React, { lazy, Suspense } from "react";
import { useParams } from "react-router-dom";

function CustomPage() {
  const { file_name } = useParams();

  const File = lazy(() => import("../../custom-pages/" + file_name));

  return (
    <Suspense>
      <File></File>
    </Suspense>
  );
}

export default CustomPage;
