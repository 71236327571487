export const readonlyFormFields = [
  {
    label: "Channel : ",
    field_type: "readOnly",
    accessor: "channel",
  },
  {
    label: "Category : ",
    field_type: "readOnly",
    accessor: "l0_name",
  },
  {
    label: "Department : ",
    field_type: "readOnly",
    accessor: "l1_name",
  },
  {
    label: "Size : ",
    field_type: "readOnly",
    accessor: "size",
  },
];
export const checkValidation = (data, params) => {
  if (parseInt(data.min_store) > parseInt(data.max_store)) {
    if (params.column.colId === "min_store") {
      params.node.setDataValue("min_store", data.max_store);
    }
    if (params.column.colId === "max_store") {
      params.node.setDataValue("max_store", data.min_store);
    }
    return true;
  }
  return false;
};
