import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  setCreateGradeStep,
  setCreateGradeClassification,
} from "../grading-services";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import GRADING_STEPS from "../grading-constants/stringConstants";
import { replaceCharacter } from "Utils/formatter";
import globalStyles from "Styles/globalStyles";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";

const CreateGrade = (props) => {
  const globalClasses = globalStyles();
  const history = useHistory();
  const [gradeBy, setGradeBy] = useState("Clustering");
  const [steps, setSteps] = useState([]);
  const dispatch = useDispatch();
  const { createGradeStep } = useSelector(
    (store) => store?.createGradeReducer
  );
  const routeOptions = [
    {
      id: "store_grading",
      label: "Grading",
      action: () => history.push("/store-grading"),
    },
    {
      id: "store_create_grading",
      label: "Create Grading",
      action: () => null,
    },
  ];

  /**
   * @desc Initial setup on Primary Load
   */
  useEffect(() => {
    setStepperState();
    dispatch(setCreateGradeClassification(gradeBy));
  }, []);

  /**
   * @desc Set stepper state on change of grade
   */
  useEffect(() => {
    setStepperState();
    dispatch(setCreateGradeClassification(gradeBy));
  }, [gradeBy]);

  /**
   * @func
   * @desc Set the Create Grading Screen Flow based on selected Grading Type
   */
  const setStepperState = () => {
    if (gradeBy === "Clustering") {
      setSteps(GRADING_STEPS.clusterSteps);
    } else {
      setSteps(GRADING_STEPS.percentile);
    }
  };

  return (
    <>
      <HeaderBreadCrumbs options={routeOptions} />
      <Container maxWidth={false}>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.layoutAlignSpaceBetween} ${globalClasses.marginBottom}`}
        >
          <Typography
            variant="h3"
            component="h3"
            className={`${globalClasses.pageHeader} ${globalClasses.marginBottom}`}
          >
            Grading
          </Typography>
          <Link
            component={RouterLink}
            underline="none"
            to="/store-grading"
            variant="button"
          >
            Return to Store grading
          </Link>
        </div>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.verticalAlignCenter} ${globalClasses.marginBottom}`}
        >
          {!createGradeStep ? (
            <>
              <Typography variant="h6">Grade By</Typography>
              <RadioGroup
                row
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={gradeBy}
                onChange={(event) => setGradeBy(event.target.value)}
                className={globalClasses.marginLeft1rem}
              >
                <FormControlLabel
                  value="Clustering"
                  control={<Radio color="primary" id="StoreRadioBtn" />}
                  label="Clustering"
                />
                <FormControlLabel
                  value="Percentile"
                  control={<Radio color="primary" id="StoreGroupRadioBtn" />}
                  label="Percentile"
                />
              </RadioGroup>
            </>
          ) : null}
        </div>
      </Container>
      <Stepper activeStep={createGradeStep}>
        {steps.map((step, index) => {
          return (
            <Step key={step.screenCode}>
              <StepButton
                onClick={() => {
                  if (index !== createGradeStep) {
                    dispatch(setCreateGradeStep(index));
                  }
                }}
                disabled={index > createGradeStep}
                id={`${replaceCharacter(
                  step.screenCode.toLowerCase(),
                  / /g,
                  "-"
                )}-stepperBtn`}
              >
                {step.label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <Container maxWidth={false}>
        <div className={globalClasses.marginBottom}>
          {steps[createGradeStep]?.screen}
        </div>
      </Container>
    </>
  );
};

export default CreateGrade;
