import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    border: `1px solid ${theme.palette.textColours.tiara}`,
    margin: `1rem 0`,
    padding: `0.5rem`,
    borderRadius: "3px",
  },
  summaryChip: {
    margin: "0.5rem",
  },
  camelCase: {
    textTransform: "capitalize",
    display: "inline-block",
    color: `${theme.palette.textColours.slateGrayLight}`,
  },
}));
const FiltersSummary = ({ filtersSummary }) => {
  const classes = useStyles();

  const getChips = (dimension) => {
    if (filtersSummary?.[dimension]?.length) {
      return (
        <>
          <span className={classes.camelCase}>{dimension}</span>
          {Array.isArray(filtersSummary?.[dimension]?.[0]) ? (
            filtersSummary?.[dimension]?.map((section) => {
              let label = "";
              label = section?.[0]?.label;
              if (section?.length > 1) label += ` +${section?.length - 1}`;
              return (
                <>
                  <Chip className={classes.summaryChip} label={label} />
                </>
              );
            })
          ) : (
            <>
              <Chip
                className={classes.summaryChip}
                label={
                  filtersSummary?.[dimension]?.length > 1
                    ? `${filtersSummary?.[dimension]?.[0]?.label} +${
                        filtersSummary?.[dimension]?.length - 1
                      }`
                    : filtersSummary?.[dimension]?.[0]?.label
                }
              />
            </>
          )}
        </>
      );
    }
  };

  return (
    <div className={classes.summaryContainer}>
      {Object.keys(filtersSummary)?.map((dimension) => getChips(dimension))}
    </div>
  );
};

export default FiltersSummary;
