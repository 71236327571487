import React from "react";
import FiltersSummary from "commonComponents/filterModal/filters-summary";

const FilterChips = ({ filterConfig, dateFilter }) => {
  let filtersSummary = filterConfig.reduce((accumulator, currentConfig) => {
    if (accumulator[currentConfig.dimension]) {
      accumulator[currentConfig.dimension] = [
        ...accumulator[currentConfig.dimension],
        currentConfig.values,
      ];
    } else {
      accumulator[currentConfig.dimension] = [currentConfig.values];
    }
    return accumulator;
  }, {});

  // Append date filter incase of custom date ranges
  if (dateFilter) {
    filtersSummary = {
      ...filtersSummary,
      Date: [{ label: `${dateFilter?.start_date} to ${dateFilter?.end_date}` }],
    };
  }

  return <FiltersSummary filtersSummary={filtersSummary} />;
};

export default FilterChips;
