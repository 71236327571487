import { addSnack } from "actions/snackbarActions";
import { isEmpty, uniqBy } from "lodash";
import {
  ERROR_MESSAGE,
  NO_DATA_FOUND,
  POP_UP_TYPE,
  tableArticleFilter,
  ROLES_ACCESS_MODULES_MAPPING,
  APP_NAME,
  FULL_ACCESS_PERMISSIONS_LIST,
  INVENTORY_SUBMODULES_NAMES,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import {
  getProductRuleTableData,
  getRuleHeaderConfiguration,
  setProductRuleTableLoader,
  setSelectedFilters,
} from "modules/inventorysmart/services-inventorysmart/Product-Profile/product-rule-services";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import AgGridComponent from "Utils/agGrid";
import { agGridRowFormatter } from "Utils/agGrid/row-formatter";
import Loader from "Utils/Loader/loader";
import ProductRulePopUp from "./ProductRulePopUp";
import {
  getModuleLevelAccess,
  setInventorySmartModulesPermissions,
  setInventorySmartPermissionLoader,
} from "modules/inventorysmart/services-inventorysmart/common/inventory-smart-common-services";
import { isActionAllowedOnSubModule } from "../../inventorysmart-utility";

const ProductRuleTable = (props) => {
  const [agGridInstance, setAgGridInstance] = useState({});
  const [userTableColumns, setUserTableColumns] = useState([]);
  const [render, setRender] = useState(true);

  // pop up states
  const [open, setOpen] = useState(false);
  const [popUpColumnData, setPopUpColumnData] = useState([]);
  const [popUpROWData, setPopUpROWData] = useState([]);

  useEffect(() => {
    if (props.inventorysmartScreenConfig) {
      const fetchModulesAccess = async () => {
        try {
          props.setInventorySmartPermissionLoader(true);
          // props.module is fetched  from routes
          const moduleName = props?.module;
          const subModules = ROLES_ACCESS_MODULES_MAPPING[props?.module];

          let rolesBasedModulesPermission = {};

          // identifying if its for vb or signet
          if (props.inventorysmartScreenConfig.roleBasedAccess) {
            await Promise.all(
              subModules.map(async (module) => {
                const accessDataResponse = await props?.getModuleLevelAccess({
                  app: APP_NAME,
                  module,
                });

                rolesBasedModulesPermission[module] = Object.keys(
                  accessDataResponse.data.data
                );
              })
            );
          } else {
            subModules.map(async (subModule) => {
              rolesBasedModulesPermission[subModule] =
                FULL_ACCESS_PERMISSIONS_LIST;
            });
          }
          props?.setInventorySmartModulesPermissions({
            [moduleName]: rolesBasedModulesPermission,
          });
        } catch (error) {
          displaySnackMessages(ERROR_MESSAGE, "error");
        } finally {
          props.setInventorySmartPermissionLoader(false);
        }
      };
      fetchModulesAccess();
    }
  }, [props.inventorysmartScreenConfig]);

  //fetch column header of grid
  useEffect(() => {
    setRender(false);
    const fetchColumnData = async () => {
      try {
        let cols = await getRuleHeaderConfiguration(
          "inventorysmart_product_rules"
        )();
        let permissionCheck = canTakeActionOnModules(
          INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_RULES,
          "edit"
        );
        if (permissionCheck) {
          cols = cols.map((item) => {
            item.onClick = (tableInfo) => {
              setPopUpColumnData(tableInfo?.cellData?.colDef || {});
              setPopUpROWData(tableInfo?.cellData?.data || {});
              openModal();
            };
            return item;
          });
        } else {
          cols.forEach((item) => {
            if (item.type === "link") {
              item.type = "str";
              item.is_editable = false;
              item.editable = false;
              item.cellRenderer = null;
            }
          });
        }
        setUserTableColumns(cols);
        setRender(true);
      } catch (err) {
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    fetchColumnData();
  }, [props.selectedRuleFilters]);

  const loadTableInstance = (params) => {
    setAgGridInstance(params);
  };

  const manualCallBack = async (manualBody, pageIndex = 0, params = []) => {
    props.setProductRuleTableLoader(true);
    let filter = props.selectedRuleFilters;

    if (pageIndex === 0 && props.isRedirectedFromDifferentPage) {
      let articleFilter = tableArticleFilter;
      articleFilter.values = [...props.selectedRulesArticles];

      filter = [...filter, articleFilter];
    }

    let body = {
      filters: [...filter],
      application_code: props.applicationObjectList[0]?.application_code || 0,
      meta: {
        ...manualBody,
        limit: { limit: 10, page: pageIndex + 1 },
      },
    };
    try {
      let response = await props.getProductRuleTableData(body);
      //agGridRowFormatter should be removed once it's handled from BE
      //to handle it from BE pass params.api.checkConfiguration in request body to the above api call
      if (response?.data?.length > 0) {
        let formattedData = agGridRowFormatter(
          response?.data,
          params?.api?.checkConfiguration,
          "article"
        );

        props.setProductRuleTableLoader(false);

        return {
          data: formattedData,
          totalCount: response.total,
        }; // returning for server side pagination on ag grid
      } else {
        props.setProductRuleTableLoader(false);
        displaySnackMessages(NO_DATA_FOUND, "success");
        return {
          data: [],
          totalCount: 0,
        };
      }
    } catch (err) {
      props.setProductRuleTableLoader(false);
      displaySnackMessages(ERROR_MESSAGE, "error");
      return [];
    }
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isEmpty(props.savePayloadForPopUp)) {
      agGridInstance?.api?.forEachNode((node) => {
        // TO DO : update for 3 type of columns
        if (props.savePayloadForPopUp?.selection_type === POP_UP_TYPE.dc) {
          if (node?.data?.ph_code === props.savePayloadForPopUp?.ph_code) {
            node.data.dc_mapped =
              props.savePayloadForPopUp.selected_values.length;
            node.data.default_dcs = props.savePayloadForPopUp.selected_values;
          }
        }
        if (
          props.savePayloadForPopUp?.selection_type ===
          POP_UP_TYPE.product_profile
        ) {
          if (node?.data?.ph_code === props.savePayloadForPopUp?.ph_code) {
            // display default value "-" if pp name is unselected
            node.data.product_profile_name =
              props.savePayloadForPopUp?.selectedRowData[0]?.name === undefined
                ? "-"
                : props.savePayloadForPopUp?.selectedRowData[0]?.name;
            node.data.default_product_profile =
              props.savePayloadForPopUp?.selected_values;
          }
        }

        if (props.savePayloadForPopUp?.selection_type === POP_UP_TYPE.store) {
          if (node.data.ph_code === props.savePayloadForPopUp.ph_code) {
            let totalStores = uniqBy(
              props.savePayloadForPopUp?.selectedRowData,
              function (e) {
                return e.store_code;
              }
            );
            let totalGroups =
              props.savePayloadForPopUp?.selected_values?.length;

            node.data.store_group_mapped_display =
              totalGroups + " / " + totalStores.length;

            node.data.default_store_groups =
              props.savePayloadForPopUp?.selected_values;
          }
        }
      });
      agGridInstance?.api?.redrawRows();
    }
  }, [props.savePayloadForPopUp]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props.inventorysmartModulesPermission,
      props.module,
      subModuleName,
      action
    );
  };

  return (
    <>
      <Loader
        loader={
          props.productRuleTableLoader || props.inventorySmartPermissionLoader
        }
        minHeight={"350px"}
      >
        {render && (
          <AgGridComponent
            uniqueRowId={"article"}
            columns={userTableColumns}
            onRowSelected
            manualCallBack={(body, pageIndex, params) =>
              manualCallBack(body, pageIndex, params)
            }
            rowModelType="serverSide"
            serverSideStoreType="partial"
            cacheBlockSize={10}
            loadTableInstance={loadTableInstance} // to make use of available grid api's
          />
        )}
      </Loader>
      <ProductRulePopUp
        active={open}
        openModal={openModal}
        closeModal={closeModal}
        filters={props.selectedRuleFilters}
        popUpColumnData={popUpColumnData}
        popUpROWData={popUpROWData}
        manualCallBack={manualCallBack}
        agGridInstance={agGridInstance}
        setProductRuleTableLoader={props.setProductRuleTableLoader}
      />
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    productRuleTableLoader:
      store.inventorysmartReducer.productRuleService.productRuleTableLoader,
    selectedRuleFilters:
      store.inventorysmartReducer.productRuleService.selectedRuleFilters,
    savePayloadForPopUp:
      store.inventorysmartReducer.productRuleService.savePayloadForPopUp,
    selectedStoreGroupDataPopUp:
      store.inventorysmartReducer.productRuleService
        .selectedStoreGroupDataPopUp,
    applicationObjectList:
      store.inventorysmartReducer.productRuleService.applicationObjectList,
    selectedRulesArticles:
      store.inventorysmartReducer.productRuleService.selectedRulesArticles,
    inventorySmartPermissionLoader:
      store.inventorysmartReducer.inventorySmartCommonService
        ?.inventorySmartPermissionLoader,
    inventorysmartModulesPermission:
      store.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
    inventorysmartScreenConfig:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSnack: (snack) => dispatch(addSnack(snack)),

  selectedFilters: (body) => dispatch(setSelectedFilters(body)),
  setProductRuleTableLoader: (body) =>
    dispatch(setProductRuleTableLoader(body)),
  getProductRuleTableData: (body) => dispatch(getProductRuleTableData(body)),
  getModuleLevelAccess: (payload) => dispatch(getModuleLevelAccess(payload)),
  setInventorySmartPermissionLoader: (payload) =>
    dispatch(setInventorySmartPermissionLoader(payload)),
  setInventorySmartModulesPermissions: (payload) =>
    dispatch(setInventorySmartModulesPermissions(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductRuleTable);
