import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Typography, Container } from "@mui/material";
import { isEmpty } from "lodash";
import Loader from "Utils/Loader/loader";
import globalStyles from "Styles/globalStyles";
import { common } from "modules/react-demo/constants/stringContants";
import { addSnack } from "actions/snackbarActions";
import { userManagementRoutes } from "config/routes";
import {
  getRolesMappedToUserData,
  setRolesMappedToUserData,
  getFilterConfiguration,
  getUserFilterAttributes,
  getTableHierarchyOnAssignRole,
  getFilteredAttributesToAssignRole,
  setAssignRoleUserRoleMgmtLoader,
  setAssignRoleFilterConfiguration,
  setTableHierarchyOnAssignRole,
  saveUserRoleMappings,
  updateUserRoleMappings,
  getTableConfig,
  getUserDetailsFormFilter,
  getCrossFiltersData,
} from "../../services/TenantManagement/User-Role-Management/user-role-management-service";
import {
  getUserDetails,
  setUserTableDetails,
  setLocationStateValue,
} from "../../services/TenantManagement/User-Management/user-management-service";
import UserFilters from "./user-filters";
import UserDetailsFilterForm from "./user-details-filter-form";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";

const AssignRole = (props) => {
  const globalClasses = globalStyles();
  // to set selected values for submission
  const [formValues, setFormValues] = useState({
    users_id: [],
  });
  const [formFilterValues, setFormFilterValues] = useState({
    application: [],
    role: "",
  });
  const [tableValues, setTableValues] = useState([]);
  const [unSelectedTableValues, setUnSelectedTableValues] = useState([]);
  const [confirmDeleteRoles, setConfirmDeleteRoles] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [disableRevokeButton, setDisableRevokeButton] = useState(false);
  const [confirmCloseAssignRole, setConfirmCloseAssignRole] = useState(false);
  const [hierarchyTableData, setHierarchyTableData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    if (!isEmpty(props.assignRoleFilterList) && !formLoader && !filterLoader) {
      props.setAssignRoleUserRoleMgmtLoader(false);
    } else {
      props.setAssignRoleUserRoleMgmtLoader(true);
    }
  }, [props.assignRoleFilterList, formLoader, filterLoader]);

  useEffect(() => {
    if (tableValues.length > 0) {
      // tableValues is a list of hierarchies fetched on edit (pre selected hierarchies)
      // if the current rows rendered on table based on filters do not have any pre selections, but there are pre selected hierarchies on other filters.
      // disable the button
      if (
        hierarchyTableData.filter((item) =>
          tableValues.some((obj) => obj.id === item.id)
        ).length
      )
        setDisableRevokeButton(false);
    } else setDisableRevokeButton(true);
  }, [tableValues, hierarchyTableData]);

  useEffect(() => {
    // to handle user filter and table component visibility on edit
    if (
      formFilterValues.role === "superuser" ||
      formFilterValues.role === "admin"
    )
      setFilterLoader(false);
  }, [formFilterValues]);

  const saveRowSelectionsRoleMappings = (selectedRows, allRows) => {
    setTableValues(selectedRows);
    // deselections and other rows of table data of a selected filter are stored
    let unSelectedList = allRows.filter(
      (obj) => !selectedRows.some((item) => item.id === obj.id)
    );
    setUnSelectedTableValues(unSelectedList);
  };

  const saveFormFilterValues = (data) => {
    if (data.length) {
      let applicationName = [],
        roleName = "",
        users = [];
      data.forEach((item) => {
        if (item.attribute_name === "user_email") {
          users = item.values;
        }
        if (item.attribute_name === "role_name") {
          roleName = item.values[0];
        }
        if (item.attribute_name === "application_name") {
          applicationName = item.values;
        }
      });
      setFormValues({
        ...formValues,
        users_id: users,
      });
      setFormFilterValues({
        ...formFilterValues,
        application: applicationName,
        role: roleName,
      });
    }
  };

  const displaySnackMessages = (message, variance, onClose) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
        ...(onClose && { onClose: onClose }),
      },
    });
  };

  const submitRoleMappings = async () => {
    let formCondition = Object.values(formValues).some((obj) => isEmpty(obj)); // checks if users field is empty
    let formFilterCondition = Object.values(formFilterValues).some(
      (
        obj // checks if application and role field is empty
      ) => isEmpty(obj)
    );

    let roleNameCondition =
      formFilterValues.role === "superuser" ||
      formFilterValues.role === "admin";
    /*
      on edit if the value of role is superuser/admin/bnm/approver, selectedRowCondition should be false 
      as for superuser or admin the hierarchy list should be empty, for other roles there are already few preselected
      on revoke we need to check the length based on role so make use of roleNameCondition
    */
    let selectedRowCondition = roleNameCondition
      ? false
      : props.formDataOnEdit
      ? false // if its in edit mode, based on the role check the hierarchy length
      : tableValues.length === 0;
    if (formCondition || formFilterCondition || selectedRowCondition) {
      displaySnackMessages(
        "Select mandatory fields and hierarchies to assign",
        "error"
      );
    } else {
      props.setAssignRoleUserRoleMgmtLoader(true);
      let body = {
        users_id: formValues.users_id,
        attributes: [
          {
            application: formFilterValues.application,
            role: formFilterValues.role,
            access_hierarchy: roleNameCondition ? [] : tableValues,
          },
        ],
        // new changes
        filters: selectedFilters,
      };
      try {
        if (props.formDataOnEdit) {
          callUpdateRolesFunc(roleNameCondition);
        } else {
          let response = await props.saveUserRoleMappings(body);
          props.setLocationStateValue("manage_user_role");
          displaySnackMessages(response.data.message, "success", () =>
            props.history.push(userManagementRoutes.user)
          );
          props.setAssignRoleUserRoleMgmtLoader(false);
        }
      } catch (err) {
        props.setAssignRoleUserRoleMgmtLoader(false);
        displaySnackMessages(
          !isEmpty(err.response.data.message)
            ? err.response.data.message
            : "Something went wrong",
          "error"
        );
      }
    }
  };

  const updateIndividualHierarchiesOnSetAll = (selectedRowCondition) => {
    let combinedMultiUserList = props.formDataOnEdit.map((item) => {
      return {
        ...item,
        department_name: [...item.department_name, ...tableValues],
      };
    });

    let combinedUniqueFilterList = combinedMultiUserList.map((item) => {
      return {
        ...item,
        department_name: item.department_name.filter(
          (value, index, arr) =>
            index === arr.findIndex((t) => t.id === value.id)
        ),
      };
    });
    // filtering out for deselected records of a category or dept
    let uniqueListBasedOnDeselections = combinedUniqueFilterList.map((item) => {
      return {
        ...item,
        department_name: item.department_name.filter(
          (obj) => !unSelectedTableValues.some((item) => item.id === obj.id)
        ),
      };
    });

    if (!selectedRowCondition && isEmpty(uniqueListBasedOnDeselections)) {
      props.setAssignRoleUserRoleMgmtLoader(false);
      displaySnackMessages(
        "You are trying to revoke access to all assigned hierarchies, selection of hierarchy is mandatory",
        "error"
      );
    } else {
      let body = uniqueListBasedOnDeselections
        .map((item, i) => {
          if (formValues.users_id.includes(item.user_code)) {
            return {
              users_id: [item.user_code],
              attributes: [
                {
                  application: formFilterValues.application,
                  role: formFilterValues.role,
                  hierarchy_id: [item.hierarchy_id],
                  access_hierarchy:
                    formFilterValues.role === "superuser" ||
                    formFilterValues.role === "admin"
                      ? []
                      : item.department_name, // final depts values
                },
              ],
            };
          }
        })
        .filter((obj) => obj);
      let updatedBody = {
        updateBody: body,
      };
      saveUpdatedUserHierarchyData(uniqueListBasedOnDeselections, updatedBody);
    }
  };

  const callUpdateRolesFunc = (selectedRowCondition) => {
    // based on single and multiple edits
    let recordIds = [];
    let preselectedTableRecords = [];
    if (Array.isArray(props.formDataOnEdit)) {
      updateIndividualHierarchiesOnSetAll(selectedRowCondition);
    } else {
      recordIds[0] = props.formDataOnEdit.hierarchy_id;
      preselectedTableRecords = props.formDataOnEdit?.department_name;

      // combining existing hierarchies and new selections
      let combinedList = [...preselectedTableRecords, ...tableValues];
      // removing duplicates
      let combinedUniqueList = combinedList.filter(
        (value, index, arr) => index === arr.findIndex((t) => t.id === value.id)
      );
      // filtering out for deselected records of a category or dept
      let listBasedOnDeselections = combinedUniqueList.filter(
        (obj) => !unSelectedTableValues.some((item) => item.id === obj.id)
      );
      // if the role is not superuser/admin(false) then check the length
      if (!selectedRowCondition && isEmpty(listBasedOnDeselections)) {
        props.setAssignRoleUserRoleMgmtLoader(false);
        displaySnackMessages(
          "You are trying to revoke access to all assigned hierarchies, selection of hierarchy is mandatory",
          "error"
        );
      } else {
        let updatedBody = {
          updateBody: [
            {
              users_id: formValues.users_id,
              attributes: [
                {
                  application: formFilterValues.application,
                  role: formFilterValues.role,
                  hierarchy_id: recordIds,
                  access_hierarchy:
                    formFilterValues.role === "superuser" ||
                    formFilterValues.role === "admin"
                      ? []
                      : listBasedOnDeselections,
                },
              ],
            },
          ],
        };
        saveUpdatedUserHierarchyData(listBasedOnDeselections, updatedBody);
      }
    }
  };

  const saveUpdatedUserHierarchyData = async (
    listBasedOnDeselections,
    updatedBody
  ) => {
    // condition to check if the user has made any new edits if not do not allow him to save
    let noChangeInDataOnEdit = false,
      userNameCond = false,
      roleNameCond = false,
      applicationNameCond = false,
      hierarchyChangesCond = false;
    // to make changes here
    if (Array.isArray(props.formDataOnEdit)) {
      let userIdsList = updatedBody.updateBody.map((item) => item.users_id[0]);
      // check the length of both arrays
      let userIdsFromEdit = props.formDataOnEdit.map((item) => item.user_code);
      userIdsFromEdit.sort((a, b) => a - b);
      userIdsList.sort((a, b) => a - b);
      // Order matters - sorting for comparison
      userNameCond =
        JSON.stringify(userIdsFromEdit) === JSON.stringify(userIdsList);
      // state will be populated only when all user's have same role and application name
      // fetching first data as role and application name on onchange would be same for all records
      // empty condition is being checked at the top
      roleNameCond =
        updatedBody.updateBody[0].attributes[0].role ===
        props.formDataOnEdit[0].role_name;
      applicationNameCond =
        updatedBody.updateBody[0].attributes[0].application[0] ===
        props.formDataOnEdit[0].application_name;
      // checking if no new records are added
      // if condition fails, check with id to fetch same records
      hierarchyChangesCond = hierarchyListChanges(
        listBasedOnDeselections[0].department_name,
        props.formDataOnEdit[0].department_name
      );
    } else {
      userNameCond =
        // as only one user is present in the dropdown when its a single edit comparing its value
        updatedBody.updateBody[0].users_id[0] ===
        props.formDataOnEdit.user_code;
      roleNameCond =
        updatedBody.updateBody[0].attributes[0].role ===
        props.formDataOnEdit.role_name;
      applicationNameCond =
        updatedBody.updateBody[0].attributes[0].application[0] ===
        props.formDataOnEdit.application_name;
      hierarchyChangesCond = hierarchyListChanges(
        listBasedOnDeselections,
        props.formDataOnEdit.department_name
      );
    }
    if (
      userNameCond &&
      roleNameCond &&
      applicationNameCond &&
      hierarchyChangesCond
    ) {
      noChangeInDataOnEdit = true;
      displaySnackMessages("No changes to save", "info");
      props.setAssignRoleUserRoleMgmtLoader(false);
    }
    if (!noChangeInDataOnEdit) {
      try {
        let response = await props.updateUserRoleMappings(updatedBody);
        displaySnackMessages(response.data.message, "success");
        props.setAssignRoleUserRoleMgmtLoader(false);
        // to close pop up modal on edit
        props.onCancel();
        let postBody = {
          meta: {
            search: [],
          },
          filters: [],
        };
        let responseObject = await props.getRolesMappedToUserData(postBody);
        props.setRolesMappedToUserData(responseObject.data.data);
      } catch (err) {
        props.setAssignRoleUserRoleMgmtLoader(false);
        displaySnackMessages(
          !isEmpty(err.response.data.message)
            ? err.response.data.message
            : "Something went wrong",
          "error"
        );
      }
    }
  };

  const hierarchyListChanges = (listBasedOnDeselections, editData) => {
    let hierarchyListArr1,
      hierarchyListArr2 = [];
    if (listBasedOnDeselections.length > editData.length) {
      hierarchyListArr1 = listBasedOnDeselections;
      hierarchyListArr2 = editData;
    } else {
      hierarchyListArr1 = editData;
      hierarchyListArr2 = listBasedOnDeselections;
    }
    let changeInHierarchyRecord = hierarchyListArr1.filter(
      (item1) => !hierarchyListArr2.some((item2) => item1.id === item2.id)
    );
    // change in length denotes - new hierarchy/hierarchies are added or existing hierarchies have been removed
    // else no changes made
    return changeInHierarchyRecord.length ? false : true;
  };

  const confirmDeleteHierarchyRecords = () => {
    setConfirmDeleteRoles(false);
    // the one's that are to be revoked
    let commonDepts = hierarchyTableData
      .filter((obj) => tableValues.some((item) => item.id === obj.id))
      .map((item) => {
        // to deselect revoked rows - adding a condition
        return { ...item, [item.id]: false };
      });
    let otherDepts = hierarchyTableData.filter(
      (obj) => !tableValues.some((item) => item.id === obj.id)
    );
    // set state as this info is used in child component to set rowselection
    setHierarchyTableData([...commonDepts, ...otherDepts]);
  };

  const deleteRoleAssigned = () => {
    setConfirmDeleteRoles(true);
  };

  const setLoadingOfFormElements = (value) => {
    setFormLoader(value);
  };

  const setLoadingOfHierarchyFilters = (value) => {
    setFilterLoader(value);
  };

  const closeAssignRole = () => {
    props.history.push(userManagementRoutes.user);
    props.setLocationStateValue("manage_user_role");
  };

  const storeHierarchyTableListOnEdit = (hierarchies) => {
    setHierarchyTableData(hierarchies);
  };

  const onCancelAssignRoles = () => {
    let formCondition = Object.values(formValues).some((obj) => isEmpty(obj));
    let formFilterCondition = Object.values(formFilterValues).some((obj) =>
      isEmpty(obj)
    );
    let roleNameCondition =
      formFilterValues.role === "superuser" ||
      formFilterValues.role === "admin";
    let selectedRowCondition = roleNameCondition
      ? false
      : props.formDataOnEdit
      ? false
      : tableValues.length === 0;
    if (formCondition && formFilterCondition && selectedRowCondition) {
      closeAssignRole();
    } else {
      setConfirmCloseAssignRole(true);
    }
  };

  const selectedFiltersDependency = (value) => {
    setSelectedFilters(value);
  };

  return (
    <>
      {props.hideIconButtonsOnEdit ? (
        <Typography variant="h5">Access Control and User Management</Typography>
      ) : (
        <HeaderBreadCrumbs
          options={[
            {
              label: "Access Control and User Management",
              id: 1,
            },
          ]}
        ></HeaderBreadCrumbs>
      )}
      <Loader loader={props.assignRoleUserRoleMgmtLoader}>
        <Container maxWidth={false}>
          <UserDetailsFilterForm
            getUserDetails={props.getUserDetails}
            setUserTableDetails={props.setUserTableDetails}
            userList={props.userList}
            getUserFilterAttributes={props.getUserFilterAttributes}
            saveFormFilterValues={saveFormFilterValues}
            hideIconButtonsOnEdit={props.hideIconButtonsOnEdit}
            formDataOnEdit={props.formDataOnEdit}
            setLoadingOfFormElements={setLoadingOfFormElements}
            addSnack={props.addSnack}
            getUserDetailsFormFilter={props.getUserDetailsFormFilter}
          />
          {formFilterValues.role === "superuser" ||
          formFilterValues.role === "admin" ? null : (
            <UserFilters
              setAssignRoleUserRoleMgmtLoader={
                props.setAssignRoleUserRoleMgmtLoader
              }
              getFilterConfiguration={props.getFilterConfiguration}
              setAssignRoleFilterConfiguration={
                props.setAssignRoleFilterConfiguration
              }
              assignRoleFilterList={props.assignRoleFilterList}
              assignRoleToUserTableData={props.assignRoleToUserTableData}
              getTableHierarchyOnAssignRole={
                props.getTableHierarchyOnAssignRole
              }
              setTableHierarchyOnAssignRole={
                props.setTableHierarchyOnAssignRole
              }
              getFilteredAttributesToAssignRole={
                props.getFilteredAttributesToAssignRole
              }
              saveRowSelectionsRoleMappings={(selectedRows, allRows) =>
                saveRowSelectionsRoleMappings(selectedRows, allRows)
              }
              getTableConfig={props.getTableConfig}
              formDataOnEdit={props.formDataOnEdit}
              addSnack={props.addSnack}
              setLoadingOfHierarchyFilters={setLoadingOfHierarchyFilters}
              hierarchyTableListOnEdit={storeHierarchyTableListOnEdit}
              hierarchyTableData={hierarchyTableData}
              selectedFiltersDependency={selectedFiltersDependency}
              getCrossFiltersData={props.getCrossFiltersData}
            />
          )}
          <Typography
            variant="h6"
            className={`${globalClasses.marginVertical}`}
            color="error"
          >
            Note: If selected users already have access to application and role
            then any assignment further on will overwrite previous assignments.
          </Typography>
          {props.hideIconButtonsOnEdit ? (
            <div
              className={`${globalClasses.flexRow} ${globalClasses.layoutAlignCenter} ${globalClasses.gap}`}
            >
              <Button
                onClick={() => submitRoleMappings()}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
              <Button
                onClick={() => setConfirmCloseAssignRole(true)}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              {formFilterValues.role === "superuser" ||
              formFilterValues.role === "admin" ? null : (
                <Button
                  onClick={() => deleteRoleAssigned()}
                  variant="outlined"
                  disabled={disableRevokeButton}
                  color="warning"
                >
                  Revoke Access
                </Button>
              )}
            </div>
          ) : (
            <div
              className={`${globalClasses.flexRow} ${globalClasses.layoutAlignCenter} ${globalClasses.gap}`}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={submitRoleMappings}
              >
                Assign
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={onCancelAssignRoles}
              >
                Cancel
              </Button>
            </div>
          )}
          <ConfirmPrompt
            showModal={confirmDeleteRoles}
            title="Revoke Access"
            message="Are you sure you want to revoke access?"
            ariaLabeledBy="revoke-access-dialog"
            primaryBtnText={common.__ConfirmBtnText}
            secondaryBtnText={common.__RejectBtnText}
            showCloseIcon={true}
            setConfirm={setConfirmDeleteRoles}
            confirmCallback={(val) => {
              if (val) {
                confirmDeleteHierarchyRecords();
              }
            }}
          />
          <ConfirmPrompt
            showModal={confirmCloseAssignRole}
            title="Assign Roles"
            message="Your changes will be discarded if you proceed. Are you sure you want to close?"
            ariaLabeledBy="assign-roles-dialog"
            primaryBtnText={common.__ConfirmBtnText}
            secondaryBtnText={common.__RejectBtnText}
            showCloseIcon={true}
            setConfirm={setConfirmCloseAssignRole}
            confirmCallback={(val) => {
              if (val) {
                props.hideIconButtonsOnEdit
                  ? props.onCancel()
                  : closeAssignRole();
              }
            }}
          />
        </Container>
      </Loader>
    </>
  );
};

const mapStateToProps = (store) => {
  const { tenantUserRoleMgmtReducer } = store;
  return {
    assignRoleUserRoleMgmtLoader:
      tenantUserRoleMgmtReducer.userRoleManagementReducer
        .assignRoleUserRoleMgmtLoader,
    assignRoleFilterList:
      tenantUserRoleMgmtReducer.userRoleManagementReducer.assignRoleFilterList,
    userList: tenantUserRoleMgmtReducer.userManagementReducer.listOfUserData,
    assignRoleToUserTableData:
      tenantUserRoleMgmtReducer.userRoleManagementReducer.assignRoleToUserTable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRolesMappedToUserData: (body) =>
      dispatch(getRolesMappedToUserData(body)),
    setRolesMappedToUserData: (body) =>
      dispatch(setRolesMappedToUserData(body)),
    addSnack: (snack) => dispatch(addSnack(snack)),
    getFilterConfiguration: () => dispatch(getFilterConfiguration()),
    getUserDetails: (body) => dispatch(getUserDetails(body)),
    setUserTableDetails: (body) => dispatch(setUserTableDetails(body)),
    getUserFilterAttributes: (body) => dispatch(getUserFilterAttributes(body)),
    getTableHierarchyOnAssignRole: (body) =>
      dispatch(getTableHierarchyOnAssignRole(body)),
    getFilteredAttributesToAssignRole: (body) =>
      dispatch(getFilteredAttributesToAssignRole(body)),
    setAssignRoleUserRoleMgmtLoader: (body) =>
      dispatch(setAssignRoleUserRoleMgmtLoader(body)),
    setAssignRoleFilterConfiguration: (body) =>
      dispatch(setAssignRoleFilterConfiguration(body)),
    setTableHierarchyOnAssignRole: (body) =>
      dispatch(setTableHierarchyOnAssignRole(body)),
    saveUserRoleMappings: (body) => dispatch(saveUserRoleMappings(body)),
    updateUserRoleMappings: (body) => dispatch(updateUserRoleMappings(body)),
    getTableConfig: (body) => dispatch(getTableConfig(body)),
    setLocationStateValue: (body) => dispatch(setLocationStateValue(body)),
    getUserDetailsFormFilter: (body) =>
      dispatch(getUserDetailsFormFilter(body)),
    getCrossFiltersData: (body) => dispatch(getCrossFiltersData(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignRole);
