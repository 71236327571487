import proj4 from "proj4";
import mapData from "@highcharts/map-collection/countries/us/us-all.geo.json";

export const mapviewOptionsData = (props) => {
  return {
    chart: {
      map: "countries/us/us-all",
      proj4,
    },
    title: {
      text: "",
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    series: [
      {
        name: "Countries",
        nullColor: "#fff",
        showInLegend: false,
        mapData: mapData,
      },
      ...props?.series
    ]
  };
};
