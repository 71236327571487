import { getFiscalCalendar } from "modules/ada/services-ada/ada-dashboard/ada-dashboard-services";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

export const APP_NAME = "inventorysmart";
export const ERROR_MESSAGE = "Something Went Wrong!!";
export const BLANK_LIST = "Select at least one value";
export const NO_DATA_FOUND = "No Data Found!";
export const RANGE_FILTER_ERROR_MESSAGE = "Select both start and end dates!!";
export const SUCCESS_MESSAGE = "Successfully fetched";
export const UPDATED_MESSAGE = "Successfully Updated";
export const API_SUCCESS_MESSAGE_FINALIZE = "Allocation Finalized Successfully";
export const FUTURE_DATE_RANGE_ERROR =
  "Future dates selection are not applicable!!";
export const ALLOCATION_IN_EACHES_MESSAGE =
  "Allocation will be done in Eaches!!";

export const FILL_MANDATORY_FIELDS = "Please fill mandatory fields.";
export const MIN_ARTICLE_SELECTION_MESSAGE =
  "Please select atleast one article to continue";
export const DELETE_MESSAGE = "Are you sure you want to delete";
export const DIALOG_REJECT_BTN_TEXT = "No";
export const DIALOG_CONFIRM_BTN_TEXT = "Yes";
export const DIALOG_APPLY_BTN_TEXT = "Apply";
export const DIALOG_CANCEL_BTN_TEXT = "Cancel";
export const DIALOG_FINALIZE_BTN_TEXT = "Finalize";

// labels for headerbreadcrumbs
export const DECISION_DASHBOARD = "Decision Dashboard";
export const CREATE_ALLOCATION = "Create New Allocation";

// labels for dashboard filter
export const FILTER_BUTTON_LABEL = "Filter";
export const RESET_BUTTON_LABEL = "Reset";
export const DASHBOARD_FILTERS_HEADER = "Product Filters";
export const ORDER_BATCHING_FILTERS_HEADER = "Filters";

// labels for dashboard - view plan table
export const VIEW_PLANS = "View Plans";
export const STORE_INVENTORY_ALERT = "Store Inventory Alert";
export const DETAILS = "Details";
export const CREATE_NEW_PLAN = "Create New Allocation Plan";

//Sorting Order
export const DESC_ORDER = "desc";
export const ASC_ORDER = "asc";

// Create Allocation form fields
export const CREATE_ALLOCATION_FORM = [
  {
    accessor: "allocationName",
    field_type: "TextField",
    label: "Allocation Plan Name",
    required: false,
    isDisabled: false,
  },
];

export const CHECKALL_VALIDATION = "This feature doesnot support check all!!";

export const ALERT = "Alert!!";
export const ERROR = "Error!!";
export const INVALID_DRAFT = "Draft is not valid!!";

export const FILTER_LEVELS = ["l0_name", "l1_name", "l2_name", "l3_name"];

export const INVENTORY_SUBMODULES_NAMES = {
  INVENTORY_DASHBOARD_FORECAST_KPI: "Forecast KPI",
  INVENTORY_DASHBOARD_FORECAST_ALERTS: "Forecast Alert",
  INVENTORY_DASHBOARD_STORE_INVENTORY_KPI: "Store Inventory KPI",
  INVENTORY_DASHBOARD_STORE_INVENTORY_ALERTS: "Store Inventory Alert",
  INVENTORY_DASHBOARD_VIEW_PLANS: "View Plan",
  INVENTORY_DASHBOARD_ARTICLE_DETAILS: "Article Details",
  INVENTORY_CREATE_ALLOCATION_STORE_TABLE: "Create Allocation Store Table",
  INVENTORY_IA_RECOMMENDED_PRODUCT_PROFILE: "IA Recommended Product Profile",
  INVENTORY_USER_CREATED_PRODUCT_PROFILE: "User Created Product Profile",
  INVENTORY_IA_RECOMMENDED_STORE_SIZE_CONTRIBUTION:
    "IA Recommended Store Size Contribution",
  INVENTORY_USER_CREATED_STORE_SIZE_CONTRIBUTION:
    "User Created Store SIze Contribution",
  INVENTORY_CREATED_STYLE_DESCRIPTION: "User Created Style Description",
  INVENTORY_CREATE_PRODUCT_PROFILE_FORM: "Create Product Profile Form",
  INVENTORY_CREATE_PRODUCT_PROFILE_STYLE_DESCRIPTION:
    "Create Product Profile Style Description",
  INVENTORY_CREATE_PRODUCT_PROFILE_STORE_SIZE_CONTRIBUTION:
    "Create Product Profile STore Size Contribution",
  INVENTORY_STORE_CONSTRAINTS: "Store Constraints",
  INVENTORY_STORE_GRADE_CONSTRAINTS: "Store Grade Constraints",
  INVENTORY_STORE_GROUP_CONSTRAINTS: "Store Group Constraints",
  INVENTORY_PRODUCT_RULES: "Product Rules",
  INVENTORY_PRODUCT_STATUS: "Product Status",
  INVENTORY_PRODUCT_MAPPING: "Product Mapping",
  INVENTORY_STORE_STATUS: "Store Status",
  INVENTORY_STORE_MAPPING: "Store Mapping",
  INVENTORY_DC_STATUS: "DC Status",
  INVENTORY_DC_MAPPING: "DC Mapping",
  INVENTORY_STORE_GROUPING: "Store Group",
  INVENTORY_PRODUCT_GROUPING: "Product Group",
};

export const ROLES_ACCESS_MODULES_MAPPING = {
  dashboard: [
    INVENTORY_SUBMODULES_NAMES.INVENTORY_DASHBOARD_FORECAST_KPI,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_DASHBOARD_FORECAST_ALERTS,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_DASHBOARD_STORE_INVENTORY_KPI,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_DASHBOARD_STORE_INVENTORY_ALERTS,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_DASHBOARD_VIEW_PLANS,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_DASHBOARD_ARTICLE_DETAILS,
  ],
  inventorysmart_create_allocation: [
    INVENTORY_SUBMODULES_NAMES.INVENTORY_CREATE_ALLOCATION_STORE_TABLE,
  ],
  inventorysmart_product_profile: [
    INVENTORY_SUBMODULES_NAMES.INVENTORY_IA_RECOMMENDED_PRODUCT_PROFILE,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_USER_CREATED_PRODUCT_PROFILE,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_IA_RECOMMENDED_STORE_SIZE_CONTRIBUTION,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_USER_CREATED_STORE_SIZE_CONTRIBUTION,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_CREATED_STYLE_DESCRIPTION,
  ],
  inventorysmart_create_product_profile: [
    INVENTORY_SUBMODULES_NAMES.INVENTORY_CREATE_PRODUCT_PROFILE_FORM,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_CREATE_PRODUCT_PROFILE_STYLE_DESCRIPTION,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_CREATE_PRODUCT_PROFILE_STORE_SIZE_CONTRIBUTION,
  ],
  inventorysmart_constraints: [
    INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_CONSTRAINTS,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_GRADE_CONSTRAINTS,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_GROUP_CONSTRAINTS,
  ],
  inventorysmart_configuration: [
    INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_RULES,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_STATUS,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_MAPPING,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_STATUS,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_MAPPING,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_DC_STATUS,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_DC_MAPPING,
  ],
  inventorysmart_store_eligibility_group: [
    INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_GROUPING,
    INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_GROUPING,
  ],
};

export const FULL_ACCESS_PERMISSIONS_LIST = [
  "create",
  "edit",
  "delete",
  "view",
];

export const SCREENS_LIST_MAP = {
  INVENTORYSMART_DASHBOARD_STORE_INVENTORY:
    "inventorysmart_dashboard_store_inventory",
  INVENTORYSMART_DASHBOARD_FORECAST: "inventorysmart_dashboard_forecast",
};

export const SCREENS_SUBCOMPONENT_LIST_MAP = {
  INVENTORYSMART_DASHBOARD_WITH_STORE_INVENTORY: "dashboardWithStoreInventory",
  INVENTORYSMART_DASHBOARD_WITH_FORECAST_AND_STORE_INVENTORY:
    "dashboardWithForecastAndStoreInventory",
  INVENTORYSMART_DASHBOARD_WITH_STORE_INVENTORY_AND_FORECAST:
    "dashboardWithStoreInventoryAndForecast",
};

export const KITS_ALLOCATION_ALERT_CONFIG_NAME = "get_kits_allocation";

export const STORE_INVENTORY_LABEL_MAPPINGS = {
  stock_out: "Stock Out",
  stockout: "Stock Out",
  shortfall: "Shortfall",
  normal: "Normal",
  excess: "Excess",
};

export const STORE_INVENTORY_LINK_COLUMNS_RIGHT_ALIGNED = [
  "stock_out",
  "stockout",
  "shortfall",
  "normal",
  "excess",
];

export const defaultTableData = {
  data: [],
  totalCount: 0,
};

export const tableConfigurationMetaData = {
  meta: {
    search: [],
    sort: [],
    range: [],
  },
};

export const tableArticleFilter = {
  filter_type: "cascaded",
  attribute_name: "article",
  operator: "in",
  dimension: "Product",
  values: [],
};

export const tableStoreCodeFilter = {
  filter_type: "cascaded",
  attribute_name: "store_code",
  operator: "in",
  dimension: "Store",
  values: [],
};

export const productMappingTableArticleFilter = {
  filter_type: "cascaded",
  attribute_name: "product_code",
  operator: "in",
  dimension: "Product",
  values: [],
};

export const ACTUAL_PREDICTED = "Actual/Predicted";
export const KPI_RECOMMENDED = "Recommended";
export const KPI_PLANNED = "Planned";

export const PRODUCT_RULE_POP_UP_TITLE = {
  store_group_mapped_display: "Store Groups/Store Mapped",
  dc_mapped: "DC Mapped",
  old_id: "Old Style Mapped",
  product_profile_name: "Product Profiled Mapped",
};

export const POP_UP_TYPE = {
  store: "store",
  product_profile: "product_profile",
  dc: "dc",
};

export const TAB_TYPES_LABEL = {
  Level1Tab1: dynamicLabelsBasedOnTenant("product"),
  Level1Tab2: "Store",
  Level1Tab3: "DC",
  Level2Tab1: dynamicLabelsBasedOnTenant("product_rules"),
  Level2Tab2: dynamicLabelsBasedOnTenant("product_status"),
  Level2Tab3: dynamicLabelsBasedOnTenant("product_mapping"),
};

export const TAB_TYPES_VALUE = {
  Level1: "Level1",
  Level2: "Level2",
  Level3: "Level3",
  Level1Sector1Label: "Level1Sector1",
  Level1Sector2Label: "Level1Sector2",
  Level1Sector3Label: "Level1Sector3",
  Level1Sector1: dynamicLabelsBasedOnTenant("product_rules"),
  Level1Sector2: dynamicLabelsBasedOnTenant("product_status"),
  Level1Sector3: dynamicLabelsBasedOnTenant("product_mapping"),
};

// Create Product profile form fields
export const CREATE_PRODUCT_PROFILE_FORM = [
  {
    accessor: "profileName",
    field_type: "TextField",
    label: "Profile Name",
    required: true,
    isDisabled: false,
  },
  {
    accessor: "profileDescription",
    field_type: "TextField",
    label: "Profile Description",
    required: true,
    isDisabled: false,
  },
];

export const CREATE_PRODUCT_PROFILE_TIME_PERIOD_DROP_DOWN = [
  {
    accessor: "productProfileTimePeriod",
    field_type: "list",
    label: "Period",
    required: true,
    options: [
      { label: "Last 30 days", value: "Last 30 days", id: "Last 30 days" },
      { label: "Last 90 days", value: "Last 90 days", id: "Last 90 days" },
      { label: "Last 180 days", value: "Last 180 days", id: "Last 180 days" },
      { label: "Last 365 days", value: "Last 365 days", id: "Last 365 days" },
    ],
    isMulti: false,
    isSearchable: false,
    isClearable: false,
  },
];

export const CREATE_PRODUCT_PROFILE_TIME_PERIOD_DATE_PICKER = [
  {
    required: true,
    label: "Period",
    field_type: "rangePicker",
    accessor: "productProfileRangePeriod",
    disableType: "disableOnlyFuture",
  },
];

export const PLAN_STATUS_TO_HIDE_BACK_BUTTON = ["Failure", null];

export const PLAN_TYPE_TO_HIDE_BACK_BUTTON = ["Auto Allocation"];

export const PLAN_STATUS_TO_HIDE_FINALIZE_BUTTON = [
  "Finalized",
  "Failure",
  null,
];

export const PAST_ALLOCATION_TIME_PERIOD_DATE_PICKER = [
  {
    required: false,
    label: "Period",
    field_type: "rangePicker",
    accessor: "pastAllocationRangePeriod",
    disableType: "disableOnlyFuture",
  },
];

export const CREATE_PRODUCT_PROFILE_SALE_ATTRIBUTE = [
  {
    required: false,
    label: "Regular",
    field_type: "BooleanField",
    accessor: "regular",
    disabled: false,
    value: true,
  },
  {
    required: false,
    label: "Clearance",
    field_type: "BooleanField",
    accessor: "clearance",
    disabled: false,
    value: true,
  },
  {
    required: false,
    label: "Promo",
    field_type: "BooleanField",
    accessor: "promo",
    disabled: false,
    value: true,
  },
  {
    required: false,
    label: "Retirement",
    field_type: "BooleanField",
    accessor: "retirement",
    disabled: true,
    value: true,
  },
];

export const CREATE_PRODUCT_PROFILE_PRODUCT_ATTRIBUTE = [
  {
    required: true,
    label: "MIN",
    field_type: "TextField",
    accessor: "min",
    disabled: false,
  },
  {
    required: true,
    label: "MAX",
    field_type: "TextField",
    accessor: "max",
    disabled: false,
  },
];

export const STORE_INVENTORY_ALERT_ACTION_CONFIG = [
  {
    sub_headers: [],
    tc_code: 301,
    column_name: "action",
    type: "review_btn",
    label: "Action",
    is_frozen: false,
    is_hidden: false,
    is_editable: true,
    is_aggregated: false,
    order_of_display: 4,
    dimension: "Store Inventory Alert",
    is_required: false,
    tc_mapping_code: 55002,
    aggregate: "",
    formatter: "",
    is_row_span: false,
    footer: "",
    is_searchable: false,
    is_sortable: true,
    extra: null,
    pinned: null,
  },
];

export const LOST_SALES_FISCAL_WEEK = [
  {
    accessor: "fiscal_week",
    field_type: "dropdown",
    label: "Week",
    required: false,
    options: [],
    isMulti: false,
    isSearchable: true,
    isClearable: true,
  },
];

export const INVENTORY_DASHBOARD_STATIC_FILTERS = [
  {
    label: null,
    api: () => getFiscalCalendar(),
    type: "dateRange",
    reducerKey: "fiscalDates",
    isMandatory: true,
  },
];

export const REPORT_SCREEN_TABS = [
  { label: "Model Stock Deep Dive", value: "model_stock_deep_dive" },
  { label: "Store Stock drill down", value: "store_stock" },
  { label: "Lost Sales", value: "lost_sales" },
  { label: "Lost Sales", value: "lost_sales_with_fiscal_calendar" },
  { label: "Excess Inventory", value: "excess_inv" },
  { label: "Excess Inventory", value: "excess_report" },
  { label: "Deep Dive Allocation", value: "deep_dive" },
  { label: "Daily Allocation Summary", value: "daily_allocation" },
  { label: "Forecasted units", value: "forecasted_units" },
  { label: "Additional Reports", value: "additional_reports" },
  { label: "Future Receipts", value: "future_receipts" },
  { label: "Forecast Reports", value: "forecast_reports" },
  { label: "In Stock", value: "in_stock" },
];

export const MODEL_STOCK_DEEP_DIVE_TABS = [
  { label: "Product View", value: "article" },
  { label: "Store View", value: "store" },
];

export const INVENTORY_DASHBOARD_TAB_OPTIONS = {
  dashboardWithForecastAndStoreInventory: [
    {
      label: "Forecast",
      value: "forecast",
    },
    {
      label: "Store Inventory",
      value: "store_inventory",
    },
  ],
  dashboardWithStoreInventory: [
    {
      label: "Store Inventory",
      value: "store_inventory",
    },
  ],
  dashboardWithStoreInventoryAndForecast: [
    {
      label: "Store Inventory",
      value: "store_inventory",
    },
    {
      label: "Forecast",
      value: "forecast",
    },
  ],
};

export const DAILY_ALLOCATION_VIEW_TYPE = [
  {
    field_type: "radioGroup",
    accessor: "productStore",
    options: [
      {
        value: "product",
        label: "",
        isDisabled: false,
      },
      { value: "store", label: "Store", isDisabled: false },
    ],
  },
];

export const DAILY_ALLOCATION_SUMMARY_SIGNET = [
  {
    label: "# of Allocation",
    key: "allocation_count",
  },
  {
    label: "# of style Allocation",
    key: "style_color_count",
  },
  {
    label: "# of units Allocation",
    key: "unit_allocated",
  },
  {
    label: "DC Net Available inv",
    key: "dc_available",
  },
  {
    label: "Reserve qty",
    key: "reserve_quantity",
  },
];

export const DAILY_ALLOCATION_DATE_PICKER = [
  {
    required: true,
    label: "Date",
    field_type: "DateTimeField",
    accessor: "datePicker",
    disabled: true,
    disablePast: false,
    disableFuture: true,
  },
];

export const DEEP_DIVE_RANGE_PICKER = [
  {
    required: true,
    label: "Period",
    field_type: "rangePicker",
    accessor: "rangePicker",
    disableType: "disableOnlyFuture",
  },
];

export const FORECASTED_UNITS_DATE_PICKER = [
  {
    required: true,
    label: "Calendar",
    field_type: "rangePicker",
    accessor: "rangePicker",
    disableType: "disableOnlyFuture",
  },
];

export const UNDER_FORECASTING_PERCENTAGE = [
  {
    required: false,
    label: "",
    field_type: "IntegerField",
    accessor: "underForecastValue",
    value_type: "percentage",
    step_value: 5,
  },
];

export const OVER_FORECASTING_PERCENTAGE = [
  {
    required: false,
    label: "",
    field_type: "IntegerField",
    accessor: "overForecastValue",
    value_type: "percentage",
    step_value: 5,
  },
];

// displaying only these summary values for now
export const DEEP_DIVE_ALLOCATION_SUMMARY = [
  {
    label: "# of Allocation",
    key: "total_allocation_code",
  },
  {
    label: "# of units Allocation",
    key: "allocated_total",
  },
];

export const ALERTS_ACTION_MAP = {
  NEW_TABLE: "new_table",
  POP_UP: "pop_up",
  POP_UP_LINK: "pop_up_link",
  DYNAMIC_POP_UP: "dynamic_pop_up",
};

export const UPDATE_MODAL_STOCK = [
  {
    label: "Model Stock",
    isDisabled: false,
    accessor: "model_stock",
    field_type: "IntegerField",
    value_type: "number",
  },
];

export const ADDITIONAL_REPORTS_FILTERS_WEEK = [
  {
    accessor: "fiscal_week",
    field_type: "dropdown",
    label: "Weeks",
    required: true,
    options: [],
    isMulti: false,
    isSearchable: true,
    isClearable: true,
    isDisabled: true,
  },
];

export const FUTURE_RECEIPTS_RANGE_PICKER = [
  {
    required: true,
    label: "Calendar",
    field_type: "rangePicker",
    accessor: "rangePicker",
    disableType: "disableOnlyFuture",
  },
];

export const REPORTS_TARGET_WOS_PAYLOAD = [
  {
    filter_type: "cascaded",
    attribute_name: "channel",
    operator: "in",
    dimension: "Store",
    values: [],
  },
  {
    filter_type: "cascaded",
    attribute_name: "l0_name",
    operator: "in",
    dimension: "Product",
    values: [],
  },
  {
    filter_type: "cascaded",
    attribute_name: "l1_name",
    operator: "in",
    dimension: "Product",
    values: [],
  },
  {
    filter_type: "cascaded",
    attribute_name: "l2_name",
    operator: "in",
    dimension: "Product",
    values: [],
  },
  {
    filter_type: "cascaded",
    attribute_name: "style",
    operator: "in",
    dimension: "Product",
    values: [],
  },
  {
    filter_type: "cascaded",
    attribute_name: "style_description",
    operator: "in",
    dimension: "Product",
    values: [],
  },
  {
    filter_type: "cascaded",
    attribute_name: "store_code",
    operator: "in",
    dimension: "Store", // should be store
    values: [],
  },
];

export const FUTURE_RECEIPTS_SUB_ROWS_PAYLOAD = [
  {
    filter_type: "cascaded",
    attribute_name: "channel",
    operator: "in",
    dimension: "Store",
    values: "",
  },
  {
    filter_type: "cascaded",
    attribute_name: "l0_name",
    operator: "in",
    dimension: "Product",
    values: "",
  },
  {
    filter_type: "cascaded",
    attribute_name: "l1_name",
    operator: "in",
    dimension: "Product",
    values: "",
  },
  {
    filter_type: "non-cascaded",
    attribute_name: "store_code",
    operator: "in",
    dimension: "Store",
    values: "",
  },
];
export const SHOW_HISTORICALMAPPING = {
  "New SKU Forecast": 1,
  "Percentage DC Forecast Error": 1,
  "Constrained Forecast": 0,
  "Percentage DC Forecast Error SD/C": 1,
  "Model Stock vs IA System Forecast": 1,
};
export const IN_STOCK_TABS = [
  { label: "SKU View", value: "article" },
  { label: "Store View", value: "store" },
];
export const EXCESS_INVENTORY_TABS = [
  { label: "SKU View", value: "product_code" },
  { label: "Store View", value: "store" },
];
