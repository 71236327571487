import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../Utils/axios";
import {
  GET_WEDGE_METRICS,
  GET_WEDGE_ATTRIBUTES,
  GET_WEDGE_TABLE_DATA,
  UPDATE_WEDGE_TABLE_DATA,
  OPTIMIZE_WEDGE,
  GET_PLAN_OPTMIZATION_CONSTRAINT,
  UPDATE_OPTMIZATION_CONSTRAINT_DATA,
  DEPTH_MULTIPLIER,
  UPLOAD_WEDGE,
  GET_PLAN_SETUP_DROPS,
  UPDATE_PLAN_SETUP_DROPS,
  WEDGE_ADD_CHOICE,
  WEDGE_ADD_CHOICE_VALIDATION,
  WEDGE_DELETE_CHOICE,
  WEDGE_DELETE_FETCH_DATA,
  ADD_DROP_SHIP_CHOICES
} from "../../../constants/apiConstants";

export const planInitialService = createSlice({
  name: "planInitialService",
  initialState: {
    loader_2_3: false,
    planMetricsData: [],
    wedgeFiltersData: [],
    wedgeData: [],
    wedgeAttributeData: [],
    planOptimizationConstraintData: {},
    planSetupDropsData: {},
    updateSetupDropsResponse: false,
    l3MinQtyJson: {},
    deleteChoiceLoader: false,
    deleteChoiceData: [],
    showWedge: false
  },
  reducers: {
    set2_3_Loader: (state, action) => {
      state.loader_2_3 = action.payload;
    },
    setPlanMetricsData: (state, action) => {
      state.planMetricsData = action.payload;
    },
    setWedgeFiltersData: (state, action) => {
      state.wedgeFiltersData = action.payload;
    },
    setWedgeAttributeData: (state, action) => {
      state.wedgeAttributeData = action.payload;
    },
    setWedgeData: (state, action) => {
      state.wedgeData = action.payload;
    },
    setPlanOptimizationConstraintData: (state, action) => {
      state.planOptimizationConstraintData = action.payload;
    },
    setPlanSetupDrops: (state, action) => {
      state.planSetupDropsData = action.payload;
      state.updateSetupDropsResponse = false;
    },
    setUpdatePlanSetupDropsResponse: (state, action) => {
      state.updateSetupDropsResponse = action.payload;
    },
    setl3MinQtyJson: (state, action) => {
      state.l3MinQtyJson = action.payload;
    },
    setDeleteChoiceLoader: (state, action) => {
      state.deleteChoiceLoader = action.payload;
    },
    setDeleteChoiceData: (state, action) => {
      state.deleteChoiceData = action.payload;
    },
    setShowWedgeScreen: (state, action) => {
      state.showWedge = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  set2_3_Loader,
  setPlanMetricsData,
  setWedgeFiltersData,
  setWedgeData,
  setWedgeAttributeData,
  setOptimizeWedgeData,
  setPlanOptimizationConstraintData,
  setPlanSetupDrops,
  setUpdatePlanSetupDropsResponse,
  setl3MinQtyJson,
  setDeleteChoiceData,
  setDeleteChoiceLoader,
  setShowWedgeScreen
} = planInitialService.actions;

//budget level2 table apis
export const getPlanMetricsData = (postBody) => () => {
  return axiosInstance({
    url: GET_WEDGE_METRICS,
    method: "POST",
    data: postBody,
  });
};
export const getWedgeAttributesData = (postBody) => () => {
  return axiosInstance({
    url: GET_WEDGE_ATTRIBUTES,
    method: "POST",
    data: postBody,
  });
};

export const getWedgeData = (postBody) => () => {
  return axiosInstance({
    url: GET_WEDGE_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const updateWedgeData = (postBody) => () => {
  return axiosInstance({
    url: UPDATE_WEDGE_TABLE_DATA,
    method: "PUT",
    data: postBody,
  });
};

export const getOptimizeWedge = (postBody) => () => {
  return axiosInstance({
    url: OPTIMIZE_WEDGE,
    method: "POST",
    data: postBody,
  });
};

export const getPlanOptimizationConstraintData = (postBody) => () => {
  return axiosInstance({
    url: GET_PLAN_OPTMIZATION_CONSTRAINT,
    method: "POST",
    data: postBody,
  });
};

export const updateOptimizationConstraintData = (postBody) => () => {
  return axiosInstance({
    url: UPDATE_OPTMIZATION_CONSTRAINT_DATA,
    method: "PUT",
    data: postBody,
  });
};

export const getDepthMultiplierData = (postBody) => () => {
  return axiosInstance({
    url: DEPTH_MULTIPLIER,
    method: "POST",
    data: postBody,
  });
};

export const uploadWedgeData = (postBody) => () => {
  return axiosInstance({
    url: UPLOAD_WEDGE,
    method: "POST",
    data: postBody,
  });
};

export const getPlanSetupdrops = (postBody) => () => {
  return axiosInstance({
    url: GET_PLAN_SETUP_DROPS,
    method: "POST",
    data: postBody,
  });
};

export const updatePlanSetupdrops = (postBody) => () => {
  return axiosInstance({
    url: UPDATE_PLAN_SETUP_DROPS,
    method: "PUT",
    data: postBody,
  });
};

export const addWedgeChoice = (postBody) => () => {
  return axiosInstance({
    url: WEDGE_ADD_CHOICE,
    method: "POST",
    data: postBody,
  });
};

export const addWedgeChoiceValidtion = (postBody) => () => {
  return axiosInstance({
    url: WEDGE_ADD_CHOICE_VALIDATION,
    method: "POST",
    data: postBody,
  });
};

export const deleteWedgeChoice = (reqBody) => () => {
  return axiosInstance({
    url:WEDGE_DELETE_CHOICE,
    method: "POST",
    data: reqBody
  });
};

export const deleteDataFetch = (reqBody) => () => {
  return axiosInstance({
    url: WEDGE_DELETE_FETCH_DATA,
    method: "POST",
    data: reqBody
  });
};

export const addDropshipChoices = (reqBody) => () => {
  return axiosInstance({
    url: ADD_DROP_SHIP_CHOICES,
    method: "POST",
    data: reqBody
  });
};
export default planInitialService.reducer;
