import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "modules/inventorysmart/pages-inventorysmart/styles-inventorysmart";
import globalStyles from "Styles/globalStyles";
import LoadingOverlay from "Utils/Loader/loader";
import InventoryDashboardForecastKPI from "./components/InventoryDashboardForecastKPI";
import InventoryDashboardStyleInventoryDetailsKPI from "./components/InventoryDashboardStyleInventoryDetailsKPI";
import InventoryDashboardStyleInventoryKPI from "./components/InventoryDashboardStyleInventoryKPI";
import {
  SCREENS_LIST_MAP,
  SCREENS_SUBCOMPONENT_LIST_MAP,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import {
  getInventoryDashboardForecastKPIData,
  getInventoryDashboardStoreInventoryKPIData,
  getInventoryDashboardKPIData,
  setInventoryDashboardKPIConfigLoader,
  setInventoryDashboardKPIDataLoader,
  setInventoryDashboardForecastKPIDataLoader,
} from "modules/inventorysmart/services-inventorysmart/KPI-Matrix/kpi-services";
import { addSnack } from "actions/snackbarActions";
import InventoryDashboardKPI from "./components/InventoryDashboardKPI";
import { EventBusy, EastOutlined } from "@mui/icons-material";
import DateRangeFilter from "./components/DateRangeFilter";
import { setSelectedDates } from "modules/inventorysmart/services-inventorysmart/Decision-Dashboard/decision-dashboard-services";
import { isEmpty } from "lodash";
import KPIAlertsData from "./components/KPIAlertsData";

const KPI = (props) => {
  const classes = useStyles();
  const globalClasses = globalStyles();

  const [kpiData, setKPIData] = useState(null);

  const generateKPIFilterBody = (startDate, endDate) => {
    const filters = {
      product_attributes: [],
      store_attributes: [],
      other_attributes: [
        {
          attribute_name: "start_date",
          attribute_value: startDate,
        },
        {
          attribute_name: "end_date",
          attribute_value: endDate,
        },
      ],
    };

    props.selectedFilters.forEach((filter) => {
      if (filter.dimension === "Product" && filter?.values?.length > 0) {
        filters.product_attributes.push(filter);
      } else if (filter.dimension === "Store" && filter?.values?.length > 0) {
        filters.store_attributes.push(filter);
      }
    });

    return filters;
  };

  const formatForecastKPIDetails = (kpis) => {
    const kpiDetails = { ...kpis };

    kpiDetails.kpis = kpiDetails.kpis.map((kpiItem) => {
      kpiItem.ia_forecast_value_delta =
        kpiItem.value - kpiItem.ia_forecast_value;
      kpiItem.ia_forecast_value_abs_delta = Math.abs(
        kpiItem.ia_forecast_value_delta
      );
      kpiItem.ia_adj_forecast_value_delta =
        kpiItem.value - kpiItem.ia_adj_forecast_value;
      kpiItem.ia_adj_forecast_value_abs_delta = Math.abs(
        kpiItem.ia_adj_forecast_value_delta
      );

      if (kpiItem.value) {
        kpiItem.ia_forecast_value_delta_percentage = parseFloat(
          (kpiItem.ia_forecast_value_delta / kpiItem.value) * 100
        ).toFixed(2);
        kpiItem.ia_adj_forecast_value_delta_percentage = parseFloat(
          (kpiItem.ia_adj_forecast_value_delta / kpiItem.value) * 100
        ).toFixed(2);
      }
      return kpiItem;
    });

    return kpiDetails;
  };

  const formatStoreInventoryKPIDetails = (kpis) => {
    const kpiDetails = { ...kpis };

    kpiDetails.kpis = kpiDetails.kpis.map((kpiItem) => {
      if (kpiItem.value && kpiItem.ly_value) {
        kpiItem.value_delta = Math.abs(kpiItem.value - kpiItem.ly_value);
        kpiItem.value_delta_percentage = parseFloat(
          (kpiItem.value_delta / kpiItem.ly_value) * 100
        ).toFixed(2);
      }
      return kpiItem;
    });

    delete kpiDetails.alerts;
    return kpiDetails;
  };

  /** Fetch KPI Data for Store Inventory Tab */
  const fetchInventoryDashboardStoreInventoryKPIData = async () => {
    try {
      const startDate = moment(
        props?.selectedDates?.fiscalInfoStartDate?.calendar_week_start_date
      ).format("YYYY-MM-DD");
      const endDate = moment(
        props?.selectedDates?.fiscalInfoStartDate?.calendar_week_start_date
      )
        .endOf("week")
        .format("YYYY-MM-DD");

      props.setInventoryDashboardKPIDataLoader(true);

      const filters = generateKPIFilterBody(startDate, endDate);

      let body = {
        ...filters,
      };

      let response = await props.getInventoryDashboardStoreInventoryKPIData(
        body
      );

      let kpiDetails = response.data.data;

      if (
        props.inventorysmartScreenConfig?.dashboard?.subComponent !==
        SCREENS_SUBCOMPONENT_LIST_MAP.INVENTORYSMART_DASHBOARD_WITH_FORECAST_AND_STORE_INVENTORY
      ) {
        kpiDetails = formatStoreInventoryKPIDetails(kpiDetails);
      }

      setKPIData(kpiDetails);
    } finally {
      props.setInventoryDashboardKPIDataLoader(false);
    }
  };

  /** Fetch KPI Data for Forecast Tab */
  const fetchInventoryDashboardForecastKPIData = async () => {
    try {
      const startDate = moment().subtract(8, "weeks").format("YYYY-MM-DD");
      const endDate = moment().format("YYYY-MM-DD");

      props.setInventoryDashboardForecastKPIDataLoader(true);

      const filters = generateKPIFilterBody(startDate, endDate);

      let body = {
        ...filters,
      };

      let response = await props.getInventoryDashboardForecastKPIData(body);
      const kpiDetails = formatForecastKPIDetails(response.data.data);

      setKPIData(kpiDetails);
    } finally {
      props.setInventoryDashboardForecastKPIDataLoader(false);
    }
  };

  const fetchKPIData = () => {
    if (
      props.screen === SCREENS_LIST_MAP.INVENTORYSMART_DASHBOARD_STORE_INVENTORY
    ) {
      fetchInventoryDashboardStoreInventoryKPIData();
    } else {
      fetchInventoryDashboardForecastKPIData();
    }
  };

  useEffect(() => {
    !isEmpty(props.selectedFilters) &&
      (!props.showDateFilter ||
        (props.showDateFilter &&
          props.selectedDates?.fiscalInfoStartDate &&
          props.selectedDates.fiscalInfoEndDate)) &&
      fetchKPIData();
  }, [props.selectedFilters, props.selectedDates]);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <LoadingOverlay
      loader={
        props.inventoryDashboardKPIDataLoader ||
        props.inventoryDashboardForecastKPIDataLoader ||
        props.inventorysmartDatesLoader
      }
      minHeight={"180px"}
    >
      <div className={classes.kpiCard}>
        <Grid container columnSpacing={2}>
          {props.showDateFilter && (
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item display={"flex"} alignItems={"center"}>
                <DateRangeFilter displayRow={true} />
              </Grid>
              <Grid item display={"flex"} alignItems={"center"}>
                <EventBusy className={globalClasses.marginHorizontal} />
                <Typography variant="body">
                  Date range not applicable
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {kpiData?.kpis?.length > 3 && (
          <Grid item display={"flex"} justifyContent={"flex-end"}>
            <EastOutlined className={classes.scrollArrow} />
          </Grid>
        )}
        <Grid
          container
          columnSpacing={2}
          className={
            props.screen ===
              SCREENS_LIST_MAP.INVENTORYSMART_DASHBOARD_STORE_INVENTORY &&
            classes.kpiContainerOverflow
          }
        >
          {kpiData?.kpis.map((kpi) =>
            kpi.type === "kpi_details" ? (
              /**Switching KPI Cards on the basis of their type & different details will be visible on different type */
              <Grid item xs={3} key={kpi.key}>
                <InventoryDashboardKPI kpi={kpi} />
              </Grid>
            ) : kpi.type === "kpi_details_right_aligned" ? (
              <Grid item xs={12} md={4}>
                <InventoryDashboardForecastKPI kpi={kpi} />
              </Grid>
            ) : kpi.type === "kpi_details_cost" ? (
              <Grid item xs={12} md={3}>
                <InventoryDashboardStyleInventoryKPI kpi={kpi} />
              </Grid>
            ) : (
              <Grid item xs={12} md={3}>
                <InventoryDashboardStyleInventoryDetailsKPI kpi={kpi} />
              </Grid>
            )
          )}
        </Grid>

        {!props.storeForecastAlertsTableLoader &&
          !props.storeInventoryAlertsTableDataLoader &&
          props?.inventoryDashboardAlertCount?.length > 0 && (
            <KPIAlertsData alerts={props.inventoryDashboardAlertCount} />
          )}
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (store) => {
  return {
    selectedFilters:
      store.inventorysmartReducer.inventorySmartDashboardService
        .selectedFilters,
    selectedDates:
      store.inventorysmartReducer.inventorySmartDashboardService.selectedDates,
    inventoryDashboardAlertCount:
      store.inventorysmartReducer.inventorySmartKPIService
        .inventoryDashboardAlertCount,
    inventorysmartDatesLoader:
      store.inventorysmartReducer.inventorySmartDashboardService
        .inventorysmartDatesLoader,
    inventoryDashboardKPIConfigLoader:
      store.inventorysmartReducer.inventorySmartKPIService
        .inventoryDashboardKPIConfigLoader,
    inventoryDashboardKPIDataLoader:
      store.inventorysmartReducer.inventorySmartKPIService
        .inventoryDashboardKPIDataLoader,
    inventoryDashboardForecastKPIDataLoader:
      store.inventorysmartReducer.inventorySmartKPIService
        .inventoryDashboardForecastKPIDataLoader,
    storeForecastAlertsTableLoader:
      store.inventorysmartReducer.inventorySmartStoreForecastAlerts
        .storeForecastAlertsTableLoader,
    storeInventoryAlertsTableDataLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryAlertsService
        .storeInventoryAlertsTableDataLoader,
    inventorysmartScreenConfig:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInventoryDashboardKPIConfigLoader: (payload) =>
    dispatch(setInventoryDashboardKPIConfigLoader(payload)),
  setInventoryDashboardKPIDataLoader: (payload) =>
    dispatch(setInventoryDashboardKPIDataLoader(payload)),
  setInventoryDashboardForecastKPIDataLoader: (payload) =>
    dispatch(setInventoryDashboardForecastKPIDataLoader(payload)),
  setSelectedDates: (payload) => dispatch(setSelectedDates(payload)),
  getInventoryDashboardKPIData: (payload) =>
    dispatch(getInventoryDashboardKPIData(payload)),
  getInventoryDashboardForecastKPIData: (payload) =>
    dispatch(getInventoryDashboardForecastKPIData(payload)),
  getInventoryDashboardStoreInventoryKPIData: (payload) =>
    dispatch(getInventoryDashboardStoreInventoryKPIData(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KPI);
