import SetAllMultiRow from "Utils/reactTable/components/setall-multirow-form"; //For SetAll Multi Row to add dates
import ConflictResolutionModal from "pages/storeStatus/components/conflict-resolution-modal";
import { forwardRef, useState } from "react";
import {
  dateValidationMessage,
  isDateRangeConflict,
} from "Utils/functions/helpers/validation-helpers";
import { connect } from "react-redux";
import { API_META_BODY, END_DATE } from "config/constants";
import { getSetAllFormFields } from "pages/product-mapping/components/common-functions";
import { getModifyTablePayloadMeta } from "./common-mapping-functions";
import { mapStoreToProduct } from "../services/storeMappingService";
import { mapProductToStore } from "pages/product-mapping/services-product-mapping/productMappingService";
import { addSnack } from "actions/snackbarActions";
import { cloneDeep } from "lodash";
import { setAllProductMappingAPI } from "pages/product-mapping/services-product-mapping/productMappingService";
import { setAllStoreMappingAPI } from "../services/storeMappingService";

const SetAllComponent = forwardRef((props, ref) => {
  const [resolutionType, setResolutionType] = useState("hard_reset");
  const dateValidation = (startDate, endDate) => {
    const startdate = new Date(startDate);
    const enddate = new Date(endDate);
    const errMessage = dateValidationMessage(startdate, enddate);
    if (errMessage) {
      displaySnackMessages("Date is not correct", "error");
      throw Error("Date is not correct");
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const formatSetAllData = (input, fieldRowId) => {
    let setAllOutput = [];
    for (let i = 0; i <= fieldRowId; i++) {
      const unique_delimiter = "_" + i;
      const attributeValue =
        input[
          (props.screenName === "store_mapping" ? "storecode" : "productcode") +
            unique_delimiter
        ];
      const row = {
        attribute_name:
          props.screenName === "store_mapping" ? "store" : "product",
        start_date: input["start_date" + unique_delimiter],
        end_date: input["end_date" + unique_delimiter]
          ? input["end_date" + unique_delimiter]
          : END_DATE,
      };
      if (Array.isArray(attributeValue)) {
        attributeValue.map((attribute) => {
          let tempRow = cloneDeep(row);
          tempRow["attribute_value"] = attribute;
          dateValidation(row.start_date, row.end_date);
          setAllOutput.push(tempRow);
        });
      } else {
        let tempRow = cloneDeep(row);
        tempRow["attribute_value"] = attributeValue;
        dateValidation(row.start_date, row.end_date);
        setAllOutput.push(tempRow);
      }
    }
    let setAllOutPutDictionary = {};
    setAllOutput.forEach((item) => {
      const dateObj = {
        start_time: item.start_date,
        end_time: item.end_date,
      };
      if (setAllOutPutDictionary[item.attribute_value]) {
        setAllOutPutDictionary[item.attribute_value].push(dateObj);
      } else {
        setAllOutPutDictionary[item.attribute_value] = [dateObj];
      }
    });
    for (const setAllStoreKey in setAllOutPutDictionary) {
      // checking if conflict exists in date ranges
      const hasConflict = isDateRangeConflict(
        setAllOutPutDictionary[setAllStoreKey],
        "YYYY-MM-DD",
        "[]"
      );
      if (hasConflict) {
        displaySnackMessages("Please resolve overlapping date ranges", "error");
        throw Error("Date is overlapping");
      }
    }

    return setAllOutput;
  };

  const getSelectedObjects = (screenName) => {
    if (screenName === "store_mapping") {
      return props.selectedProductObjects;
    }
    if (screenName === "product_mapping") {
      return props.selectedStoreObjects;
    }
    if (screenName === "product_mapping_store_group") {
      return props.selectedStoreGroupObjects;
    }
  };

  const getColKey = (screenName) => {
    if (screenName === "store_mapping") {
      return "product_code";
    }
    if (screenName === "product_mapping") {
      return "store_code";
    }
    if (screenName === "product_mapping_store_group") {
      return "sg_code";
    }
  };

  const storeOrProductSetAllApply = async (formattedAttributes) => {
    let allselectedRowsIDs = [];
    let selectedProdOrStoreObjects = getSelectedObjects(props.screenName);
    let colKey = getColKey(props.screenName);
    if (
      selectedProdOrStoreObjects &&
      !Array.isArray(selectedProdOrStoreObjects)
    ) {
      allselectedRowsIDs = [selectedProdOrStoreObjects].map(
        (storeOrProdObj) => storeOrProdObj[colKey]
      );
    }
    if (
      selectedProdOrStoreObjects &&
      Array.isArray(selectedProdOrStoreObjects)
    ) {
      allselectedRowsIDs = selectedProdOrStoreObjects.map(
        (storeOrProdObj) => storeOrProdObj[colKey]
      );
    }
    let newValues = [];

    formattedAttributes.forEach((attribute) => {
      let body = {
        select: Array.isArray(attribute.attribute_value)
          ? attribute.attribute_value
          : [attribute.attribute_value],
        valid_from: attribute.start_date,
        valid_to: attribute.end_date,
      };
      newValues.push(body);
    });

    if (props.screenName === "store_mapping") {
      let body = {
        products: getModifyTablePayloadMeta(
          props.filterDependency,
          "store",
          props.selectedProducts,
          ref.current,
          0,
          API_META_BODY,
          allselectedRowsIDs,
          props.isBulkEdit
        ),
        stores: newValues,
        action: resolutionType,
      };
      await setAllStoreMappingAPI(body)();
    } else {
      let body = {
        stores: getModifyTablePayloadMeta(
          props.filterDependency,
          "product",
          props.selectedProducts,
          ref.current,
          0,
          API_META_BODY,
          allselectedRowsIDs,
          props.isBulkEdit
        ),
        products: newValues,
        action: resolutionType,
      };
      await setAllProductMappingAPI(body)();
    }
    props.onClickFilter();
  };

  /**
   * SetAllChanges function is a callback to setallchanges dialog
   * @param {dates} formattedAttributes
   */
  const setAllChanges = async (formattedAttributes) => {
    if (
      props.screenName === "store_mapping" ||
      props.screenName === "product_mapping"
    ) {
      await storeOrProductSetAllApply(formattedAttributes);
    } else {
      props.storeGroupSetAllApply(formattedAttributes);
    }
  };

  return (
    <>
      {props.showSetAllPopUp && (
        <SetAllMultiRow
          onApply={setAllChanges}
          fieldList={getSetAllFormFields(
            props.setAllPopUpFields,
            props.selectedProducts,
            props.commonSetAllStores,
            props.screenName,
            props.referenceStore
          )}
          handleModalClose={() => props.setshowSetAllPopUp(false)}
          formatMultiRowData={formatSetAllData}
          additionalContainer={
            props.screenName !== "product_mapping_store_group" ? (
              <ConflictResolutionModal
                resolutionType={resolutionType}
                setResolutionType={setResolutionType}
              />
            ) : null
          }
        />
      )}
    </>
  );
});
const mapActionsToProps = {
  mapStoreToProduct,
  mapProductToStore,
  addSnack,
};
export default connect(null, mapActionsToProps, null, { forwardRef: true })(
  SetAllComponent
);
