import StyledChip from "Utils/chip/StyledChip";
import { STORE_GROUPING_CHANNEL_BGCOLOR_MAPPER } from "config/constants";
import {
  getAllFilters,
  getFiltersValues,
  getAppSpecificFilters,
} from "actions/filterAction";
import { capitalize, isEcommOrWholeSale } from "Utils/formatter";

export const renderGroupTypeCell = (groupTypeColumn) => {
  groupTypeColumn.cellRenderer = (params, extraProps) => {
    return (
      <StyledChip
        label={
          params.data.special_classification[0].toUpperCase() +
          params.data.special_classification.slice(1)
        }
        color={
          STORE_GROUPING_CHANNEL_BGCOLOR_MAPPER[
            params.data.special_classification
          ]
        }
      />
    );
  };
  return groupTypeColumn;
};
/**
 *
 * @param {array} options
 * @returns Array
 */
export const configureAttributeOptions = (options) => {
  return options.map((item) => {
    return {
      value: item.attribute,
      label: item.attribute,
      id: item.attribute,
    };
  });
};

export const filterStoreChannels = (channels) => {
  return channels.filter((channel) => !isEcommOrWholeSale(channel.attribute));
};

export const fetchStoreFilters = async (
  dependency = [],
  isSGdashboard = false,
  application,
  is_urm_filter,
  screen_name,
  application_code
) => {
  let response = application
    ? await getAppSpecificFilters("store group", application)()
    : await getAllFilters("store group")();
  //For payload preparation to fetch the individual filter dropdown options, we
  //seperate the selection based on the dimension and send the dependency of the
  //dropdown element - That is suppose we are trying to fetch the options for a
  //channel dropdown, as it is part of store dimension, we pass only store dimension
  //dependency as filters to it
  const filterElements = response.data.data.map(async (key) => {
    let body = {
      attribute_name: key.column_name,
      filter_type: key.type,
      filters: dependency.filter(
        (filter) => filter.dimension === key.dimension
      ),
    };
    if (is_urm_filter) {
      body.is_urm_filter = is_urm_filter;
      body.screen_name = screen_name;
      body.application_code = application_code;
    }
    const options = await getFiltersValues(key.dimension, body)();
    key.filter_keyword = key.column_name;
    if (key.column_name === "channel") {
      if (!isSGdashboard) key.is_multiple_selection = false;
      key.initialData = configureAttributeOptions(
        filterStoreChannels(options.data.data.attribute)
      );
    } else {
      key.initialData = configureAttributeOptions(options.data.data.attribute);
    }
    return key;
  });
  await Promise.all(filterElements);
  return response.data.data;
};

export const capitalizeFirstLetterDropdown = (options) => {
  return options.map((option) => {
    return {
      label: capitalize(option),
      value: option,
    };
  });
};
/**
 *
 * @param { filters array } filters
 * @param { dimension } dimension ex : "store" or "product"
 * @param { isAPIPayload } boolean - If the format is for API payload
 * @returns
 */
export const formatFiltersDependency = (
  filters,
  dimension,
  isAPIPayload = false
) => {
  return filters.map((filter) => {
    return {
      ...filter,
      dimension: dimension || filter?.dimension,
      attribute_name: filter.filter_id,
      operator: "in",
      values: Array.isArray(filter.values)
        ? filter.values.map(
            (selectedValue) => selectedValue.value || selectedValue
          )
        : filter.values,
      filter_type: filter.filter_type,
      ...(isAPIPayload && { column_name: filter.attribute_name }),
    };
  });
};

/**
 *
 * @param {Array of Objects} updatedFiltersSelection
 * @returns converted values into objects of label and value for dropdown compatilibility
 */
export const convertFilterStrValsToDropdwnObjects = (
  updatedFiltersSelection
) => {
  return updatedFiltersSelection.map((filter) => {
    return {
      ...filter,
      values: filter.values.map((val) => ({
        label: val,
        value: val,
      })),
    };
  });
};
