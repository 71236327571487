/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import globalStyles from "Styles/globalStyles";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";

const ReportsDashboardComponent = () => {
  const globalClasses = globalStyles();

  const homeIcon = [
    {
      label: "",
      id: 1,
    },
  ];

  return (
    <>
      <HeaderBreadCrumbs options={homeIcon}></HeaderBreadCrumbs>
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "100%", marginLeft: "20px" }}
          data={`https://bkp-common.products.impactsmartsuite.com${window.location.pathname}`}
          // data="https://sandbox.inventory.products.com/ulta/plan-smart/reports-dashboard"
        ></object>
      </div>
    </>
  );
};

export default ReportsDashboardComponent;
