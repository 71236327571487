import React from "react";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import globalStyles from "Styles/globalStyles";

export default function Grouping() {
  const globalClasses = globalStyles();

  const homeIcon = [
    {
      label: "Grouping",
      id: 1,
    },
  ];

  return (
    <>
      <HeaderBreadCrumbs options={homeIcon}></HeaderBreadCrumbs>
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "100vh" }}
          data={`https://bkp.products.impactsmartsuite.com${window.location.pathname}`}
          // data="https://sandbox.inventory.impactsmartsuite.com/inventory-smart/product-profile"
        ></object>
      </div>
    </>
  );
}
