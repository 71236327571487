import React from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import EuroIcon from "@mui/icons-material/Euro";
import DialpadIcon from "@mui/icons-material/Dialpad";
import CategoryIcon from "@mui/icons-material/Category";
import { BsPlusLg, BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { Inventory2Outlined } from "@mui/icons-material";
import {
  SALES_DASHBOARD,
  SPENDS_OVERVIEW,
  BUDGET_PLANNING,
  PROMO_CREATION,
  PROMO_CALENDAR,
  REPORTING,
  MASTERS,
  WORKBENCH,
  DECISION_DASHBOARD,
  POST_PROMO_ANALYTICS,
} from "../constants-promosmart/routesContants";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";
import SalesDashboard from "../pages-promosmart/react-demo/sales-dashboard";
import SpendsOverview from "../pages-promosmart/react-demo/spends-overview";
import Masters from "../pages-promosmart/react-demo/masters";
import BudgetPlanning from "../pages-promosmart/react-demo/budget-planning";
import Reporting from "../pages-promosmart/react-demo/reporting";
import PromoCreation from "../pages-promosmart/react-demo/promo-creation";
import CalendarToday from "@mui/icons-material/CalendarToday";
import PromoCalendar from "../pages-promosmart/react-demo/promo-calendar";
import WorkBench from "../pages-promosmart/react-demo/work-bench";
import DecisionDashboard from "../pages-promosmart/react-demo/Decision Dashboard";
import GridViewIcon from "@mui/icons-material/GridView";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PostPromoAnalytics from "../pages-promosmart/react-demo/post-promo-analytics";

export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + SPENDS_OVERVIEW,
    title: "Spends Overview",
    icon: React.createElement(MonetizationOnIcon),
    order: 1,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + POST_PROMO_ANALYTICS,
    title: "Post Promo Analytics",
    icon: React.createElement(CategoryIcon),
    order: 2,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + SALES_DASHBOARD,
    title: "Sales Dashboard",
    icon: React.createElement(Inventory2Outlined),
    order: 3,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + BUDGET_PLANNING,
    title: "Budget Planning",
    icon: React.createElement(DialpadIcon),
    order: 4,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + WORKBENCH,
    title: "WorkBench",
    icon: React.createElement(BsReverseLayoutTextWindowReverse),
    order: 5,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PROMO_CREATION,
    title: "Promo Creation",
    icon: React.createElement(BsPlusLg),
    order: 6,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + DECISION_DASHBOARD,
    title: "Decision Dashboard",
    icon: React.createElement(GridViewIcon),
    order: 7,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PROMO_CALENDAR,
    title: "Promo Calendar",
    icon: React.createElement(CalendarToday),
    order: 8,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + REPORTING,
    title: "Reporting",
    icon: React.createElement(AutoAwesomeMosaicIcon),
    order: 9,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + MASTERS,
    title: "Masters",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 10,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + SPENDS_OVERVIEW,
      component: SpendsOverview,
      title: "Spends Overview",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + POST_PROMO_ANALYTICS,
      component: PostPromoAnalytics,
      title: "Post Promo Analytics",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + SALES_DASHBOARD,
      component: SalesDashboard,
      title: "Sales Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + BUDGET_PLANNING,
      component: BudgetPlanning,
      title: "Budget Planning",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + WORKBENCH,
      component: WorkBench,
      title: "WorkBench",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PROMO_CREATION,
      component: PromoCreation,
      title: "Promo Creation",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + DECISION_DASHBOARD,
      component: DecisionDashboard,
      title: "Decision Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PROMO_CALENDAR,
      component: PromoCalendar,
      title: "Promo Calendar",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + REPORTING,
      component: Reporting,
      title: "Reporting",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + MASTERS,
      component: Masters,
      title: "Masters",
    },
  ];

  return (
    <Layout routes={routes} sideBarOptions={sideBarOptions} app="promosmart" />
  );
};

export default Routes;
