import { createSlice } from "@reduxjs/toolkit";

import axiosInstance from "Utils/axios";

import {
  GET_MODEL_STOCK_DEEP_DIVE_POP_UP_TABLE_CONFIG,
  GET_MODEL_STOCK_DEEP_DIVE_TABLE_DATA,
  PRODUCT_RULE_DASHBOARD_TABLE_FILTER_CONFIG,
} from "modules/inventorysmart/constants-inventorysmart/apiConstants";

export const modelStockDeepDiveService = createSlice({
  name: "modelStockDeepDiveService",
  initialState: {
    modelStockDeepDiveFilterLoader: false,
    modelStockDeepDiveConfigLoader: false,
    modelStockDeepDivePopupConfigLoader: false,
    modelStockDeepDiveDataLoader: false,
    modelStockDeepDiveTableData: [],
    modelStockDeepDiveFilterElements: [],
    modelStockDeepDiveFilterDependency: [],
    isFiltersValid: false,
    selectedFilters: null,
  },
  reducers: {
    setModelStockDeepDiveFilterLoader: (state, action) => {
      state.modelStockDeepDiveFilterLoader = action.payload;
    },
    setModelStockDeepDiveConfigLoader: (state, action) => {
      state.modelStockDeepDiveConfigLoader = action.payload;
    },
    setModelStockDeepDivePopupConfigLoader: (state, action) => {
      state.modelStockDeepDivePopupConfigLoader = action.payload;
    },
    setModelStockDeepDiveDataLoader: (state, action) => {
      state.modelStockDeepDiveDataLoader = action.payload;
    },
    setModelStockDeepDiveTableData: (state, action) => {
      state.modelStockDeepDiveTableData = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setModelStockDeepDiveFilterElements: (state, action) => {
      state.modelStockDeepDiveFilterElements = action.payload;
    },
    setModelStockDeepDiveFilterDependency: (state, action) => {
      state.modelStockDeepDiveFilterDependency = action.payload;
    },
    setIsFiltersValid: (state, action) => {
      state.isFiltersValid = action.payload;
    },
    clearModelStockDeepDiveStates: (state) => {
      state.modelStockDeepDiveFilterLoader = false;
      state.modelStockDeepDiveConfigLoader = false;
      state.modelStockDeepDivePopupConfigLoader = false;
      state.modelStockDeepDiveDataLoader = false;
      state.modelStockDeepDiveTableData = [];
      state.modelStockDeepDiveFilterElements = [];
      state.modelStockDeepDiveFilterDependency = [];
      state.isFiltersValid = false;
      state.selectedFilters = null;
      state.fiscalDates = null;
    },
  },
});

export const {
  setModelStockDeepDiveFilterLoader,
  setModelStockDeepDiveConfigLoader,
  setModelStockDeepDivePopupConfigLoader,
  setModelStockDeepDiveDataLoader,
  setModelStockDeepDiveTableData,
  setSelectedFilters,
  setModelStockDeepDiveFilterElements,
  setModelStockDeepDiveFilterDependency,
  setIsFiltersValid,
  clearModelStockDeepDiveStates,
} = modelStockDeepDiveService.actions;

export const getModelStockDeepDiveTableConfig = (postBody) => () => {
  return axiosInstance({
    url: `${PRODUCT_RULE_DASHBOARD_TABLE_FILTER_CONFIG}?table_name=${postBody}`,

    method: "GET",
  });
};

export const getModelStockDeepDivePopupTableConfig = () => () => {
  return axiosInstance({
    url: GET_MODEL_STOCK_DEEP_DIVE_POP_UP_TABLE_CONFIG,
    method: "GET",
  });
};

export const getModelStockDeepDiveTableData = (postbody) => () => {
  return axiosInstance({
    url: `${GET_MODEL_STOCK_DEEP_DIVE_TABLE_DATA}/${postbody.meta}`,
    method: "POST",
    data: postbody.filters,
  });
};

export default modelStockDeepDiveService.reducer;
