export const customSetAllField = (fieldId, fieldType, fieldName) => {
  return {
    accessor: fieldId,
    cellStyle: { padding: "0" },
    column_name: fieldId,
    editable: true,
    field: fieldId,
    field_type: fieldType,
    headerName: fieldName,
    id: fieldId,
    is_editable: true,
    is_required: true,
    label: fieldName,
    required: true,
    tooltipField: fieldName,
    type: fieldType,
  };
};
