import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  BsPlusLg,
  BsFillPatchQuestionFill,
  BsReverseLayoutSidebarInsetReverse,
  BsReverseLayoutTextWindowReverse,
} from "react-icons/bs";
import GridViewIcon from "@mui/icons-material/GridView";
import DialpadIcon from "@mui/icons-material/Dialpad";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import TourIcon from "@mui/icons-material/Tour";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import CategoryIcon from "@mui/icons-material/Category";
import {
  CREATE_ALLOCATION,
  PRODUCT_CONFIGURATION,
  DASHBOARD,
  WHATIF,
  CREATE_PRODUCT_PROFILE,
  CONFIGURATION,
  CONSTRAINTS,
  PRODUCT_PROFILE,
  ALLOCATION_REPORT,
  ORDER_BATCHING,
  VIEW_PAST_ALLOCATION,
  ORDER_MANAGEMENT,
  ADA_VISUAL,
  ORDER_REPOSITORY,
  ORDER_REPOSITORY_WHATIF,
  CREATE_NEW_ORDER,
  SETTINGS,
  GROUPING,
} from "../constants-inventorysmart/routesConstants";
import DashboardComponent from "../pages-inventorysmart/Decision-Dashboard";
import WhatIfComponent from "../pages-inventorysmart/WhatIf";
import AdaVisual from "../pages-inventorysmart/ADAVisual";
import OrderRepository from "../pages-inventorysmart/order-repository";
import CreateNewOrder from "../pages-inventorysmart/create-new-order";
import OrderRepositoryWhatif from "../pages-inventorysmart/order-repository-whatif";
import CreateNewAllocationComponent from "../pages-inventorysmart/Create-Allocation";
import CreateProductProfileFiltersComponent from "../pages-inventorysmart/Product-Profile/Create-Product-Profile/create-product-profile-filters";
import Configuration from "../pages-inventorysmart/Configuration";
import Constraints from "../pages-inventorysmart/Constraints";
import Grouping from "../pages-inventorysmart/Grouping";
import ProductProfileComponent from "../pages-inventorysmart/Product-Profile";
import ProductConfiguration from "../pages-inventorysmart/Product-Configuration";
import Settings from "../pages-inventorysmart/Settings";
import Configurations from "../pages-inventorysmart/Configurations";
import AllocationReportingComponent from "../pages-inventorysmart/Allocation-Reporting";
import OrderBatchingComponent from "../pages-inventorysmart/Order-Batching";
import ViewPastAllocation from "../pages-inventorysmart/View-Past-Allocation";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";
import editGrpStores from "pages/store-grouping/components/editGrpStores";
import CreateStoreGroup from "pages/store-grouping/components/createGroup";
import ModifyStoreGroup from "pages/store-grouping/components/modifyGroup";
import { Inventory2Outlined } from "@mui/icons-material";
import {
  getInventorySmartAttributes,
  resetCommonStoreState,
  setInventorysmartScreenConfig,
  setInventorysmartScreenConfigLoader,
} from "../services-inventorysmart/common/inventory-smart-common-services";
import { addSnack } from "actions/snackbarActions";
import CustomPage from "../pages-inventorysmart/CustomPage";
import OrderManagementPage from "../pages-inventorysmart/Order-Management";
export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
    title: "Dashboard",
    icon: React.createElement(GridViewIcon),
    order: 1,
    module: "dashboard",
    onclick: "refresh",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CREATE_NEW_ORDER,
    title: "Create new order",
    icon: React.createElement(BsPlusLg),
    order: 2,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + WHATIF,
    title: "What if",
    icon: React.createElement(BsFillPatchQuestionFill),
    order: 3,
  },
  {
    link:
      "/" +
      window.location.pathname.split("/")[1] +
      `${CREATE_ALLOCATION}?step=0`,
    title: "Create new allocation",
    icon: React.createElement(BubbleChartIcon),
    order: 4,
    module: "inventorysmart_create_allocation",
  },
  // {
  //   link: PRODUCT_CONFIGURATION,
  //   title: "Configuration",
  //   icon: React.createElement(WebhookIcon),
  //   order: 2,
  // },
  {
    link:
      "/" + window.location.pathname.split("/")[1] + GROUPING,
    title: "Grouping",
    icon: React.createElement(DialpadIcon),
    order: 5,
    module: "inventorysmart_grouping",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CONFIGURATION,
    title: "Configuration",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 6,
    module: "inventorysmart_Configuration",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + SETTINGS,
    title: "Settings",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 7,
    module: "inventorysmart_Settings",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + SETTINGS,
    title: "Configurations",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 8,
    module: "inventorysmart_Settings",
  },
  // {
  //   link: CONSTRAINTS,
  //   title: "Constraints",
  //   icon: React.createElement(TourIcon),
  //   order: 5,
  //   module: "inventorysmart_constraints",
  // },
  {
    link: "/" + window.location.pathname.split("/")[1] + PRODUCT_PROFILE,
    title: "Product profile",
    icon: React.createElement(CategoryIcon),
    order: 9,
    module: "inventorysmart_product_profile",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ALLOCATION_REPORT,
    title: "Reports",
    icon: React.createElement(AutoAwesomeMosaicIcon),
    order: 10,
    module: "inventorysmart_allocation_report",
  },
  // {
  //   link: ORDER_BATCHING,
  //   title: "Order Batching",
  //   icon: React.createElement(Inventory2Outlined),
  //   order: 8,
  //   module: "order_triage",
  //  },
  // {
  //   link: VIEW_PAST_ALLOCATION,
  //   title: "View Past Allocation",
  //   icon: React.createElement(AccessTimeIcon),
  //   order: 8,
  // },
  {
    link: "/" + window.location.pathname.split("/")[1] + ORDER_MANAGEMENT,
    title: "Order management",
    icon: React.createElement(BsReverseLayoutSidebarInsetReverse),
    order: 11,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_VISUAL,
    title: "ADA visual",
    icon: React.createElement(Inventory2Outlined),
    order: 12,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ORDER_REPOSITORY,
    title: "Order repository",
    icon: React.createElement(BsReverseLayoutTextWindowReverse),
    order: 13,
  },
];

const Routes = (props) => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
      component: DashboardComponent,
      title: "Dashboard",
      screenName: "InventoryDashboard",
      module: "dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CREATE_NEW_ORDER,
      component: CreateNewOrder,
      title: "Create new order",
      screenName: "Create new order",
      module: "Create_new_order",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + WHATIF,
      component: WhatIfComponent,
      title: "What if",
      screenName: "What if",
      module: "what_if",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CREATE_ALLOCATION,
      component: CreateNewAllocationComponent,
      title: "Create new allocation",
      screenName: "Allocation",
      module: "inventorysmart_create_allocation",
    },
    {
      path:
        "/" + window.location.pathname.split("/")[1] + GROUPING,
      component: Grouping,
      title: "Grouping",
      module: "inventorysmart_grouping",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CONFIGURATION,
      component: Configuration,
      title: "Configuration",
      module: "inventorysmart_configuration",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + SETTINGS,
      component: Settings,
      title: "Settings",
      module: "inventorysmart_settings",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + SETTINGS,
      component: Configurations,
      title: "Configurations",
      module: "inventorysmart_settings",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CONSTRAINTS,
      component: Constraints,
      title: "Constraints",
      screenName: "inventorysmart_constraints",
      module: "inventorysmart_constraints",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PRODUCT_PROFILE,
      component: ProductProfileComponent,
      title: "Product profile",
      screenName: "Product profile",
      module: "inventorysmart_product_profile",
    },
    {
      path:
        "/" + window.location.pathname.split("/")[1] + PRODUCT_CONFIGURATION,
      component: ProductConfiguration,
      title: "Configuration",
      module: "inventorysmart_product_configuration",
    },
    {
      path:
        "/" + window.location.pathname.split("/")[1] + CREATE_PRODUCT_PROFILE,
      component: CreateProductProfileFiltersComponent,
      screenName: "Create product profile",
      module: "inventorysmart_create_product_profile",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ALLOCATION_REPORT,
      component: AllocationReportingComponent,
      title: "Reports",
      screenName: "inventorysmart_loss_sales_reports",
      module: "inventorysmart_allocation_report",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ORDER_BATCHING,
      component: OrderBatchingComponent,
      title: "View current allocations",
      screenName: "inventorysmart_order_triaging",
      module: "order_triage",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + VIEW_PAST_ALLOCATION,
      component: ViewPastAllocation,
      title: "View past allocations",
      screenName: "inventorysmart_view_past_allocations",
      module: "inventorysmart_view_past_allocation",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ORDER_MANAGEMENT,
      component: OrderManagementPage,
      title: "Order management",
      screenName: "inventorysmart_order_management",
      module: "order_management",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_VISUAL,
      component: AdaVisual,
      title: "ADA visuals",
      screenName: "ADA visuals",
      module: "ada_visuals",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ORDER_REPOSITORY,
      component: OrderRepository,
      title: "Order repository",
      screenName: "Order repository",
      module: "order_repositories",
    },
    {
      path:
        "/" + window.location.pathname.split("/")[1] + ORDER_REPOSITORY_WHATIF,
      component: OrderRepositoryWhatif,
      title: "Order repository",
      screenName: "Order repository",
      module: "order_repositories_whatif",
    },
  ];

  const [navBarOptions, setNavBarOptions] = useState([...sideBarOptions]);
  const [navRoutes, setNavRoutes] = useState([...routes]);

  /** Component map to change base component for a particular module for any client if required */
  const routesComponentMap = {
    dashboard: {
      default: DashboardComponent,
    },
    inventorysmart_create_allocation: {
      default: CreateNewAllocationComponent,
    },
    inventorysmart_grouping: {
      default: Grouping,
    },
    inventorysmart_configuration: {
      default: Configuration,
    },
    inventorysmart_constraints: {
      default: Constraints,
    },
    inventorysmart_product_profile: {
      default: ProductProfileComponent,
    },
    inventorysmart_product_configuration: {
      default: ProductConfiguration,
    },
    inventorysmart_create_product_profile: {
      default: CreateProductProfileFiltersComponent,
    },
    inventorysmart_allocation_report: {
      default: AllocationReportingComponent,
    },
    order_triage: {
      default: OrderBatchingComponent,
    },
    inventorysmart_view_past_allocation: {
      default: ViewPastAllocation,
    },
  };

  useEffect(() => {
    const fetchInventorySmartScreenConfiguration = async () => {
      /**
       * Fetch and filter side bar options & routes based on the client config
       * Base component for any component can also be changed here
       * If the config is not defined for any particular module, in that case default side bar options, routes & base components will be applicable
       */
      try {
        props.setInventorysmartScreenConfigLoader(true);
        let config = await props.getInventorySmartAttributes(
          "inventory_smart_screen_configuration"
        );
        const inventorysmartScreenConfig = config.data.data[0]?.attribute_value;
        let navOptions = sideBarOptions;
        let finalRoutes = routes;

        if (inventorysmartScreenConfig) {
          navOptions = navOptions.filter((option) => {
            return (
              inventorysmartScreenConfig?.hiddenModules.indexOf(
                option.module
              ) === -1
            );
          });

          finalRoutes = finalRoutes
            .filter((route) => {
              return (
                inventorysmartScreenConfig?.hiddenModules.indexOf(
                  route.module
                ) === -1
              );
            })
            .map((route) => {
              route.component =
                routesComponentMap[route.module][
                  inventorysmartScreenConfig[route.module]?.component
                ] || routesComponentMap[route.module].default;
              return route;
            });
        }

        setNavBarOptions([...navOptions]);
        setNavRoutes([...finalRoutes]);
        props.setInventorysmartScreenConfig(
          config.data.data[0]?.attribute_value
        );
      } catch (error) {
        console.log("Error", error);
      } finally {
        props.setInventorysmartScreenConfigLoader(false);
      }
    };
    // fetchInventorySmartScreenConfiguration();
  }, []);

  return (
    <Layout
      routes={navRoutes}
      sideBarOptions={navBarOptions}
      loading={props.inventorysmartScreenConfigLoader}
      app={"inventorysmartgeneric"}
    />
  );
};

const mapStateToProps = (store) => {
  return {
    inventorysmartScreenConfigLoader:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfigLoader,
    inventorysmartScreenConfig:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInventorySmartAttributes: (payload) =>
    dispatch(getInventorySmartAttributes(payload)),
  setInventorysmartScreenConfigLoader: (payload) =>
    dispatch(setInventorysmartScreenConfigLoader(payload)),
  setInventorysmartScreenConfig: (payload) =>
    dispatch(setInventorysmartScreenConfig(payload)),
  resetCommonStoreState: (payload) => dispatch(resetCommonStoreState(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
