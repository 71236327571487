import { connect } from "react-redux";
import { forwardRef, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import "./groupTable.scss";
import { useHistory } from "react-router-dom";
import {
  Typography,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
} from "@mui/material";
import GroupName from "./storeGroupName";
import {
  fetchStoreGrpFilteredStores,
  setStoreGroupFilteredStores,
  setSelectedStores,
  ToggleLoader,
  fetchStoreGroups,
  setGroupsCols,
  addSelectedGroups,
  addToExistingStores,
  deletedRowsInEdit,
  newRowsInEdit,
  updateGrp,
  newGrpsInEdit,
  deletedGrpsInEdit,
  setSelectedStoresSelectAllState,
} from "../services-store-grouping/custom-store-group-service";
import { setSelectedFilters } from "../../../actions/filterAction";
import { addSnack } from "../../../actions/snackbarActions";
import { getColumns, getColumnsAg } from "../../../actions/tableColumnActions";
import { isEmpty, cloneDeep } from "lodash";
import { storeGrouping } from "config/routes";
import {
  capitalizeFirstLetterDropdown,
  formatFiltersDependency,
  renderGroupTypeCell,
} from "./common-functions";
import { StyledCheckbox } from "Utils/selection/selection";
import RequestsTable from "pages/product-grouping/components/product-group-components/clusterRequestsTable";
import {
  setStoreGroupCustomLoaderValue,
  fetchStoreGroupCustomClusterInfo,
  setClassificationValue,
  setClusterParamters,
  setClusterTimeFormat,
  setClusterTimePeriod,
  setSelectedCluster,
  setSelectedClusterFilters,
} from "../services-store-grouping/custom-store-group-service";
import moment from "moment";
import globalStyles from "Styles/globalStyles";
import AgGridTable from "Utils/agGrid";

const useStyles = makeStyles((theme) => ({
  grpFiltersContainer: {
    backgroundColor: "white",
    padding: 18,
    margin: theme.typography.pxToRem(20),
  },
  contentStyle: {
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontWeight: 500,
    fontSize: 18,
  },
  actionStyle: {
    backgroundColor: "#F7F7F7",
  },
}));
const FilteredStores = forwardRef((props, ref) => {
  const globalClasses = globalStyles();
  const classes = useStyles();
  const [open, setopen] = useState(false);
  const history = useHistory();
  const [isIncludeGrpsChecked, setisIncludeGrpsChecked] = useState(false);
  const [grpColumns, setgroupColumns] = useState([]);
  const [confirmPopUp, setconfirmPopUp] = useState(false);
  const [openViewClusterStatus, setopenViewClusterStatus] = useState(false);

  const goBack = () => {
    if (props.isEdit) {
      const urlSplitArr = history.location.pathname.split("/");
      const grpId = urlSplitArr.pop();
      props.prevScr
        ? history.push(props.prevScr)
        : history.push(`${storeGrouping.viewGroup}/${grpId}`);
    } else {
      props.prevScr
        ? history.push(props.prevScr)
        : history.push({
            pathname: storeGrouping.home,
          });
    }
  };

  useEffect(() => {
    const fetchColumns = async () => {
      if (isIncludeGrpsChecked && props.groupsCols.length === 0) {
        props.ToggleLoader(true);
        let cols = await props.getColumnsAg("table_name=store_group");
        props.setGroupsCols(cols);
      }
    };
    fetchColumns();
  }, [isIncludeGrpsChecked]);

  useEffect(() => {
    let cols = cloneDeep(props.groupsCols);
    cols = cols.map((col) => {
      if (col.accessor === "special_classification") {
        col = renderGroupTypeCell(col);
      }
      return col;
    });
    setgroupColumns(cols);
  }, [props.groupsCols]);

  useEffect(() => {
    if (isIncludeGrpsChecked) {
      ref.storeGroupTableRef.current?.api?.refreshServerSideStore({
        purge: true,
      });
    } else {
      props.addSelectedGroups([]);
    }
  }, [isIncludeGrpsChecked, props.filteredStores]);

  useEffect(() => {
    let obj = {};
    obj["store_hierarchy"] = [];
    props.setSelectedFilters(obj);
  }, []);

  const rowSelectionHandler = (rows, data) => {
    const unselectdRows = data.filter((storeNode) => {
      return !rows.some((select) => {
        return select.store_code === storeNode.data.store_code;
      });
    });
    if (props.isEdit) {
      //selected rows
      //Already mapped - and in delete window - Remove from Delete
      //Not mapped -
      //If it is not mapped => Add it to new window
      //If it is mapped and excluded => Add it to delete window
      let should_include_in_delete = [];
      let should_exclude_in_delete = [];
      let should_include_in_new = [];
      let should_exclude_in_new = [];
      rows.forEach((row, _idx) => {
        if (row.is_mapped) {
          if (
            props.deleteEditStores.some((del) => {
              return del.store_code === row.store_code;
            })
          ) {
            should_exclude_in_delete.push(row);
          }
        } else {
          if (
            !props.newEditStores.some((newone) => {
              return newone.store_code === row.store_code;
            })
          ) {
            should_include_in_new.push(row);
          }
        }
      });
      unselectdRows.forEach((row, _idx) => {
        if (row.data.is_mapped) {
          if (
            !props.deleteEditStores.some((del) => {
              return del.store_code === row.data.store_code;
            })
          ) {
            should_include_in_delete.push(row.data);
          }
        } else {
          if (
            props.newEditStores.some((newone) => {
              return newone.store_code === row.data.store_code;
            })
          ) {
            should_exclude_in_new.push(row.data);
          }
        }
      });

      let updatedDelete = props.deleteEditStores.filter((del) => {
        return !should_exclude_in_delete.some((exclude) => {
          return exclude.store_code === del.store_code;
        });
      });
      updatedDelete = [...updatedDelete, ...should_include_in_delete];
      props.newRowsInEdit(should_include_in_new);
      props.deletedRowsInEdit(updatedDelete);
    } else {
      props.setSelectedStores(rows);
    }
  };

  const grpSelectionHandler = (grps, data) => {
    if (props.isEdit) {
      //selected rows
      //Already mapped - and in delete window - Remove from Delete
      //Not mapped -
      //If it is not mapped => Add it to new window
      //If it is mapped and excluded => Add it to delete window
      let should_include_in_delete = [];
      let should_exclude_in_delete = [];
      let should_include_in_new = [];
      let should_exclude_in_new = [];
      const unselectdRows = data.filter((prod) => {
        return !grps.some((select) => {
          return select.sg_code === prod.data.sg_code;
        });
      });
      grps.forEach((grp, _idx) => {
        if (grp.is_mapped) {
          if (
            props.deleteEditGrps.some((del) => {
              return del.sg_code === grp.sg_code;
            })
          ) {
            should_exclude_in_delete.push(grp);
          }
        } else {
          if (
            !props.newEditGrps.some((newone) => {
              return newone.sg_code === grp.sg_code;
            })
          ) {
            should_include_in_new.push(grp);
          }
        }
      });
      unselectdRows.forEach((row, _idx) => {
        if (row.data.is_mapped) {
          if (
            !props.deleteEditGrps.some((del) => {
              return del.sg_code === row.data.sg_code;
            })
          ) {
            should_include_in_delete.push(row.data);
          }
        } else {
          if (
            props.newEditGrps.some((newone) => {
              return newone.sg_code === row.data.sg_code;
            })
          ) {
            should_exclude_in_new.push(row.data);
          }
        }
      });

      let updatedDelete = props.deleteEditGrps.filter((del) => {
        return !should_exclude_in_delete.some((exclude) => {
          return exclude.sg_code === del.sg_code;
        });
      });
      updatedDelete = [...updatedDelete, ...should_include_in_delete];
      props.newGrpsInEdit(should_include_in_new);
      props.deletedGrpsInEdit(updatedDelete);
    } else {
      props.addSelectedGroups(grps);
    }
  };

  const toggleModalState = (status) => {
    if (props.isEdit) {
      onUpdate();
      return;
    }
    setopen(status);
  };

  const onUpdate = async () => {
    const body = {
      name: props.grpObj.name,
      special_classification: props.selectedGroupType,
      channel: props.manualSelectedStoreFilters.filter(
        (filter) => filter.filter_id === "channel"
      )[0].values[0],
    };
    let selectedFilters = [];
    if (props.manualSelectedStoreFilters) {
      const storeFilters = formatFiltersDependency(
        props.manualSelectedStoreFilters,
        "store",
        true
      );
      selectedFilters.push(...storeFilters);
    }
    if (props.manualSelectedProductFilters) {
      const productFilters = formatFiltersDependency(
        props.manualSelectedProductFilters,
        "product",
        true
      );
      selectedFilters.push(...productFilters);
    }
    body["store_ids"] = {
      filters: selectedFilters,
      meta: {
        search: [],
        range: [],
        sort: [],
      },
      metrics: [],
      selection: {
        data: [
          {
            searchColumns: {
              is_mapped: {
                filterType: "bool",
                filter: true,
              },
            },
            checkAll: true,
          },
          ...(ref.storeTableRef?.current?.api?.checkConfiguration || []),
        ],
        unique_columns: ["store_code"],
      },
    };
    body["store_group_ids"] = {
      filters: selectedFilters,
      meta: {
        search: [],
        range: [],
        sort: [],
      },
      metrics: [],
      selection: {
        data: ref.storeGroupTableRef?.current?.api?.checkConfiguration || [],
        unique_columns: ["sg_code"],
      },
    };
    try {
      props.ToggleLoader(true);
      const urlSplitArr = history.location.pathname.split("/");
      const grpId = urlSplitArr.pop();
      await props.updateGrp(grpId, body);
      props.newRowsInEdit([]);
      props.deletedRowsInEdit([]);
      props.newGrpsInEdit([]);
      props.deletedGrpsInEdit([]);
      props.addSnack({
        message: "Updated successfully",
        options: {
          variant: "success",
          onClose: () =>
            props.prevScr
              ? history.push(props.prevScr)
              : history.push(`${storeGrouping.viewGroup}/${grpId}`),
        },
      });
      props.ToggleLoader(false);
    } catch (error) {
      props.ToggleLoader(false);
      props.addSnack({
        message: "Update Failed",
        options: {
          variant: "error",
        },
      });
    }
  };

  const manualCallBack = async (body, pageIndex, params) => {
    let manualbody = {};
    manualbody = {
      filters: [],
      meta: { ...body },
      metrics: [],
    };
    if (props.selectedGroupType === "custom") {
      let dependency = props.selectedClusterFilters
        ? props.selectedClusterFilters.map((item) => {
            return {
              attribute_name: item.filter_id,
              operator: "in",
              column_name: item.filter_id,
              dimension: item.dimension || "store",
              values: Array.isArray(item.values)
                ? item.values.map((opt) => opt.value)
                : item.values,
              filter_type: item.filter_type,
            };
          })
        : [];
      manualbody = {
        ...manualbody,
        filters: dependency,
        request_id: props.selectedCluster.id,
      };
    } else {
      let selectedFilters = [];
      if (ref.storeFiltersRef.current) {
        selectedFilters.push(...ref.storeFiltersRef.current);
      }
      if (ref.productFiltersRef.current) {
        selectedFilters.push(...ref.productFiltersRef.current);
      }
      let dependency = selectedFilters.map((item) => {
        return {
          attribute_name: item.filter_id,
          operator: "in",
          column_name: item.filter_id,
          dimension: item.dimension || "store",
          values: Array.isArray(item.values)
            ? item.values.map((opt) => opt.value || opt)
            : item.values,
          filter_type: item.filter_type,
        };
      });
      manualbody = {
        ...manualbody,
        filters: dependency,
      };
    }
    try {
      props.ToggleLoader(true);
      if (!manualbody?.filters?.length) {
        props.ToggleLoader(false);
        return {
          data: [],
          totalCount: 0,
        };
      }
      if (props.isEdit) {
        manualbody = {
          ...manualbody,
          selection: {
            data: [
              {
                searchColumns: {
                  is_mapped: {
                    filterType: "bool",
                    filter: true,
                  },
                },
                checkAll: true,
              },
              ...params?.api?.checkConfiguration,
            ],
            unique_columns: ["store_code"],
          },
        };
        const urlSplitArr = history.location.pathname.split("/");
        const grpId = urlSplitArr.pop();
        const res = await props.fetchStoreGrpFilteredStores(
          manualbody,
          grpId,
          pageIndex + 1
        );
        props.setStoreGroupFilteredStores({
          data: res.data.data,
          count: res.data.total,
        });
        props.ToggleLoader(false);
        return {
          data: res.data.data,
          totalCount: res.data.total,
        };
      } else {
        manualbody = {
          ...manualbody,
          selection: {
            data: params?.api?.checkConfiguration,
            unique_columns: ["store_code"],
          },
        };
        const res = await props.fetchStoreGrpFilteredStores(
          manualbody,
          "",
          pageIndex + 1
        );
        props.setStoreGroupFilteredStores({
          data: res.data.data,
          count: res.data.total,
        });
        props.ToggleLoader(false);
        return {
          data: res.data.data,
          totalCount: res.data.total,
        };
      }
    } catch (error) {
      props.ToggleLoader(false);
      props.addSnack({
        message: "Something went wrong.",
        options: {
          variant: "error",
        },
      });
      //Error handling
    }
  };

  const manualGrpCallBack = async (body, pageIndex, params) => {
    try {
      props.ToggleLoader(true);
      let selectedFilters = [];
      if (ref.storeFiltersRef.current) {
        const storeFilters = formatFiltersDependency(
          ref.storeFiltersRef.current,
          "store",
          true
        );
        selectedFilters.push(...storeFilters);
      }
      if (ref.productFiltersRef.current) {
        const productFilters = formatFiltersDependency(
          ref.productFiltersRef.current,
          "product",
          true
        );
        selectedFilters.push(...productFilters);
      }
      let dependency = selectedFilters;
      if (dependency.length === 0) {
        props.ToggleLoader(false);
        return {
          data: [],
          totalCount: 0,
        };
      }
      let manualbody = {
        filters: dependency,
        meta: { ...body },
        metrics: [],
      };
      if (props.isEdit) {
        manualbody = {
          ...manualbody,
          selection: {
            data: params?.api?.checkConfiguration,
            unique_columns: ["sg_code"],
          },
        };
        const urlSplitArr = history.location.pathname.split("/");
        const grpId = urlSplitArr.pop();
        const res = await props.fetchStoreGroups(
          manualbody,
          grpId,
          pageIndex + 1
        );
        props.ToggleLoader(false);
        return {
          data: res.data.data,
          total: res.data.total,
        };
      } else {
        manualbody = {
          ...manualbody,
          selection: {
            data: params?.api?.checkConfiguration,
            unique_columns: ["sg_code"],
          },
        };
        const res = await props.fetchStoreGroups(manualbody, "", pageIndex + 1);
        props.ToggleLoader(false);
        return {
          data: res.data.data,
          total: res.data.total,
        };
      }
    } catch (error) {
      //Error handling
      props.ToggleLoader(false);
      props.addSnack({
        message: "Something went wrong.",
        options: {
          variant: "error",
        },
      });
    }
  };
  const handleClose = () => {
    setconfirmPopUp(false);
  };

  const onProceed = () => {
    setconfirmPopUp(false);
    if (props.isEdit) {
      const urlSplitArr = history.location.pathname.split("/");
      const grpId = urlSplitArr.pop();
      history.push({
        pathname: `${storeGrouping.viewGroup}/${grpId}`,
      });
    } else {
      history.push(storeGrouping.home);
    }
  };

  const onClickViewClusterStatus = () => {
    setopenViewClusterStatus(true);
  };
  const closeViewClusterStatus = async (callBackData = {}) => {
    setopenViewClusterStatus(false);
    if (!isEmpty(callBackData)) {
      props.setStoreGroupCustomLoaderValue(true);
      const reqInfo = await props.fetchStoreGroupCustomClusterInfo(
        callBackData.id
      );

      const body = {
        filters: reqInfo.data.data.metrics.filters,
        sort: [],
        range: [],
        search: [],
        definitions: [],
        request_id: callBackData.id,
      };
      /**
       * Here assign the cluster filters to respective variables to populate
       */
      const filters = reqInfo.data.data.metrics.filters.map((filter) => {
        return {
          ...filter,
          filter_id: filter.attribute_name,
          values: filter.values.map((val) => {
            return {
              label: val,
              value: val,
            };
          }),
        };
      });
      props.setSelectedClusterFilters(filters);
      props.setClassificationValue(
        reqInfo.data.data.metrics.metrics.classification
      );
      props.setClusterParamters(
        capitalizeFirstLetterDropdown(reqInfo.data.data.metrics.metrics.value)
      );
      props.setClusterTimeFormat(
        capitalizeFirstLetterDropdown([
          reqInfo.data.data.metrics.metrics.time_format,
        ])
      );
      props.setClusterTimePeriod([
        moment(reqInfo.data.data.metrics.metrics.start_date, "YYYY-MM-DD"),
        moment(reqInfo.data.data.metrics.metrics.end_date, "YYYY-MM-DD"),
      ]);
      const res = await props.fetchStoreGrpFilteredStores(body);
      props.setStoreGroupFilteredStores({
        data: res.data.data,
        count: res.data.total,
      });
      props.setSelectedCluster(reqInfo.data.data);
      props.setStoreGroupCustomLoaderValue(false);
    }
  };

  useEffect(() => {
    closeViewClusterStatus(props.selectedCluster);
  }, [props.selectedCluster?.id]);

  const onStoreLevelSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    rowSelectionHandler(selectedRows, event.api.getRenderedNodes());
  };

  const onStoreGroupLevelSelectionChanged = (event) => {
    const selectedGrpRows = event.api.getSelectedRows();
    grpSelectionHandler(selectedGrpRows, event.api.getRenderedNodes());
  };

  return (
    <>
      <Dialog
        id="storeGrpingCnclDialog"
        open={confirmPopUp}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent classes={{ root: classes.contentStyle }}>
          <Typography className={classes.textStyle}>
            Changes will be lost. Are you sure want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.actionStyle }}>
          <Button
            id="storeGrpingCnclDialogCnclBtn"
            size="small"
            className={classes.btnStyle}
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            id="storeGrpingCnclDialogCnfmBtn"
            size="small"
            variant="contained"
            onClick={onProceed}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {open && (
        <GroupName
          ref={{
            storeTableRef: ref.storeTableRef.current,
            storeGroupTableRef: ref.storeGroupTableRef.current,
          }}
          id="storeGrpingGrpNameComp"
          open={open}
          handleClose={() => toggleModalState(false)}
          prevScr={props.prevScr}
        />
      )}
      {openViewClusterStatus && (
        <RequestsTable
          dimension="store"
          selectedGroupType={props.selectedGroupType}
          open={openViewClusterStatus}
          handleClose={closeViewClusterStatus}
        />
      )}
      {props.columns.length !== 0 && (
        <Paper
          id={props.isEdit ? "storeGrpingEditCont" : "storeGrpingCrtCont"}
          elevation={0}
        >
          <div
            className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter}`}
          >
            <Typography variant="h5" id="storeGrpingCrtEditTableTitle">
              Filtered Stores
            </Typography>

            {props.selectedGroupType === "manual" && (
              <FormControlLabel
                id="storeGrpingIncldGrpsFormLabel"
                control={
                  <StyledCheckbox
                    id="storeGrpingIncldGrpsChckbox"
                    color="primary"
                    checked={isIncludeGrpsChecked}
                    onChange={(event) =>
                      setisIncludeGrpsChecked(event.target.checked)
                    }
                  />
                }
                label="Include Store Groups"
              />
            )}
            {props.selectedGroupType !== "manual" && (
              <Button
                onClick={onClickViewClusterStatus}
                variant="contained"
                color="primary"
              >
                View Cluster Status
              </Button>
            )}
          </div>
          <AgGridTable
            columns={cloneDeep(props.columns)}
            selectAllHeaderComponent={true}
            sizeColumnsToFitFlag
            onGridChanged
            onRowSelected
            manualCallBack={(body, pageIndex, params) =>
              manualCallBack(body, pageIndex, params)
            }
            loadTableInstance={(gridInstance) => {
              ref.storeTableRef.current = gridInstance;
            }}
            rowModelType="serverSide"
            serverSideStoreType="partial"
            cacheBlockSize={10}
            uniqueRowId={"store_code"}
            onSelectionChanged={onStoreLevelSelectionChanged}
          />
        </Paper>
      )}
      {isIncludeGrpsChecked && grpColumns.length > 0 && (
        <Paper
          id={
            props.isEdit ? "storeGrpingEditGrpsCont" : "storeGrpingCrtGrpsCont"
          }
          className={globalClasses.marginTop}
          elevation={0}
        >
          <Typography variant="h5" id="storeGrpingCrtEditTableTitle">
            Filtered Grouping
          </Typography>
          <AgGridTable
            columns={grpColumns}
            selectAllHeaderComponent={true}
            sizeColumnsToFitFlag
            onGridChanged
            onRowSelected
            manualCallBack={(body, pageIndex, params) =>
              manualGrpCallBack(body, pageIndex, params)
            }
            loadTableInstance={(gridInstance) => {
              ref.storeGroupTableRef.current = gridInstance;
            }}
            rowModelType="serverSide"
            serverSideStoreType="partial"
            cacheBlockSize={10}
            uniqueRowId={"sg_code"}
            onSelectionChanged={onStoreGroupLevelSelectionChanged}
          />
        </Paper>
      )}
      <div
        className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.marginTop}`}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => toggleModalState(true)}
          id={props.isEdit ? "storeGrpingUpdBtn" : "storeGrpingSaveBtn"}
        >
          {props.isEdit ? "Update" : "Save"}
        </Button>
        <Button
          variant="outlined"
          onClick={goBack}
          id={props.isEdit ? "storeGrpingEditCnclBtn" : "storeGrpingCrtCnclBtn"}
        >
          {" "}
          Cancel
        </Button>
      </div>
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    selectedStores: state.storeGroupReducer.selectedstores,
    columns: state.storeGroupReducer.manualFilteredStoresCols,
    selectedFilters: state.filterReducer.selectedFilters["store_hierarchy"],
    manualSelectedStoreFilters:
      state.filterReducer.selectedFilters["store_filters_create_group"],
    manualSelectedProductFilters:
      state.filterReducer.selectedFilters["product_filters_create_group"],
    filteredStores: state.storeGroupReducer.manualFilteredStores,
    filteredStoresCount: state.storeGroupReducer.manualFilteredStoresCount,
    selectedManualFilterType: state.storeGroupReducer.selectedManualFilterType,
    groupsCols: [...state.storeGroupReducer.groupsTableCols],
    selectedGrps: state.storeGroupReducer.manualselectedGroups,
    existingStores: state.storeGroupReducer.exisitingStoresInEdit,
    newEditStores: state.storeGroupReducer.newStoresInEdit,
    deleteEditStores: state.storeGroupReducer.deletedStoresInEdit,
    selectedGroupType: state.storeGroupReducer.selectedGroupType,
    newEditGrps: state.storeGroupReducer.newGrpsInEdit,
    deleteEditGrps: state.storeGroupReducer.deletedGrpsInEdit,
    selectedCluster: state.storeGroupReducer.selectedCluster,
    selectedClusterFilters: state.storeGroupReducer.selectedClusterFilters,
  };
};

const mapActionsToProps = {
  fetchStoreGrpFilteredStores,
  setStoreGroupFilteredStores,
  setSelectedStores,
  ToggleLoader,
  fetchStoreGroups,
  setGroupsCols,
  getColumns,
  addSelectedGroups,
  addToExistingStores,
  newRowsInEdit,
  deletedRowsInEdit,
  updateGrp,
  addSnack,
  setSelectedFilters,
  deletedGrpsInEdit,
  newGrpsInEdit,
  setSelectedStoresSelectAllState,
  setStoreGroupCustomLoaderValue,
  fetchStoreGroupCustomClusterInfo,
  setClassificationValue,
  setClusterParamters,
  setClusterTimeFormat,
  setClusterTimePeriod,
  setSelectedCluster,
  setSelectedClusterFilters,
  getColumnsAg,
};

export default connect(mapStateToProps, mapActionsToProps, null, {
  forwardRef: true,
})(FilteredStores);
