import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { DASHBOARD, HOME } from "../constants-clustersmart/routesConstants.js";
import DashboardComponent from "../pages-clustersmart/dashboard";
import ClusterHomeComponent from "../pages-clustersmart/home-page";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";

export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
    title: "Clustering",
    icon: React.createElement(DashboardIcon),
    order: 1,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
      component: DashboardComponent,
      title: "Metric Selection",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + HOME,
      component: ClusterHomeComponent,
      title: "Home",
    },
  ];
  return (
    <Layout
      routes={routes}
      sideBarOptions={sideBarOptions}
      app={"clustersmart"}
    />
  );
};
const mapStateToProps = () => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Routes));
