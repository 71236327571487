import { Button, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import globalStyles from "Styles/globalStyles";

export const ReviewClusters = (props) => {
  const globalClasses = globalStyles();
  const gotoPreviousStep = () => {};
  const gotoNextStep = () => {};

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Performance Clusters Chart
      </Typography>
      <div className={`${globalClasses.flexRow} ${globalClasses.gap}`}>
        <Button color="primary" variant="outlined" onClick={gotoPreviousStep}>
          Back
        </Button>
        <Button color="primary" variant="contained" onClick={gotoNextStep}>
          Next
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewClusters);
