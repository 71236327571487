// Dashboard API URLs
export const PLANSMART_DASHBOARD_COLUMN_DATA =
  "plan-smart/dashboard/table_conf";
export const PLANSMART_DASHBOARD_TABLE_DATA = "plan-smart/dashboard/data/list";
export const PLANSMART_DASHBOARD_FILTER_CONFIG =
  "/plan-smart/dashboard/filter_conf";
export const PLANSMART_DELETE_PLAN = "plan-smart/plan/delete";

// Create Plan URLs
export const GET_PLAN_LEVELS = "/plan-smart/plan/screen_conf";
export const GET_PLAN_ATTRIBUTES = "/plan-smart/plan/attributes";
export const CREATE_PLAN = "/plan-smart/plan/create_plan";
export const GET_STORE_ATTRIBUTES = "/plan-smart/plan/store-attributes";

// RETRIEVE PLAN URL
export const PLANSMART_BUDGET_PLAN_DETAILS = "/plan-smart/plan/retrieve";

// FETCH BUDGET TABLE DATA FOR A PLAN
export const PLANSMART_BUDGETTABLE_PLANCODE_DETAILS = "/plan-smart/budget/list";
export const PLANSMART_PLANNING_COLUMN_CONF =
  "/plan-smart/plan/planning-col-conf";
export const PLANSMART_PLAN_HIERARCHIES =
  "/plan-smart/plan/planning-hierarchies";
export const PLANSMART_PLAN_FILTER_OPTIONS =
  "/plan-smart/plan/planning-filters/";
export const PLANSMART_UPDATE_BUDGET_TABLE = "/plan-smart/budget";
export const PLANSMART_FORECASTED_DATA = "/plan-smart/plan/forecast-data/";
export const PLANSMART_COMPARE_PLAN_COL_DEF =
  "/plan-smart/plan/compare-plan-schema";
export const PLANSMART_COMPARE_PLAN_FILTER_CONFIG =
  "/plan-smart/plan/compare-plan-filter-config/";
export const PLANSMART_GET_PLANS_TO_COMPARE = "/plan-smart/budget/list-compare";
export const PLANSMART_UPDATE_BUDGET_TABLE_MATCH_WITH =
  "/plan-smart/budget/match-with";
export const PLANSMART_SKU_VIEW_DATA = "/plan-smart/budget/sku-data";
export const PLANSMART_SKU_VIEW_COL_DEF = "/plan-smart/plan/sku-col-conf";

//MASTER PLAN FILTER CONFIGURATION
export const PLANSMART_MASTER_PLAN_FILTER_CONFIG =
  "plan-smart/master-plan/filter-conf";

export const PLANSMART_MASTER_PLAN_COLUMN_CONF =
  "/plan-smart/master-plan/planning-col-conf";

export const PLANSMART_MASTER_PLAN_TABLE_DATA =
  "/plan-smart/master-plan/table-data";
//report
export const PLANSMART_REPORT_KPI_COLUMN_CONF =
  "/plan-smart/reports/planning-col-conf";
export const PLANSMART_REPORT_KPI_TABLE_DATA = "/plan-smart/reports/table-data";
export const PLANSMART_REPORT_SAVE_TEMPLATE =
  "/plan-smart/reports/save-template";
export const PLANSMART_REPORT_GET_TEMPLATE = "/plan-smart/reports/get-template";
export const PLANSMART_REPORT_TEMPLATE_TABLE_COL_DEF =
  "/plan-smart/reports/template-col-def";
export const PLANSMART_SAVE_PLAN = "/plan-smart/plan/save-plan";
export const PLANSMART_COPY_PLAN = "/plan-smart/plan/copy-plan";
export const PLANSMART_PLAN_METRIC_CONFIG = "/plan-smart/config/metrics";
export const PLANSMART_PIVOT_VIEW_DATA = "/plan-smart/budget/pivot-data";
export const PLANSMART_PIVOT_VIEW_COL_DEF = "/plan-smart/plan/pivot-col-conf";
export const PLANSMART_PIVOT_VIEW_SAVE_TEMPLATE =
  "/plan-smart/plan/pivot-save-template";
export const PLANSMART_EDITABLE_METRICS_TENANT_CONFIG =
  "/plan-smart/config/editable-kpis";
export const PLANSMART_EDITABLE_METRICS_TENANT_CONFIG_SAVE =
  "/plan-smart/config/editable-kpis";
export const PLANSMART_EDITABLE_METRIC_TENANT_CONFIG_TABLE_COL_DEF =
  "core/table-fields?table_name=plan_smart_editable_metrics";
export const PLANSMART_EDITABLE_METRIC_FETCH_FORMULA =
  "/plan-smart/config/editable-kpis/formulas";
