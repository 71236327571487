import { createSlice } from "@reduxjs/toolkit";

import axiosInstance from "Utils/axios";
import {
  GET_ROLE_MGMT_FILTER_CONFIG,
  TABLE_HIERARCHY_ASSIGN_ROLE,
  FETCH_TABLE_BASED_ON_FILTER,
  GET_ROLE_MGMT_FILTER_ATTRIBUTES,
  GET_FILTERED_ATTRS_TO_ASSING_ROLE,
  SAVE_UPDATE_DELETE_USER_ROLE_MAPPING,
  GET_ROLE_MGMT_TABLE_CONFIG,
  ASSIGN_USER_ROLE_FORM_FILTERS,
  CROSS_DIMENSIONAL_API,
} from "config/api";

export const userRoleManagementService = createSlice({
  name: "userRoleManagementService",
  initialState: {
    assignUserRoleMgmtLoader: false,
    loaderUserRoleMgmt: false,
    userRoleTableData: [],
    assignRoleToUserTable: [],
    assignRoleFilterList: [],
    filtersList: [],
    isSuperUser: false,
    planningLevelHierarchy: [],
    userAccessList: { create: {}, delete: {}, edit: {}, approve: {} },
    tenantDateFormat: "MM-DD-YYYY",
    tenantTimeZone: "",
    filter_attribute_exclusion_values: [],
  },
  reducers: {
    setRolesMappedToUserData: (state, action) => {
      state.userRoleTableData = action.payload;
      state.loaderUserRoleMgmt = false;
    },
    setUserRoleMgmtLoader: (state, action) => {
      state.loaderUserRoleMgmt = action.payload;
    },
    setAssignRoleUserRoleMgmtLoader: (state, action) => {
      state.assignRoleUserRoleMgmtLoader = action.payload;
    },
    setFilterConfiguration: (state, action) => {
      state.filtersList = action.payload;
      state.loaderUserRoleMgmt = false;
    },
    setAssignRoleFilterConfiguration: (state, action) => {
      state.assignRoleFilterList = action.payload;
    },
    setTableHierarchyOnAssignRole: (state, action) => {
      state.assignRoleToUserTable = action.payload;
    },
    clearTableHierarchyOnAssignRole: (state, _action) => {
      state.assignRoleToUserTable = [];
      state.assignRoleFilterList = [];
    },
    clearUserRoleConfigStates: (state, _action) => {
      state.filtersList = [];
      state.userRoleTableData = [];
    },
    setIsSuperUser: (state, action) => {
      state.isSuperUser = action.payload;
    },
    setPlanningLevelHierarchy: (state, action) => {
      state.planningLevelHierarchy = action.payload;
    },
    setUserAccessList: (state, action) => {
      state.userAccessList = action.payload;
    },
    setfilterAttributeExclusionValues: (state, action) => {
      state.filter_attribute_exclusion_values = action.payload;
    },
    setTenantTimeconfig: (state, action) => {
      state.tenantTimeZone = action.payload[0].attribute_value.value.time_zone;
      state.tenantDateFormat =
        action.payload[0].attribute_value.value.time_format;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setRolesMappedToUserData,
  setUserRoleMgmtLoader,
  setFilterConfiguration,
  setAssignRoleFilterConfiguration,
  setTableHierarchyOnAssignRole,
  setAssignRoleUserRoleMgmtLoader,
  clearTableHierarchyOnAssignRole,
  clearUserRoleConfigStates,
  setIsSuperUser,
  setPlanningLevelHierarchy,
  setUserAccessList,
  setTenantTimeconfig,
  setfilterAttributeExclusionValues,
} = userRoleManagementService.actions;

export const getFilterConfiguration = () => () => {
  return axiosInstance({
    url: GET_ROLE_MGMT_FILTER_CONFIG,
    method: "GET",
    data: "",
  });
};

export const getTableConfig = () => async () => {
  return axiosInstance({
    url: GET_ROLE_MGMT_TABLE_CONFIG,
    method: "GET",
  });
};

export const getTableHierarchyOnAssignRole = (postBody) => () => {
  return axiosInstance({
    url: TABLE_HIERARCHY_ASSIGN_ROLE,
    method: "POST",
    data: postBody,
  });
};

export const getFilteredAttributesToAssignRole = (postBody) => async () => {
  return axiosInstance({
    url: `${GET_FILTERED_ATTRS_TO_ASSING_ROLE}`,
    method: "POST",
    data: postBody,
  });
};

export const getUserFilterAttributes = (postBody) => async () => {
  return axiosInstance({
    url: `${GET_ROLE_MGMT_FILTER_ATTRIBUTES}`,
    method: "POST",
    data: postBody,
  });
};

export const saveUserRoleMappings = (postBody) => async () => {
  return axiosInstance({
    url: `${SAVE_UPDATE_DELETE_USER_ROLE_MAPPING}`,
    method: "POST",
    data: postBody,
  });
};

export const updateUserRoleMappings = (postBody) => async () => {
  return axiosInstance({
    url: `${SAVE_UPDATE_DELETE_USER_ROLE_MAPPING}`,
    method: "PUT",
    data: postBody,
  });
};

export const getRolesMappedToUserData = (postBody) => async () => {
  return axiosInstance({
    url: `${FETCH_TABLE_BASED_ON_FILTER}`,
    method: "POST",
    data: postBody,
  });
};

export const deleteRolesAssigned = (id) => async () => {
  return axiosInstance({
    url: `${SAVE_UPDATE_DELETE_USER_ROLE_MAPPING}/${id}`,
    method: "DELETE",
  });
};

export const getUserDetailsFormFilter = (postBody) => async () => {
  return axiosInstance({
    url: `${ASSIGN_USER_ROLE_FORM_FILTERS}`,
    method: "POST",
    data: postBody,
  });
};

export const getCrossFiltersData = (postBody) => () => {
  return axiosInstance({
    url: CROSS_DIMENSIONAL_API,
    method: "POST",
    data: postBody,
  });
};

export default userRoleManagementService.reducer;
