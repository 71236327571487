import { Button, Tooltip } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { PLAN_SMART_PLAN_DETAILS } from "modules/plansmart/constants-plansmart/routesConstants";
import { parseBudgetTableResponseData } from "../plansmart-budget-table/budget-table-functions";
import {
  getHeaderForExcel,
  csvFormatter,
  fetchInitialPlans,
} from "../plansmart-utility";
import {
  SCENARIO_FORECAST,
  SCENARIO_PLAN,
  statusCodeBasedOnPlanType,
  REGULAR_PLAN,
  FINAL_FORECAST,
  seasonTypesObj,
} from "modules/plansmart/constants-plansmart/stringConstants";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";

export const fetchDataPlansmartDashboard = async (
  props,
  setDashboardTableCols
) => {
  props.setPlansmartDashboardLoader(true);
  let dashboardColumns = await props.getPlanSmartDashboardColumns({});
  let columns = dashboardColumns?.data?.data;
  columns.forEach((column) => {
    if (column.column_name === "action") {
      return Object.assign(column, { is_hidden: true });
    }
    if (column.type === "DateTimeField") {
      return Object.assign(column, { dateFormatter: "MM/DD/YYYY" });
    }
  });
  setDashboardTableCols(agGridColumnFormatter(columns));
  props.setPlansmartDashboardLoader(false);
};

export const editViewUi = (selectedRows, classes, history) => {
  let actionArray = selectedRows?.[0]?.action;
  let viewAction = actionArray?.indexOf("view") !== -1 ? true : false;
  let editAction = actionArray?.indexOf("edit") !== -1 ? true : false;

  const editButton = () => {
    return (
      <Tooltip title="Edit">
        <Button
          className={classes.button}
          variant="contained"
          onClick={() =>
            onViewEditClick(selectedRows[0].plan_code, "edit", history)
          }
        >
          <EditIcon />
        </Button>
      </Tooltip>
    );
  };

  const viewButton = () => {
    return (
      <Tooltip title="View">
        <Button
          className={classes.button}
          variant="contained"
          onClick={() =>
            onViewEditClick(selectedRows[0].plan_code, "view", history)
          }
        >
          <Visibility />
        </Button>
      </Tooltip>
    );
  };

  if (viewAction && editAction) {
    return (
      <>
        {editButton()}
        {viewButton()}
      </>
    );
  } else if (!editAction && viewAction) {
    return viewButton();
  } else if (editAction && !viewAction) {
    return editButton();
  }
};

export const onViewEditClick = (plan_code, action, history) => {
  history.location.pathname = `${PLAN_SMART_PLAN_DETAILS}/${plan_code}`;
  history.location.type = action === "view" ? "view" : "";
  history.push(history.location);
};

/**
 * @function
 * @desc Fetch Headers for the required excel
 */

/**
 * @function
 * @desc Deleting the plan
 */
export const onDeletePlan = async (props, selectedRows, history) => {
  let selectedPlanCode = selectedRows?.map((dashboardRowData) => {
    return dashboardRowData.plan_code;
  });
  let deletePlanResponse = await props.deletePlanSmartPlanAPI({
    plan_codes: selectedPlanCode,
  });
  if (deletePlanResponse?.data?.status) {
    generateSnackMessages(
      "Plan(s) deleted successfully",
      "success",
      props.addSnack
    );
    fetchInitialPlans(history?.location?.path, props);
  } else {
    generateSnackMessages("Error in deleting plan(s)", "error", props.addSnack);
  }
};

export const generateSnackMessages = (response, variant, addSnack) => {
  addSnack({
    message: response,
    options: {
      variant,
    },
  });
};

//get the plan hierarchy data from the selected plan for filters
export const getFilterForRows = (obj, planHierarchies) => {
  let filteredRows = {};

  planHierarchies.forEach((planHierarchy) => {
    filteredRows[planHierarchy.column_name] = obj[planHierarchy.column_name];
  });

  return filteredRows;
};

//getting the data for the plan selected from dashboard
export const getDownloadData = async (
  obj,
  setCsvData,
  setCsvHeaders,
  props
) => {
  let planCode = obj.plan_code;
  //fetching the headers for the plan selected from dashboard
  let planColDef = await props.getPlanningTableColumns(planCode);
  let planTableHeaders = getHeaderForExcel(planColDef.data.data);
  setCsvHeaders(planTableHeaders);

  let filterForRows = getFilterForRows(obj, props.planHierarchies);
  const postBody = {
    plan_code: planCode,
    level: filterForRows,
  };
  //fetching the values for the plan selected from dashboard
  try {
    let csvValues = await props.fetchPlanBudgetDetails(postBody);
    let parsedData = parseBudgetTableResponseData(csvValues?.data?.data, []);
    let planTableValues = csvFormatter(parsedData, planTableHeaders);
    setCsvData(planTableValues);
    props.setPlansmartDashboardLoader(false);
  } catch (error) {
    props.addSnack({
      message: "Error while coping plan",
      options: {
        variant: "error",
      },
    });
    props.setPlansmartDashboardLoader(false);
  }
};

export const disbaleCreateOrReviewButton = (props, selectedRows) => {
  if (props?.userAccessList?.["create"] && !props?.isInSeasonDashbaord) {
    return false;
  } else if (
    props?.userAccessList?.["create"] &&
    props?.isInSeasonDashbaord &&
    selectedRows?.length === 1
  ) {
    return false;
  } else return true;
};

export const copyPlan = async (
  planType,
  scenarioName,
  selectedRows,
  props,
  setCopyModal,
  history
) => {
  if (!planType) {
    props.addSnack({
      message: "Please select copy option",
      options: {
        variant: "error",
      },
    });
    return;
  }
  try {
    const [selectedPlan] = selectedRows;
    const planCode = selectedPlan.plan_code;
    const seasonType = seasonTypesObj[history.location.pathname];
    const status = statusCodeBasedOnPlanType[planType];
    if (planType === REGULAR_PLAN || planType === FINAL_FORECAST) {
      props.setSavePlanLoader(true);
      const result = await props.savePlanRequest(planCode, status);
      if (result.data.status) {
        props.addSnack({
          message: "Plan save successfully",
          options: {
            variant: "success",
          },
        });
        setCopyModal(false);
        props.setTabValue(0);
      } else {
        props.addSnack({
          message: "Error in saving the plan details.",
          options: {
            variant: "error",
          },
        });
      }
    } else if (planType === SCENARIO_PLAN || planType === SCENARIO_FORECAST) {
      props.setCopyLoader(true);
      if (!scenarioName) {
        props.addSnack({
          message: "Please enter scenario name",
          options: {
            variant: "error",
          },
        });
        props.setCopyLoader(false);
        return;
      }
      const body = {
        status,
        scenario_name: scenarioName,
      };
      const result = await props.copyPlanReq(planCode, seasonType, body);
      if (result.data.status) {
        history.push(
          `${PLAN_SMART_PLAN_DETAILS}/${result.data.data.plan_code}`
        );
        props.setTabValue(1);
        setCopyModal(false);
      } else {
        props.addSnack({
          message: "Error while coping plan",
          options: {
            variant: "error",
          },
        });
      }
    }
  } catch (error) {
    props.addSnack({
      message: "Error while coping plan",
      options: {
        variant: "error",
      },
    });
  }
  props.setCopyLoader(false);
  props.setSavePlanLoader(false);
};

/**
 * @function
 * @description Set state for Data to be downloaded
 */
export const updateDataForDownload = (rows, setDataForDownload) => {
  setDataForDownload(rows);
};
