import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import moment from "moment";
import Form from "Utils/form";
import AgGridComponent from "Utils/agGrid";
import { getColumnsAg } from "actions/tableColumnActions";

import {
  getDailyAllocationStoreTableData,
  getDailyAllocationTableData,
  setDailyAllocationTableData,
  setDailyAllocationScreenLoader,
} from "../../../services-inventorysmart/Allocation-Reports/daily-allocation-service";
import {
  DAILY_ALLOCATION_VIEW_TYPE,
  ERROR_MESSAGE,
  tableConfigurationMetaData,
} from "../../../constants-inventorysmart/stringConstants";
import DailyAllocationStoreProductView from "./daily-allocation-store-product-view";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { cloneDeep, isEmpty } from "lodash";

const DailyAllocationTableView = (props) => {
  const [
    dailyAllocationProductViewColumns,
    setDailyAllocationProductViewColumns,
  ] = useState([]);
  const [dailyAllocationStoreViewColumns, setDailyAllocationStoreViewColumns] =
    useState([]);

  const [dailyAllocationProductViewData, setDailyAllocationProductViewData] =
    useState([]);
  const [dailyAllocationStoreViewData, setDailyAllocationStoreViewData] =
    useState([]);

  const [enableStoreViewSplit, setEnableStoreViewSplit] = useState(false);
  const [styleColorId, setStyleColorId] = useState("");
  const [storeCode, setStoreCode] = useState("");

  const [productStore, setProductStore] = useState({ productStore: "product" });
  const [formConfig, setFormConfig] = useState([]);

  const viewStoreSplitDetails = (data) => {
    setEnableStoreViewSplit(true);
    setStyleColorId(data.article);
  };

  const viewProductSplitDetails = (data) => {
    setEnableStoreViewSplit(true);
    setStoreCode(data.store_code);
  };

  const dailyAllocationProductViewAction = {
    article: viewStoreSplitDetails,
  };

  const dailyAllocationStoreViewAction = {
    store_code: viewProductSplitDetails,
  };

  useEffect(() => {
    if (!isEmpty(props.dynamicLabels)) {
      let clonedFormFieldConfig = cloneDeep(DAILY_ALLOCATION_VIEW_TYPE);
      clonedFormFieldConfig[0].options[0].label =
        dynamicLabelsBasedOnTenant("article");
      setFormConfig(clonedFormFieldConfig);
    }
  }, [props.dynamicLabels]);

  useEffect(() => {
    if (productStore.productStore === "product") {
      (async () => {
        try {
          setEnableStoreViewSplit(false);
          props.setDailyAllocationScreenLoader(true);
          let col = await getColumnsAg(
            "table_name=inventory_daily_allocation_article_list",
            null,
            dailyAllocationProductViewAction
          )();
          setDailyAllocationProductViewColumns(col);
          let reqBody = {
            date: moment(props.selectedDate.datePicker).format("YYYY-MM-DD"),
            meta: tableConfigurationMetaData.meta,
            filters: props.dailyAllocationSelectedFilters,
          };
          let response = await props.getDailyAllocationTableData(reqBody);
          props.setDailyAllocationTableData(response.data.data);
          setDailyAllocationProductViewData(response.data.data?.table_data);
          props.setDailyAllocationScreenLoader(false);
          setStoreCode("");
        } catch (e) {
          props.setDailyAllocationScreenLoader(false);
          props.displaySnackMessages(ERROR_MESSAGE, "error");
        }
      })();
    } else {
      (async () => {
        try {
          setEnableStoreViewSplit(false);
          props.setDailyAllocationScreenLoader(true);
          let col = await getColumnsAg(
            "table_name=inventory_daily_allocation_store_list",
            null,
            dailyAllocationStoreViewAction
          )();
          setDailyAllocationStoreViewColumns(col);

          let reqBody = {
            date: moment(props.selectedDate.datePicker).format("YYYY-MM-DD"),
            filters: props.dailyAllocationSelectedFilters,
          };
          let response = await props.getDailyAllocationStoreTableData(reqBody);
          setDailyAllocationStoreViewData(response.data.data?.table_data);
          props.setDailyAllocationScreenLoader(false);
          setStyleColorId("");
        } catch (e) {
          props.setDailyAllocationScreenLoader(false);
          props.displaySnackMessages(ERROR_MESSAGE, "error");
        }
      })();
    }
  }, [productStore, props.dailyAllocationSelectedFilters]);

  const handleChangeDailyAllocationView = (updatedFormData) => {
    setProductStore(updatedFormData);
  };

  return (
    <>
      <Form
        layout={"vertical"}
        maxFieldsInRow={2}
        handleChange={handleChangeDailyAllocationView}
        fields={formConfig}
        updateDefaultValue={false}
        defaultValues={productStore}
        labelWidthSpan={5}
        fieldTypeWidthSpan={2}
      ></Form>
      {productStore.productStore === "product" && (
        <AgGridComponent
          downloadAsExcel={props.enableDownload}
          rowdata={dailyAllocationProductViewData}
          columns={dailyAllocationProductViewColumns}
          uniqueRowId={"key"}
        />
      )}
      {productStore.productStore === "store" && (
        <AgGridComponent
          downloadAsExcel={props.enableDownload}
          rowdata={dailyAllocationStoreViewData}
          columns={dailyAllocationStoreViewColumns}
          uniqueRowId={"store_code"}
          sizeColumnsToFitFlag
          pagination={false}
        />
      )}

      {enableStoreViewSplit && (
        <DailyAllocationStoreProductView
          selectedId={styleColorId}
          selectedStore={storeCode}
          productStore={productStore}
          setDailyAllocationScreenLoader={props.setDailyAllocationScreenLoader}
          displaySnackMessages={props.displaySnackMessages}
          dailyAllocationSelectedFilters={props.dailyAllocationSelectedFilters}
          selectedDate={props.selectedDate}
          enableDownload={props.enableDownload}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    dynamicLabels:
      store.inventorysmartReducer?.inventorySmartCommonService
        ?.inventorysmartScreenConfig?.dynamicLabels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDailyAllocationStoreTableData: (body) =>
      dispatch(getDailyAllocationStoreTableData(body)),
    getDailyAllocationTableData: (body) =>
      dispatch(getDailyAllocationTableData(body)),
    setDailyAllocationTableData: (body) =>
      dispatch(setDailyAllocationTableData(body)),
    setDailyAllocationScreenLoader: (body) =>
      dispatch(setDailyAllocationScreenLoader(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyAllocationTableView);
