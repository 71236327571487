import axios from "axios";
import { config } from "../../config";
import { logoutUser } from "../../actions/authActions";
import store from "../../store";
import {
  screenNamesNotIncludedInFilterExclusion,
  screenUrlsNotIncludedInFilterExclusion,
} from "config/constants";
import { getToken } from "Utils/functions/helpers/authentication-helpers";
import axiosRetry from "axios-retry";
const axiosInstance = axios.create({
  baseURL:
    "https://sandbox.products.impactsmartsuite.com/generic/generic-service/api/v1/",
});

axiosRetry(axiosInstance, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) => {
    // 429 - Rate exceed, 500 - Error in api
    return [500, 503, 429].includes(error?.response?.status);
  },
});

const requestHandler = async (request) => {
  const token = await getToken();
  request.headers.common = { Authorization: `${token}` };
  let excludedFilterValues = store.getState().tenantUserRoleMgmtReducer
    .userRoleManagementReducer.filter_attribute_exclusion_values;
  let excludedFilterValuesForScreen = store.getState().assortsmartReducer
    .commonAssortReducer.activeScreenName;

  if (
    request.data &&
    request.data?.filters?.length &&
    excludedFilterValues?.length > 0 &&
    screenUrlsNotIncludedInFilterExclusion.indexOf(request.url) === -1 &&
    request.url === "core/cross-filter" &&
    screenNamesNotIncludedInFilterExclusion.indexOf(
      excludedFilterValuesForScreen
    ) < 0
  ) {
    request.data.filters = [...request.data.filters, ...excludedFilterValues];
  }
  return request;
};

const errorHandler = async (error) => {
  if (error.response && error.response.status === 401) {
    store.dispatch(logoutUser());
  }
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export default axiosInstance;
