import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import "./cell.scss";

const InputCell = React.memo(({ ...instance }) => {
  const {
    updateMyData,
    lockCellApi,
    row,
    column,
    type,
    value,
    disabled = false,
    inputType,
    isRoundOffTwoDecimals,
    isWhole,
    isCeil,
    onInputBlur,
    onInputChange,
    findSubRows,
    fixTo,
    className,
    primaryKey,
    setInstanceOnUpdate,
    titleText,
    onBlurForWeek,
    showEmptyOnZero,
    showEmptyOnNull,
    formatter,
  } = instance;
  const [inputValue, setInputValue] = useState(null);
  const [showLock, setShowLock] = useState(false);
  const [inputTypeAdornment, setInputTypeAdornment] = useState();

  useEffect(() => {
    formatInputValue(value);
  }, [value]);

  useEffect(() => {
    setInputTypeAdornment(inputType);
  }, []);
  const getFormatedNumber = (temp) => {
    let formatedValue = parseFloat(temp);
    if (isRoundOffTwoDecimals) {
      //isRoundOffTwoDecimals is used when need value to be rounded off to 2 decimals
      formatedValue = Math.round(temp * 100) / 100;
    } else if (isWhole) {
      //isWhole is used when we need value to be a whole number (without decimals)
      formatedValue = Math.round(temp);
    } else if (isCeil) {
      //isCeil is used when we need value to be a whole number without rounding off (without decimals)
      formatedValue = parseInt(temp);
    } else if (inputType === "dollar") {
      //if the input type is dollar then the value should always have 2 decimals like 0.00
      //columns like aur, aic
      formatedValue = parseFloat(temp).toFixed(2);
    }
    return formatedValue;
  };

  const getFormattedText = (value) => {
    if (
      (typeof value == Number || (value && !isNaN(Number(value)))) &&
      !column.id.includes("date") &&
      formatter != "nonCommaFormatter"
    ) {
      //if the value is of type number and  not nan then we are converting it to whole number and adding commas
      let newvaluefor =
        Number(value) < 0
          ? 0
          : Number(value)
              .toFixed(0)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let formatedValue = newvaluefor;
      setInputValue(newvaluefor);
    } else {
      //To include commas inside Input columns like quantity etc.
      //if the value is of type string
      let temp = value?.toString();
      var letters = /[a-zA-Z]/g;
      if (value) {
        if (
          !value.toString().match(letters) &&
          formatter != "nonCommaFormatter"
        ) {
          //value doesn't have any alphabets then replace comma and convert to whole number
          temp = temp.replaceAll(",", "");
          temp = Math.round(temp) < 0 ? 0 : Math.round(temp);
          //adding commas to value
          let newvaluefor1 = temp
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          setInputValue(newvaluefor1);
        } else {
          temp = temp.replaceAll(",", "");
          setInputValue(temp);
        }
      } else {
        setInputValue(value);
      }
    }
  };

  const formatInputValue = (value) => {
    let formatedValue = value;
    if (type === "number") {
      //fixTo is used to fix it to 2 or 0 or 1 like that
      let temp = value;
      //temp = temp < 0 ? 0 : value;
      if ((!value || value < 0) && !showEmptyOnZero && !showEmptyOnNull) {
        temp = 0;
      }
      if (inputType === "percentage" && !column.removeValidation) {
        temp = temp > 100 ? 100 : temp;
        temp = temp === 0 || temp ? temp : instance.value || 0;
      }
      if (fixTo || fixTo === 0) {
        if (temp) {
          temp = parseFloat(temp);
          temp = temp.toFixed(fixTo);
        }
        formatedValue = temp;
      } else if (temp) {
        formatedValue = getFormatedNumber(temp);
      } else {
        formatedValue = parseFloat(temp);
      }
      setInputValue(formatedValue);
    } else if (type === "text" && value !== null) {
      getFormattedText(value);
    } else setInputValue(value);
    return formatedValue;
  };

  const setDisabledValue = () => {
    if (
      row &&
      row.original &&
      row.original.locked_cells &&
      column.is_editable
    ) {
      return row.original.locked_cells.indexOf(column.id) > -1 ? true : false;
    } else if (!column?.is_editable) {
      return true;
    }
    return disabled;
  };

  /**
   * @function
   * @description Used to lock or unlock that cell, It will add a key locked_cells as an array having the locked column id in that particular row,
   *  @description if that cell lock is interdependent on other fields then you can return new TableData having
   *               lockedCells key in that row from lockCellApi func
   */

  const cellLockUnlockRequest = () => {
    if (row) {
      let lockedRowData = row.original;
      if (lockedRowData.locked_cells) {
        lockedRowData.locked_cells =
          lockedRowData.locked_cells.indexOf(column.id) > -1
            ? lockedRowData.locked_cells.filter((cell) => {
                return cell !== column.id;
              })
            : [...lockedRowData.locked_cells, column.id];
      } else {
        lockedRowData.locked_cells = [column.id];
      }

      let customUpdatedTableData = lockCellApi(
        lockedRowData,
        column.id,
        instance
      );
      if (customUpdatedTableData) {
        instance.settabledata(customUpdatedTableData);
      } else {
        const setTableRowdata = (data) => {
          return data.map((item) => {
            if (item[primaryKey] === lockedRowData[primaryKey]) {
              item = lockedRowData;
            }
            if (item.subRows) {
              item.subRows = setTableRowdata(item.subRows);
            }
            return item;
          });
        };
        instance.settabledata(setTableRowdata(instance.data));
      }
    }
  };

  const renderLockIcon = () => {
    if (row && row.original.locked_cells) {
      return row.original.locked_cells.indexOf(column.id) > -1 ? (
        <LockOpenOutlinedIcon className="lock_icon_size" />
      ) : (
        <LockOutlinedIcon className="lock_icon_size" />
      );
    } else {
      return <LockOutlinedIcon className="lock_icon_size" />;
    }
  };

  const styleInputText = (value) => {
    let cellColorValues = row?.original;
    let rowsWithDifferentCellStyle = ["variance"];
    let style = {};
    if (rowsWithDifferentCellStyle.indexOf(row?.original?.reference) > -1) {
      if (
        value >= cellColorValues?.min_val &&
        value <= cellColorValues?.max_val
      ) {
        style.color = cellColorValues?.max_color;
      } else if (!isNaN(value)) {
        style.color = cellColorValues?.min_color;
      }
      return { style };
    }
  };

  return (
    <div style={{ display: "flex" }} title={titleText || ""}>
      <TextField
        variant="outlined"
        className={className}
        value={inputValue !== value ? inputValue : value}
        disabled={setDisabledValue()}
        type={type || "text"}
        min={type === "number" ? 0 : ""}
        onMouseEnter={() => {
          setShowLock(true);
        }}
        prefix={inputType}
        onMouseLeave={() => {
          setShowLock(false);
        }}
        onBlur={(e) => {
          let temp = e.target.value;
          let newValue = formatInputValue(temp);
          if (onInputBlur) {
            if (findSubRows) {
              let isSubRow = isEmpty(row.subRows);
              onInputBlur(row.index, newValue, column.id, isSubRow, row.id);
            } else {
              onInputBlur(row?.index, newValue, column.id);
            }
          }
          if (updateMyData) {
            updateMyData(
              row?.index,
              column?.id,
              newValue,
              value,
              column,
              instance,
              row,
              row?.id
            );
          }
          if (onBlurForWeek) {
            onBlurForWeek({
              rowIndex: row.index,
              value: newValue,
              oldValue: value,
              columnId: column.id,
              rowId: row.id,
              row: row.values,
            });
          }
          if (setInstanceOnUpdate) setInstanceOnUpdate(instance);
        }}
        onChange={(e) => {
          let newValue = e.target.value;
          if (column.onlyPositiveNumbers && newValue < 0) {
            return;
          }
          setInputValue(newValue);
          if (onInputChange) {
            onInputChange(e, type);
          }
          setInputTypeAdornment(inputTypeAdornment);
        }}
        onFocus={() => {
          setShowLock(true);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                "&.MuiInputAdornment-root ": {
                  marginRight: "-4px",
                  marginLeft: "-5px",
                },
              }}
            >
              {inputTypeAdornment === "dollar" && <span>$ </span>}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                "&.MuiInputAdornment-root ": {
                  marginRight: "-12px",
                },
              }}
            >
              {inputTypeAdornment === "percentage" && (
                <span className="endAdornment">% </span>
              )}
              {column?.showLock && showLock && (
                <span style={{ display: "flex", justifyContent: "end" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={cellLockUnlockRequest}
                    edge="end"
                    size="small"
                    color="primary"
                    style={{
                      backgroundColor: "#C8D5E3",
                      borderRadius: "0px 4px 4px 0px",
                      paddingLeft: "6px",
                    }}
                  >
                    {renderLockIcon()}
                  </IconButton>
                </span>
              )}
            </InputAdornment>
          ),
          inputProps: styleInputText(value),
        }}
      />
    </div>
  );
});

export default InputCell;
