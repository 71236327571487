import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DialpadIcon from "@mui/icons-material/Dialpad";
import {
  CalendarToday,
  FactCheck,
  PieChart,
  Factory,
} from "@mui/icons-material";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import Layout from "commonComponents/layout";
import {
  REPORTS,
  PRE_SEASON,
  IN_SEASON,
  BUSINESS_PERFORMANCE,
  CLUSTER,
  ITEM_PLANNING
} from "../constants-plansmart/routesConstants";

import InseasonDashboardComponent from "../pages-plansmart/react-demo/inseason-dashboard";
import PreseasonDashboardComponent from "../pages-plansmart/react-demo/preseason-dashboard";
import ReportsDashboardComponent from "../pages-plansmart/react-demo/reports-dashboard";
import BusinessPerformanceComponent from "../pages-plansmart/react-demo/business-performance";
import ItemPlanningComponent from "../pages-plansmart/react-demo/item-planning";
import ClusterComponent from "../pages-plansmart/react-demo/cluster";
import "commonComponents/layout/layout.css";

export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + PRE_SEASON,
    title: "Preseason Planning",
    icon: React.createElement(CalendarToday),
    order: 1,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + IN_SEASON,
    title: "Inseason Planning",
    icon: React.createElement(FactCheck),
    order: 2,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + REPORTS,
    title: "Reports",
    icon: React.createElement(PieChart),
    order: 3,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + BUSINESS_PERFORMANCE,
    title: "Corporate Planning",
    icon: React.createElement(Factory),
    order: 4,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CLUSTER,
    title: "Cluster",
    icon: React.createElement(BubbleChartIcon),
    order: 5,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ITEM_PLANNING,
    title: "Item Planning",
    icon: React.createElement(DialpadIcon),
    order: 6,
  },
];

const Routes = (props) => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + PRE_SEASON,
      component: PreseasonDashboardComponent,
      title: "Preseason Planning",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + IN_SEASON,
      component: InseasonDashboardComponent,
      title: "Inseason Planning",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + REPORTS,
      component: ReportsDashboardComponent,
      title: "Reports",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + BUSINESS_PERFORMANCE,
      component: BusinessPerformanceComponent,
      title: "Corporate Planning",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CLUSTER,
      component: ClusterComponent,
      title: "Cluster",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ITEM_PLANNING,
      component: ItemPlanningComponent,
      title: "Item Planning",
    },
  ];
  return (
    <Layout routes={routes} sideBarOptions={sideBarOptions} app={"plansmart"} />
  );
};

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Routes));
