import { createSlice } from "@reduxjs/toolkit";
import { GET_MODULE_LEVEL_ACCESS, GET_TENANT_CONFIG } from "config/api";
import axiosInstance from "../../../../Utils/axios/index";

export const inventorySmartCommonService = createSlice({
  name: "inventorySmartCreateAllocationService",
  initialState: {
    inventorySmartPermissionLoader: false,
    inventorysmartScreenConfigLoader: false,
    inventorysmartScreenConfig: null,
    inventorysmartModulesPermission: {},
  },
  reducers: {
    setInventorySmartPermissionLoader: (state, action) => {
      state.inventorySmartPermissionLoader = action.payload;
    },
    setInventorysmartScreenConfigLoader: (state, action) => {
      state.inventorysmartScreenConfigLoader = action.payload;
    },
    setInventorysmartScreenConfig: (state, action) => {
      state.inventorysmartScreenConfig = action.payload;
    },
    setInventorySmartModulesPermissions: (state, action) => {
      state.inventorysmartModulesPermission = {
        ...state.inventorysmartModulesPermission,
        ...action.payload,
      };
    },
    resetCommonStoreState: (state, _action) => {
      state.inventorySmartPermissionLoader = false;
      state.inventorysmartScreenConfigLoader = false;
      state.inventorysmartScreenConfig = null;
      state.inventorysmartModulesPermission = {};
    },
  },
});

export const {
  setInventorySmartPermissionLoader,
  setInventorysmartScreenConfigLoader,
  setInventorysmartScreenConfig,
  setInventorySmartModulesPermissions,
  resetCommonStoreState,
} = inventorySmartCommonService.actions;

export const getInventorySmartAttributes = (
  applicationCode,
  attributeName
) => () => {
  const queryParam = {
    attribute_name: attributeName,
  };
  // return axiosInstance({
  //   url: `${GET_TENANT_CONFIG}/${applicationCode}`,
  //   params: queryParam,
  //   method: "GET",
  // });
};

export const getModuleLevelAccess = ({ app, module }) => () => {
  const queryParam = {
    app,
    module,
  };
  return axiosInstance({
    url: `${GET_MODULE_LEVEL_ACCESS}`,
    params: queryParam,
    method: "GET",
  });
};

export default inventorySmartCommonService.reducer;
