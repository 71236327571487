// Chart Icon cell renderer

import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useStyles } from "modules/react-demo/pages/styles";

const blueBG = {
  fontSize: "19px",
  color: "#41A6F7",
  backgroundColor: "white",
  cursor: "pointer",
};

const ChartCell = ({ field, ...instance }) => {
  const { onChartClick } = instance;
  const classes = useStyles();

  return (
    <>
      <div
        onClick={() => {
          onChartClick(instance);
        }}
      >
        <BarChartIcon id="chart-icon" className={classes.iconBlue} />
      </div>
    </>
  );
};

export default ChartCell;
