import { useDragLayer, useDragDropManager } from "react-dnd";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import BlockIcon from "@mui/icons-material/Block";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
const layerStyles = () => {
  return {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  };
};
function getItemStyles(clientOffset) {
  if (!clientOffset) {
    return {
      display: "none",
    };
  }
  let { x, y } = clientOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
export const CustomDragLayer = (props) => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
    clientOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const dragDropManager = useDragDropManager();
  const targets = dragDropManager.getMonitor().getTargetIds();
  let canDrop = false;
  if (targets.length == 0) {
    canDrop = false;
  } else {
    for (const target of targets) {
      canDrop = dragDropManager.getMonitor().canDropOnTarget(target);
    }
  }
  function renderItem() {
    switch (itemType) {
      case "col":
        return <CustomPreview heading={item.heading} canDrop={canDrop} />;
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles()}>
      <div style={getItemStyles(clientOffset)}>{renderItem()}</div>
    </div>
  );
};

const CustomPreview = (props) => {
  const { heading, canDrop } = props;
  let firstLetter = heading.match(/[a-zA-Z]/).pop();
  if (firstLetter == null) {
    firstLetter = firstLetter.charAt(0);
  } else {
    firstLetter = firstLetter.toUpperCase();
  }
  return (
    <Chip
      avatar={<Avatar>{firstLetter}</Avatar>}
      color="primary"
      label={<LabelNode heading={heading} canDrop={canDrop} />}
    />
  );
};

const useStyles = makeStyles((theme) => {
  return {
    headingText: {
      marginRight: 4,
      fontSize: 12,
      fontWeight: 500,
    },
    addIcon: {
      fontSize: 16,
    },
    blockIcon: {
      fontSize: 16,
    },
  };
});
const LabelNode = (props) => {
  const classes = useStyles();
  return (
    <>
      <Box display="flex">
        <Typography classes={{ root: classes.headingText }}>
          {props.heading}
        </Typography>
        {props.canDrop ? (
          <AddIcon classes={{ root: classes.addIcon }} />
        ) : (
          <BlockIcon classes={{ root: classes.blockIcon }} />
        )}
      </Box>
    </>
  );
};
