import axiosInstance from "Utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import {
  STORE_GRADE_ATTRIBUTES,
  STORE_GRADE_HIERARCHIES,
  STORE_GRADE_NOMENCLATURE,
  CREATE_STORE_GRADE,
  ADD_GRADING_MATRICES,
} from "./grading-constants/api-constants";

export const fetchBasicDetialsAttributes = async (screen) => {
  return axiosInstance({
    url: `${STORE_GRADE_ATTRIBUTES}/${screen}`,
    method: "GET",
  });
};

export const fetchHierarchies = async (attribute) => {
  return axiosInstance({
    url: `${STORE_GRADE_HIERARCHIES}/${attribute}`,
    method: "GET",
  });
};

export const fetchAttributes = async () => {
  return axiosInstance({
    url: `${STORE_GRADE_NOMENCLATURE}`,
    method: "GET",
  });
};

export const createNewGrade = async (reqBody) => {
  return axiosInstance({
    url: `${CREATE_STORE_GRADE}`,
    method: "POST",
    data: reqBody,
  });
};

export const addGradingMatrices = async (reqBody) => {
  return axiosInstance({
    url: `${ADD_GRADING_MATRICES}`,
    method: "POST",
    data: reqBody,
  });
}

// STORE GRADING STORE OPERATIONS
export const createGradeService = createSlice({
  name: "createGradeService",
  initialState: {
    createGradeStep: 0,
    classification: "",
    gradeID: null,
  },
  reducers: {
    setCreateGradeStep: (state, action) => {
      state.createGradeStep = action.payload;
    },
    setCreateGradeClassification: (state, action) => {
      state.classification = action.payload;
    },
    setGradeID: (state, action) => {
      state.gradeID = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  setCreateGradeStep,
  setCreateGradeClassification,
  setGradeID,
} = createGradeService.actions;

// Export Reducer
export default createGradeService.reducer;
