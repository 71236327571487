import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../../Utils/Loader/loader";
import PaginatedTable from "../../../Utils/reactTable";
import ProductFilterGroup from "../../../commonComponents/filters/filterGroup";
import "../ProductFilter.scss";
import makeStyles from "@mui/styles/makeStyles";
import { getAllFilters, getFiltersValues } from "../../../actions/filterAction";
import {
  getProductStatusData,
  mapProductData,
  getProductStatusStyleData,
  mapStyleProductData,
  checkValidationForMapping,
} from "../../../actions/productStoreStatusActions";
import BreadCrumb from "../../../Utils/HeaderBreadCrumbs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  Button,
  Container,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
} from "@mui/material";
import globalStyles from "Styles/globalStyles";
import { getParentGroupingRow } from "Utils/functions/helpers/table-helpers";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "0.6rem",
  },
  styleSelection: {
    width: "100%",
    justifyContent: "center",
    "& .MuiToggleButton-label": {
      textTransform: "none",
    },
  },
  confirmBox: {
    "& .MuiDialog-paper": {
      minWidth: "430px",
      borderRadius: "10px 10px 6px 6px",
    },
  },
  contentBody: {
    minHeight: "10rem",
  },
  action: {
    background: "#f7f7f7",
    padding: "1rem",
  },
  title: {
    fontSize: "1rem",
    fontWeight: "600",
    paddingTop: "1.5rem",
  },
  text: {
    paddingTop: "1rem",
    fontSize: "0.9rem",
    color: "#5a5a5ad9",
  },
  selectedProducts: {
    fontSize: "0.85rem",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  labelText: {
    color: "#a0acb9",
    padding: "0.5rem",
  },
  fieldText: {
    color: "#5f6b7a",
    padding: "0.5rem",
  },
  footer: {
    textAlign: "center",
    padding: "1rem",
  },
}));

function ProductsFilter(props) {
  const [filterData, setFilterData] = useState([]);
  const [showloader, setloader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [defaultFilterDataSet, setDefaultDataSet] = useState([]);
  const [columns, setColumns] = useState([]);

  const [styleSelection, setStyleSelection] = useState(
    "reference_product_codes"
  );

  const [filterDependency, setDependency] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [errorPopup, setErrorPopup] = useState(false);
  const classes = useStyles();
  const globalClasses = globalStyles();
  const styleMapping = props.selectedProducts.style ? true : false;
  const primaryKey = props.selectedProducts.style ? "style" : "product_code";

  useEffect(() => {
    const getInitialData = async () => {
      try {
        let response = await getAllFilters("product status")();
        const filterElements = response.data.data.map(async (key) => {
          let body = {
            attribute_name: key.column_name,
            filter_type: key.type,
            filters: [],
          };
          const options = await getFiltersValues("product", body)();
          key.filter_keyword = key.column_name;
          key.initialData = options.data.data.attribute.map((item) => {
            return {
              value: item.attribute,
              label: item.attribute,
            };
          });
          return key;
        });

        await Promise.all(filterElements);
        setColumns(props.cols);

        const defaultValues = JSON.parse(
          JSON.stringify([...response.data.data])
        );
        setDefaultDataSet([...defaultValues]);
        setFilterData(response.data.data);
        setloader(false);
      } catch (error) {
        console.log(error);
      }
    };

    getInitialData();
  }, []);

  const updateData = async (dependency) => {
    setloader(true);
    let selectionDependency =
      dependency.length > 0
        ? dependency.map((item) => {
            return {
              attribute_name: item.filter_id,
              operator: "in",
              values: Array.isArray(item.values)
                ? item.values.map((opt) => opt.value)
                : item.values,
              filter_type: item.filter_type,
            };
          })
        : [];
    try {
      let initialFilterElements = [...filterData];
      const filterElements = initialFilterElements.map(async (key) => {
        if (key.type === "cascaded") {
          let body = {
            attribute_name: key.column_name,
            filter_type: key.type,
            filters: selectionDependency,
          };
          const options = await getFiltersValues("product", body)();
          key.initialData = options.data.data.attribute.map((opt) => {
            return {
              value: opt.attribute,
              label: opt.attribute,
            };
          });
        }
        return key;
      }); // }

      await Promise.all(filterElements);
      setDependency(selectionDependency);
      setFilterData(initialFilterElements);
      setloader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveRequest = () => {
    if (selectedRowsIDs.length === 1) {
      setShowModal(true);
    } else {
      setErrorMsg("Please select only a single row");
      setErrorPopup(true);
    }
  };
  const onConfirm = async () => {
    try {
      setloader(true);
      if (props.selectedProducts.product_code) {
        let queryParams = `?product=${props.selectedProducts[primaryKey]}&mapping_product=${selectedRowsIDs[0][primaryKey]}`;
        await checkValidationForMapping(queryParams)();
        let reqBody = {
          body: [
            {
              attribute_name: styleSelection,
              attribute_value: `{${selectedRowsIDs[0][primaryKey]}}`,
            },
          ],
          code: props.selectedProducts[primaryKey],
        };

        await mapProductData("product", reqBody)();
      } else {
        let queryParams = `?style=${props.selectedProducts[primaryKey]}&mapping_style=${selectedRowsIDs[0][primaryKey]}`;
        let productTobeMapped = await checkValidationForMapping(queryParams)();
        productTobeMapped = productTobeMapped.data.data.map((item) => {
          return {
            code: item.product,
            attributes: [
              {
                attribute_name: styleSelection,
                attribute_value: `{${item.mapping_product}}`,
              },
            ],
          };
        });

        await props.mapStyleProductData({ body: productTobeMapped });
      }
      setSuccessPopup(true);
      setShowModal(false);
      setloader(false);
      props.goBack(true);
    } catch (err) {
      setErrorMsg("Mapping is not possible please select a different Product");
      setErrorPopup(true);
      setloader(false);
      setShowModal(false);
      console.log(err);
    }
  };

  const manualCallBack = async (manualbody, pageIndex, pageSize) => {
    setloader(true);
    try {
      let meta = manualbody
        ? { ...manualbody, limit: { limit: 10, page: pageIndex + 1 } }
        : {
            range: [],
            sort: [],
            search: [],
            limit: { limit: 10, page: 1 },
          };
      let body = {
        filters: filterDependency,
        meta: {
          ...meta,
        },
        headers: [],
      };
      let rowData = [];
      if (styleMapping) {
        rowData = await props.getProductStatusStyleData("product", body);
      } else {
        rowData = await props.getProductStatusData("product", body);
        rowData.data.data = rowData.data.data.map((item) => {
          return { ...item, hideCheckBox: true };
        });
      }

      setTableData(rowData.data.data);
      setloader(false);
    } catch (err) {
      console.log(err);
      setloader(false);
    }
  };

  const onReset = () => {
    setFilterData(defaultFilterDataSet);
    setTableData([]);
  };
  const handleStyleSlection = (event, newSelection) => {
    if (newSelection !== null) {
      setStyleSelection(newSelection);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessPopup(false);
  };
  const handleWarningClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setWarningPopup(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorPopup(false);
  };
  const renderContent = () => {
    return (
      <Loader loader={showloader}>
        <BreadCrumb
          options={[
            {
              label: dynamicLabelsBasedOnTenant("product_status", "core"),
              id: 1,
              action: () => {
                props.goBack();
              },
            },
            { label: "Modify", id: 1, action: null },
          ]}
        ></BreadCrumb>
        <div data-testid="filterContainer" className="filterContainer">
          <Snackbar
            open={successPopup}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Products mapped Successfully
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorPopup}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={handleErrorClose}
          >
            <Alert
              onClose={handleErrorClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            open={warningPopup}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={handleWarningClose}
          >
            <Alert
              onClose={handleWarningClose}
              severity="warning"
              sx={{ width: "100%" }}
            >
              There is no change to save.
            </Alert>
          </Snackbar>

          <Dialog
            open={showModal}
            className={classes.confirmBox}
            onClose={() => setShowModal(false)}
            id={"routePrompt"}
          >
            <DialogContent className={classes.contentBody}>
              <div className={classes.title}> Confirm Changes</div>
              <div className={classes.text}>
                Do you want to map the selected product ?
              </div>
            </DialogContent>
            <DialogActions className={classes.action}>
              <Button
                id="routePromptYes"
                onClick={() => setShowModal(false)}
                color="primary"
                autoFocus
              >
                Close
              </Button>
              <Button
                id="routePromptYes"
                onClick={() => onConfirm()}
                color="primary"
                autoFocus
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>

          <ToggleButtonGroup
            value={styleSelection}
            exclusive
            className={classes.styleSelection}
            onChange={handleStyleSlection}
            aria-label="text alignment"
          >
            <ToggleButton
              value="reference_product_codes"
              aria-label="left aligned"
            >
              Map Reference
            </ToggleButton>
            <ToggleButton
              value="replacement_product_codes"
              aria-label="centered"
            >
              Map Replacement
            </ToggleButton>
          </ToggleButtonGroup>
          {props.selectedProducts.product_code ? (
            <div className={classes.selectedProducts}>
              <span className={classes.labelText}>Selected Products -</span>
              <span className={classes.labelText}>
                Product ID :{" "}
                <span className={classes.fieldText}>
                  {" "}
                  {`${props.selectedProducts.product_code}`}{" "}
                </span>
              </span>
              <span className={classes.labelText}>
                Size :{" "}
                <span className={classes.fieldText}>
                  {" "}
                  {`${props.selectedProducts.size || ""}`}{" "}
                </span>
              </span>
              <span className={classes.labelText}>
                Color :{" "}
                <span className={classes.fieldText}>
                  {" "}
                  {`${props.selectedProducts.color || ""}`}{" "}
                </span>
              </span>
              <span className={classes.labelText}>
                Des:{" "}
                <span className={classes.fieldText}>
                  {" "}
                  {`${props.selectedProducts.product_description || ""}`}{" "}
                </span>
              </span>
            </div>
          ) : (
            <div className={classes.selectedProducts}>
              <span className={classes.labelText}>Selected Style</span>
              <span className={classes.labelText}>
                Style ID :{" "}
                <span className={classes.fieldText}>
                  {" "}
                  {`${props.selectedProducts.style}`}{" "}
                </span>
              </span>
            </div>
          )}

          <ProductFilterGroup
            filters={filterData}
            onFilter={manualCallBack}
            onReset={onReset}
            doNotUpdateDefaultValue={true}
            screen={"product Modify status"}
            update={updateData}
          />

          <div data-testid="resultContainer">
            <Container maxWidth={false}>
              <Typography
                variant="h6"
                gutterBottom
                className={globalClasses.marginBottom}
              >
                Filtered Product
              </Typography>
              {columns.length > 0 && (
                <PaginatedTable
                  columns={columns}
                  rowSelection={true}
                  totalCount={1000}
                  rowdata={tableData}
                  selectedRowHandler={(flatids) => {
                    const data =
                      primaryKey === "style"
                        ? flatids.map((item) => item.values)
                        : getParentGroupingRow(flatids, "product_code");

                    setSelectedRowsIDs(data);
                  }}
                  showPagination={true}
                  backgroundColor={"white"}
                  tableId={"productDataModify"}
                  hidePageSelection={true}
                  paginatorCallback={() => null}
                  manualFilters={true}
                  manualSortBy={true}
                  manualCallBack={(body, pageIndex) =>
                    manualCallBack(body, pageIndex)
                  }
                  groupBy={["product_code", "info_type"]}
                  shouldShowCheckBoxHandler={
                    primaryKey === "style" ? null : props.checkBoxDisplayHandler
                  }
                >
                  {" "}
                </PaginatedTable>
              )}
            </Container>
          </div>
          <div className={classes.footer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              id="productMapSelectionBtn"
              onClick={() => {
                saveRequest();
              }}
            >
              Map Selection
            </Button>
            <Button
              variant="outlined"
              id="modifyCancelBtn"
              className={classes.button}
              onClick={() => {
                setFilterData(defaultFilterDataSet);
                setTableData([]);
                props.goBack();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Loader>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
}
const mapDispatchToProps = {
  getProductStatusStyleData,
  getProductStatusData,
  mapStyleProductData,
};

export default connect(null, mapDispatchToProps)(ProductsFilter);
