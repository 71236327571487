import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Paper, Typography } from "@mui/material";

import AgGridComponent from "Utils/agGrid";
import { addSnack } from "actions/snackbarActions";
import { getColumnsAg } from "actions/tableColumnActions";
import globalStyles from "Styles/globalStyles";
import {
  setProductsToSelectForProductProfile,
  getProductsToSelectForProductProfile,
  setNewProductProfileLoader,
} from "../../../services-inventorysmart/Product-Profile/create-product-profile-service";
import { isEmpty } from "lodash";

const SelectProductTableComponent = (props) => {
  const [selectProductTableColumn, setSelectProductTableColumn] = useState([]);
  const [selectProductTableData, setSelectProductTableData] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  useEffect(() => {
    (async () => {
      props.setNewProductProfileLoader(true);
      let styleColorColumnDef = await getColumnsAg(
        "table_name=create_product_profile_style_color_table"
      )();
      setSelectProductTableColumn(styleColorColumnDef);
    })();
  }, []);

  // preselected values on edit to be set
  useEffect(() => {
    if (!isEmpty(props.listOfProducts)) {
      setSelectProductTableData(props.listOfProducts);
    }
  }, [props.listOfProducts]);

  useEffect(() => {
    if (!isEmpty(props.preSelectedArticles)) {
      setSelectedProductIds(props.preSelectedArticles);
    }
  }, [props.preSelectedArticles]);

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows().map((item) => item.article);
    setSelectedProductIds(selections);
    props.selectedArticles(selections);
  };

  const globalClasses = globalStyles();
  return (
    <div className={globalClasses.marginAround}>
      <Paper>
        <Typography variant="h5" className={globalClasses.paperHeader}>
          Select Product
        </Typography>
        <div className={globalClasses.evenPaddingAround}>
          <AgGridComponent
            rowdata={selectProductTableData}
            columns={selectProductTableColumn}
            selectAllHeaderComponent={true}
            onSelectionChanged={onSelectionChanged}
            uniqueRowId={"article"}
            sizeColumnsToFitFlag
            onGridChanged
            rowSelection={"multiple"}
            selectedRows={selectedProductIds}
          />
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { inventorysmartReducer } = store;
  return {
    newProductProfileLoader:
      inventorysmartReducer.createProductProfileReducer.newProductProfileLoader,
    newProductProfileFilterConfiguration:
      inventorysmartReducer.createProductProfileReducer
        .newProductProfileFilterConfiguration,
    listOfProducts:
      inventorysmartReducer.createProductProfileReducer.listOfProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    setNewProductProfileLoader: (body) =>
      dispatch(setNewProductProfileLoader(body)),
    setProductsToSelectForProductProfile: (body) =>
      dispatch(setProductsToSelectForProductProfile(body)),
    getProductsToSelectForProductProfile: (body) =>
      dispatch(getProductsToSelectForProductProfile(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectProductTableComponent);
