import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Paper, Typography } from "@mui/material";

import AgGridComponent from "Utils/agGrid";
import { addSnack } from "actions/snackbarActions";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import globalStyles from "Styles/globalStyles";
import { cloneDeep, isEmpty } from "lodash";

import { setNewProductProfileLoader } from "../../../services-inventorysmart/Product-Profile/create-product-profile-service";
import { scrollIntoView } from "../../inventorysmart-utility";

const StorePriceContributionComponent = (props) => {
  const [storePriceContributionColumn, setStorePriceContributionColumn] =
    useState([]);
  const [storePriceContributionData, setStorePriceContributionData] = useState(
    []
  );
  const storePriceContributionRef = useRef();

  const globalClasses = globalStyles();

  useEffect(() => {
    (async () => {
      props.setNewProductProfileLoader(true);
      if (!isEmpty(props.listOfProductsPenetrationValue)) {
        let copyOfStoreSizeContributionData = cloneDeep(
          props.listOfProductsPenetrationValue?.columns
        );
        let penetrationColDef = agGridColumnFormatter(
          copyOfStoreSizeContributionData
        );
        setStorePriceContributionColumn(penetrationColDef);
        let sortedRows = cloneDeep(
          props.listOfProductsPenetrationValue?.data
        )?.sort((a, b) => b.overall_proportion - a.overall_proportion);
        setStorePriceContributionData(sortedRows);
        scrollIntoView(storePriceContributionRef);
      }
    })();
  }, [props.listOfProductsPenetrationValue]);

  return (
    <div className={globalClasses.marginAround}>
      <Paper ref={storePriceContributionRef}>
        <Typography variant="h5" className={globalClasses.paperHeader}>
          Store and Size Contribution
        </Typography>
        <div className={globalClasses.evenPaddingAround}>
          <AgGridComponent
            rowdata={storePriceContributionData}
            columns={storePriceContributionColumn}
            uniqueRowId={"store_code"}
            sizeColumnsToFitFlag
            downloadAsExcel={true}
            sideBar={false}
            pagination={false}
          />
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (store) => {
  const { inventorysmartReducer } = store;
  return {
    newProductProfileLoader:
      inventorysmartReducer.createProductProfileReducer.newProductProfileLoader,
    listOfProductsPenetrationValue:
      inventorysmartReducer.createProductProfileReducer
        .listOfProductsPenetrationValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    setNewProductProfileLoader: (body) =>
      dispatch(setNewProductProfileLoader(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorePriceContributionComponent);
