// Defined Colors to be utilized.
// Color Name should be taken from https://chir.ag/projects/name-that-color/#1976D2

const colours = {
  //Primary Colors
  fireFly: "#091927", // Base
  fireFlyLight: "#0F2236",
  kashmirBlue: "#567190",
  shipCove: "#6B8CB3",
  spindle: "#C7DAEF",

  // Accent Colors
  ceruleanBlue: "#2F58CB",
  royalBlue: "#3D67DB", // Base
  cornflowerBlue: "#6186ED",
  periwinkle: "#D5E0FF",
  zumthor: "#E8EEFF",
  zircon: "#F4F6FF",

  // Secondary Color
  //success
  emerald: "#36C15C", // Base
  pastelGreen: "#64DB85",
  magicMint: "#A0F2B6",

  // warning
  selectiveYellow: "#F4BC03", // Base
  dandelion: "#FCD659",
  newOrleans: "#F4DF99",

  // error
  jaffa: "#F4743B", // Base
  atomicTangerine: "#FD9B70",
  apriocotPeach: "#FACCB8",

  //secondary
  froly: "#F76D9A", // Base
  sweetPink: "#FA97B6",
  pink: "#FFC3D6",

  // info
  java: "#26C1BF", // Base
  spray: "#6DEBE9",
  charlotte: "#AAF8F7",

  // Semantic Colors
  // Error
  valencia: "#D63838", // Base
  roman: "#DD5151",
  frolyLight: "#F77D7D",
  cornflowerLilac: "#FFACAC",
  pippin: "#FFE2E2",

  // Warning
  buddhaGold: "#CB9D03",
  superNova: "#FFCE02", // Base
  sunglow: "#FDD631",
  kournikova: "#FDE375",
  picasso: "#FFEB96",
  varden: "#FFF6DE",

  // Succes
  salem: "#108647", // Base
  emeraldLight: "#59D091",
  algaeGreen: "#91E6B9",
  whiteIce: "#EAFCF4",

  // Neutral Colors
  white: "#FFFFFF",
  black: "#000000",
  zirconLight: "#F8F9FF",
  aircraftWhite: "#e9eff7",
  mirage: "#1F2D3D",
  oxfordBlue: "#2D3B48",
  blueBayoux: "#516272",
  slateGray: "#65788B",
  regentGray: "#7A8C9F",
  gullGray: "#97A8BA",
  cadetBlue: "#A5B6C7",
  botticelli: "#C8D5E3",
  linkWaterLight: "#E1EBF7",
  scorpion: "#5F5F5F",
  doveGray: "#707070",
  gray: "#7F7F7F",
  lightGray: "#d3d3d3",
  mercury: "#E6E6E6",
  concrete: "#F2F2F2",
  catskillWhiteDark: "#F9FAFC",
  lightslategray: "#758498",
  silver: "#C4C4C4",

  // New Colours
  // Primary Colours
  greenVogue: "#033162",
  endavour: "#0055AF",
  cerulean: "#00ACCB",

  // Text Colours
  codGray: "#1D1D1D",
  fiord: "#394960",

  // To be changed back to slategray if removed
  slateGrayLight: "#758490",
  tiara: "#C8CED0",
  darkSilverChalice: "#AFAFAF",

  // Semantic Colours
  eucalyptus: "#24A148",
  crusta: "#FF832B",
  alizarinCrimson: "#DA1E28",
  trout: "#535B63",
  panache: "#E8F5EC",
  seashellPeach: "#FFF3EA",
  wispPink: "#FCE9EA",
  linkWater: "#E5EDF7",

  // Backgrounds
  catskillWhite: "#EEF0F7",
  selago: "#F3F7FD",
  mystic: "#DFE7EE",
  athensGray: "#E2E4ED",
  princetonOrange: "#FF832B19",
  navajoWhite: "#FFD8A8",
  alabaster: "#F7F7F7",

  // Neutral Colours
  gallery: "#EFEFEF",
  darkSilver: "#C7C7C7",
  alto: "#D4D4D4",
  silverChalice: "#ACACAC",
  silver: "#C4C4C4",
  athensGrayLight: "#EBECF0",

  //Update these Home Colours
  // Not to be used in applications
  poloBlue: "#92B0D1",
  periwinkleGrey: "#C1D2E5",
};

export default colours;
