import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.scss";
import {
  DateRangePicker,
  isInclusivelyBeforeDay,
  isInclusivelyAfterDay,
} from "react-dates";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import { NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER } from "config/constants";
const useStyles = makeStyles({
  monthYearDropDown: {
    display: "inline-flex",
  },
});
const DateRangePickerComponent = (props) => {
  const classes = useStyles();
  const [focusedInput, setfocusedInput] = useState(null);
  const [yearArray, setyearArray] = useState([moment().year()]);
  const configureYear = () => {
    let yearArr = [moment().year()];
    for (let i = 0; i < NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER; i++) {
      if (props.disableType && props.disableType.includes("Future")) {
        yearArr.push(yearArr[i] - 1);
      } else {
        yearArr.push(yearArr[i] + 1);
      }
    }
    setyearArray(yearArr);
  };

  const checkStartEndRanges = (day) => {
    /**
     * This method checks what dates to disable along with the PAST/FUTURE disabled conditions
     * Once you select start date and end date, dates before of start date and dates after end
     * date would be disabled
     */
    if (props.startDate && props.endDate) {
      return (
        !isInclusivelyBeforeDay(day, props.endDate) ||
        !isInclusivelyAfterDay(day, props.startDate)
      );
    }
    if (props.startDate) {
      return !isInclusivelyAfterDay(day, props.startDate);
    }
    if (props.endDate) {
      return !isInclusivelyBeforeDay(day, props.endDate);
    }
    return false;
  };
  const isOutsideRange = (day) => {
    switch (props.disableType) {
      case "disableOnlyPast":
        //disables strictly past i.e current/today's date is not included
        return moment(day).isBefore() ? true : checkStartEndRanges(day);
      case "disablePast":
        //disables past i.e current/today's date is included
        return moment(day).isSame(moment(), "days") || moment(day).isBefore()
          ? true
          : checkStartEndRanges(day);
      case "disableOnlyFuture":
        //disables strictly future i.e current/today's date is not included
        return moment(day).isAfter() ? true : checkStartEndRanges(day);
      case "disableFuture":
        //disables future i.e current/today's date is included
        return moment(day).isSame(moment(), "days") || moment(day).isAfter()
          ? true
          : checkStartEndRanges(day);
      case "customRange":
        //we can pass custom range which evaluates if the date to be disabled or not
        //For example: disabling specific dates or only fridays etc..
        return props.customOutsideRange();
      default:
        //By default, it doesn't disable any date
        return checkStartEndRanges(day);
    }
  };

  const dateRangeCalculator = (startDate, endDate) => {
    /**
     * If the date selection is weekly, we keep start date as starting day of the week,
     * i.e if we select a day, we return the output as starting day in that week
     */
    let weekStart = props.weeklySelection
      ? moment(startDate).startOf("week")
      : startDate;

    /**
     * If we make the date selection as weekly
     * End date would be end of the week but if the end date
     * is outside the range i.e future including today, we set
     * enddate as one day prior to today i.e subtract 1 day from today
     * We also make sure if the endDate is either null or invalid, we don't check for outside range
     */
    const validEndDate =
      endDate &&
      endDate.isValid() &&
      isOutsideRange(moment(endDate).endOf("week"))
        ? moment().subtract(1, "days")
        : moment(endDate).endOf("week");

    let weekEnd = props.weeklySelection ? validEndDate : endDate;
    if (weekStart && !weekStart.isValid()) {
      weekStart = null;
    }
    if (weekEnd && !weekEnd.isValid()) {
      weekEnd = null;
    }
    props.onDatesChange(weekStart, weekEnd);
  };

  const isDayBlocked = (date) => {
    if (focusedInput === "startDate") {
      return (
        props.enabledStartDays &&
        !props.enabledStartDays.includes(moment(date).format("dddd"))
      );
    }
    if (focusedInput === "endDate") {
      return (
        props.enabledEndDays &&
        !props.enabledEndDays.includes(moment(date).format("dddd"))
      );
    }
    return false;
  };

  useEffect(() => {
    configureYear();
  }, []);
  return (
    <div id={"dateRangePicker"}>
      <DateRangePicker
        showClearDates
        startDateId={props.startDateId || "startDate"}
        endDateId={props.endDateId || "endDate"}
        startDate={props.startDate}
        endDate={props.endDate}
        withPortal={!props.noPortal}
        small
        autoFocus
        preventScroll
        displayFormat={props.tenantDateFormat}
        onDatesChange={({ startDate, endDate }) => {
          dateRangeCalculator(startDate, endDate);
        }}
        onClose={props.onClose}
        focusedInput={focusedInput}
        onFocusChange={(focusedInp) => {
          setfocusedInput(focusedInp);
        }}
        disabled={props.disabled}
        isOutsideRange={isOutsideRange}
        isDayBlocked={isDayBlocked}
        showDefaultInputIcon
        hideKeyboardShortcutsPanel
        numberOfMonths={props.numberOfMonths || 2} //default number of months is 2 else it can be passed from props
        renderMonthElement={function noRefCheck({
          month,
          onMonthSelect,
          onYearSelect,
        }) {
          return (
            <div className={classes.monthYearDropDown}>
              {yearArray.length ? (
                <>
                  <div>
                    <select
                      value={month.month()}
                      onChange={(e) => onMonthSelect(month, e.target.value)}
                    >
                      {moment.months().map((label, value) => (
                        <option value={value}>{label}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <select
                      value={month.year()}
                      onChange={(e) => onYearSelect(month, e.target.value)}
                    >
                      {yearArray.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </>
              ) : (
                <div />
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantDateFormat:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer
        .tenantDateFormat,
  };
};

export default connect(mapStateToProps, null)(DateRangePickerComponent);
