import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Paper, Grid, Button } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";

import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import CustomAccordion from "commonComponents/Custom-Accordian";
import Loader from "Utils/Loader/loader";
import Form from "Utils/form";
import Filters from "commonComponents/filters/filterGroup";
import { addSnack } from "actions/snackbarActions";
import globalStyles from "Styles/globalStyles";
import { useStyles } from "modules/inventorysmart/pages-inventorysmart/styles-inventorysmart";
import colours from "Styles/colours";

import DailyAllocationTableView from "./daily-allocation-table-view";
import CardComponent from "../components/CardComponent";
import {
  setDailyAllocationFilterConfiguration,
  clearDailyAllocationStates,
  setDailyAllocationFilterLoader,
} from "../../../services-inventorysmart/Allocation-Reports/daily-allocation-service";
import {
  fetchFilterConfig,
  updateCrossFiltersData,
} from "../../inventorysmart-utility";
import {
  DAILY_ALLOCATION_SUMMARY_SIGNET,
  ERROR_MESSAGE,
  DAILY_ALLOCATION_DATE_PICKER,
} from "../../../constants-inventorysmart/stringConstants";

const DailyAllocationSummary = (props) => {
  const [dailySummaryCards, setDailySummaryCards] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [dailyAllocationFilterConfig, setDailyAllocationFilterConfig] =
    useState([]);
  const [dailyAllocationFilterDependency, setDailyAllocationFilterDependency] =
    useState([]);
  const [
    dailyAllocationFilterDependencyChips,
    setDailyAllocationFilterDependencyChips,
  ] = useState([]);
  const [dailyAllocationDetails, setDailyAllocationDetails] = useState(false);
  const [dailyAllocationDatePicker, setDailyAllocationDatePicker] = useState({
    datePicker: "",
  });
  const [filterValuesOnRender, setFilterValuesOnRender] = useState([]);

  const globalClasses = globalStyles();
  const classes = useStyles();

  useEffect(() => {
    const getInitialFilterConfiguration = async () => {
      try {
        props.setDailyAllocationFilterLoader(true);
        let response = await fetchFilterConfig("inventorysmart_reportings");
        props.setDailyAllocationFilterConfiguration(response);
        props.setDailyAllocationFilterLoader(false);
      } catch (e) {
        props.setDailyAllocationFilterLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    getInitialFilterConfiguration();
    return () => clearDailyAllocationStates();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.dailyAllocationFilterConfiguration)) {
      props.setDailyAllocationFilterLoader(true);
      const getFilterValues = async () => {
        try {
          let initialFilterElements = cloneDeep(
            props.dailyAllocationFilterConfiguration
          ).sort((a, b) => (a.dimension > b.dimension ? -1 : 1));
          let onUpdateFilters = await updateCrossFiltersData(
            initialFilterElements,
            [],
            "fetchOptions"
          );
          setDailyAllocationFilterConfig(onUpdateFilters);
          props.setDailyAllocationFilterLoader(false);
        } catch (err) {
          displaySnackMessages(ERROR_MESSAGE, "error");
          props.setDailyAllocationFilterLoader(false);
        }
      };
      getFilterValues();
    }
  }, [props.dailyAllocationFilterConfiguration]);

  useEffect(() => {
    if (!isEmpty(props.dailyAllocationTableData?.aggregated_data)) {
      let dailySummaryCardsWithIcons = DAILY_ALLOCATION_SUMMARY_SIGNET.map(
        (item) => {
          let color = colours.royalBlue;
          let icon = "inventory_icon";
          return {
            ...item,
            color,
            icon,
            count: props.dailyAllocationTableData.aggregated_data[0][item.key],
          };
        }
      );
      setDailySummaryCards(dailySummaryCardsWithIcons);
    }
  }, [props.dailyAllocationTableData]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const updateDailyAllocationFilters = async (dependency, filter) => {
    props.setDailyAllocationFilterLoader(true);
    /*
      dependency is a list that consists of selected filter configuration
      that is used for creating request body to fetch the corresponding filter's drop down values
    */
    let selectionValueDependency =
      dependency.length > 0
        ? dependency.map((opt) => {
            return {
              attribute_name: opt.filter_id,
              operator: "in",
              // passing boolean values instead of strings TRUE and FAlSE when active drop down is selected, else pass the default value for other filters
              // as it is a multiSelect filter the values are in the array format
              values: Array.isArray(opt.values)
                ? opt.values.map((option) =>
                    option.value === "TRUE"
                      ? true
                      : option.value === "FALSE"
                      ? false
                      : option.value
                  )
                : opt.values,
              filter_type: opt.filter_type,
              dimension: opt.dimension,
            };
          })
        : [];
    setDailyAllocationFilterDependencyChips(dependency);
    try {
      if (!filter || filter.filter_type === "cascaded") {
        let initialFilterElements = [...dailyAllocationFilterConfig];
        let onUpdateFilters = await updateCrossFiltersData(
          initialFilterElements,
          selectionValueDependency,
          "updateOptions"
        );
        setDailyAllocationFilterConfig(onUpdateFilters);
      }
      setDailyAllocationFilterDependency(selectionValueDependency);
      props.setDailyAllocationFilterLoader(false);
    } catch (err) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setDailyAllocationFilterLoader(false);
      setDailyAllocationFilterDependencyChips([]);
    }
  };

  const fetchValuesBasedOnFilterApplied = async () => {
    if (isEmpty(dailyAllocationDatePicker.datePicker))
      displaySnackMessages("Select the date", "error");
    else {
      props.setDailyAllocationFilterLoader(true);
      try {
        setFilterValuesOnRender(dailyAllocationFilterDependency);
        props.setDailyAllocationFilterLoader(false);
        setDailyAllocationDetails(true);
      } catch (err) {
        displaySnackMessages(ERROR_MESSAGE, "error");
        props.setDailyAllocationFilterLoader(false);
      }
      setOpenModal(false);
    }
  };

  const resetSelectedFilterValues = () => {
    props.setDailyAllocationFilterLoader(true);
    setDailyAllocationFilterDependency([]);
    setFilterValuesOnRender([]);
    setDailyAllocationFilterDependencyChips([]);
    setDailyAllocationDatePicker({ datePicker: "" });
    setDailyAllocationDetails(false);
    setOpenModal(false);
  };

  const handleChangeDailyAllocationDatePicker = (updatedFormData) => {
    setDailyAllocationDatePicker(updatedFormData);
  };

  return (
    <>
      <div className={globalClasses.filterWrapper}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {dailyAllocationFilterDependencyChips.length > 0 && (
          <FilterChips
            filterConfig={dailyAllocationFilterDependencyChips}
          ></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <Loader loader={props.dailyAllocationFilterLoader}>
            <div className={globalClasses.marginAround}>
              <Form
                layout={"vertical"}
                maxFieldsInRow={5}
                handleChange={handleChangeDailyAllocationDatePicker}
                fields={DAILY_ALLOCATION_DATE_PICKER}
                updateDefaultValue={false}
                defaultValues={dailyAllocationDatePicker}
                labelWidthSpan={2}
                fieldTypeWidthSpan={2}
              ></Form>
            </div>
            <Filters
              filters={dailyAllocationFilterConfig}
              update={updateDailyAllocationFilters}
              doNotUpdateDefaultValue={true}
              onFilter={fetchValuesBasedOnFilterApplied}
              onReset={resetSelectedFilterValues}
            />
          </Loader>
        </CustomAccordion>
      </FilterModal>
      {dailyAllocationDetails && (
        <Loader loader={props.dailyAllocationScreenLoader}>
          <div className={globalClasses.filterWrapper}>
            <Grid container className={classes.kpiContainer} spacing={3}>
              {dailySummaryCards.map((item) => {
                return (
                  <Grid item xs={2.4}>
                    <Paper elevation={3} className={classes.summaryContainer}>
                      <CardComponent kpiItem={item} />
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
            <DailyAllocationTableView
              enableDownload={props.enableDownload}
              displaySnackMessages={displaySnackMessages}
              dailyAllocationSelectedFilters={filterValuesOnRender}
              selectedDate={dailyAllocationDatePicker}
            />
          </div>
        </Loader>
      )}
    </>
  );
};
const mapStateToProps = (store) => {
  const { inventorysmartReducer } = store;
  return {
    dailyAllocationScreenLoader:
      inventorysmartReducer.inventorySmartDailyAllocationService
        .dailyAllocationScreenLoader,
    dailyAllocationFilterConfiguration:
      inventorysmartReducer.inventorySmartDailyAllocationService
        .dailyAllocationFilterConfiguration,
    dailyAllocationTableData:
      inventorysmartReducer.inventorySmartDailyAllocationService
        .dailyAllocationTableData,
    dailyAllocationFilterLoader:
      inventorysmartReducer.inventorySmartDailyAllocationService
        .dailyAllocationFilterLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    setDailyAllocationFilterConfiguration: (body) =>
      dispatch(setDailyAllocationFilterConfiguration(body)),
    clearDailyAllocationStates: (body) =>
      dispatch(clearDailyAllocationStates(body)),
    setDailyAllocationFilterLoader: (body) =>
      dispatch(setDailyAllocationFilterLoader(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyAllocationSummary);
