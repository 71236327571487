import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { cloneDeep, isEmpty } from "lodash";

import Filters from "commonComponents/filters/filterGroup";
import { addSnack } from "actions/snackbarActions";
import globalStyles from "Styles/globalStyles";
import Loader from "Utils/Loader/loader";

import {
  setStockDrillDownTableData,
  setStockDrillDownScreenLoader,
  setStockDrillDownFilterConfiguration,
  getStockDrillDownTableData,
  clearStoreStockDrillDownStates,
  getStockDrillDownSizeDetails,
  setStockDrillDownTableLoader,
} from "../../../services-inventorysmart/Allocation-Reports/store-stock-drill-down-service";
import { ERROR_MESSAGE } from "../../../constants-inventorysmart/stringConstants";

import {
  fetchFilterConfig,
  updateCrossFiltersData,
} from "../../inventorysmart-utility";
import { Button, Grid } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import CustomAccordion from "commonComponents/Custom-Accordian";
import StoreStockDrillDownViewTableComponent from "./store-stock-drilldown-table-view";

const StoreStockDrillDownComponent = (props) => {
  const globalClasses = globalStyles();
  const [stockDrillDownFilterConfig, setStockDrillDownFilterConfig] = useState(
    []
  );
  const [stockDrillDownFilterDependency, setStockDrillDownFilterDependency] =
    useState([]);
  const [
    stockDrillDownFilterDependencyChips,
    setStockDrillDownFilterDependencyChips,
  ] = useState([]);
  const [
    showServerSideStoreStockDrillDownDetails,
    setShowServerSideStoreStockDrillDownDetails,
  ] = useState(false);
  const [renderTable, setRenderTable] = useState({});

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const getInitialFilterConfiguration = async () => {
      try {
        props.setStockDrillDownScreenLoader(true);
        let response = await fetchFilterConfig("inventorysmart_reportings ");
        props.setStockDrillDownFilterConfiguration(response);
        props.setStockDrillDownScreenLoader(false);
      } catch (e) {
        props.setStockDrillDownScreenLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    getInitialFilterConfiguration();
    return () => props.clearStoreStockDrillDownStates();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.stockDrillDownFilterConfiguration)) {
      props.setStockDrillDownScreenLoader(true);
      const getFilterValues = async () => {
        try {
          // Performing a sort on the filter configuration to display filters of store dimension followed by product dimension filters in alphabetical order
          // Sort operation resultant = 1 takes precedence over the other.
          let initialFilterElements = cloneDeep(
            props.stockDrillDownFilterConfiguration
          ).sort((a, b) => (a.dimension > b.dimension ? -1 : 1));
          let onUpdateFilters = await updateCrossFiltersData(
            initialFilterElements,
            [],
            "fetchOptions"
          );
          setStockDrillDownFilterConfig(onUpdateFilters);
          props.setStockDrillDownScreenLoader(false);
        } catch (err) {
          displaySnackMessages(ERROR_MESSAGE, "error");
          props.setStockDrillDownScreenLoader(false);
        }
      };
      getFilterValues();
    }
  }, [props.stockDrillDownFilterConfiguration]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const updateStoreStockFilters = async (dependency, filter) => {
    props.setStockDrillDownScreenLoader(true);
    /*
      dependency is a list that consists of selected filter configuration
      that is used for creating request body to fetch the corresponding filter's drop down values
    */
    let selectionValueDependency =
      dependency.length > 0
        ? dependency.map((opt) => {
            return {
              attribute_name: opt.filter_id,
              operator: "in",
              // passing boolean values instead of strings TRUE and FAlSE when active drop down is selected, else pass the default value for other filters
              // as it is a multiSelect filter the values are in the array format
              values: Array.isArray(opt.values)
                ? opt.values.map((option) =>
                    option.value === "TRUE"
                      ? true
                      : option.value === "FALSE"
                      ? false
                      : option.value
                  )
                : opt.values,
              filter_type: opt.filter_type,
              dimension: opt.dimension,
            };
          })
        : [];
    setStockDrillDownFilterDependencyChips(dependency);
    try {
      if (!filter || filter.filter_type === "cascaded") {
        let initialFilterElements = [...stockDrillDownFilterConfig];
        let onUpdateFilters = await updateCrossFiltersData(
          initialFilterElements,
          selectionValueDependency,
          "updateOptions"
        );
        setStockDrillDownFilterConfig(onUpdateFilters);
      }
      setStockDrillDownFilterDependency(selectionValueDependency);
      props.setStockDrillDownScreenLoader(false);
    } catch (err) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setStockDrillDownScreenLoader(false);
      setStockDrillDownFilterDependencyChips([]);
    }
  };

  const fetchServerSideTableBasedOnFilterApplied = () => {
    let body = {
      filters: stockDrillDownFilterDependency,
    };
    setRenderTable(body);
    setShowServerSideStoreStockDrillDownDetails(true);
    setOpenModal(false);
  };

  const resetSelectedFilterValues = () => {
    props.setStockDrillDownScreenLoader(true);
    setStockDrillDownFilterDependency([]);
    setStockDrillDownFilterDependencyChips([]);
    setShowServerSideStoreStockDrillDownDetails(false);
    setOpenModal(false);
    setRenderTable({});
  };

  return (
    <>
      <div className={globalClasses.filterWrapper}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {stockDrillDownFilterDependencyChips.length > 0 && (
          <FilterChips
            filterConfig={stockDrillDownFilterDependencyChips}
          ></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <Loader loader={props.stockDrillDownScreenLoader}>
            <Filters
              filters={stockDrillDownFilterConfig}
              update={updateStoreStockFilters}
              doNotUpdateDefaultValue={true}
              onFilter={fetchServerSideTableBasedOnFilterApplied}
              onReset={resetSelectedFilterValues}
            />
          </Loader>
        </CustomAccordion>
      </FilterModal>

      {showServerSideStoreStockDrillDownDetails && (
        <Loader loader={props.stockDrillDownTableLoader}>
          <div className={globalClasses.filterWrapper}>
            <StoreStockDrillDownViewTableComponent
              setStockDrillDownTableLoader={props.setStockDrillDownTableLoader}
              displaySnackMessages={displaySnackMessages}
              getStockDrillDownTableData={props.getStockDrillDownTableData}
              getStockDrillDownSizeDetails={props.getStockDrillDownSizeDetails}
              renderTable={renderTable}
            />
          </div>
        </Loader>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  const { inventorysmartReducer } = store;
  return {
    stockDrillDownScreenLoader:
      inventorysmartReducer.inventoryStoreStockDrillDownService
        .stockDrillDownScreenLoader,
    stockDrillDownTableLoader:
      inventorysmartReducer.inventoryStoreStockDrillDownService
        .stockDrillDownTableLoader,
    stockDrillDownFilterConfiguration:
      inventorysmartReducer.inventoryStoreStockDrillDownService
        .stockDrillDownFilterConfiguration,
    stockDrillDownTableData:
      inventorysmartReducer.inventoryStoreStockDrillDownService
        .stockDrillDownTableData,
    inventorysmartScreenConfig:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig?.dynamicLabels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    setStockDrillDownScreenLoader: (body) =>
      dispatch(setStockDrillDownScreenLoader(body)),
    setStockDrillDownTableLoader: (body) =>
      dispatch(setStockDrillDownTableLoader(body)),
    setStockDrillDownTableData: (body) =>
      dispatch(setStockDrillDownTableData(body)),
    setStockDrillDownFilterConfiguration: (body) =>
      dispatch(setStockDrillDownFilterConfiguration(body)),
    getStockDrillDownTableData: (body) =>
      dispatch(getStockDrillDownTableData(body)),
    clearStoreStockDrillDownStates: (body) =>
      dispatch(clearStoreStockDrillDownStates(body)),
    getStockDrillDownSizeDetails: (body) =>
      dispatch(getStockDrillDownSizeDetails(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreStockDrillDownComponent);
