export const DASHBOARD = "/assort-smart/plan-dashboard";
export const CLUSTERING = "/assort-smart/cluster";
export const PLAN = "/assort-smart/plan";
export const COMPAREPLAN = "/assort-smart/compareplan";
export const DASHBOARD_HINDSIGHT = "/assort-smart/plan-dashboard/hindsight";
export const OMNI_DASHBOARD = "/assort-smart/plan-dashboard/omnichannel";
export const OMNI_MAPPING_SCREEN = "/assort-smart/omnimapping";
export const CORE_CHOICE_CONFIGURATION_DASHBOARD =
  "/assort-smart/plan-dashboard/alldoorchoiceconfiguration";
export const ASSORT_CLUSTER_DASHBOARD = "/assort-smart/cluster-dashboard";
export const PRODUCT_ATTRIBUTES = "/assort-smart/product-attributes";
export const LOCATION_ATTRIBUTES = "/assort-smart/location-attributes";
