/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

import { Tabs, Tab } from "@mui/material";

import globalStyles from "Styles/globalStyles";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";

import LostSalesComponent from "./Lost-Sales";
import ExcessInventoryComponent from "./Excess-Inventory";
import DailyAllocationSummary from "./Daily-Allocation-Summary";
import { REPORT_SCREEN_TABS } from "../../constants-inventorysmart/stringConstants";
import StoreStockDrillDownComponent from "./Store-Stock-Drilldown";

const AllocationReportingComponent = () => {
  const [tabValue, setTabValue] = useState(0);

  const globalClasses = globalStyles();

  const homeIcon = [
    {
      label: "Report",
      id: 1,
    },
  ];

  const handleChangeTabValue = (_event, newValue) => {
    setTabValue(newValue);
  };

  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      label: REPORT_SCREEN_TABS[index],
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      <HeaderBreadCrumbs options={homeIcon}></HeaderBreadCrumbs>
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "100%" }}
          data={`https://sandbox.inventory.impactsmartsuite.com/inventory-smart/report?queryPath=${window.location.pathname}`}
          // data={`https://bkp.products.impactsmartsuite.com${window.location.pathname}`}
        ></object>
      </div>
    </>
  );
};

export default AllocationReportingComponent;
