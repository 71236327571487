import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Loader from "../../../Utils/Loader/loader";
import "./filter.scss";
import { Button, Container } from "@mui/material";
import { Prompt } from "react-router";
import { setProductStatusData } from "../../../actions/productStoreStatusActions";
import {
  getAllStoreDC,
  getAllFCDC,
  getAllDCData,
  mapDCtoFC,
} from "../services-dc-mapping/dc-mapping-service";
import ConfirmBox from "../../../Utils/reactTable/components/confirmPopup";
import { mapStoretoDC } from "pages/storeMapping/services/storeMappingService";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { setActiveScreenName } from "modules/react-demo/services/common-assort-service";
import CellRenderers from "Utils/agGrid/cellRenderer";
import globalStyles from "Styles/globalStyles";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import { getTenantConfigApplicationLevel } from "actions/tenantConfigActions";
import { addSnack } from "actions/snackbarActions";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import AgGridComponent from "Utils/agGrid";
import { customSetAllField } from "commonComponents/coreComponentScreen/constants";
import { difference, isEmpty } from "lodash";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import { setFilterConfiguration } from "actions/filterAction";

import { isActionAllowedOnSubModule } from "modules/inventorysmart/pages-inventorysmart/inventorysmart-utility";
import { INVENTORY_SUBMODULES_NAMES } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

function DCtoStore(props) {
  const [showloader, setloader] = useState(true);
  const [columns, setColumns] = useState([]);
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [confirmBox, showConfirmBox] = useState(false);
  const [dimension, changeDimension] = useState("store");
  const [setAllData, updateSetAllData] = useState([]);
  const [storeCols, setStoreCols] = useState([]);
  const [fcCols, setfcCols] = useState([]);
  const [dcList, setdcList] = useState([]);
  const [flag_edit, setFlag_edit] = useState(false);
  const [showFCLevelView, setShowFCLevelView] = useState(true);

  const globalClasses = globalStyles();

  // Filter Variables
  const [openModal, setOpenModal] = useState(false);
  const [showFilterLoader, setShowFilterLoader] = useState(false);

  const tableInstance = useRef({});

  const onFilterDependency = useRef([]);

  const setNewTableInstance = (params) => {
    tableInstance.current = params;
  };

  const getData = (inputdata, final_cols, dim) => {
    let final_data = [];

    inputdata.forEach((data) => {
      let temp = {};
      if (data.dc_map !== null) {
        for (const fc_key of final_cols) {
          temp[fc_key.accessor] = false;
          for (const dc_key of data.dc_map) {
            if (dc_key.name === fc_key.accessor) {
              temp[fc_key.accessor] = true;
            }
          }
        }
      } else {
        for (const fc_key of final_cols) {
          temp[fc_key.accessor] = false;
        }
      }
      if (dim === "store") {
        temp["store_code"] = data.store_code;
        temp["store_name"] = data.store_name;
      } else {
        temp["fc_code"] = data.fc_code;
      }
      temp["name"] = data.name;
      final_data.push(temp);
    });

    return final_data;
  };

  const columnUpdate = (final_cols) => {
    let editPermission = canTakeActionOnModules(
      INVENTORY_SUBMODULES_NAMES.INVENTORY_DC_MAPPING,
      "edit"
    );
    final_cols = final_cols.map((item) => {
      if (
        item.column_name !== "fc_code" &&
        item.column_name !== "store_code" &&
        item.column_name !== "name" &&
        item.column_name !== "store_name"
      ) {
        item.type = "bool";
        item.is_editable = props.isSuperUser ? true : false;
      } else {
        item.showTooltip = true;
      }
      item.disabled = !editPermission;
      item.cellRenderer = (params, extraProps) => {
        return (
          <CellRenderers
            cellData={params}
            column={item}
            extraProps={extraProps}
            actions={null}
          ></CellRenderers>
        );
      };
      return item;
    });
    return final_cols;
  };

  const setdcData = async (dccolsdata) => {
    let dcdata = [];
    for (const item of dccolsdata) {
      let newdata = {};
      newdata["id"] = item["dc_code"];
      newdata["value"] = item["dc_code"];
      newdata["label"] = item["accessor"];
      if (item["dc_code"]) {
        dcdata.push(newdata);
      }
    }

    setdcList(dcdata);
    return dcdata;
  };

  const setdcToStoreFilterConfiguration = async () => {
    if (isEmpty(props.storeFilterDashboardConfiguration)) {
      const storeFilterData = props?.roleBasedAccess
        ? await fetchFilterFieldValues(
            "DC store",
            [],
            true,
            props.screenName,
            1
          )
        : await fetchFilterFieldValues("DC store");
      let filterConfigData = [
        {
          filterDashboardData: storeFilterData,
          expectedFilterDimensions: ["store"],
          screenDimension: "store",
          isCrossDimensionFilter: false,
        },
      ];
      if (props?.roleBasedAccess) {
        filterConfigData[0] = {
          ...filterConfigData[0],
          is_urm_filter: true,
          screen_name: props.screenName,
          application_code: 1,
        };
      }
      const filterConfig = formattedFilterConfiguration(
        "dcToStoreMappingFilterConfiguration",

        filterConfigData,
        "Dc To Store Mapping"
      );
      props.setFilterConfiguration(filterConfig);
    } else {
      onFilterDependency.current =
        props.storeFilterDashboardConfiguration.appliedFilterData.dependencyData;
    }
  };

  const setdcToFcFilterConfiguration = async () => {
    if (isEmpty(props.fcFilterDashboardConfiguration)) {
      const fcFilterData = await fetchFilterFieldValues("DC fc");
      const filterConfigData = [
        {
          filterDashboardData: fcFilterData,
          expectedFilterDimensions: ["store"],
          screenDimension: "store",
          isCrossDimensionFilter: false,
        },
      ];
      const filterConfig = formattedFilterConfiguration(
        "dcToFcMappingFilterConfiguration",
        filterConfigData,
        "Dc To FC Mapping"
      );
      props.setFilterConfiguration(filterConfig);
    } else {
      onFilterDependency.current =
        props.fcFilterDashboardConfiguration.appliedFilterData.dependencyData;
    }
  };

  useEffect(() => {
    const getFilterData = async () => {
      try {
        if (dimension === "store") {
          await setdcToStoreFilterConfiguration();
        } else if (dimension === "fc") {
          await setdcToFcFilterConfiguration();
        }
      } catch (error) {
        setloader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };

    getFilterData();
  }, [dimension]);

  useEffect(() => {
    const getInitialData = async () => {
      try {
        let dcbody = {
          filters: [],
        };
        let fccols = await getAllDCData(dcbody, "fc");
        let storecols = await getAllDCData(dcbody, "store");
        let storeColumns = columnUpdate(storecols);
        let fcColumns = columnUpdate(fccols);
        setStoreCols(storeColumns);
        setfcCols(fcColumns);
        setColumns(storeColumns);
        setdcData(storecols);

        setloader(false);

        let showFCLevelViewResp = await props.getTenantConfigApplicationLevel(
          3,
          {
            attribute_name: "core_show_fc_level_view",
          }
        );

        if (showFCLevelViewResp?.data?.data?.[0]?.["attribute_value"]) {
          setShowFCLevelView(
            showFCLevelViewResp?.data?.data?.[0]?.["attribute_value"]?.value
          );
        }
      } catch (error) {
        setloader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };

    getInitialData();

    props.setActiveScreenName("DC/FC  to Store mapping");
  }, []);

  // function is called on edit action in table
  const onCellValueChanged = (params) => {
    const dataSet = {
      store_code: params.data.store_code,
      dc: {
        map: params.newValue ? [params.colDef.dc_code] : [],
        unmap: !params.newValue ? [params.colDef.dc_code] : [],
      },
    };

    updateSetAllData([...setAllData, dataSet]);
  };

  const formatData = (data, selectedIds) => {
    const mappedIds = dcList.map((item) => item.id);

    const output = selectedIds.map((item) => {
      let dc = {
        map: data.dc ? data.dc : [],
        unmap: difference(mappedIds, data.dc),
      };

      return {
        store_code: item.store_code,
        dc,
      };
    });

    return output;
  };

  // updating ag-grid data
  const onSetAllApply = (data, agGrid) => {
    const dataSet = formatData(data, selectedRowsIDs);
    updateSetAllData([...setAllData, ...dataSet]);
    let rowNodes = agGrid.api.getSelectedNodes();
    rowNodes.forEach((item) => {
      const rowNode = agGrid.api.getRowNode(item.id);

      let dcOptions = [];
      data?.dc_options?.forEach((item) => {
        dcOptions = [...dcOptions, item.label];
      });

      let updatedNodeData = { ...rowNode.data };
      // setting all dc options to false
      Object.keys(updatedNodeData).forEach((key) => {
        if (typeof updatedNodeData[key] === "boolean") {
          if (dcOptions.includes(key)) {
            updatedNodeData[key] = true;
          } else {
            updatedNodeData[key] = false;
          }
        }
      });

      rowNode.setData(updatedNodeData);
    });
    setFlag_edit(true);
    agGrid.api.flashCells({ rowNodes });
  };

  const onConfirm = async () => {
    try {
      setloader(true);
      setShowModal(false);

      dimension === "store"
        ? await mapStoretoDC({ elements: setAllData })()
        : await mapDCtoFC({ elements: setAllData })();

      tableInstance.current.api.deselectAll(true);
      updateSetAllData([]);
      setFlag_edit(false);
      setloader(false);
      tableInstance.current.api?.refreshServerSideStore({ purge: false });
      displaySnackMessages("Mapping Data Updated Successfully", "success");
    } catch (err) {
      const errMsg = !isEmpty(err.response.data.message)
        ? err.response.data.message
        : "Something went wrong";
      displaySnackMessages(errMsg, "error");
      setloader(false);
    }
  };

  const dcStoreMappingManualCallBack = async (
    manualbody,
    pageIndex,
    pageSize
  ) => {
    setloader(true);
    try {
      let body = {
        filters: onFilterDependency.current,
        meta: {
          ...manualbody,
        },
      };

      if (dimension === "store") {
        let { data: STORE } = await getAllStoreDC(body);

        // fetch columns
        let storedata = getData(STORE.data, storeCols, "store");
        setloader(false);
        return {
          data: storedata,
          totalCount: STORE.total,
        }; // returning for server side pagination on ag grid
      } else {
        let { data: FC } = await getAllFCDC(body);
        let fdata = getData(FC.data, fcCols, "fc");
        setloader(false);
        return {
          data: fdata,
          totalCount: FC.total,
        }; // returning for server side pagination on ag grid
      }
    } catch (err) {
      displaySnackMessages("Something went wrong", "error");
      setloader(false);
    }
  };

  const onFilter = async () => {
    try {
      tableInstance.current.api?.refreshServerSideStore({ purge: true });
      tableInstance.current.api?.deselectAll(true);
    } catch (error) {
      setloader(false);
    }
  };

  const onFilterDashboardClick = (dependencyData) => {
    onFilterDependency.current = dependencyData;
    onFilter();
  };

  const saveRequest = () => {
    if (setAllData.length) {
      setShowModal(true);
    } else {
      displaySnackMessages("There is no change to save.", "warning");
    }
  };

  const handleChangeDimension = async (event) => {
    changeDimension(event.target.value);
    if (event.target.value === "store") {
      setColumns(storeCols);
    } else {
      setColumns(fcCols);
    }
  };

  // const onReset = () => {
  //   updateData([]);
  //   setFlag_edit(false);
  // };

  const onCancel = () => {
    tableInstance.current.api?.refreshServerSideStore({ purge: false });
    tableInstance.current.api.deselectAll(true);
    updateSetAllData([]);
    setFlag_edit(false);
  };

  const displaySnackMessages = (message, variance, onClose) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
        ...(onClose && { onClose: onClose }),
      },
    });
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows().map((item) => {
      if (dimension === "store") {
        return {
          store_code: item.store_code,
        };
      } else {
        return {
          fc_code: item.fc_code,
        };
      }
    });
    setSelectedRowsIDs(selections);
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props?.inventorysmartModulesPermission,
      props?.module,
      subModuleName,
      action
    );
  };

  const renderContent = () => {
    let formData = [
      {
        ...customSetAllField("dc", "list", "Map DCs"),
        options: dcList,
        isMulti: true,
        is_required: false,
        required: false,
      },
    ];

    return (
      <>
        {dimension === "store" && (
          <CoreComponentScreen
            showFilterDashboard={true}
            filterConfigKey={"dcToStoreMappingFilterConfiguration"}
            onApplyFilter={onFilterDashboardClick}
          />
        )}
        {dimension === "fc" && (
          <CoreComponentScreen
            showFilterDashboard={true}
            filterConfigKey={"dcToFcMappingFilterConfiguration"}
            onApplyFilter={onFilterDashboardClick}
          />
        )}

        <Loader loader={showloader}>
          <Prompt when={flag_edit} message={""} />
          <div data-testid="filterContainer">
            {confirmBox && (
              <ConfirmBox
                onClose={() => showConfirmBox(false)}
                onConfirm={() => {
                  onCancel();
                  showConfirmBox(false);
                }}
              />
            )}
            <ConfirmPrompt
              showModal={showModal}
              title="Confirm Changes"
              message="Are you sure to save all your changes?"
              ariaLabeledBy="confirm-changes-dialog"
              primaryBtnText="Update"
              secondaryBtnText="Close"
              showCloseIcon={true}
              setConfirm={setShowModal}
              confirmCallback={(val) => {
                if (val) {
                  onConfirm();
                }
              }}
            />
            <div data-testid="resultContainer">
              <Container maxWidth={false}>
                <div
                  className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
                >
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    value={dimension}
                    onChange={handleChangeDimension}
                  >
                    <FormControlLabel
                      value="store"
                      control={<Radio color="primary" />}
                      label="Store"
                    />
                    {showFCLevelView && (
                      <FormControlLabel
                        value="fc"
                        control={<Radio color="primary" />}
                        label="FC"
                      />
                    )}
                  </RadioGroup>

                  <div
                    className={`${globalClasses.flexRow} ${globalClasses.gap}`}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        if (selectedRowsIDs.length > 0) {
                          tableInstance.current.trigerSetAll(true);
                        } else {
                          displaySnackMessages(
                            "Please select atleast one row",
                            "error"
                          );
                        }
                      }}
                      // disabled={!props.isSuperUser}
                    >
                      Set All
                    </Button>
                  </div>
                </div>
                {columns.length > 0 && (
                  <AgGridComponent
                    columns={columns}
                    selectAllHeaderComponent={true}
                    uniqueRowId={"store_code"}
                    sizeColumnsToFitFlag
                    onSelectionChanged={onSelectionChanged}
                    onGridChanged
                    manualCallBack={(body, pageIndex, params) =>
                      dcStoreMappingManualCallBack(body, pageIndex, params)
                    }
                    rowModelType="serverSide"
                    serverSideStoreType="partial"
                    cacheBlockSize={10}
                    loadTableInstance={setNewTableInstance}
                    onSetAllApply={onSetAllApply}
                    customSetAllFields={formData}
                    onCellValueChanged={onCellValueChanged}
                  />
                )}
              </Container>
            </div>
            <div
              className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  saveRequest();
                }}
                disabled={
                  !canTakeActionOnModules(
                    INVENTORY_SUBMODULES_NAMES.INVENTORY_DC_MAPPING,
                    "edit"
                  )
                }
              >
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  if (setAllData.length) {
                    showConfirmBox(true);
                  } else {
                    tableInstance.current?.api.deselectAll(true);
                    displaySnackMessages("There are no changes.", "warning");
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Loader>
      </>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
}
const mapStateToProps = (state) => {
  return {
    selectedFilters: state.filterReducer.selectedFilters["dctostore"],
    selectedfcFilters: state.filterReducer.selectedFilters["fctostore"],
    isSuperUser:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer.isSuperUser,
    inventorysmartModulesPermission:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
    storeFilterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "dcToStoreMappingFilterConfiguration"
      ],
    fcFilterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "dcToFcMappingFilterConfiguration"
      ],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (body) => dispatch(addSnack(body)),
    setProductStatusData: (data) => dispatch(setProductStatusData(data)),
    setActiveScreenName: (data) => dispatch(setActiveScreenName(data)),
    getTenantConfigApplicationLevel: (dynamicRoute, queryParam) =>
      dispatch(getTenantConfigApplicationLevel(dynamicRoute, queryParam)),
    setFilterConfiguration: (filterConfiguration) =>
      dispatch(setFilterConfiguration(filterConfiguration)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DCtoStore);
