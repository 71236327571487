import { addSnack } from "actions/snackbarActions";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";

import {
  defaultTableData,
  DELETE_MESSAGE,
  DIALOG_CONFIRM_BTN_TEXT,
  DIALOG_REJECT_BTN_TEXT,
  ERROR_MESSAGE,
  tableConfigurationMetaData,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import {
  getViewPlanTableConfiguration,
  getViewPlanTableData,
  setViewPlanTableData,
  setViewPlanTableLoader,
  setViewPlanTableConfigLoader,
} from "modules/inventorysmart/services-inventorysmart/Decision-Dashboard/decision-dashboard-services";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import { agGridRowFormatter } from "Utils/agGrid/row-formatter";
import Loader from "Utils/Loader/loader";
import { CREATE_ALLOCATION } from "modules/inventorysmart/constants-inventorysmart/routesConstants";
import ConfirmPrompt from "commonComponents/confirmPrompt";

const ViewPlansTable = (props) => {
  const history = useHistory();

  const [plansTableColumns, setPlansTableColumns] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState([]);

  useEffect(() => {
    const fetchColumnData = async () => {
      props.setViewPlanTableConfigLoader(true);
      let columns = await props.getViewPlanTableConfiguration();
      props.setViewPlanTableConfigLoader(false);
      let formattedColumns = agGridColumnFormatter(columns?.data?.data);
      setPlansTableColumns(formattedColumns);
      props.setRenderAgGrid(true);
    };
    fetchColumnData();
  }, [props.selectedFilters]);

  useEffect(() => {
    !isEmpty(props.selectedFilters) && props.setRenderAgGrid(false);
  }, [props.selectedFilters]);

  useEffect(() => {
    if (!isEmpty(selectedPlans)) {
      let selectedPlanIds = selectedPlans.map((plan) => {
        return plan.plan_code;
      });
      props.setSelectedPlanIds(selectedPlanIds);
    }
  }, [selectedPlans]);

  const manualCallBack = async (manualbody, pageIndex, params) => {
    try {
      props.setViewPlanTableLoader(true);
      let tableColumnsToBeSentInDataRequest = plansTableColumns
        ?.filter((columnConfig) => !columnConfig?.extra?.ignore_in_api)
        ?.map((column) => {
          return {
            attribute_name: column.column_name,
            dimension: "Product",
            filter_type: "cascaded",
            operator: "in",
            values: [],
          };
        });
      let body = {
        filters: [
          ...props.selectedFilters,
          ...tableColumnsToBeSentInDataRequest,
        ],
        meta: manualbody
          ? {
              ...manualbody,
              limit: { limit: 10, page: Number(pageIndex) ? pageIndex + 1 : 1 },
            }
          : {
              ...tableConfigurationMetaData.meta,
              limit: { limit: 10, page: Number(pageIndex) ? pageIndex + 1 : 1 },
            },
      };
      let response = await props.getViewPlanTableData(body);
      if (response.data.status) {
        let formatedData = agGridRowFormatter(
          response.data.data,
          params?.api?.checkConfiguration,
          "plan_code"
        );
        props.setViewPlanTableData(formatedData);
        props.setViewPlanTableLoader(false);
        return { data: formatedData, totalCount: response.data.total };
      } else {
        displaySnackMessages(ERROR_MESSAGE, "error");
        props.setViewPlanTableLoader(false);
        return defaultTableData;
      }
    } catch {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setViewPlanTableLoader(false);
      return defaultTableData;
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const onDeleteClick = (p_rowData) => {
    setSelectedPlans([p_rowData]);
    setShowDeleteDialog(true);
  };

  const onEditClick = (rowData) => {
    history.push(
      `${CREATE_ALLOCATION}?step=1&allocation_code=${rowData.plan_code}`
    );
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows();
    setSelectedPlans(selections);
  };

  const confirmDelete = () => {
    setShowDeleteDialog(false);
    props.confirmDeletePlans();
  };

  const getDeleteMessage = (p_msg = "") => {
    return `${DELETE_MESSAGE} ${p_msg}`;
  };

  return (
    <>
      <ConfirmPrompt
        showModal={showDeleteDialog}
        title="Delete Selected Allocation Plan"
        message={getDeleteMessage("the selected allocation Plan?")}
        ariaLabeledBy="confirm-delete-dialog"
        primaryBtnText={DIALOG_CONFIRM_BTN_TEXT}
        secondaryBtnText={DIALOG_REJECT_BTN_TEXT}
        showCloseIcon={true}
        setConfirm={setShowDeleteDialog}
        confirmCallback={(val) => {
          if (val) {
            confirmDelete();
          }
        }}
      />
      <Loader
        loader={
          props.viewPlanLoader ||
          props.viewPlanTableConfigLoader ||
          props.inventorysmartDeletePlanLoader
        }
        minHeight={"260px"}
      >
        {props.renderAgGrid && (
          <AgGridComponent
            columns={plansTableColumns}
            manualCallBack={(body, pageIndex, params) =>
              manualCallBack(body, pageIndex, params)
            }
            selectAllHeaderComponent={true}
            onSelectionChanged={onSelectionChanged}
            rowSelection="multiple"
            rowModelType="serverSide"
            serverSideStoreType="partial"
            onRowSelected
            onEditClick={(tableInfo) => onEditClick(tableInfo.data)}
            callDeleteApi={(tableInfo) => onDeleteClick(tableInfo.data)}
            isEditDisabled={!props.isEditAllowed}
            isDeleteDisabled={!props.isDeleteAllowed}
            totalCount={props.viewPlansTableData.total} // to set the total count once received from BE
            cacheBlockSize={10}
            uniqueRowId={"plan_code"}
          />
        )}
      </Loader>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    inventorysmartDeletePlanLoader:
      store.inventorysmartReducer.inventorySmartDashboardService
        .inventorysmartDeletePlanLoader,
    viewPlanLoader:
      store.inventorysmartReducer.inventorySmartDashboardService.viewPlanLoader,
    viewPlanTableConfigLoader:
      store.inventorysmartReducer.inventorySmartDashboardService
        .viewPlanTableConfigLoader,
    fetchViewPlansData:
      store.inventorysmartReducer.inventorySmartDashboardService
        .fetchViewPlansData,
    selectedFilters:
      store.inventorysmartReducer.inventorySmartDashboardService
        .selectedFilters,
    viewPlansTableData:
      store.inventorysmartReducer.inventorySmartDashboardService
        .viewPlansTableData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getViewPlanTableConfiguration: (payload) =>
    dispatch(getViewPlanTableConfiguration(payload)),
  getViewPlanTableData: (payload) => dispatch(getViewPlanTableData(payload)),
  setViewPlanTableLoader: (payload) =>
    dispatch(setViewPlanTableLoader(payload)),
  setViewPlanTableConfigLoader: (payload) =>
    dispatch(setViewPlanTableConfigLoader(payload)),
  setViewPlanTableData: (payload) => dispatch(setViewPlanTableData(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPlansTable);
