import React, { useEffect, useState } from "react";
import Loader from "../../../Utils/Loader/loader";
import PaginatedTable from "../../../Utils/reactTable";
import { Paper, Button } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import makeStyles from "@mui/styles/makeStyles";
import { getAllFilters, getFiltersValues } from "../../../actions/filterAction";
import { getAllProductAndGroups } from "../services-product-mapping/productMappingService";
import MappedStore from "./mapped-stores";
import Switch from "../../../Utils/switch";
import ProductStyleTable from "./style-level-Table";
import { setActiveScreenName } from "modules/react-demo/services/common-assort-service";
import { getColumnsAg } from "../../../actions/tableColumnActions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
} from "@mui/material";
import { connect } from "react-redux";
import { addSnack } from "actions/snackbarActions";
import { configureViewButton } from "pages/storeMapping/components/common-mapping-functions";
import AgGridTable from "Utils/agGrid";
import CellRenderers from "Utils/agGrid/cellRenderer";
import { useRef } from "react";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { getTenantConfigApplicationLevel } from "actions/tenantConfigActions";
import { productMappingTableArticleFilter } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

import { isActionAllowedOnSubModule } from "modules/inventorysmart/pages-inventorysmart/inventorysmart-utility";
import { INVENTORY_SUBMODULES_NAMES } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

const useStyles = makeStyles((theme) => ({
  confirmBox: {
    "& .MuiDialog-paper": {
      minWidth: "430px",
      borderRadius: "10px 10px 6px 6px",
    },
  },
  contentBody: {
    minHeight: "10rem",
  },
  action: {
    background: "#f7f7f7",
    padding: "1rem",
  },
  title: {
    ...theme.typography.h4,
    fontWeight: "600",
    paddingTop: "1.5rem",
  },
  text: {
    fontSize: "0.9rem",
    color: "#5a5a5ad9",
  },
  toggleStyle: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ProductsStoreMapping = React.forwardRef((props, ref) => {
  const [showloader, setloader] = useState(true);
  const [defaultDataSet, setDefaultDataSet] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [styleRowData, setStyleRowData] = useState([]);
  const [productCurrentPage, setProductCurrentPage] = useState(0);
  const [styleCurrentPage, setStyleCurrentPage] = useState(0);
  const [showMappedStore, setShowMappedStore] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [checked, setChecked] = useState(false);
  const [productDimension, setProductDimension] = useState("product");
  const [styleLevelColumns, setStyleColumns] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productGroupData, setProductGroupData] = useState([]);
  const [productCols, setProductCols] = useState([]);
  const [productGroupCols, setProductGroupCols] = useState([]);
  const [totalProduct, setProductTotal] = useState("");
  const [totalProductStyle, setProductStyleTotal] = useState("");
  const [tableInstance, setTableInstance] = useState(null);
  const [totalProductGroup, setPGTotal] = useState("");
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [showStyleLevelData, setShowStyleLevelData] = useState(true);

  const productGroupTableRef = useRef(null);
  const productTableRef = useRef(null);
  const styleTableRef = useRef(null);

  const displaySnackMessages = (msg, type) => {
    props.addSnack({
      message: msg,
      options: {
        variant: type,
      },
    });
  };

  useEffect(() => {
    const getInitialData = async () => {
      try {
        let cols = [];
        let productGroupColumns = [];
        cols = await getColumnsAg("table_name=product_store_products")();
        productGroupColumns = await getColumnsAg(
          "table_name=product_store_product_groups"
        )();
        let styleCols = await getColumnsAg("table_name=product_store_styles")();
        cols = cols.map((item) => {
          if (item.column_name === "mapped_stores_count") {
            item.type = "link";
            item.is_aggregated = false;
            item.is_editable = true;
            item.cellRenderer = (cellProps, extraProps) => {
              return (
                <CellRenderers
                  cellData={cellProps}
                  column={item}
                  extraProps={extraProps}
                ></CellRenderers>
              );
            };
          }
          item.onClick = (tableInfo) => {
            setSelectedID(tableInfo.cellData.data);
            setShowMappedStore(true);
          };
          return item;
        });
        styleCols = styleCols.map((item) => {
          if (item.column_name === "mapped_stores") {
            item.type = "link";
            item.is_aggregated = false;
            item.is_editable = true;
            item.cellRenderer = (cellProps, extraProps) => {
              return (
                <CellRenderers
                  cellData={cellProps}
                  column={item}
                  extraProps={extraProps}
                ></CellRenderers>
              );
            };
          }
          item.onClick = (tableInfo) => {
            setSelectedID(tableInfo.cellData.data);
            setShowMappedStore(true);
          };
          return item;
        });
        productGroupColumns = productGroupColumns.map((item) => {
          if (item.column_name === "mapped_stores_count") {
            item.type = "link";
            item.is_aggregated = false;
            item.is_editable = true;
            item.cellRenderer = (cellProps, extraProps) => {
              return (
                <CellRenderers
                  cellData={cellProps}
                  column={item}
                  extraProps={extraProps}
                ></CellRenderers>
              );
            };
            item.onClick = (tableInfo) => {
              setSelectedID(tableInfo.cellData.data);
              setShowMappedStore(true);
            };
          }
          return item;
        });
        let response = await getAllFilters("product mapping")();
        const filterElements = response.data.data.map(async (key) => {
          let body = {
            attribute_name: key.column_name,
            filter_type: key.type,
            filters: [],
          };
          const options = await getFiltersValues("product", body)();
          key.filter_keyword = key.column_name;
          key.initialData = options.data.data.attribute.map((item) => {
            return {
              value: item.attribute,
              label: item.attribute,
            };
          });
          return key;
        });

        await Promise.all(filterElements);

        const defaultValues = JSON.parse(
          JSON.stringify([...response.data.data])
        );
        setColumns(cols);
        setProductGroupCols(productGroupColumns);
        setProductCols(cols);
        setStyleColumns(styleCols);
        setDefaultDataSet([...defaultValues]);
        setloader(false);

        let showStyleLevelDataResp =
          await props.getTenantConfigApplicationLevel(3, {
            attribute_name: "core_show_style_level_info",
          });

        if (showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"]) {
          setShowStyleLevelData(
            showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"].value
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    getInitialData();

    props.setActiveScreenName("Product to Store mapping");
  }, []);

  const manualCallBack = async (manualbody, pageIndex, pageSize) => {
    setloader(true);
    if (ref.current.length === 0) {
      setloader(false);
      return {
        data: [],
        totalCount: 0,
      };
    }
    let filter = ref.current;
    // to filter table based on selected sku's, show data selected on alerts on inventory screen
    if (pageIndex === 0 && props.isRedirectedFromDifferentPage) {
      let articleFilter = productMappingTableArticleFilter;
      articleFilter.values = [...props.selectedProductMappingArticles];
      filter = [...ref.current, articleFilter];
    }

    try {
      let body = {
        filters: filter,
        meta: {
          ...manualbody,
        },
      };
      if (productDimension === "product") {
        if (checked) {
          const { data: styleDetails } = await getAllProductAndGroups(
            body,
            `product_level=style`
          )();
          styleDetails.data = configureViewButton(
            styleDetails.data,
            "mapped_stores"
          );
          setStyleRowData(styleDetails.data);
          setStyleCurrentPage(pageIndex + 1);
          setProductStyleTotal(styleDetails.total);
          setloader(false);
          return {
            data: styleDetails.data,
            totalCount: styleDetails.total,
          };
        } else {
          const { data: products } = await getAllProductAndGroups(
            body,
            `product_level=product&page=${pageIndex + 1}`
          )();
          products.data = configureViewButton(
            products.data,
            "mapped_stores_count"
          );
          setProductTotal(products.total);
          setProductData(products.data);
          setProductCurrentPage(pageIndex + 1);
          setloader(false);
          return {
            data: products.data,
            totalCount: products.total,
          };
        }
      } else if (productDimension === "product_group") {
        const { data: group } = await getAllProductAndGroups(
          body,
          `product_level=product_group&page=${pageIndex + 1}`
        )();
        group.data = configureViewButton(group.data, "mapped_stores_count");
        setPGTotal(group.total);
        setProductGroupData(group.data);
        setloader(false);
        return {
          data: group.data,
          totalCount: group.total,
        };
      }
      setloader(false);
    } catch (err) {
      setloader(false);
    }
  };

  useEffect(() => {
    props.filtersSelection.length ? onClickFilter() : onReset();
  }, [props.filtersSelection]);

  const onFilter = async () => {
    setloader(true);
    tableInstance.toggleAllRowsSelected(false);
    try {
      let body = {
        filters: props.filtersSelection,
        meta: {
          range: [],
          sort: [],
          search: [],
          // limit: { limit: 10, page: 1 },
        },
      };
      const { data: product } = await getAllProductAndGroups(
        body,
        `product_level=product`
      )();
      const { data: group } = await getAllProductAndGroups(
        body,
        `product_level=product_group`
      )();

      /**
       * disabling style related API
       */
      // const { data: styleDetails } = await getAllProductAndGroups(
      //   body,
      //   `product_level=style`
      // )();
      // styleDetails.data = configureViewButton(styleDetails.data, "mapped_stores_count");
      product.data = configureViewButton(product.data, "mapped_stores_count");
      group.data = configureViewButton(group.data, "mapped_stores_count");
      // setStyleRowData(styleDetails.data);
      setProductTotal(product.total);
      // setProductStyleTotal(styleDetails.total);
      setProductData(product.data);
      setPGTotal(group.total);
      setProductGroupData(group.data);
      setloader(false);
    } catch (err) {
      setloader(false);
    }
  };

  const onClickFilter = () => {
    if (productTableRef.current && !checked)
      productTableRef.current.api.refreshServerSideStore({ purge: true });
    if (styleTableRef.current && checked)
      styleTableRef.current.api.refreshServerSideStore({ purge: true });
    if (productGroupTableRef.current)
      productGroupTableRef.current.api.refreshServerSideStore({ purge: true });
  };

  const onConfirm = async () => {
    try {
      displaySnackMessages("Product Data Updated Successfully", "success");
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeDimension = (event) => {
    if (event.target.value === "product") {
      setColumns(productCols);
    } else {
      setColumns(productGroupCols);
    }
    props.changeDimension(event.target.value);
    setProductDimension(event.target.value);
  };

  const onReset = () => {
    setSelectedRowsIDs([]);
    onClickFilter();
  };

  const onStyleSwitch = async (event) => {
    const checkChecked = event.target.checked;
    setChecked(checkChecked);
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedRowsIDs(selectedRows);
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props?.inventorysmartModulesPermission,
      props?.module,
      subModuleName,
      action
    );
  };

  const renderContent = () => {
    return (
      <Loader loader={showloader}>
        <div data-testid="filterContainer">
          {showMappedStore && (
            <MappedStore
              checked={checked}
              selectedID={selectedID}
              dimension={productDimension}
              onModify={(dataBody) => {
                props.toggleModifyMapping(dataBody);
              }}
              onCancel={() => {
                setShowMappedStore(false);
              }}
              disableModify={canTakeActionOnModules(
                INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_MAPPING,
                "edit"
              )}
            ></MappedStore>
          )}

          <Dialog
            open={showModal}
            className={classes.confirmBox}
            onClose={() => setShowModal(false)}
            id={"routePrompt"}
          >
            <DialogContent className={classes.contentBody}>
              <div className={classes.title}> Update Changes</div>
              <div className={classes.text}>
                Are you sure to update all changes ?
              </div>
            </DialogContent>
            <DialogActions className={classes.action}>
              <Button
                id="routePromptYes"
                onClick={() => setShowModal(false)}
                color="primary"
                autoFocus
              >
                Close
              </Button>
              <Button
                id="routePromptYes"
                onClick={() => onConfirm()}
                color="primary"
                autoFocus
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>

          <div data-testid="resultContainer">
            <div
              className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
            >
              <RadioGroup
                row
                name="controlled-radio-buttons-group"
                value={productDimension}
                onChange={handleChangeDimension}
              >
                <FormControlLabel
                  value="product"
                  control={<Radio color="primary" />}
                  label={dynamicLabelsBasedOnTenant("product", "core")}
                />
                <FormControlLabel
                  value="product_group"
                  control={<Radio color="primary" />}
                  label={dynamicLabelsBasedOnTenant("product_grouping", "core")}
                />
              </RadioGroup>
              {productDimension === "product" ? (
                <div className={classes.toggleStyle}>
                  {dynamicLabelsBasedOnTenant("product", "core")}
                  &nbsp;level
                  {showStyleLevelData && (
                    <>
                      <Switch
                        className="switch"
                        id="productToggleBtn"
                        checked={checked}
                        onChange={onStyleSwitch}
                        // disabled={true}
                      ></Switch>
                      Style level
                    </>
                  )}
                </div>
              ) : (
                <div className={classes.toggleStyle} />
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (selectedRowsIDs.length > 0) {
                    props.toggleModifyMapping({
                      defaultDataSet: defaultDataSet,
                      cols: columns,
                      selectedProducts: selectedRowsIDs,
                    });
                  } else {
                    displaySnackMessages(
                      "Please select atleast one product",
                      "error"
                    );
                  }
                }}
                disabled={
                  !canTakeActionOnModules(
                    INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_MAPPING,
                    "edit"
                  )
                }
              >
                Modify
              </Button>
            </div>
            {productCols.length > 0 && productDimension === "product" && (
              <ProductStyleTable
                ref={checked ? styleTableRef : productTableRef}
                productColumns={productCols}
                styleColumns={styleLevelColumns}
                styleRowData={styleRowData}
                productRowData={productData}
                totalProductStyle={totalProductStyle}
                totalProduct={totalProduct}
                checked={checked}
                setSelectedRowsIDs={setSelectedRowsIDs}
                productCurrentPage={productCurrentPage}
                styleCurrentPage={styleCurrentPage}
                manualCallBack={manualCallBack}
                setTableInstance={setTableInstance}
                onSelectionChanged={onSelectionChanged}
              ></ProductStyleTable>
            )}
            {productDimension === "product_group" && (
              <AgGridTable
                columns={productGroupCols}
                selectAllHeaderComponent={true}
                hideSelectAllRecords={true}
                sizeColumnsToFitFlag
                onGridChanged
                onRowSelected
                manualCallBack={(body, pageIndex, params) =>
                  manualCallBack(body, pageIndex, params)
                }
                loadTableInstance={(gridInstance) => {
                  productGroupTableRef.current = gridInstance;
                }}
                rowModelType="serverSide"
                serverSideStoreType="partial"
                cacheBlockSize={10}
                uniqueRowId={"pg_code"}
                onSelectionChanged={onSelectionChanged}
              />
            )}
          </div>
        </div>
      </Loader>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
});

const mapStateToProps = (state) => {
  return {
    inventorysmartModulesPermission:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveScreenName: (data) => dispatch(setActiveScreenName(data)),
    addSnack: (snackObj) => dispatch(addSnack(snackObj)),
    getTenantConfigApplicationLevel: (dynamicRoute, queryParam) =>
      dispatch(getTenantConfigApplicationLevel(dynamicRoute, queryParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ProductsStoreMapping);
