import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useConstraintsStyles } from "./constraints-style";
import { checkValidation } from "./config";
import Table from "./view-table";
import StoreModalTable from "./store-table";
import { addSnack } from "actions/snackbarActions";
import { connect } from "react-redux";
import { getColumnsAg } from "actions/tableColumnActions";
import { isEmpty } from "lodash";
import {
  getStoreTableData,
  getStoreGradeTableData,
  saveStoreTableData,
  setConstraintsLoader,
  getStoreGroupTableData,
  setAllTableData,
  getStoreGradeAggregateData,
  getStoreGroupAggregateData,
} from "modules/inventorysmart/services-inventorysmart/Constraints/constraints-services";
import CellRenderers from "Utils/agGrid/cellRenderer";
import LoadingOverlay from "Utils/Loader/loader";
import CustomAccordion from "commonComponents/Custom-Accordian";
import {
  tableArticleFilter,
  tableStoreCodeFilter,
  INVENTORY_SUBMODULES_NAMES,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import { fetchProductCodes } from "../../inventorysmart-utility";
import { isActionAllowedOnSubModule } from "../../inventorysmart-utility";

const ConstraintsTables = (props) => {
  const [dimension, setDimension] = useState("store");
  const [finalColumn, updatefinalColumn] = useState([]);
  const [storeColumns, setStoreColumn] = useState([]);
  const [storeGradeColumn, setStoreGradeColumn] = useState([]);
  const [storeGroupColumn, setStoreGroupColumn] = useState([]);
  const [tableInstance, setTableInstance] = useState(null);
  const [showStoreModalTable, updateShowStoreModalTable] = useState(false);
  const [clickedRowData, updateClickedRowData] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [editedStoreGradeRows, setEditedStoreGradeRows] = useState([]);
  const [editedStoreGroupRows, setEditedStoreGroupRows] = useState([]);
  const [showloading, setShowloading] = useState(false);
  const [viewBy, setViewBy] = useState([]);
  const classes = useConstraintsStyles();
  const filterDependencies = useRef({});

  const setNewTableInstance = (params) => {
    setTableInstance(params);
  };
  const storePopupClick = (params) => {
    let finalCellData = params.cellData.node?.parent?.data
      ? { ...params.cellData.node.parent.data, ...params.cellData.data }
      : params.cellData.data;
    updateClickedRowData(finalCellData);
    updateShowStoreModalTable(true);
  };

  const setDynamicRenderer = (cellProps, extraProps, item) => {
    if (cellProps.node.level > 0) {
      return (
        <CellRenderers
          cellData={cellProps}
          column={item}
          extraProps={extraProps}
          // actions={actions}
        ></CellRenderers>
      );
    }
    return " ";
  };

  const setStoreGradeCols = (columns) => {
    // if user does not have edit access do not provide on click on hyperlink and call cell renderer for editable fields
    let permissionCheck = canTakeActionOnModules(
      INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_CONSTRAINTS,
      "edit"
    );
    if (!permissionCheck) {
      return columns.map((item) => {
        if (item.accessor === "article") {
          item.cellRenderer = "agGroupCellRenderer";
        }
        return item;
      });
    } else {
      return columns.map((item) => {
        if (item.type === "link") {
          item.onClick = storePopupClick;
        }
        if (
          item.accessor === "min_store" ||
          item.accessor === "max_store" ||
          item.accessor === "wos"
        ) {
          item.cellRenderer = (cellProps, extraProps) => {
            return setDynamicRenderer(cellProps, extraProps, item);
          };
        }
        if (item.accessor === "article") {
          item.cellRenderer = "agGroupCellRenderer";
        }
        return item;
      });
    }
  };

  useEffect(() => {
    setShowloading(true);
    const setTableConfig = async () => {
      let storeCols = await props.getColumnsAg(
        "table_name=inventorysmart_constraints_store_list"
      );
      let storeGradeCols = await props.getColumnsAg(
        "table_name=inventorysmart_constraints_sg_list"
      );
      let storeGroupCols = await props.getColumnsAg(
        "table_name=inventorysmart_constraints_sgs_list"
      );
      let viewBySegment = await props.getColumnsAg(
        "table_name=inventorysmart_constraints_view_by"
      );
      viewBySegment = viewBySegment.map((item) => {
        return {
          label: item.label,
          value: item.column_name,
        };
      });

      storeGroupCols = setStoreGradeCols(storeGroupCols);
      storeGradeCols = setStoreGradeCols(storeGradeCols);
      //  modifying table config, if the logged user does not have edit permission make editable fields non editable
      let permissionCheck = canTakeActionOnModules(
        INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_CONSTRAINTS,
        "edit"
      );
      if (!permissionCheck) {
        storeCols.forEach((item) => {
          if (item.is_editable) {
            item.is_editable = false;
            item.editable = false;
            item.cellRenderer = null;
          }
        });
      }
      updatefinalColumn(storeCols);
      setStoreColumn(storeCols);
      setStoreGradeColumn(storeGradeCols);
      setStoreGroupColumn(storeGroupCols);
      setViewBy(viewBySegment);
      setShowloading(false);
    };
    setTableConfig();
  }, []);

  const dimensionHandleChange = (event) => {
    let finalCols = setColumn(event.target.value);
    updatefinalColumn(finalCols);
    setEditedRows([]);
    setEditedStoreGradeRows([]);
    setDimension(event.target.value);
  };
  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };
  const setColumn = (updatedDimension) => {
    let finalDimension = updatedDimension ? updatedDimension : dimension;
    switch (finalDimension) {
      case "store":
        return storeColumns;
      case "store_group":
        return storeGroupColumn;
      case "store_grade":
        return storeGradeColumn;
      default:
        return storeColumns;
    }
  };
  useEffect(() => {
    if (!isEmpty(props.filterDependency)) {
      filterDependencies.current = props.filterDependency;
      tableInstance?.api?.refreshServerSideStore({ purge: true });
    } else {
      filterDependencies.current = {};
    }
  }, [props.filterDependency]);

  const setRequestBody = (manualbody, pageIndex, filterDependencyBody) => {
    let manualFilterbody = manualbody
      ? manualbody
      : { range: [], sort: [], search: [] };

    let payloadFilter = filterDependencies.current;
    if (pageIndex === 0 && props.isRedirectedFromDifferentPage) {
      let articleFilter = tableArticleFilter;
      let storeCodeFilter = tableStoreCodeFilter;

      const isArticleFilterApplied = payloadFilter.find(
        (filter) => filter.attribute_name === articleFilter.attribute_name
      );
      const isStoreCodeFilterApplied = payloadFilter.find(
        (filter) => filter.attribute_name === storeCodeFilter.attribute_name
      );

      if (!isArticleFilterApplied) {
        articleFilter.values = fetchProductCodes(
          props.selectedConstraintArticles
        );

        articleFilter?.values?.length > 0 && payloadFilter.push(articleFilter);
      }

      if (!isStoreCodeFilterApplied) {
        props.selectedStoreCodes?.length > 0
          ? (storeCodeFilter.values = [...props.selectedStoreCodes])
          : props.selectedConstraintArticles.forEach((article) => {
              if (article.store_code) {
                storeCodeFilter.values.push(article.store_code);
              }
            });

        storeCodeFilter?.values?.length > 0 &&
          payloadFilter.push(storeCodeFilter);
      }
    }

    return {
      filters: payloadFilter,
      meta: {
        ...manualFilterbody,
        limit: { limit: 10, page: pageIndex + 1 },
      },
    };
  };

  const manualCallBackStore = async (
    manualbody,
    pageIndex,
    filterDependencyBody
  ) => {
    try {
      let reqBody = setRequestBody(
        manualbody,
        pageIndex,
        props.filterDependencyBody
      );
      let { data: storeData } = await props.getStoreTableData(reqBody);
      storeData.data = storeData.data.map((item) => {
        item.uniqueKey = `${item.product_code} + ${item.store_code}`;
        return item;
      });
      return { data: storeData.data, totalCount: storeData?.total };
    } catch (err) {
      return [];
    }
  };

  const manualCallBackStoreGroup = async (
    manualbody,
    pageIndex,
    filterDependencyBody
  ) => {
    try {
      let reqBody = setRequestBody(manualbody, pageIndex, filterDependencyBody);
      reqBody.application_code = 1;
      let { data: storeData } = await props.getStoreGroupTableData(reqBody);
      storeData.data = storeData.data.map((item) => {
        item.sub_row = true;
        return item;
      });
      return { data: storeData.data, totalCount: storeData?.total };
    } catch (err) {
      return [];
    }
  };

  const manualCallBackStoreGrade = async (
    manualbody,
    pageIndex,
    filterDependencyBody
  ) => {
    try {
      let reqBody = setRequestBody(manualbody, pageIndex, filterDependencyBody);
      let { data: storeData } = await props.getStoreGradeTableData(reqBody);
      storeData.data = storeData.data.map((item) => {
        item.sub_row = true;
        return item;
      });
      return { data: storeData.data, totalCount: storeData?.total };
    } catch (err) {
      return { data: [], totalCount: 0 };
    }
  };

  const onCellValueChanged = (params) => {
    let validateFlag = checkValidation(params.data, params);
    if (!validateFlag) {
      let updatedRows = [];
      if (editedRows.length > 0) {
        let checkAlreadyExists = editedRows.some(
          (item) =>
            item.store_code === params.data.store_code &&
            item.product_code === params.data.product_code
        );
        if (checkAlreadyExists) {
          updatedRows = editedRows.map((item) => {
            if (
              item.store_code === params.data.store_code &&
              item.product_code === params.data.product_code
            ) {
              item = params.data;
            }
            return item;
          });
        } else {
          updatedRows = [...editedRows, params.data];
        }
      } else {
        updatedRows.push(params.data);
      }
      setEditedRows(updatedRows);
    }
  };

  const setPostRequestBody = (arr, storeLevel) => {
    return arr.map((item) => {
      return {
        product_code: item.product_code,
        min: Number(item.min_store),
        wos: Number(item.wos),
        max: Number(item.max_store),
        stores: storeLevel ? [item.store_code] : item.store_codes,
      };
    });
  };

  const onSaveRequest = async () => {
    props.setConstraintsLoader(true);
    try {
      let reqBody = [];
      if (editedRows.length > 0) {
        reqBody = setPostRequestBody(editedRows, true);
      } else if (editedStoreGradeRows.length > 0) {
        reqBody = setPostRequestBody(editedStoreGradeRows);
      } else if (editedStoreGroupRows.length > 0) {
        reqBody = setPostRequestBody(editedStoreGroupRows);
      }
      if (reqBody.length > 0) {
        await props.saveStoreTableData({ data: reqBody });
        displaySnackMessages("Data saved successfully", "success");
        setEditedRows([]);
        setEditedStoreGradeRows([]);
        setEditedStoreGroupRows([]);
      } else {
        displaySnackMessages("No change to save", "error");
      }
      props.setConstraintsLoader(false);
    } catch (err) {
      props.setConstraintsLoader(false);
      displaySnackMessages("Error while saving", "error");
    }
  };

  const onStoreGradeCellValueChanged = (params) => {
    let validateFlag = checkValidation(params.data, params);
    if (!validateFlag) {
      let updatedRows = [];
      if (editedStoreGradeRows.length > 0) {
        let checkAlreadyExists = editedStoreGradeRows.some(
          (item) =>
            item.store_grade === params.data.store_grade &&
            item.product_code === params.data.product_code
        );
        if (checkAlreadyExists) {
          updatedRows = editedStoreGradeRows.map((item) => {
            if (
              item.store_grade === params.data.store_grade &&
              item.product_code === params.data.product_code
            ) {
              item = params.data;
            }
            return item;
          });
        } else {
          updatedRows = [...editedStoreGradeRows, params.data];
        }
      } else {
        updatedRows.push(params.data);
      }
      setEditedStoreGradeRows(updatedRows);
    }
  };
  const modalClose = (success) => {
    if (success) {
      tableInstance.api.refreshServerSideStore({ route: [], purge: true });
    }
    updateShowStoreModalTable(false);
  };

  const setAllRequest = () => {
    let checkSelection =
      tableInstance?.api?.getSelectedNodes()?.length > 0 ? true : false;
    if (checkSelection) {
      let selectAllFlag = checkSetAllFlag();
      selectAllFlag
        ? tableInstance.trigerSetAll(true)
        : displaySnackMessages(
            "Set all is not valid for whole data set",
            "error"
          );
    } else {
      displaySnackMessages("Please select atleast one row", "error");
    }
  };

  const checkSetAllFlag = () => {
    let flag = false;
    if (tableInstance && tableInstance?.api?.checkConfiguration?.length > 1) {
      flag =
        tableInstance.api?.checkConfiguration[
          tableInstance.api?.checkConfiguration?.length - 2
        ].checkAll;
    }
    return !flag;
  };

  const onStoreGroupCellValueChanged = (params) => {
    let validateFlag = checkValidation(params.data, params);
    if (!validateFlag) {
      let updatedRows = [];
      if (editedStoreGroupRows.length > 0) {
        let checkAlreadyExists = editedStoreGroupRows.some(
          (item) =>
            item.store_group_name === params.data.store_group_name &&
            item.product_code === params.data.product_code
        );
        if (checkAlreadyExists) {
          updatedRows = editedStoreGroupRows.map((item) => {
            if (
              item.store_group_name === params.data.store_group_name &&
              item.product_code === params.data.product_code
            ) {
              item = params.data;
            }
            return item;
          });
        } else {
          updatedRows = [...editedStoreGroupRows, params.data];
        }
      } else {
        updatedRows.push(params.data);
      }
      setEditedStoreGroupRows(updatedRows);
    }
  };

  const onSetAllApply = async (rows, params) => {
    try {
      setShowloading(true);
      let checkAll =
        params.api.checkConfiguration.length > 1
          ? params.api.checkConfiguration[
              params.api.checkConfiguration.length - 2
            ].checkAll
          : false;
      if (checkAll) {
        let reqBody = {
          filters: props.filterDependency,
          meta: { range: [], sort: [], search: [] },
          values: {
            min: Number(rows.min_store),
            wos: Number(rows.wos),
            max: Number(rows.max_store),
          },
        };
        await props.setAllTableData(reqBody);
      } else {
        let reqBody = params.api.getSelectedNodes().map((item) => {
          let row = item.data;
          return {
            product_code: row.product_code,
            min: rows.min_store ? Number(rows.min_store) : row.min_store,
            wos: rows.wos ? Number(rows.wos) : row.wos,
            max: rows.max_store ? Number(rows.max_store) : row.max_store,
            stores: [row.store_code],
          };
        });
        await props.saveStoreTableData({ data: reqBody });
      }
      displaySnackMessages("Data saved successfully", "success");
      setShowloading(false);
      params.trigerSetAll(false);
      tableInstance.api.deselectAll(true);
      tableInstance.api.refreshServerSideStore({ purge: true });
    } catch (err) {
      setShowloading(false);
      displaySnackMessages("Error while saving", "error");
    }
  };
  const checkFlagEdit = () => {
    if (dimension === "store_grade") {
      return editedStoreGradeRows.some(
        (item) =>
          item.store_grade === clickedRowData.store_grade &&
          item.product_code === clickedRowData.product_code
      );
    }
    if (dimension === "store_group") {
      return editedStoreGroupRows.some(
        (item) =>
          item.store_group_name === clickedRowData.store_group_name &&
          item.product_code === clickedRowData.product_code
      );
    }
  };

  const setAggregateBody = (params) => {
    return {
      filters: props.filterDependency,
      product_code: params.parentNode.data.product_code,
      article: params.parentNode.data.article,
    };
  };
  const getStoreGradeSubRowsRequest = async (params) => {
    let reqBody = setAggregateBody(params);
    let { data } = await props.getStoreGradeAggregateData(reqBody);
    return {
      data: data.data,
      totalCount: data.data?.length || 0,
    };
  };
  const getStoreGroupSubRowsRequest = async (params) => {
    let reqBody = setAggregateBody(params);
    reqBody.application_code = 1;
    let { data } = await props.getStoreGroupAggregateData(reqBody);
    return {
      data: data.data,
      totalCount: data.data?.length || 0,
    };
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props.inventorysmartModulesPermission,
      props.module,
      subModuleName,
      action
    );
  };

  return (
    <div className={classes.paddingContent}>
      <CustomAccordion label="Store Data" defaultExpanded={true}>
        <LoadingOverlay loader={showloading}>
          {viewBy.length > 0 && (
            <div className={classes.constraintsToolbar}>
              <Grid
                className={classes.radioBtnsHeader}
                container
                direction="row"
                alignItems="center"
              >
                <span>View By : </span>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="controlled-radio-buttons-group"
                  value={dimension}
                  onChange={dimensionHandleChange}
                >
                  {viewBy.map((item) => {
                    return (
                      <FormControlLabel
                        value={item.value}
                        control={<Radio color="primary" />}
                        label={item.label}
                      />
                    );
                  })}
                </RadioGroup>
              </Grid>
              {dimension === "store" && (
                <div>
                  <Button
                    onClick={setAllRequest}
                    color="primary"
                    variant="contained"
                    disabled={
                      !canTakeActionOnModules(
                        INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_CONSTRAINTS,
                        "edit"
                      )
                    }
                  >
                    Set All
                  </Button>
                </div>
              )}
            </div>
          )}

          {finalColumn.length > 0 && (
            <Table
              columns={finalColumn}
              loadTableInstance={setNewTableInstance}
              dimension={dimension}
              manualCallBackStore={manualCallBackStore}
              manualCallBackStoreGrade={manualCallBackStoreGrade}
              manualCallBackStoreGroup={manualCallBackStoreGroup}
              onCellValueChanged={onCellValueChanged}
              onStoreGradeCellValueChanged={onStoreGradeCellValueChanged}
              onStoreGroupCellValueChanged={onStoreGroupCellValueChanged}
              onSetAllApply={onSetAllApply}
              getStoreGradeSubRowsRequest={getStoreGradeSubRowsRequest}
              getStoreGroupSubRowsRequest={getStoreGroupSubRowsRequest}
              inventorysmartModulesPermission={
                props.inventorysmartModulesPermission
                  ?.inventorysmart_constraints
              }
            />
          )}
          {showStoreModalTable && (
            <StoreModalTable
              formvalues={clickedRowData}
              displaySnackMessages={displaySnackMessages}
              onCancel={modalClose}
              flagEdit={checkFlagEdit()}
            />
          )}
          <div className={classes.footer}>
            <Button
              onClick={() => {
                onSaveRequest();
              }}
              color="primary"
              variant="contained"
              disabled={
                !canTakeActionOnModules(
                  INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_CONSTRAINTS,
                  "create"
                )
              }
            >
              Apply
            </Button>
          </div>
        </LoadingOverlay>
      </CustomAccordion>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    selectedConstraintArticles:
      store.inventorysmartReducer.inventorySmartConstraints
        .selectedConstraintArticles,
    inventorysmartModulesPermission:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartModulesPermission,
    selectedStoreCodes:
      store.inventorysmartReducer.inventorySmartConstraints.selectedStoreCodes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSnack: (payload) => dispatch(addSnack(payload)),
  setConstraintsLoader: (payload) => dispatch(setConstraintsLoader(payload)),
  getColumnsAg: (payload) => dispatch(getColumnsAg(payload)),
  getStoreTableData: (payload) => dispatch(getStoreTableData(payload)),
  getStoreGradeTableData: (payload) =>
    dispatch(getStoreGradeTableData(payload)),
  getStoreGroupTableData: (payload) =>
    dispatch(getStoreGroupTableData(payload)),
  setAllTableData: (payload) => dispatch(setAllTableData(payload)),
  saveStoreTableData: (payload) => dispatch(saveStoreTableData(payload)),
  getStoreGradeAggregateData: (payload) =>
    dispatch(getStoreGradeAggregateData(payload)),
  getStoreGroupAggregateData: (payload) =>
    dispatch(getStoreGroupAggregateData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsTables);
