import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CalendarToday,
  FactCheck,
  Storefront,
  PieChart,
  Factory,
} from "@mui/icons-material";
import Layout from "commonComponents/layout";
import {
  CREATE_ALLOCATION,
  PRODUCT_CONFIGURATION,
  DASHBOARD,
  WHATIF,
  CREATE_PRODUCT_PROFILE,
  CONFIGURATION,
  CONSTRAINTS,
  PRODUCT_PROFILE,
  ALLOCATION_REPORT,
  ORDER_BATCHING,
  VIEW_PAST_ALLOCATION,
  ORDER_MANAGEMENT,
  ADA_VISUAL,
  ORDER_REPOSITORY,
  ORDER_REPOSITORY_WHATIF,
  CREATE_NEW_ORDER,
  SETTINGS,
  GROUPING,
  MEIO,
} from "../constants-ootb/routesConstants";

import {
  BsPlusLg,
  BsFillPatchQuestionFill,
  BsReverseLayoutSidebarInsetReverse,
  BsReverseLayoutTextWindowReverse,
} from "react-icons/bs";
import GridViewIcon from "@mui/icons-material/GridView";
import DialpadIcon from "@mui/icons-material/Dialpad";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import { Inventory2Outlined } from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category";
import TourIcon from "@mui/icons-material/Tour";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import "commonComponents/layout/layout.css";

import DashboardComponent from "../pages-ootb/react-demo/dashboard";
import CreateNewAllocationComponent from "../pages-ootb/react-demo/create-new-allocation";
import Grouping from "../pages-ootb/react-demo/grouping";
import ConfigurationComponent from "../pages-ootb/react-demo/configuration";
import ConstraintsComponent from "../pages-ootb/react-demo/constraints";
import ProductProfileComponent from "../pages-ootb/react-demo/product-profile";
import ADAVisualsComponent from "../pages-ootb/react-demo/ada-visual";
import ReportsComponent from "../pages-ootb/react-demo/reports";
import ViewPastAllocationComponent from "../pages-ootb/react-demo/view-past-allocation";
import OrderManagement from "../pages-ootb/react-demo/order-management";
import OrderRepository from "../pages-ootb/react-demo/order-repository";
import CreateNewOrderComponent from "../pages-ootb/react-demo/create-new-order";
export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
    title: "Dashboard",
    icon: React.createElement(GridViewIcon),
    order: 1,
  },
  {
    link:
      "/" +
      window.location.pathname.split("/")[1] +
      `${CREATE_ALLOCATION}?step=0`,
    title: "Create New Allocation",
    icon: React.createElement(BubbleChartIcon),
    order: 2,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + GROUPING,
    title: "Grouping",
    icon: React.createElement(DialpadIcon),
    order: 3,
  },

  {
    link: "/" + window.location.pathname.split("/")[1] + CONFIGURATION,
    title: "Configuration",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 4,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CONSTRAINTS,
    title: "Constraints",
    icon: React.createElement(TourIcon),
    order: 5,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PRODUCT_PROFILE,
    title: "Product Profile",
    icon: React.createElement(CategoryIcon),
    order: 6,
  },

  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_VISUAL,
    title: "ADA Visual",
    icon: React.createElement(Inventory2Outlined),
    order: 7,
  },

  {
    link: "/" + window.location.pathname.split("/")[1] + ALLOCATION_REPORT,
    title: "Reports",
    icon: React.createElement(BarChartRoundedIcon),
    order: 8,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + VIEW_PAST_ALLOCATION,
    title: "View Past Allocation",
    icon: React.createElement(VisibilityRoundedIcon),
    order: 9,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ORDER_MANAGEMENT,
    title: "Order Management",
    icon: React.createElement(BsReverseLayoutSidebarInsetReverse),
    order: 10,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ORDER_REPOSITORY,
    title: "Order Repository",
    icon: React.createElement(BsReverseLayoutTextWindowReverse),
    order: 11,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CREATE_NEW_ORDER,
    title: "Create New Order",
    icon: React.createElement(BsPlusLg),
    order: 12,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
      component: DashboardComponent,
      title: "Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CREATE_ALLOCATION,
      component: CreateNewAllocationComponent,
      title: "Create New Allocation",
      screenName: "Allocation",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + GROUPING,
      component: Grouping,
      title: "Grouping",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CONFIGURATION,
      component: ConfigurationComponent,
      title: "Configuration",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CONSTRAINTS,
      component: ConstraintsComponent,
      title: "Constraints",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PRODUCT_PROFILE,
      component: ProductProfileComponent,
      title: "Product Profile",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_VISUAL,
      component: ADAVisualsComponent,
      title: "ADA Visuals",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ALLOCATION_REPORT,
      component: ReportsComponent,
      title: "Reports",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + VIEW_PAST_ALLOCATION,
      component: ViewPastAllocationComponent,
      title: "View Past Allocation",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ORDER_MANAGEMENT,
      component: OrderManagement,
      title: "Order Management",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ORDER_REPOSITORY,
      component: OrderRepository,
      title: "Order Repository",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CREATE_NEW_ORDER,
      component: CreateNewOrderComponent,
      title: "Create New Order",
    }
  ];
  return (
    <Layout routes={routes} sideBarOptions={sideBarOptions} app={"ootb"} />
  );
};

const mapStateToProps = () => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Routes));
