import React from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Inventory2Outlined } from "@mui/icons-material";
import {
  ADA_DASHBOARD,
  ADA_ATTRIBUTE,
  ADA_FILE_SELECTOR,
  ADA_GENERATE_ATTRIBUTES,
} from "../constants-adachatbot/routesContants";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";
import Dashboard from "../pages-adachatbot/react-demo/dashboard";
import Attribute from "../pages-adachatbot/react-demo/attribute";
import Fileselector from "../pages-adachatbot/react-demo/fileSelector";
import GenerateAttributes from "../pages-adachatbot/react-demo/generateAttributes";

export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_DASHBOARD,
    title: "Dashboard",
    icon: React.createElement(Inventory2Outlined),
    order: 1,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_ATTRIBUTE,
    title: "Products Dashboard",
    icon: React.createElement(AutoAwesomeMosaicIcon),
    order: 2,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_FILE_SELECTOR,
    title: "File Selector",
    icon: React.createElement(CloudUploadIcon),
    order: 3,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_GENERATE_ATTRIBUTES,
    title: "Generate Attributes",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 4,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_DASHBOARD,
      component: Dashboard,
      title: "Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_ATTRIBUTE,
      component: Attribute,
      title: "Products Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_FILE_SELECTOR,
      component: Fileselector,
      title: "File Selector",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_GENERATE_ATTRIBUTES,
      component: GenerateAttributes,
      title: "Generate Attributes",
    },
  ];
  return (
    <Layout routes={routes} sideBarOptions={sideBarOptions} app="adachatbot" />
  );
};

export default Routes;
