import { GRAPH_MENU_LIST } from "./constants";

export const stackedBarChartOptions = (props) => {
  let filteredSeries = props.series.filter(
    (data) => data.name !== "Other Msrp"
  );
  let deletedItem = props.series.filter((data) => data.name == "Other Msrp");
  let sortedSeries;
  if (deletedItem.length > 0) {
    sortedSeries = filteredSeries.sort(
      (a, b) => parseInt(a.name) - parseInt(b.name)
    );
    sortedSeries.push(deletedItem[0]);
  } else {
    sortedSeries = props.series.sort(
      (a, b) => parseInt(a.name) - parseInt(b.name)
    );
  }
  return {
    chart: {
      type: "column",
    },
    title: {
      useHTML: props.chartTitle ? false : true,
      text: props.chartTitle ? props.chartTitle : "&nbsp",
    },
    xAxis: {
      categories: props.axisLegends.xaxis.categories,
      title: {
        text: props.axisLegends.xaxis.title,
        style: {
          fontSize: "14px",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: props.axisLegends.yaxis.title,
        style: {
          fontSize: "14px",
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        propsLabels: {
          enabled: true,
        },
      },
      series: {
        pointWidth: 40,
      },
    },
    // to show navigation of legends
    legend: {
      y: 10,
      maxHeight: 60,
      itemDistance: 10,
      padding:25,
      itemStyle: {
        fontSize: "14px"
      },
    },
    credits: {
      enabled: false,
    },
    exporting: GRAPH_MENU_LIST,
    series: sortedSeries,
  };
};
