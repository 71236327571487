import React from "react";
import moment from "moment";
import Home from "@mui/icons-material/Home";
import {
  BREAD_CRUMB_TITLES,
  Plan,
  coreChoicePlanLevels,
} from "../constants/stringContants";
import Tooltip from "@mui/material/Tooltip";
import Select from "commonComponents/filters/Select/Select";
import {
  percentFormatter,
  numbersWithComma,
  dollarFormatter,
  decimalsFormatter,
  arrayToCommaFormatter,
  capitalize,
  isEcommOrWholeSale,
} from "Utils/formatter";
import { cloneDeep, isArray, isEmpty } from "lodash";
import {
  DASHBOARD_HINDSIGHT,
  ASSORT_CLUSTER_DASHBOARD,
} from "../constants/routesConstants";
import ReactSelect from "Utils/select";

export const AgGridAssortnonEditableCell = (item, ins) => {
  //it returns the formatter to cell based on type
  const isDecimal = item.formatter === "roundOfftoTwoDecimals" ? true : false;
  switch (item.type) {
    case "percentage":
      return percentFormatter(
        ins,
        isDecimal,
        item.is_editable ? true : false //for footer we don't want to multiply it by 100 so passing true
      );
    case "int":
      return numbersWithComma(ins, isDecimal);
    case "float":
      return decimalsFormatter(ins, isDecimal);
    case "dollar":
      return dollarFormatter(ins, isDecimal);
    case "attribute":
      return attributeFormatter(ins.value, false);
    case "bool":
      return ins?.value || "";
    case "array":
      return arrayToCommaFormatter(ins.value);
    default:
      if (ins.value === 0 || ins.value === false || ins.value) {
        return ins.value;
      }
      return "";
  }
};

export const updateLyColumnHeading = (compareType) => {
  //Used to update the LY columns as LY for -1, LLY for -2 and LLLY for -3
  switch (compareType) {
    case -1:
      return "LY";
    case -3:
      return "LLLY";
    default:
      return "LLY";
  }
};

export const updateTableData = (
  rowIndex,
  columnId,
  value,
  _initialValue,
  _column,
  instance,
  _row,
  _id
) => {
  instance.settabledata((old) =>
    old.map((eachRow, index) => {
      if (index === rowIndex) {
        return {
          ...eachRow,
          [columnId]: value,
        };
      }
      return eachRow;
    })
  );
};

//Check if drops option should be disabled or enabled
export const isDropsDisabled = (startDate, endDate, compareType) => {
  if (startDate && endDate) {
    let rangeEndDate = endDate.subtract(Math.abs(compareType), "years");
    return rangeEndDate.isAfter(moment());
  }
  return true;
};

//Calculate number of weeks in selling period
export const calculateNoOfWeeks = (startDate, endDate) => {
  if (startDate && endDate) {
    let date1 = new Date(startDate);
    let date2 = new Date(endDate);
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff = diff / (60 * 60 * 24 * 7);
    return Math.abs(Math.round(diff));
  } else {
    return -1;
  }
};
//convert year into value
export const convertCompareYrToNum = (yrStr) => {
  switch (yrStr) {
    case "LY":
      return -1;
    case "LLY":
      return -2;
    case "LLLY":
      return -3;
    default:
      return -1;
  }
};
//Converts steps into texts
export const planStepFormatter = (value) => {
  switch (value) {
    case "1.2":
      return "Finalize Cluster";
    case "2.1":
      return "Plan";
    case "2.2":
      return "Depth and Choice";
    case "2.3":
      return "Build the wedge";
    case "2.4":
      return "Finalize";
    case "3":
      return "Completed";
    case "4":
      return "Approved";
    case "5":
      return "OMNI Plan";
    case "6":
      return "OMNI Plan Placeholder created";
    case "7":
      return "OMNI Plan Merged";
    case "8":
      return "Finalise for PO";
    default:
      return "Cluster Input";
  }
};

const getFloatValue = (isPrice, val) => {
  if (parseFloat(val) >= 0) {
    return (isPrice ? "$" : "") + parseInt(val).toString();
  } else return "";
};

export const parseValue = (data) => {
  if (typeof data === "string") {
    //removing commas from numbers
    return data?.toString()?.replaceAll(",", "");
  }
  //parsing the data to float if it has values else returning zero
  if (parseFloat(data)) {
    return parseFloat(data);
  } else {
    return 0;
  }
};

//Prepare filters Array in format required to pass to API
export const getFiltersRespArr = (filterNames) => {
  return filterNames.map((filterName) => {
    return {
      attribute_name: filterName,
      operator: "in",
      filter_type: "cascaded",
      values: [],
    };
  });
};

export const attributeFormatter = function (value) {
  //isPrice key is used for inserting dollar in value formatter

  //If value is not a valid type, return empty string
  if (!value) {
    return "";
  }

  //If value is not a numerical type, return by camel casing the text
  if (isNaN(parseInt(value))) {
    return value.length < 4
      ? value
          .replace(" penetration", "")
          .split("_")
          .map((setAllfield) => {
            //If setAll field contains term "cc",e.g max_cc whole term needs to be converted to uppercase
            if (setAllfield === "cc") return setAllfield.toUpperCase();
            return (
              setAllfield.charAt(0).toUpperCase() +
              setAllfield.slice(1).toUpperCase() +
              " "
            );
          })
          .join("")
      : value
          .replace(" penetration", "")
          .split("_")
          .map((setAllfield) => {
            //If setAll field contains term "cc",e.g max_cc whole term needs to be converted to uppercase
            if (setAllfield === "cc") return setAllfield.toUpperCase() + " ";
            return (
              setAllfield.charAt(0).toUpperCase() + setAllfield.slice(1) + " "
            );
          })
          .join("");
  }

  //If value is in numerical format
  return value
    .replace(" penetration", "")
    .replace("_", " ")
    .split("_")
    .map((e) => {
      let temp = e.split(" ");
      temp[0] = parseFloat(temp[0]) >= 0 ? parseInt(temp[0]).toString() : "";
      temp[1] = parseFloat(temp[1]) >= 0 ? parseInt(temp[1]).toString() : "";
      return temp[1].length ? temp[0] + " - " + temp[1] : temp[0];
    })
    .join("");
};

//Format String Array into Label and Value array
export const formatStringArray = (strArr) => {
  return strArr.map((str) => {
    return {
      label: str,
      value: str,
      id: str,
    };
  });
};

export const generateLevelJson = (data) => {
  //From levels api response we are creating a json
  //{ "l1_name": "Department", "l2_name": "Sub Department"}
  let level = {};
  data.level_info.forEach((element) => {
    level[element.column_name] = element.label;
  });
  const final_plan_level = data.final_plan_level;
  level[final_plan_level.column_name] = final_plan_level.label;
  return level;
};

export const hyphenatedFormatter = (data) => {
  // Remove hyphen from string and format it into a readable text
  return data
    .split("-")
    .map((e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase() + " ")
    .join("");
};

export const getBreadCrumbHeader = (planStep, location) => {
  if (planStep === 0) {
    if (location.includes("hindsight")) {
      return BREAD_CRUMB_TITLES[3];
    } else if (location.includes("omnichannel")) {
      return BREAD_CRUMB_TITLES[4];
    } else if (location.includes("omnimapping")) {
      return BREAD_CRUMB_TITLES[5];
    } else if (location.includes("alldoorchoiceconfiguration")) {
      return BREAD_CRUMB_TITLES[6];
    } else if (location.includes("cluster-dashboard")) {
      return BREAD_CRUMB_TITLES[7];
    }
    return BREAD_CRUMB_TITLES[0];
  }
  if (planStep >= 1.1 && planStep <= 1.2) {
    if (location.includes("hindsight")) {
      let breadCrum = cloneDeep(BREAD_CRUMB_TITLES[1]);
      breadCrum[0] = {
        label: "AssortSmart",
        route: DASHBOARD_HINDSIGHT,
        icon: <Home />,
      };
      return breadCrum;
    } else if (location.includes("cluster-dashboard")) {
      let breadCrum = cloneDeep(BREAD_CRUMB_TITLES[8]);
      breadCrum[0] = {
        label: "AssortSmart",
        route: ASSORT_CLUSTER_DASHBOARD,
        icon: <Home />,
      };
      return breadCrum;
    }
    return BREAD_CRUMB_TITLES[1];
  }
  if (planStep >= 2.1 && planStep <= 2.4) {
    return BREAD_CRUMB_TITLES[2];
  }
};

export const formatBreadCrumbs = (steps) => {
  return steps.map((step) => {
    return {
      id: `${step.label}_scr`,
      label: step.label,
      action: step.action,
    };
  });
};

export const getDateFilterSubractCompareYear = (planData) => {
  return {
    attribute_name: "date",
    value: [
      "'" +
        moment(planData.selling_period_sdate)
          .subtract(Math.abs(planData.compare_year), "years")
          .format("YYYY-MM-DD") +
        "' and '" +
        moment(planData.selling_period_edate)
          .subtract(Math.abs(planData.compare_year), "years")
          .format("YYYY-MM-DD") +
        "'",
    ],
    operator: "between",
  };
};

export const getDateFilters = (planData) => {
  return {
    attribute_name: "date",
    value: [
      "'" +
        moment(planData.selling_period_sdate).format("YYYY-MM-DD") +
        "' and '" +
        moment(planData.selling_period_edate).format("YYYY-MM-DD") +
        "'",
    ],
    operator: "between",
  };
};

export const getLevelFiltersBigQuery = (planData, levels) => {
  return levels?.data?.level_info.map((level) => {
    return {
      attribute_name: level.column_name,
      value: planData[level.column_name],
      operator: "in",
    };
  });
};

export const getDefaultValues = (elementsData, selectedData) => {
  let defaultValues = {};
  elementsData.forEach((item) => {
    if (selectedData[item.accessor]) {
      defaultValues[item.accessor] = selectedData[item.accessor];
    } else {
      defaultValues[item.accessor] = "";
    }
  });
  return defaultValues;
};
export const getLevelFilters = (planData, levels) => {
  return levels?.data?.level_info.map((level) => {
    return {
      attribute_name: level.column_name,
      value: planData[level.column_name],
      prefix: "levels",
      operator: "in",
    };
  });
};

export const filterView = (label, key, options, onChange, value, className) => {
  if (options?.length > 1) {
    // returning drop down if it has more than one options
    return (
      <div className={className || ""} id={label}>
        <label className={"drop-down-label"}>{`${label}: `}</label>
        <Select
          menuPosition={"fixed"}
          isSearchable={true}
          menuShouldBlockScroll={true}
          pagination={true}
          fetchOptions={options}
          initialData={options}
          selectedOptions={[value]}
          updateDependency={(_key, option) => {
            onChange(option[0]);
          }}
          label={key}
        />
      </div>
    );
  } else {
    // return label instead of drop down if it has only one option
    return (
      <div className={className} id={label}>
        <label className={"bold-label"}>{`${label}: `} </label>
        <Tooltip
          placement="top"
          title={<React.Fragment>{value?.label}</React.Fragment>}
        >
          <span className={"truncate-text"}>{value?.label}</span>
        </Tooltip>
      </div>
    );
  }
};

export const getPlanPayload = (
  planData,
  levels,
  isDateRequired,
  withoutPrefix = false
) => {
  let filtersArray = [
    {
      attribute_name: "plan_code",
      value: [planData.plan_code],
      operator: "in",
    },
  ];
  if (withoutPrefix) {
    filtersArray.push(...getLevelFiltersBigQuery(planData, levels));
  } else {
    filtersArray.push(...getLevelFilters(planData, levels));
  }
  if (isDateRequired) {
    filtersArray.push(getDateFilters(planData));
  }
  return {
    filters: filtersArray,
  };
};

export const getAttrGraphPayload = (planData, levels, formData) => {
  let filtersArray = [
    {
      attribute_name: "cluster_plan_code",
      value: [planData.cluster_plan_code],
      operator: "in",
    },
  ];
  //getting levels filter json
  filtersArray.push(...getLevelFiltersBigQuery(planData, levels));

  //subtracting compare year from start date
  let startDate = new Date(planData.selling_period_sdate);
  startDate.setFullYear(
    startDate.getFullYear() - Math.abs(planData.compare_year || 0)
  );
  startDate = moment(startDate).format("YYYY-MM-DD");

  //subtracting compare year from end date
  let endDate = new Date(planData.selling_period_edate);
  endDate.setFullYear(
    endDate.getFullYear() - Math.abs(planData.compare_year || 0)
  );
  endDate = moment(endDate).format("YYYY-MM-DD");

  filtersArray.push({
    attribute_name: "date",
    operator: "between",
    value: [`'${startDate}' and '${endDate}'`],
  });

  if (formData?.attribute) {
    filtersArray.push({
      attribute_name: "attributes_selected",
      value: isArray(formData?.attribute)
        ? formData?.attribute
        : [formData?.attribute],
      operator: "in",
    });
  }

  if (formData?.attributeBucketId) {
    filtersArray.push({
      attribute_name: "bucket_id",
      value: [parseInt(formData?.attributeBucketId)],
      operator: "in",
    });
  }

  if (formData?.channel) {
    filtersArray.push({
      attribute_name: "channel",
      value: [formData?.channel],
      operator: "in",
    });
  }
  return {
    filters: filtersArray,
  };
};

export const getOmniLevelFilters = (planData, levels) => {
  return levels?.data?.level_info.map((level) => {
    return {
      attribute_name: level.column_name,
      value: planData[level.column_name],
      prefix: "source_levels",
      operator: "in",
    };
  });
};

export const getOmniPlanPayload = (planData, levels) => {
  let filtersArray = [
    {
      attribute_name: "source_plan_code",
      value: [planData.plan_code],
      operator: "in",
    },
  ];
  filtersArray.push(...getOmniLevelFilters(planData, levels));
  return {
    filters: filtersArray,
  };
};

/**
 *
 * @param {any} value
 * @returns array
 *
 * This method checks if the passed value is array or not and return in the array format
 */
export const returnInArrayFormat = (value) => {
  return Array.isArray(value) ? value : [value];
};

export const generateColumnsToFilter = (columns, levelsJson) => {
  if (columns?.length) {
    return columns.map((col) => {
      return {
        key: col,
        label: levelsJson[col] || attributeFormatter(col),
        defaultInx: 0,
      };
    });
  }
};

export const assortCustomCellRenderer = (cellProps, history) => {
  const { column, tableId, row } = cellProps;
  if (column.type === "ToogleField") {
    if (tableId === "assortDashboardPlansTable") {
      if (history.location.pathname.includes("omnichannel")) {
        if (row.original.plan_step < 7) {
          return "-";
        }
        return "";
      }
      if (row.original.plan_step < 3) {
        return "-";
      }
      return "";
    }
  }
  if (tableId === "wedge table") {
    if (column.id === "all_door_choice" || column.id === "dropship_choice") {
      if (row.original.flow_name !== "-") {
        return "-";
      } else if (
        cellProps.value === "Yes" ||
        row.original.attributes_selling_collection === "collegiate"
      ) {
        return cellProps.value || "-";
      }
    }
    if (
      column.id === "attributes_selling_collection" &&
      row.original.attributes_selling_collection === "collegiate"
    ) {
      return cellProps.value;
    }
    if (
      Plan.__Wedge_Column_Type_disable.includes(column.type) &&
      row.original.flow_name !== "-"
    ) {
      return cellProps.value || "-";
    }
    return "";
  }
  if (history.location.pathname.includes("alldoorchoiceconfiguration")) {
    if (column.Header === "All Door Choice Count") {
      if (row.original.is_finalised_plan) {
        return row.original.all_door_cc;
      }
      return "";
    }
  }
  return "";
};

const handleDropDown = (e, instance) => {
  let cellNode = instance.data;
  let colId = instance.colDef.column_name;
  cellNode[`selected_${colId}`] = e.value;
};

export const assortAgGridCustomCellRenderer = (cellProps, tableID, history) => {
  const { data, colDef } = cellProps;
  switch (tableID) {
    case "dashboard":
      if (colDef.type === "ToogleField") {
        if (history.location.pathname.includes("omnichannel")) {
          if (data.plan_step < 7) {
            return "-";
          }
          return "";
        }
        if (data.plan_step < 3) {
          return "-";
        }
        return "";
      }
      break;
    case "budget-level2-table":
      if (data.channel === "Total") {
        return colDef.column_name === "view_trends"
          ? " "
          : AgGridAssortnonEditableCell(colDef, {
              value: data[colDef.column_name],
            });
      }
      break;
    case "budget-level3-table":
      if (data.l3_name === "Total") {
        return colDef.column_name === "lock" || colDef.column_name === "delete"
          ? " "
          : colDef.column_name === "penetration_ty" ||
            colDef.column_name === "budget_ty" ||
            colDef.column_name === "aur_ty"
          ? AgGridAssortnonEditableCell(colDef, {
              value: data[colDef.column_name],
            })
          : //parseInt(data[colDef.column_name])
            null;
      }
      break;
    case "NonLinearEditTable":
      if (data.l3_name === "Total") {
        return colDef.column_name === "penetration_ty" ||
          colDef.column_name === "aur_ty"
          ? parseInt(data[colDef.column_name])
          : null;
      } else {
        return colDef.column_name === "budget_ty"
          ? parseInt(data[colDef.column_name]) === 0
            ? "0"
            : AgGridAssortnonEditableCell(colDef, {
                value: data[colDef.column_name],
              })
          : null;
      }
    case "choice-table":
      if (data.l3_name === "Total") {
        return colDef.column_name.includes("_ty")
          ? parseInt(data[colDef.column_name])
          : null;
      }
      break;
    case "setupflowtable":
      if (data.l3_name === "Total") {
        return colDef.column_name.includes("drop_split")
          ? " "
          : colDef.column_name.includes("penetration_flow_")
          ? parseInt(data[colDef.column_name]) || 0
          : null;
      }
      break;
    case "wedge_table":
      if (
        data?.flow_name !== "-" &&
        colDef.column_name.includes("attributes_")
      ) {
        return cellProps.value || "-";
      }
      if (
        colDef.column_name === "attributes_selling_collection" &&
        data.attributes_selling_collection === "collegiate"
      ) {
        return cellProps.value;
      }
      if (
        colDef.column_name === "all_door_choice" ||
        colDef.column_name === "dropship_choice"
      ) {
        if (data?.flow_name !== "-") {
          return "-";
        } else if (cellProps.value === "Yes") {
          return cellProps.value || "-";
        }
      }
      break;
    case "cluster_table":
      if (data.l3_name === "Total") {
        return colDef.column_name.includes("_ty")
          ? AgGridAssortnonEditableCell(colDef, {
              value: data[colDef.column_name],
            })
          : null;
      }
      break;
    case "all-door-cc":
      if (colDef.column_name === "all_door_cc") {
        if (data.is_finalised_plan) {
          return cellProps.value;
        }
        return "";
      }
      break;
    case "add_choice_table":
      if (colDef.accessor.includes("attributes_")) {
        colDef.cellRenderer = (instance) => {
          return (
            <div style={{ width: "100%" }}>
              <ReactSelect
                menuShouldBlockScroll={true}
                menuPortalTarget={document.body}
                name={colDef.accessor}
                isMulti={colDef.isMulti}
                isSearchable={false}
                options={instance?.data?.[colDef.accessor] || []}
                data-testid={`select${colDef.name}`}
                onChange={(option) => handleDropDown(option, instance)}
                isDisabled={colDef.disabled}
              />
            </div>
          );
        };
      }
      break;
    default:
      break;
  }
};

export const getDropName = (value) => {
  if (value) {
    if (value === "-") {
      return "All Drops";
    } else {
      return capitalize(value.replace("_", ""));
    }
  }
};

export const formatFooterCell = (item, value, forFooter = true) => {
  //it returns the formatter to cell based on type
  const isDecimal = item.formatter === "roundOfftoTwoDecimals" ? true : false;
  switch (item.type) {
    case "percentage":
      return percentFormatter(
        { value },
        isDecimal,
        forFooter && item.is_editable ? true : false //for footer we don't want to multiply it by 100 so passing true
      );
    case "int":
      return numbersWithComma({ value }, isDecimal);
    case "float":
      return decimalsFormatter({ value }, isDecimal);
    case "dollar":
      return dollarFormatter({ value }, isDecimal);
    case "bool":
      return value || "";
    case "array":
      return arrayToCommaFormatter(value);
    default:
      if (value === 0 || value === false || value) {
        //This condition will work for not undefined and not null values
        return value;
      }
      return "";
  }
};

export const isEcomPlan = (planDetails) => {
  if (
    planDetails?.channel?.[0] === "Ecom" ||
    planDetails?.channel?.[0] === "ECOMM"
  ) {
    return true;
  }
  return false;
};

export const isWholesalePlan = (planDetails) => {
  if (planDetails?.channel?.[0] === "Wholesale") {
    return true;
  }
  return false;
};

export const isDropPlan = (planDetails) => {
  if (planDetails?.drops_count > 1) {
    return true;
  }
  return false;
};

export const showCreateStoreGroup = (planDetails) => {
  return !isEcommOrWholeSale(planDetails?.channel?.[0]);
};

export const getDateFormat = () => {
  return "MM/DD/YYYY";
};

export const removeNumberFromText = (data) => {
  return data.replace(/[0-9]/g, "");
};

export const generateFilterValues = (planData, levels) => {
  const filterValues = levels.map((level) => {
    return {
      attribute_name: level,
      operator: "in",
      filter_type: "cascaded",
      values: planData[level],
    };
  });
  return filterValues;
};

export const addDropToPayload = (data, payload) => {
  if (data?.drops_count > 1) {
    let dropArr = [];
    for (let i = 1; i <= data?.drops_count; i++) {
      dropArr.push(data?.[`drops_${i}`]);
    }
    payload.filters.push({
      attribute_name: "drop",
      value: dropArr,
      prefix: "levels",
      operator: "in",
    });
  } else {
    payload.filters.push({
      attribute_name: "drop",
      value: ["-"],
      prefix: "levels",
      operator: "in",
    });
  }
  return payload;
};

export const getCoreChoiceFilterPayload = (formData) => {
  const payload = [],
    planLevels = coreChoicePlanLevels;
  for (const key in formData) {
    if (formData[key] !== "") {
      if (planLevels.includes(key)) {
        payload.push({
          attribute_name: key,
          operator: "in",
          value: [formData[key]],
          prefix: "levels",
        });
      } else {
        if (key.includes("season")) {
          payload.push({
            attribute_name: "season_id",
            operator: "in",
            value: [formData[key]],
          });
        } else {
          payload.push({
            attribute_name: key,
            operator: "in",
            value: [formData[key]],
          });
        }
      }
    }
  }
  return payload;
};

export const isChannelMultiple = (planDetails) => {
  if (planDetails?.channel?.length > 1) {
    return true;
  }
  return false;
};

export const channelContainsTotal = (planDetails) => {
  if (
    planDetails?.channel.includes("CA") &&
    planDetails?.channel.includes("US")
  ) {
    return true;
  }
  return false;
};

/**
 * Call function to remove l0_name, l1_name, l2_name from l3_name
 * @param {string} rowData - selected data of the row
 * @param {string} type - type of the table column i.e. choice_name or l3_name
 */

export const getModifiedL3Name = (rowData, type) => {
  let name = rowData.l3_name;
  // Check if l0_name || l1_name || l2_name exists in l3_name.
  for (var i = 0; i < 3; i++) {
    if (name.includes(rowData[`l${i}_name`])) {
      name = name.replace(rowData[`l${i}_name`], "");
      name = name.replaceAll("-", "");
    }
  }
  if (
    type === "choice_name" ||
    type === "style_id" ||
    type === "global_style_number" ||
    type === "source_choice_id"
  ) {
    name = rowData?.attributes?.[type]
      ? rowData.attributes[type].replace(rowData.l3_name, name)
      : rowData[type].replace(rowData.l3_name, name);
  }
  return name;
};

export const externalFilterChannelSubChannel = (
  node,
  tableData,
  formData,
  planDetails
) => {
  if (node.data) {
    let selectedChannel = isEmpty(formData)
      ? tableData?.[0]?.channel
      : formData?.channel_list;
    let selectedSubChannel = isEmpty(formData)
      ? tableData?.[0]?.sub_channel
      : formData?.sub_channel_list;
    if (isChannelMultiple(planDetails) && isWholesalePlan(planDetails)) {
      return (
        selectedChannel === node?.data?.channel &&
        selectedSubChannel === node.data?.sub_channel
      );
    } else if (isWholesalePlan(planDetails)) {
      //if a plan is wholesale filtering based on selected sub_channel
      return selectedSubChannel === node.data?.sub_channel;
    } else if (isChannelMultiple(planDetails)) {
      return selectedChannel === node.data?.sub_channel;
    }
  }
  return true;
};

export const externalFilterSubChannel = (
  node,
  tableData,
  formData,
  planDetails
) => {
  if (node.data) {
    let selectedSubChannel = isEmpty(formData)
      ? tableData?.[0]?.sub_channel
      : formData?.sub_channel_list;
    if (isWholesalePlan(planDetails)) {
      //if a plan is wholesale filtering based on selected sub_channel
      return selectedSubChannel === node.data?.sub_channel;
    }
  }
  return true;
};

export const getL3OptPayload = (planDetails, formData, isCreateNew) => {
  let payload = {
    filters: [
      {
        attribute_name: "plan_code",
        value: [parseInt(planDetails.plan_code)],
        operator: "in",
      },
      {
        attribute_name: "channel",
        value: formData?.channel_list || [planDetails.channel?.[0]],
        prefix: "levels",
        operator: "in",
      },
      {
        attribute_name: "is_active",
        value: [isCreateNew ? "NO" : "YES"],
        operator: "in",
      },
      {
        attribute_name: "is_grouping",
        operator: "in",
        value: [formData?.channel_list?.length > 1 ? "true" : "false"],
      },
    ],
  };
  return payload;
};

export const setEdiableFalse = (cols) => {
  //making all columns non ediatble
  let columns = cols.map((col) => {
    if (col.column_name !== "view_trends") {
      col.editable = false;
      col.is_editable = false;
      if (col.column_name === "lock" || col.column_name === "delete") {
        col.is_hidden = true;
        col.hide = true;
      }
      if (col.sub_headers?.length) {
        col.sub_headers = setEdiableFalse(col.sub_headers);
      }
    }
    return col;
  });
  return columns;
};

export const getDefaultChannelValue = (channelOpt, planDetails) => {
  if (planDetails.channel.includes("US")) {
    return channelOpt.find((channel) => channel.label === "US");
  }
  return channelOpt?.[0];
};

export const externalFilterSubChannelDrop = (
  node,
  tableData,
  formData,
  planDetails
) => {
  if (node.data) {
    let selectedSubChannel = isEmpty(formData)
      ? tableData?.[0]?.sub_channel
      : formData?.sub_channel_list;
    if (isWholesalePlan(planDetails)) {
      //if a plan is wholesale filtering based on selected sub_channel
      return selectedSubChannel === node.data?.sub_channel;
    }
  }
  return true;
};
