import React, { useState } from "react";
import Table from "../../../Utils/reactTable";
import AgGridTable from "Utils/agGrid";
import { generateUniqueEditedPayload } from "pages/storeMapping/components/common-mapping-functions";
const ProductStyleTable = React.forwardRef((props, ref) => {
  const styleLevelOnSelection = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIds = [];
    selectedRows.forEach((row) => {
      row.mapped_products.forEach((productUnderStyle) => {
        selectedIds.push({
          product_code: productUnderStyle,
        });
      });
    });
    props.setSelectedRowsIDs(generateUniqueEditedPayload(selectedIds));
  };
  return (
    <div>
      {props.checked && (
        <AgGridTable
          columns={props.styleColumns}
          selectAllHeaderComponent={true}
          hideSelectAllRecords={true}
          sizeColumnsToFitFlag
          onGridChanged
          onRowSelected
          manualCallBack={(body, pageIndex, params) =>
            props.manualCallBack(body, pageIndex, params)
          }
          loadTableInstance={(gridInstance) => {
            ref.current = gridInstance;
          }}
          rowModelType="serverSide"
          serverSideStoreType="partial"
          cacheBlockSize={10}
          uniqueRowId={"style"}
          onSelectionChanged={styleLevelOnSelection}
        />
      )}
      {!props.checked && (
        <AgGridTable
          columns={props.productColumns}
          selectAllHeaderComponent={true}
          hideSelectAllRecords={true}
          sizeColumnsToFitFlag
          onGridChanged
          onRowSelected
          manualCallBack={(body, pageIndex, params) =>
            props.manualCallBack(body, pageIndex, params)
          }
          loadTableInstance={(gridInstance) => {
            ref.current = gridInstance;
          }}
          rowModelType="serverSide"
          serverSideStoreType="partial"
          cacheBlockSize={10}
          uniqueRowId={"product_code"}
          onSelectionChanged={props.onSelectionChanged}
        />
      )}
    </div>
  );
});

export default ProductStyleTable;
