import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Loader from "../../../Utils/Loader/loader";
import Button from "@mui/material/Button";
import Switch from "../../../Utils/switch";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { getAllFilters, getFiltersValues } from "../../../actions/filterAction";
import {
  getAllProductDC,
  getAllProductFC,
  mapProductToDC,
  mapProductToFC,
  getAllFC,
  getAllDC,
} from "../services-product-mapping/productMappingService";
import { setProductStatusData } from "../../../actions/productStoreStatusActions";
import Modify from "./modify-product-mapping";
import { getColumnsAg } from "../../../actions/tableColumnActions";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import CellRenderer from "../../../Utils/reactTable/components/cellRenderer";
import globalStyles from "Styles/globalStyles";
import { setActiveScreenName } from "modules/react-demo/services/common-assort-service";
import ConfirmBox from "Utils/reactTable/components/confirmPopup";
import { addSnack } from "actions/snackbarActions";
import AgGridTable from "Utils/agGrid";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { cloneDeep } from "lodash";
import { productMappingTableArticleFilter } from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import { getDCFCSetAllPayload } from "./common-functions";

import { isActionAllowedOnSubModule } from "modules/inventorysmart/pages-inventorysmart/inventorysmart-utility";
import { INVENTORY_SUBMODULES_NAMES } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: "0.6rem",
  },
  confirmBox: {
    "& .MuiDialog-paper": {
      minWidth: "430px",
      borderRadius: "10px 10px 6px 6px",
    },
  },
  contentBody: {
    minHeight: "10rem",
  },
  action: {
    background: "#f7f7f7",
    padding: "1rem",
  },
  title: {
    fontSize: "1rem",
    fontWeight: "600",
    paddingTop: "1.5rem",
  },
  text: {
    fontSize: "0.9rem",
    color: "#5a5a5ad9",
  },
  HeaderBreadCrumbs: {
    display: "flex",
    paddingBottom: "2rem",
  },
  footer: {
    textAlign: "center",
    padding: "1rem",
  },
}));

const ProductsFilter = React.forwardRef((props, ref) => {
  const globalClasses = globalStyles();
  const [data, setData] = useState([]);
  const [showloader, setloader] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [defaultDataSet, setDefaultDataSet] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [errorPopup, setErrorPopup] = useState(false);

  const [modifyProduct, showModifyProduct] = useState(false);
  const [setAllDataDC, updatesetAllDataDC] = useState([]);
  const [setAllDataFC, updatesetAllDataFC] = useState([]);
  const [fcData, setfcData] = useState([]);
  const [dcData, setdcData] = useState([]);
  const [fcDefaultData, setfcDefaultData] = useState([]);
  const [dcDefaultData, setdcDefaultData] = useState([]);
  const [fcCols, setfcCols] = useState([]);
  const [dcCols, setdcCols] = useState([]);
  const [totalDC, setDCTotal] = useState("");
  const [totalFC, setFCTotal] = useState("");
  const [selectedRows, updateSelectedRows] = useState({});

  const [toggleFcValue, setToggleFcValue] = useState(false);

  const [confirmBox, showConfirmBox] = useState(false);

  const dcfcTableRef = useRef(null);

  const classes = useStyles();

  const displaySnackMessages = (msg, type) => {
    props.addSnack({
      message: msg,
      options: {
        variant: type,
      },
    });
  };
  useEffect(() => {
    const getInitialData = async () => {
      try {
        let cols = await getColumnsAg("table_name=product_dc")();
        let fcColums = await getColumnsAg("table_name=product_fc")();

        let actionPermissionCheck = canTakeActionOnModules(
          INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_MAPPING,
          "edit"
        );
        cols.forEach(async (item) => {
          if (item.column_name === "dc_map") {
            item.isMulti = true;
            const { data: dcOptions } = await getAllDC()();
            item.options = dcOptions.data.map((opt) => {
              return {
                label: opt.name,
                id: `${opt.dc_code}`,
                value: `${opt.dc_code}`,
              };
            });
            item.disabled = !actionPermissionCheck;
            item.Cell = (cellProps, extraProps) => {
              return (
                <CellRenderer
                  cellData={cellProps}
                  column={item}
                  extraProps={extraProps}
                ></CellRenderer>
              );
            };
          }
        });
        fcColums.forEach(async (item) => {
          if (item.column_name === "fc_map") {
            item.isMulti = true;
            const { data: fcOptions } = await getAllFC()();
            item.options = fcOptions.data.map((opt) => {
              return {
                label: opt.name,
                id: `${opt.fc_code}`,
                value: `${opt.fc_code}`,
              };
            });
            item.Cell = (cellProps, extraProps) => {
              return (
                <CellRenderer
                  cellData={cellProps}
                  column={item}
                  extraProps={extraProps}
                ></CellRenderer>
              );
            };
          }
        });

        let response = await getAllFilters("product mapping")();
        const filterElements = response.data.data.map(async (key) => {
          let body = {
            attribute_name: key.column_name,
            filter_type: key.type,
            filters: [],
          };
          const options = await getFiltersValues("product", body)();
          key.filter_keyword = key.column_name;
          key.initialData = options.data.data.attribute.map((item) => {
            return {
              value: item.attribute,
              label: item.attribute,
            };
          });
          return key;
        });

        await Promise.all(filterElements);

        const defaultValues = JSON.parse(
          JSON.stringify([...response.data.data])
        );
        setColumns(cols);
        setdcCols(cols);
        setfcCols(fcColums);
        setDefaultDataSet([...defaultValues]);
        setData([...defaultValues]);
        setloader(false);
      } catch (error) {
        displaySnackMessages("Something went wrong", "error");
      }
    };

    getInitialData();

    props.setActiveScreenName("Product to DC/FC mapping");
  }, []);

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props?.inventorysmartModulesPermission,
      props?.module,
      subModuleName,
      action
    );
  };

  const updateMyData = (
    rowIndex,
    columnId,
    value,
    initialValue,
    column,
    wholeData,
    row,
    id
  ) => {
    if (columnId === "dc_map") {
      let alreadyexist = setAllDataDC
        ? setAllDataDC.filter(
            (item) => row.values.product_code !== item.product_code
          )
        : [];
      let unmapped = dcDefaultData
        .filter(
          (item) =>
            Number(item.product_code) === Number(row.values.product_code)
        )
        .map((item) => item.dc_map)[0];
      let newElement = {
        product_code: row.values.product_code,
        dc: {
          map: unmapped
            ? value.filter((item) => unmapped.indexOf(item) === -1)
            : value,
          unmap: unmapped
            ? unmapped.filter((item) => value.indexOf(item) === -1)
            : [],
        },
      };
      updatesetAllDataDC([...alreadyexist, newElement]);
      let newData = rowData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      });
      setRowData(newData);
      setdcData(newData);
    }
    if (columnId === "fc_map") {
      let alreadyexist = setAllDataFC
        ? setAllDataFC.filter(
            (item) => row.values.product_code !== item.product_code
          )
        : [];
      let unmapped = fcDefaultData
        .filter((item) => item.product_code === row.values.product_code)
        .map((item) => item.fc_map)[0];
      let newElement = {
        product_code: row.values.product_code,
        fc: {
          map: unmapped
            ? value.filter((item) => unmapped.indexOf(item) === -1)
            : value,
          unmap: unmapped
            ? unmapped.filter((item) => value.indexOf(item) === -1)
            : [],
        },
      };
      updatesetAllDataFC([...alreadyexist, newElement]);
      let newData = rowData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      });
      setRowData(newData);
      setfcData(newData);
    }
  };

  const manualCallBack = async (manualbody, pageIndex, params) => {
    setloader(true);
    let filter = ref.current;
    // to filter table based on selected sku's, show data selected on alerts on inventory screen
    if (pageIndex === 0 && props.isRedirectedFromDifferentPage) {
      let articleFilter = productMappingTableArticleFilter;
      articleFilter.values = [...props.selectedProductMappingArticles];
      filter = [...ref.current, articleFilter];
    }

    let body = {
      filters: filter,
      meta: {
        ...manualbody,
        limit: { limit: 10, page: pageIndex + 1 },
      },
      selection: {
        data: params?.api?.checkConfiguration,
        unique_columns: ["product_code"],
      },
    };

    let { data: DC } = await getAllProductDC(body, `?page=${pageIndex + 1}`)();
    // let { data: FC } = await getAllProductFC(body, `?page=${pageIndex + 1}`)();
    // FC.data = FC.data.map((item) => {
    //   if (item.fc_map) {
    //     item.fc_map = item.fc_map.map((item) => `${item.fc_code}`);
    //   }
    //   return item;
    // });
    DC.data = DC.data.map((item) => {
      if (item.dc_map) {
        item.dc_map = item.dc_map.map((item) => `${item.dc_code}`);
      }
      return item;
    });
    setDCTotal(DC.total);
    // setFCTotal(FC.total);

    setdcData(DC.data);
    setdcDefaultData(cloneDeep(DC.data));
    // setfcData(FC.data);
    // setfcDefaultData(FC.data);
    if (toggleFcValue) {
      // setRowData(FC.data);
      // setloader(false);
      // return {
      //   data: FC.data,
      //   totalCount: FC.total,
      // };
    } else {
      setRowData(DC.data);
      setloader(false);
      return {
        data: DC.data,
        totalCount: DC.total,
      };
    }
  };

  useEffect(() => {
    props.filtersSelection.length ? onClickFilter() : onReset();
  }, [props.filtersSelection]);

  const onFilter = async () => {
    setloader(true);
    try {
      let body = {
        filters: props.filtersSelection,
        meta: {
          range: [],
          sort: [],
          search: [],
          limit: { limit: 10, page: 1 },
        },
      };
      let { data: DC } = await getAllProductDC(body)();
      // let { data: FC } = await getAllProductFC(body)();
      // FC.data = FC.data.map((item) => {
      //   if (item.fc_map) {
      //     item.fc_map = item.fc_map.map((item) => `${item.fc_code}`);
      //   }
      //   return item;
      // });
      DC.data = DC.data.map((item) => {
        if (item.dc_map) {
          item.dc_map = item.dc_map.map((item) => `${item.dc_code}`);
        }
        return item;
      });

      setDCTotal(DC.total);
      // setFCTotal(FC.total);
      setdcData(DC.data);
      setdcDefaultData(DC.data);
      // setfcData(FC.data);
      // setfcDefaultData(FC.data);
      if (toggleFcValue) {
        // setRowData(FC.data);
      } else {
        setRowData(DC.data);
      }
      setloader(false);
    } catch (err) {
      setloader(false);
    }
  };

  const getUnEditedSetAllPayload = (setAllState, dataObj) => {
    return setAllState
      ? setAllState.filter(
          (item) => dataObj.object_ids.indexOf(item.product_code) === -1
        )
      : [];
  };

  const onClickFilter = () => {
    if (dcfcTableRef.current)
      dcfcTableRef.current.api.refreshServerSideStore({ purge: true });
  };

  const setAllChanges = async (fields_values, params) => {
    const setAllPayload = getDCFCSetAllPayload(
      "product",
      ref.current,
      params,
      fields_values
    );
    if (!toggleFcValue) {
      await mapProductToDC(setAllPayload, true)();
    }
    params.api.deselectAll();
    onClickFilter();
  };

  const saveRequest = () => {
    if (setAllDataDC.length || setAllDataFC.length) {
      setShowModal(true);
    }
  };
  const onConfirm = async () => {
    try {
      setloader(true);
      if (setAllDataFC.length) {
        let body = { elements: setAllDataFC };
        const response = await mapProductToFC(body)();
        if (response?.data?.message.includes("Failed")) {
          displaySnackMessages(response?.data?.message, "error");
          return;
        }
      }
      if (setAllDataDC.length) {
        let body = { elements: setAllDataDC };
        const response = await mapProductToDC(body)();
        if (response?.data?.message.includes("Failed")) {
          displaySnackMessages(response?.data?.message, "error");
          return;
        }
      }
      //Once we save the data, we reset to the original state
      updatesetAllDataDC([]);
      updatesetAllDataFC([]);
      displaySnackMessages("Product(s) mapped successfully");
      updateSelectedRows({});
      setShowModal(false);
      setloader(false);
    } catch (err) {
      setloader(false);
      displaySnackMessages(
        "Unable to save the request.Please try again",
        "error"
      );
    }
  };

  const onReset = () => {
    setData(defaultDataSet);
    setRowData([]);
    updatesetAllDataDC([]);
    updatesetAllDataFC([]);
    updateSelectedRows({});
    onClickFilter();
  };

  const onSelectionChanged = (event) => {
    setSelectedRowsIDs(event.api.getSelectedRows());
  };

  const onCellValueChanged = (params) => {
    let rowIndex = params.rowIndex % 10; //If rowIndex is greater than 10
    let value = params.value.map((val) => val.value || val);
    let row = {
      values: params.data,
    };
    const columnId = params.column.colId;
    if (columnId === "dc_map") {
      //First filter the objects apart from the current row/product code
      //In the alreadyexist array, we will have edited objects which are not part of current product_code
      let alreadyexist = setAllDataDC
        ? setAllDataDC.filter(
            (item) => row.values.product_code !== item.product_code
          )
        : [];
      //check from the default data if the current dc-product_code are unmapped or not
      let unmapped = dcDefaultData
        .filter((item) => item.product_code === row.values.product_code)
        .map((item) => item.dc_map)[0];

      //Prepare updated product_code with updated dc mappings
      //If we are newly mapping, it would go to map key list
      //If we are unmapping, it would go to unmap key list
      let newElement = {
        product_code: row.values.product_code,
        dc: {
          map: unmapped
            ? value.filter((item) => unmapped.indexOf(item) === -1)
            : value,
          unmap: unmapped
            ? unmapped.filter((item) => value.indexOf(item) === -1)
            : [],
        },
      };
      updatesetAllDataDC([...alreadyexist, newElement]);
      let newData = dcData.map((item, index) => {
        if (index === rowIndex) {
          return {
            ...item,
            [columnId]: value,
          };
        }
        return item;
      });
      setdcData(newData);
    }
    if (columnId === "fc_map") {
      //First filter the objects apart from the current row/product code
      //In the alreadyexist array, we will have edited objects which are not part of current product_code
      let alreadyexist = setAllDataFC
        ? setAllDataFC.filter(
            (item) => row.values.product_code !== item.product_code
          )
        : [];
      //check from the default data if the current dc-product_code are unmapped or not
      let unmapped = fcDefaultData
        .filter((item) => item.product_code === row.values.product_code)
        .map((item) => item.fc_map)[0];
      //Prepare updated product_code with updated dc mappings
      //If we are newly mapping, it would go to map key list
      //If we are unmapping, it would go to unmap key list
      let newElement = {
        product_code: row.values.product_code,
        fc: {
          map: unmapped
            ? value.filter((item) => unmapped.indexOf(item) === -1)
            : value,
          unmap: unmapped
            ? unmapped.filter((item) => value.indexOf(item) === -1)
            : [],
        },
      };
      updatesetAllDataFC([...alreadyexist, newElement]);
      let newData = fcData.map((fcRow, index) => {
        if (index === rowIndex) {
          return {
            ...fcRow,
            [columnId]: value,
          };
        }
        return fcRow;
      });
      setfcData(newData);
    }
  };

  const renderContent = () => {
    return (
      <Loader loader={showloader}>
        {/* {modifyProduct ? (
            <Modify
            defaultDataSet={defaultDataSet}
            cols={columns}
             selectedPeoducts={[]}
             goBack={()=>showModifyProduct(false)}
            ></Modify>
          ) : */}
        <div
          style={{ display: modifyProduct ? "none" : "" }}
          data-testid="filterContainer"
        >
          <Dialog
            open={showModal}
            className={globalClasses.dialogConfirmBox}
            onClose={() => setShowModal(false)}
            id={"routePrompt"}
          >
            <DialogContent className={globalClasses.minHeightBody}>
              <div className={globalClasses.dialogTitle}> Update Changes</div>
              <div className={globalClasses.dialogText}>
                Are you sure to update all changes ?
              </div>
            </DialogContent>
            <DialogActions className={globalClasses.dialogActionBox}>
              <Button
                id="routePromptYes"
                onClick={() => setShowModal(false)}
                color="primary"
                autoFocus
              >
                Close
              </Button>
              <Button
                id="routePromptYes"
                onClick={() => onConfirm()}
                color="primary"
                autoFocus
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
          <div data-testid="resultContainer">
            <div
              className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
            >
              <Typography variant="h6" gutterBottom>
                Filtered {dynamicLabelsBasedOnTenant("product", "core")}
              </Typography>
              <div className={globalClasses.centerAlign}>
                DC
                <Switch
                  className="switch"
                  onChange={(event) => {
                    if (event.target.checked) {
                      setToggleFcValue(true);
                      setColumns(fcCols);
                      setRowData(fcData);
                    } else {
                      setToggleFcValue(false);
                      setColumns(dcCols);
                      setRowData(dcData);
                    }
                  }}
                  disabled={true}
                ></Switch>
                FC
              </div>
              <div>
                {/* <Link to="/modify"> */}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={async () => {
                    if (selectedRowsIDs.length > 0) {
                      dcfcTableRef.current.trigerSetAll(true);
                    } else {
                      displaySnackMessages(
                        "Please select atleast one Product",
                        "error"
                      );
                      setErrorPopup(true);
                    }
                  }}
                  disabled={
                    !canTakeActionOnModules(
                      INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_MAPPING,
                      "edit"
                    )
                  }
                >
                  Set All
                </Button>
                {/* </Link> */}
              </div>
            </div>

            {columns.length > 0 && (
              <AgGridTable
                columns={columns}
                selectAllHeaderComponent={true}
                sizeColumnsToFitFlag
                onGridChanged
                onRowSelected
                manualCallBack={(body, pageIndex, params) =>
                  manualCallBack(body, pageIndex, params)
                }
                loadTableInstance={(gridInstance) => {
                  dcfcTableRef.current = gridInstance;
                }}
                rowModelType="serverSide"
                serverSideStoreType="partial"
                cacheBlockSize={10}
                uniqueRowId={"product_code"}
                onSelectionChanged={onSelectionChanged}
                setAllInterdependentFields={true}
                onSetAllApply={setAllChanges}
                onCellValueChanged={onCellValueChanged}
              />
            )}
          </div>
          {confirmBox && (
            <ConfirmBox
              onClose={() => showConfirmBox(false)}
              onConfirm={() => {
                if (dcData.length) {
                  updatesetAllDataFC([]);
                  updatesetAllDataDC([]);
                  ref.current = [];
                  onClickFilter();
                }
                showConfirmBox(false);
              }}
            />
          )}
          <div className={classes.footer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                saveRequest();
              }}
              disabled={
                !canTakeActionOnModules(
                  INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_MAPPING,
                  "edit"
                )
              }
            >
              Save
            </Button>
            <Button
              variant="outlined"
              id="producttoDcfcCancelBtn"
              className={classes.button}
              onClick={() => {
                if (
                  (dcData.length || fcData.length) &&
                  (setAllDataDC.length || setAllDataFC.length)
                ) {
                  showConfirmBox(true);
                } else {
                  displaySnackMessages(
                    "There are no changes to save",
                    "warning"
                  );
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
        {/* } */}

        {modifyProduct && (
          <div style={{ display: modifyProduct ? "" : "none" }}>
            <Modify
              defaultDataSet={defaultDataSet}
              cols={columns}
              selectedProducts={selectedRowsIDs[0]}
              goBack={() => showModifyProduct(false)}
            ></Modify>
          </div>
        )}
      </Loader>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
});

const mapStateToProps = (state) => {
  return {
    inventorysmartModulesPermission:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setProductStatusData: (data) => dispatch(setProductStatusData(data)),
    setActiveScreenName: (data) => dispatch(setActiveScreenName(data)),
    addSnack: (snackObj) => dispatch(addSnack(snackObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ProductsFilter);
