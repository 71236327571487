import { createSelector, createSlice } from "@reduxjs/toolkit";
import get from "lodash/get";
import axiosInstance from "../../../../Utils/axios/index";
import {
  PLANSMART_MASTER_PLAN_FILTER_CONFIG,
  PLANSMART_MASTER_PLAN_COLUMN_CONF,
  PLANSMART_MASTER_PLAN_TABLE_DATA,
} from "../../constants-plansmart/apiConstants";

export const masterPlanDashboardService = createSlice({
  name: "masterPlanDashboardService",
  initialState: {
    masterPlanFilterLoader: false,
    masterPlanTableLoader: false,
  },
  reducers: {
    setMasterPlanFilterLoader: (state, action) => {
      state.masterPlanFilterLoader = action.payload;
    },
    setMasterPlanTableLoader: (state, action) => {
      state.masterPlanTableLoader = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMasterPlanFilterLoader,
  setMasterPlanTableLoader,
} = masterPlanDashboardService.actions;

export const getMasterPlanFilterConfiguration = () => () => {
  return axiosInstance({
    url: PLANSMART_MASTER_PLAN_FILTER_CONFIG,
    method: "GET",
  });
};

export const fetchMasterPlanTableData = (postBody) => async () => {
  return axiosInstance({
    url: PLANSMART_MASTER_PLAN_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const fetchMasterPlanTableColumnsDef = (body) => async () =>
  axiosInstance({
    url: PLANSMART_MASTER_PLAN_COLUMN_CONF,
    method: "POST",
    data: body,
  });

export const masterPlanSelector = createSelector(
  (state) => state,
  (state) => state.plansmartReducer.masterPlanReducer
);

export const masterPlanTableLoaderSelector = createSelector(
  masterPlanSelector,
  (state) => get(state, "masterPlanTableLoader", false)
);

export default masterPlanDashboardService.reducer;
