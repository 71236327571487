import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  InputLabel,
  Checkbox,
  Grid,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  TextField,
  FormGroup,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactSelect from "../select";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import ChipsInput from "../chips-input";
import { DEFAULT_DATE_FORMAT } from "config/constants";
import RangePicker from "../../commonComponents/dateRangePicker";
import Select from "commonComponents/filters/Select/Select";
import "./index.scss";
import { StyledRadio } from "Utils/selection/selection";
import CreatableSelect from "commonComponents/filters/CreatableSelect/CreatableSelect";

const useStyles = makeStyles((theme) => ({
  TextField: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  textfieldAttribute: {
    "& .MuiInputBase-input": {
      padding: "10px",
      borderRight: "1px solid lightgrey",
    },
    "& .MuiFormHelperText-contained": {
      margin: "0px",
      color: "red",
    },
    // to render up and down arrows on the i/p field for the type number
    "& .MuiOutlinedInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "auto",
      },
    },
  },
  checkbox: {
    padding: "0px",
  },
  verticalLabel: {
    marginBottom: "0.3rem",
  },
  dropDownError: {
    color: "#F44336",
    marginLeft: "14px",
    marginRight: "14px",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "0.75em",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
  },
  errorBorder: {
    borderRadius: "4px",
    border: "1px solid #F44336 !important",
    "&>div": {
      borderColor: theme.palette.common.white,
    },
  },
  inputLabel: {
    display: "flex",
    lineHeight: "normal",
    minHeight: "1rem",

    "& span:nth-child(1)": {
      maxWidth: "90%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& span:nth-child(2)": {
      maxWidth: "10%",
    },
  },
  IconButton: {
    padding: "0.25rem",
  },
}));

const Form = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(props.defaultValues);
  useEffect(() => {
    if (!props.updateDefaultValue) setFormData(props.defaultValues);
  }, [props.defaultValues]);

  const handleChange = (e, type, id, field) => {
    let obj = { ...formData };
    let initialValue = obj[id];
    switch (type) {
      case "BooleanField":
        obj[e.target.name] = e.target.checked;
        break;
      case "DateTimeField":
        obj[id] = e;
        break;
      case "dropdown":
        if (field.isMulti) {
          let newValue = e.map((opt) => opt.value);
          obj[id] = newValue;
          obj[`${id}_options`] = e;
        } else {
          obj[id] = e[0]?.value;
        }
        break;
      case "autocompleteDropdown":
        if (field.is_multiple_selection) {
          let newValue = e?.map((opt) => opt.value);
          obj[id] = newValue;
          obj[`${id}_options`] = e;
        } else {
          obj[id] = e?.[0]?.value;
        }
        break;
      case "list":
        if (field.isMulti) {
          let newValue = e.map((opt) => opt.value);
          obj[id] = newValue;
          obj[`${id}_options`] = e;
        } else {
          obj[id] = e.value;
        }
        break;
      case "ChipsInput":
        obj[id] = e ? e.value : "";
        break;
      case "radioGroup":
        obj[id] = e.target.value;
        break;
      case "checkBoxGroup":
        let newValue = e.map((opt) => opt.value);
        obj[id] = newValue;
        break;
      case "rangePicker":
        obj[id] = e;
        break;
      case "deleteRow":
        obj[id] = true;
        break;
      default:
        obj[id] = e.target.value;
        break;
    }
    setFormData(obj);
    props.handleChange(obj, id, field, e, initialValue);
  };

  const inputAttribute = (type) => {
    switch (type) {
      case "percentage":
        return "%";

      case "dollar":
        return "$";
      default:
        return " ";
    }
  };
  const genricValidation = (item, value) => {
    if (item.error) {
      return item.helperText;
    }
    if (item.value_type === "percentage") {
      if (parseInt(value) >= 0 && parseInt(value) > 100) {
        setFormData({ ...formData, [item.accessor]: 100 });
        return " please Enter value less than 100 ";
      }
      if (parseInt(value) < 0) {
        setFormData({ ...formData, [item.accessor]: "0" });
        return " please Enter value greater than 0 ";
      }
    }
    return "";
  };
  const onBlur = (_e, type, id) => {
    props.onBlur && props.onBlur();
  };

  const setSelectValue = (item) => {
    if (formData[item.accessor]) {
      return item.isMulti
        ? item.options
            .filter(
              (option)=>{
                if(Array.isArray(formData[item.accessor])){
                  const setData = new Set(formData[item.accessor]);
                  return setData.has(option.value)
                }
                return formData[item.accessor] === option.value
              }
            )
            .map((opt) => {
              return {
                label: opt.label || opt.name,
                value: opt.value,
              };
            })
        : item.options
            .filter((option) => {
              /** For some options we get id and in some cases we get value */
              return (
                option.id === formData[item.accessor] ||
                option.value === formData[item.accessor]
              );
            })
            .map((opt) => {
              return {
                label: opt.label || opt.name,
                value: opt.id,
              };
            })[0];
    }
    return null;
  };

  const renderForm = (item, index) => {
    const listOptions = item.options
      ? item.options.map((option) => {
          return {
            label: option.label || option.name,
            value: option.id || option.value,
          };
        })
      : [];
    switch (item.field_type) {
      case "TextField":
        return (
          <TextField
            key={index}
            type={item?.value_type || "text"}
            disabled={item.isDisabled}
            variant="outlined"
            size="small"
            multiline={item.multiline}
            rows={item.maxRows}
            className={classes.TextField}
            id={`new${item.accessor}`}
            onChange={(event) =>
              handleChange(event, item.field_type, item.accessor, item)
            }
            onBlur={(event) => props.handleOnBlur && props.handleOnBlur(event)}
            value={formData[item.accessor] || ""}
            name={item.accessor}
            helperText={item.error ? item.helperText : ""}
            error={item.error}
          />
        );
      case "dropdown":
        const newvalue = setSelectValue(item);
        const selectedOption = !item.isMulti ? [newvalue] : newvalue;

        return (
          <Select
            menuPosition={"fixed"}
            isDisabled={item.isDisabled ? true : false}
            name={item.accessor}
            isSearchable={item.isSearchable ? true : false}
            menuShouldBlockScroll={true}
            pagination={item.pagination}
            fetchOptions={item.fetchOptions}
            is_multiple_selection={item.isMulti ? true : false}
            dependency={[]}
            initialData={listOptions}
            selectedOptions={formData[item.accessor] ? selectedOption : []}
            data-testid={`select${item.name}`}
            updateDependency={(key, option) =>
              handleChange(option, item.field_type, item.accessor, item)
            }
            label={item.key ? item.key : item.label}
            isClearable={item.isClearable ? true : false}
          />
        );
      case "autocompleteDropdown":
        const selectedValues = item.is_multiple_selection
          ? formData[item.accessor].map((opt) => {
              return {
                label: opt.label || opt.name,
                value: opt.value,
              };
            }) || []
          : [
              {
                label: formData[item.accessor],
                value: formData[item.accessor],
              },
            ];
        return (
          <CreatableSelect
            name={item.accessor}
            key={`selectable__${index}`}
            isDisabled={item.isDisabled ? true : false}
            isSearchable={item.isSearchable ? true : false}
            is_multiple_selection={item.is_multiple_selection ? true : false}
            initialData={listOptions}
            selectedOptions={formData[item.accessor] ? selectedValues : []}
            updateDependency={(option) =>
              handleChange(option, item.field_type, item.accessor, item)
            }
            label={item.key ? item.key : item.label}
            isClearable={item.is_clearable ? true : false}
          />
        );
      case "list":
        const value = setSelectValue(item);
        return (
          <div>
            <ReactSelect
              id={`new${item.accessor}`}
              menuPosition={"fixed"}
              isDisabled={item.isDisabled ? true : false}
              name={item.accessor}
              isSearchable={item.isSearchable ? true : false}
              isClearable={item.isClearable ? true : false}
              className={item.error ? classes.errorBorder : ""}
              onInputChange={
                item.onInputChange
                  ? (searchkey, param) => {
                      item.onInputChange(
                        searchkey,
                        param,
                        item,
                        formData[`${item.accessor}_options`]
                      );
                    }
                  : null
              }
              menuShouldBlockScroll={false}
              pagination={item.pagination}
              fetchOptions={item.fetchOptions}
              isMulti={item.isMulti ? true : false}
              options={listOptions}
              value={formData[item.accessor] ? value : ""}
              data-testid={`select${item.name}`}
              onChange={(option) =>
                handleChange(option, item.field_type, item.accessor, item)
              }
            />
            <span className={item.error ? classes.dropDownError : ""}>
              {item.error ? item.helperText : ""}
            </span>
          </div>
        );
      case "IntegerField":
        return (
          <TextField
            key={index}
            type="number"
            variant="outlined"
            size="small"
            className={
              item.value_type ? classes.textfieldAttribute : classes.TextField
            }
            id={`new${item.accessor}`}
            onChange={(event) =>
              handleChange(event, item.field_type, item.accessor, item)
            }
            value={formData[item.accessor] || ""}
            name={item.accessor}
            helperText={genricValidation(item, formData[item.accessor])}
            error={item.error}
            disabled={item.isDisabled}
            inputProps={{ step: item.step_value ? item.step_value : 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {inputAttribute(item.value_type)}
                </InputAdornment>
              ),
            }}
          />
        );
      case "BooleanField":
        return (
          <Checkbox
            id={`${item.accessor}-checkBox`}
            disabled={item.isDisabled}
            name={item.accessor}
            color="primary"
            className={classes.checkbox}
            checked={formData[item.accessor] || false}
            onChange={(e) => {
              handleChange(e, item.field_type, item.accessor);
            }}
          />
        );
      case "ChipsInput":
        return (
          <ChipsInput
            id={`${item.accessor}-formChip`}
            {...item}
            tagifyRef={props.tagifyRef}
            formData={formData}
            value={
              props.defaultValues[item.accessor]
                ? props.defaultValues[item.accessor]
                : ""
            }
            onBlur={() => {
              onBlur("", item.field_type, item.accessor);
            }}
          />
        );
      case "DateTimeField":
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disableToolbar
              disabled={item.isDisabled}
              disablePast={item.disablePast ? true : false}
              disableFuture={item.disableFuture}
              minDateMessage={null}
              variant="inline"
              clearable={true}
              inputVariant="outlined"
              inputFormat={props.tenantDateFormat}
              id="date-picker"
              value={formData[item.accessor] || null}
              onChange={(e) => {
                handleChange(e, item.field_type, item.accessor);
              }}
              helperText={item.error ? item.helperText : ""}
              renderInput={(props) => (
                <TextField
                  helperText={item.error ? item.helperText : ""}
                  {...props}
                  variant="outlined"
                  size="small"
                  className={classes.TextField}
                />
              )}
              error={item.error}
              placeholder={item.label}
              keyboardIcon={<i class="fa fa-calendar" aria-hidden="true"></i>}
              InputProps={{
                endAdornment:
                  // to reset the selected value
                  !item.isDisabled && (
                    <IconButton
                      onClick={() =>
                        handleChange("", item.field_type, item.accessor)
                      }
                      className={classes.IconButton}
                    >
                      <ClearIcon id="closeIcon" />
                    </IconButton>
                  ),
              }}
              InputAdornmentProps={{
                position: "start",
              }}
            />
          </LocalizationProvider>
        );
      case "radioGroup":
        return (
          <FormControl
            id={`${item.accessor}-formRadioGrp`}
            component="fieldset"
          >
            <RadioGroup
              row
              value={formData[item.accessor] || ""}
              onChange={(event) => {
                handleChange(event, item.field_type, item.accessor);
              }}
              aria-label="form-field-radio-group"
              name="row-radio-buttons-group"
            >
              {item.options.map((option) => {
                return (
                  <FormControlLabel
                    disabled={option.isDisabled}
                    value={option.value}
                    control={<StyledRadio color="primary" />}
                    label={option.label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
      case "checkBoxGroup":
        return (
          <FormControl
            id={`${item.accessor}-formRadioGrp`}
            component="fieldset"
          >
            <FormGroup
              value={formData[item.accessor] || ""}
              onChange={(event) => {
                handleChange(event, item.field_type, item.accessor);
              }}
              aria-label="form-field-radio-group"
              name="row-radio-buttons-group"
            >
              {item.options.map((option) => {
                return (
                  <FormControlLabel
                    disabled={option.isDisabled}
                    value={option.value}
                    control={<Checkbox />}
                    color="primary"
                    label={option.label}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        );
      case "rangePicker":
        return (
          <RangePicker
            disabled={item.isDisabled}
            disableType={item.disableType || "disablePast"}
            startDateId="start_date_id"
            endDateId="end_date_id"
            startDate={
              formData[item.accessor] ? formData[item.accessor][0] : null
            }
            endDate={
              formData[item.accessor] ? formData[item.accessor][1] : null
            }
            noPortal={props.noPortal !== undefined ? props.noPortal : true}
            onDatesChange={(start, end) => {
              handleChange([start, end], item.field_type, item.accessor);
            }}
            enabledStartDays={item.enabledStartDays}
            enabledEndDays={item.enabledEndDays}
            dateFormat={DEFAULT_DATE_FORMAT}
            onClose={
              props.onClose &&
              ((event) => {
                props.onClose(event);
              })
            }
          />
        );
      case "deleteRow":
        return (
          <IconButton
            onClick={() => handleChange(true, item.field_type, item.accessor)}
            size="small"
            disabled={item.disabled}
            color="primary"
          >
            <DeleteIcon id="closeIcon" />
          </IconButton>
        );
      case "readOnly":
        return (
          <span className={classes.textReadOnly}>
            {formData[item.accessor] || ""}
          </span>
        );
      default:
        return null;
    }
  };

  const render = () => {
    let num = props.maxFieldsInRow ? props.maxFieldsInRow : 1;
    if (props.layout === "vertical") {
      let count = Math.trunc(12 / num);
      return (
        <Grid container alignItems="center" spacing={4}>
          {props.fields.map((field, index) => {
            return (
              <Grid item xs={field.autoSize ? "auto" : count}>
                {!field.hideLabel && (
                  <InputLabel
                    className={`${classes.inputLabel} ${classes.verticalLabel}`}
                    required={field.required}
                  >
                    <span title={field.label}>{field.label}</span>
                  </InputLabel>
                )}
                {renderForm(field, index)}
              </Grid>
            );
          })}
        </Grid>
      );
    } else {
      let count = Math.trunc(12 / num);
      return (
        <div id="formbody">
          <Grid container alignItems="center" spacing={4}>
            {props?.fields?.map((field, index) => {
              return (
                field && (
                  <Grid container alignItems="center" item xs={count}>
                    {!field.hideLabel && (
                      <Grid
                        item
                        xs={props.labelWidthSpan ? props.labelWidthSpan : 4}
                      >
                        <InputLabel
                          className={`ticket-text ${classes.inputLabel}`}
                          required={field.required}
                          title={field.label}
                        >
                          <span>{field.label}</span>
                        </InputLabel>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={
                        props.fieldTypeWidthSpan ? props.fieldTypeWidthSpan : 8
                      }
                      className="dropdown-label-padding"
                    >
                      {renderForm(field, index)}
                      <div style={{ width: "100%" }}>
                        {field.children ? field.children : ""}
                      </div>
                    </Grid>
                  </Grid>
                )
              );
            })}
          </Grid>
        </div>
      );
    }
  };

  return <>{render()}</>;
};

const mapStateToProps = (state) => {
  return {
    tenantDateFormat:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer
        .tenantDateFormat,
  };
};

export default connect(mapStateToProps, null)(Form);
