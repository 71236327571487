import {
  getAllFilters,
  getFiltersValues,
  getCrossDimensionFiltersData,
} from "actions/filterAction";
import { getColumnsAg } from "actions/tableColumnActions";
import { fetchVendorData } from "actions/vendorActions";
import { capitalize, kebabCase } from "lodash";
import { API_META_BODY } from "config/constants";
import { fetchAllProducteGroups } from "pages/product-grouping/product-grouping-service";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

// fetch filter dashboard data and filter field dropdown values
export const fetchFilterFieldValues = async (
  // to destructure later
  screenName,
  initialDependency = [],
  is_urm_filter,
  screen_name,
  application_code
) => {
  let response = await getAllFilters(screenName)();
  const filterElements = response.data.data.map(async (key) => {
    let body = {
      attribute_name: key.column_name,
      filter_type: key.type,
      filters: initialDependency,
      dimension: key.dimension,
    };
    if (is_urm_filter) {
      body.is_urm_filter = is_urm_filter;
      body.screen_name = screen_name;
      body.application_code = application_code;
    }
    if (key.column_name === "group" && key.dimension === "product") {
      let body = {
        meta: {
          ...API_META_BODY,
          limit: { limit: 1000, page: 1 },
        },
        filters: [],
      };
      const options = await fetchAllProducteGroups(body);
      key.initialData = generateFilterValues(options.data.data);
    } else {
      const options = await getFiltersValues(key.dimension, body)();
      key.initialData = options.data.data.attribute.map((item) => {
        return mapDataToLabel(item);
      });
    }
    key.filter_keyword = key.column_name;
    return key;
  });
  await Promise.allSettled(filterElements);
  return response.data.data;
};

// fetch table columns
export const fetchTableColumnData = async (tableName, optionsList = {}) => {
  let colData = await getColumnsAg(`${"table_name="}${tableName}`)();
  if (optionsList) {
    const keys = Object.keys(optionsList);
    colData = colData.map((item) => {
      if (keys.includes(item.column_name)) {
        item.options = optionsList[item.column_name];
        item.disabled = true;
      }
      return item;
    });
  }
  return colData;
};

// fetch vendor status table data
export const fetchTableData = async (body, url) => {
  let res = await fetchVendorData(body, url)();
  res.data.data = res.data.data.map((item) => {
    item.vendor_id = `${item.vendor_code}${item.product_code}`;
    return item;
  });
  return res;
};

export const updateDependencyData = (item) => {
  let body = {
    filter_id: item.filter_id,
    attribute_name: item.filter_id,
    operator: "in",
    dimension: item.dimension,
    values: Array.isArray(item.values)
      ? item.values.map((opt) => {
          if (!opt.value) {
            return opt;
          }
          return opt.value;
        })
      : item.values,
    filter_type: item.filter_type,
  };

  return body;
};

export const mapDataToLabel = (item) => {
  return {
    value: item.attribute,
    label: item.attribute,
    id: item.attribute,
  };
};

export const getSelectionDependency = (dependency) => {
  let selectionDependency =
    dependency.length > 0
      ? dependency.map((item) => {
          return updateDependencyData(item);
        })
      : [];

  return selectionDependency;
};

// update filter field dropdowns based on cascading
export const updateFilterData = async (
  dependency,
  filterData,
  screenDimension,
  isCrossDimensionFilter = false,
  filterDependency,
  filterDimension,
  is_urm_filter,
  screen_name,
  application_code
) => {
  dependency = getAllDimensionDependency(
    filterDimension,
    dependency,
    filterDependency
  );

  let selectionDependency = getSelectionDependency(dependency);
  try {
    let initialFilterElements = [...filterData];
    const filterElements = initialFilterElements.map(async (key) => {
      if (key.type === "cascaded") {
        let body = {
          attribute_name: key.column_name,
          filter_type: key.type,
          filters: selectionDependency,
          dimension: key.dimension,
        };

        if (is_urm_filter) {
          body.is_urm_filter = is_urm_filter;
          body.screen_name = screen_name;
          body.application_code = application_code;
        }
        if (isCrossDimensionFilter) {
          body = { ...body, dimension: key.dimension }; // specific key for cross-dimension filter
        }

        const options = isCrossDimensionFilter
          ? await getCrossDimensionFiltersData(body)()
          : await getFiltersValues(screenDimension, body)();
        key.initialData = options.data.data.attribute.map((item) => {
          return mapDataToLabel(item);
        });
      }
      return key;
    });
    await Promise.allSettled(filterElements);
    selectionDependency = isCrossDimensionFilter
      ? getAllDimensionDependency(
          filterDimension,
          selectionDependency,
          filterDependency
        )
      : selectionDependency;

    return { initialFilterElements, selectionDependency, loader: false };
  } catch (error) {
    return { initialFilterElements: [], selectionDependency, loader: false };
  }
};

/**
 * Returns combined filter dependency using current dependency list
 * and new dependency set from filter group
 * @param {String} dimension - dimension of new dependency
 * @param {Array} newDependencyList - new dependency
 * @param {Array} currentDependencyList - current dependency list
 * @returns Array - combined filter dependency
 */

export const getAllDimensionDependency = (
  dimension,
  newDependencyList,
  currentDependencyList = []
) => {
  // create a set object of filter ids in new dependency
  const newFilterDependencySet = new Set(
    newDependencyList.map((item) => item.filter_id)
  );

  const updatedCurrentDependencyList = currentDependencyList.filter(
    (item) =>
      /**
       * Remove filter data from current depedency
       * if, filter data is present in new dependency set
       * or filter data is of same dimension as new dependency dimension
       */

      !(
        newFilterDependencySet.has(item.attribute_name) ||
        item.dimension === dimension
      )
  );

  // combine updated dependency and new depedency
  return [...newDependencyList, ...updatedCurrentDependencyList];
};

/**
 * @func
 * @desc Return structure object to utilize as filter options
 * @param {Array} data Array of Objects
 * @returns {Array}
 */
export const generateFilterValues = (data) => {
  const filterValues = data.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });
  return filterValues;
};

// formats filter configurations sent from page component
export const formattedFilterConfiguration = (
  filterConfigId,
  filterConfigData,
  screenName
) => {
  const filterConfig = filterConfigData.map((item, index) => {
    const updatedItem = {
      ...item,
      originalFilterDashboardData: item.filterDashboardData,
      filterDashboardData: item.filterDashboardData.filter((filter) =>
        item.expectedFilterDimensions.includes(filter.dimension)
      ),
      filterDependencyData: [],
      filterDashboardClassification: item.expectedFilterDimensions.map(
        (dimension) => {
          return {
            dimension,
            screenName: `${kebabCase(
              screenName
            )}${"-"}${dimension}${"-"}${index}`,
            filterLabel: capitalize(
              dynamicLabelsBasedOnTenant(dimension, "core")
            ),
            customFilterComponent:
              item.customFilterComponent?.dimension === dimension
                ? item.customFilterComponent?.component
                : null,
            onReset: item.onReset,
          };
        }
      ),
    };
    return updatedItem;
  });

  let obj = {};
  obj[filterConfigId] = {
    filterConfig,
    appliedFilterData: {
      filterHeader: "",
      dependencyData: [],
    },
  };

  return obj;
};
