import { BASE_API } from "./api";

export const config = {
  baseUrl: BASE_API,
};

export const calendarConfig = {
  fstDayOfWk: 0, // Monday
  fstMoOfYr: 0, // Jan
};

export const apmRumConfig = {
  configHost: "https://assort-apm.impactsmartsuite.com/apm/",
  configName: "DEV-CORE-FE",
};
