import { combineReducers } from "redux";
import inventorySmartDashboardService from "./Decision-Dashboard/decision-dashboard-services";
import inventorySmartCreateAllocationService from "./Create-Allocation/create-allocation-services";
import inventorySmartStoreInventoryService from "./Decision-Dashboard/store-inventory-services";
import inventorySmartStoreInventoryAlertsService from "./StoreInventoryAlerts/store-inventory-alerts-service";
import inventorySmartAlertsActionService from "./StoreInventoryAlerts/alerts-actions-service";
import inventorySmartStoreForecastAlerts from "./StoreInventoryAlerts/store-forecast-alerts-service";
import productProfileDashboardReducer from "./Product-Profile/product-profile-dashboard-service";
import createProductProfileReducer from "./Product-Profile/create-product-profile-service";
import productRuleService from "./Product-Profile/product-rule-services";
import inventorySmartConstraints from "./Constraints/constraints-services";
import inventorySmartKPIService from "./KPI-Matrix/kpi-services";
import inventorySmartFinalizeStoreViewService from "./Finalize/store-view-services";
import inventorySmartFinalizeProductViewService from "./Finalize/product-view-services";
import inventorySmartOrderBatchingService from "./Order-Batching/order-batching-services";
import inventorySmartLostSalesService from "./Allocation-Reports/lost-sales-service";
import inventorySmartPastAllocationService from "./View-Past-Allocation/view-past-allocation";
import inventorySmartExcessInventoryService from "./Allocation-Reports/excess-inventory-service";
import inventorySmartCommonService from "./common/inventory-smart-common-services";
import inventorySmartDailyAllocationService from "./Allocation-Reports/daily-allocation-service";
import inventoryStoreStockDrillDownService from "./Allocation-Reports/store-stock-drill-down-service";
import inventoryAllocationDeepDiveService from "./Allocation-Reports/allocation-deep-dive-service";
import inventoryForecastedUnitsService from "./Allocation-Reports/forecasted-units-service";
import inventoryAdditionalReportsService from "./Allocation-Reports/additional-reports-service";
import inventoryFutureReceiptsReportsService from "./Allocation-Reports/future-receipts-service";
import inventoryModelStockDeepDiveService from "./Allocation-Reports/model-stock-deep-dive-service";
import inventoryForecastReportsService from "./Allocation-Reports/forecast-reports-service";
import inventoryInStockService from "./Allocation-Reports/in-stock-service";
import inventorySmartExcessInventoryReportService from "./Allocation-Reports/excess-inventory-fiscal-week-list-service";

export const inventorysmartReducer = combineReducers({
  inventorySmartDashboardService,
  inventorySmartKPIService,
  inventorySmartCreateAllocationService,
  inventorySmartStoreInventoryService,
  inventorySmartStoreInventoryAlertsService,
  productProfileDashboardReducer,
  createProductProfileReducer,
  productRuleService,
  inventorySmartConstraints,
  inventorySmartFinalizeStoreViewService,
  inventorySmartFinalizeProductViewService,
  inventorySmartOrderBatchingService,
  inventorySmartLostSalesService,
  inventorySmartPastAllocationService,
  inventorySmartExcessInventoryService,
  inventorySmartCommonService,
  inventorySmartStoreForecastAlerts,
  inventorySmartDailyAllocationService,
  inventoryStoreStockDrillDownService,
  inventoryAllocationDeepDiveService,
  inventorySmartAlertsActionService,
  inventoryInStockService,
  inventoryForecastedUnitsService,
  inventoryAdditionalReportsService,
  inventoryFutureReceiptsReportsService,
  inventoryModelStockDeepDiveService,
  inventoryForecastReportsService,
  inventorySmartExcessInventoryReportService,
});
