import {
  PLAN_SMART_IN_SEASON_DASHBOARD,
  PLAN_SMART_PRE_SEASON_DASHBOARD,
} from "./routesConstants";

export const common = {
  __Multiple_Value: "(s)",
  __Assortment: "PlanSmart",
  __ConfirmBtnText: "Yes",
  __RejectBtnText: "No",
  __MonthList: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  weekList: ["week1", "week2", "week3", "week4", "week5"],
  __compare_yr_constants: ["LY", "LLY", "LLLY"],
  __seed_with_constants: ["IAF", "LY", "LLY"],
  compare: "Compare",
  performance_analysis: "Performance Analysis",
  match_with: "Match WP With:",
};

export const plansmart_dashboard = {
  __overwrite_plan: "Overwrite the existing plan !",
  __overwrite_msg:
    "Plan already exists with same hierarchies and dates, Do you wish to overwrite the existing one !",
};

export const plan_stage = {
  "Scenario Plan": 2,
  "Working Plan": 0,
};

// Hierarchy Levels
export const CreatePlan = {
  __plan_levels: ["l0_name", "l1_name", "l2_name", "l3_name"],
  __store_levels: ["channel", "region", "store_group"],
};

export const reportViewByOptions = [
  {
    label: "Timeline",
    value: "timeline",
  },
  {
    label: "KPI",
    value: "kpi",
  },
];

export const reportRadioList = [
  {
    label: "Global Template",
    value: "global",
  },
  {
    label: "Personal Template",
    value: "personal",
  },
];

// Planning screen Save As details

export const DRAFT = "draft";
export const REGULAR_PLAN = "regular_plan";
export const SCENARIO_PLAN = "scenario_plan";
export const FINAL_PLAN = "final_plan";
export const ACTIVE_FORECAST = "active_forecast";
export const SCENARIO_FORECAST = "scenario_forecast";
export const FINAL_FORECAST = "final_forecast";

export const save_as_dropdown_list = [
  {
    label: "Save Plan",
    value: DRAFT,
    status: [0, 2, 3],
  },
];

export const plansmartLabelsForButtons = (isPreSeason) => {
  return {
    create_new_plan: !isPreSeason ? "Create New Plan" : "Review In-season",
    regular_plan: !isPreSeason ? "Regular Plan" : "Active Forecast",
    scenario_plan: !isPreSeason ? "Scenario Plan" : "Scenario Forecast",
  };
};

export const statusValueBasedOnTabSelection = {
  [PLAN_SMART_PRE_SEASON_DASHBOARD]: {
    [0]: ["0", "1"], // Working Plan
    [1]: ["2"], // Scenario Plan
  },
  [PLAN_SMART_IN_SEASON_DASHBOARD]: {
    [0]: ["1", "5"], // Active Forecast
    [1]: ["3"], // Scenario Forecast
  },
};

export const seasonTypesObj = {
  [PLAN_SMART_PRE_SEASON_DASHBOARD]: "pre-season",
  [PLAN_SMART_IN_SEASON_DASHBOARD]: "in-season",
};

export const saveAsWarningMsg = {
  [FINAL_PLAN]:
    "Existing final plan will be overwritten, do you want to proceed?",
  [REGULAR_PLAN]:
    "Existing working plan will be overwritten, do you want to proceed?",
  [FINAL_FORECAST]:
    "Existing final forecast will be overwritten, do you want to proceed?",
};

export const copyOptions = {
  [PLAN_SMART_PRE_SEASON_DASHBOARD]: [
    {
      label: "Working Plan",
      value: REGULAR_PLAN,
    },
    {
      label: "Scenario Plan",
      value: SCENARIO_PLAN,
    },
  ],
  [PLAN_SMART_IN_SEASON_DASHBOARD]: [
    {
      label: "Active Forecast",
      value: FINAL_FORECAST,
    },
    {
      label: "Scenario Forecast",
      value: SCENARIO_FORECAST,
    },
  ],
};

export const statusCodeBasedOnPlanType = {
  [REGULAR_PLAN]: 0,
  [FINAL_PLAN]: 1,
  [SCENARIO_PLAN]: 2,
  [SCENARIO_FORECAST]: 3,
  [ACTIVE_FORECAST]: 4,
  [FINAL_FORECAST]: 5,
};

export const disablePlanningScreen = [1, 5];

export const PRE_SEASON_STATUS_CODES = [0, 1, 2];
export const IN_SEASON_STATUS_CODES = [3, 4, 5];

export const getPlanningScreenBreadCrumbsUrlAndStage = (statusCode) => {
  if (PRE_SEASON_STATUS_CODES.indexOf(statusCode) > -1) {
    return [PLAN_SMART_PRE_SEASON_DASHBOARD, "Pre-Season - Planning Screen"];
  } else if (IN_SEASON_STATUS_CODES.indexOf(statusCode) > -1) {
    return [PLAN_SMART_IN_SEASON_DASHBOARD, "In-Season - Planning Screen"];
  }
  return [];
};

export const getStatusCodeForImportPlan = (status) => {
  const strStatus = "" + status;
  const statusList = [];
  const preSeasonStatus = ["0", "1", "2"];
  const inSeasonStatus = ["1", "3", "4", "5"];
  if (preSeasonStatus.indexOf(strStatus) > -1) {
    statusList.push(...preSeasonStatus);
  }
  if (inSeasonStatus.indexOf(strStatus) > -1) {
    statusList.push(...inSeasonStatus);
  }
  return statusList;
};

export const MAX_ROW_ALLOWED_FOR_COMPARE = 7;
export const MAX_ROW_ALLOWED_FOR_COMPARE_ERROR_MSG =
  "Max 7 rows can be viewed at a time!  You can hide the rows from show/hide metrics to view more rows !";
export const SHOW_METRIC_MAX_ROW_ALLOWED_ERROR_MSG = `Max ${MAX_ROW_ALLOWED_FOR_COMPARE} are allowed, Please hide metrics or remove version`;

export const pivotViewOptions = [
  {
    label: "KPI View",
    value: "kpi",
  },
  {
    label: "Timeline View",
    value: "timeline",
  },
  {
    label: "Product Hierarchy view",
    value: "product_hierarchy_view",
  },
  {
    label: "Version View",
    value: "version_view",
  },
];

export const intArr = [
  "qty",
  "bop_units",
  "eop_units",
  "avg_inv",
  "rcpt_units",
  "oo_units",
  "it_units",
  "comp_units",
  "non_comp_units",
  "turn_cost",
  "turn_dollars",
  "turn_units",
];
export const percentArr = ["margin_per", "st_perc", "disc", "markup_per"];
export const dollarArr = [
  "sales",
  "aur",
  "auc",
  "margin",
  "msrp",
  "cost",
  "bop_dollars",
  "bop_auc",
  "bop_aur",
  "bop_cost",
  "eop_dollars",
  "eop_auc",
  "eop_aur",
  "eop_cost",
  "rcpt_dollars",
  "rcpt_auc",
  "rcpt_aur",
  "rcpt_cost",
  "oo_dollars",
  "oo_cost",
  "it_dollars",
  "it_cost",
  "comp_dollars",
  "comp_aur",
  "non_comp_dollars",
  "non_comp_aur",
  "gmroi",
];
export const referencePercentArr = ["variance", "variance_fcst"];
