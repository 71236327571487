import { GET_FILTER_OPTIONS, GET_FISCAL_CALENDAR } from "config/api";
import {
  ADA_DASHBOARD_CHART_DATA,
  ADA_DASHBOARD_COMPARE_FISCAL_WEEKS,
  ADA_DASHBOARD_FISCAL_WEEKS,
  ADA_DASHBOARD_GET_FISCAL_CALENDAR,
  ADA_DASHBOARD_GET_FISCAL_WEEKS_MONTH,
  ADA_DASHBOARD_TABLE_DATA,
  ADA_DASHBOARD_GET_DRIVER_FORECAST_DATA,
  ADA_DASHBOARD_COLUMNS,
  ADA_DASHBOARD_GET_FORECAST_MULTIPLIER_DATA,
  ADA_DASHBOARD_AGGREGATION_LEVEL_DATA,
  ADA_SAVE_DETAILED_FORECAST,
  ADA_HISTORIC_YEARS,
  ADA_SAVE_MULTIPLIER,
  ADA_SAVE_DRIVER_FORECAST,
} from "modules/ada/constants-ada/apiConstants";
import {
  configureAttributeOptions,
  configureMonthAttributeOptions,
  configureWeekAttributeOptions,
  handleSetExclusion,
  isNumber,
} from "modules/ada/utils-ada/utilityFunctions";
import axiosInstance from "Utils/axios";

import { createSlice } from "@reduxjs/toolkit";
import {
  COMPARE_WITH_CONSTANTS,
  TIMELINE_CONSTANTS,
} from "modules/ada/constants-ada/stringContants";

import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import { agGridRowFormatter } from "Utils/agGrid/row-formatter";
import moment from "moment";

const initialState = {
  product: [],
  store: [],
  switchTimeLine: [TIMELINE_CONSTANTS[0]],
  fiscalDates: {
    start_date: "",
    end_date: "",
    start_fw: null,
    end_fw: null,
  },
  channel: [],
  productGroup: [],
  storeGroup: [],
  compareWith: COMPARE_WITH_CONSTANTS[0],
  compareWithSelectedDate: [],
  compareWithFiscalWeek: [],
  historicActuals: {},
  exclusion: {
    product: [],
    store: [],
  },
  loaderCount: 0,
  fullScreenLoaderCount: 0,
  selectedDates: {},
  fiscalCalendarDetails: [],
  exclusionFiscalWeek: [],
  exclusionFiscalMonth: [],
  inventoryAdaPayload: {},
  isRedirectedFromInventory: false,
  selectedHistoricValue: [],
  historicalDataFiscalWeek: {},
  historicalDataFiscalWeekCompare: {},
};

export const adaDashboardService = createSlice({
  name: "adaDashboardService",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setHistoricalFiscalData: (state, action) => {
      state.selectedHistoricValue = action.payload.selectedHistoricValue;
      state.historicalDataFiscalWeek = action.payload.historicalDataFiscalWeek;
      state.historicalDataFiscalWeekCompare =
        action.payload.historicalDataFiscalWeekCompare;
    },
    setInventoryAdaPayload: (state, action) => {
      const lastYear = moment(action.payload.timeline.startDate)
        .subtract(1, "year")
        .year();

      state.inventoryAdaPayload = action.payload;
      state.isRedirectedFromInventory = true;
      state.compareWithSelectedDate = [
        {
          label: lastYear,
          value: lastYear,
          id: lastYear,
        },
      ];
    },
    setSwitch: (state, action) => {
      state.switchTimeLine = [action.payload[0]];
    },
    setFiscalDates: (state, action) => {
      state.fiscalDates = action.payload;
    },
    setHistoricActuals: (state, action) => {
      state.historicActuals = action.payload;
    },
    setExclusion: (state, action) => {
      state.exclusion[action.payload.dimension] = handleSetExclusion(
        action.payload,
        state
      );
    },
    setAllExclusion: (state, action) => {
      state.exclusion[action.payload.key] = action.payload.value;
    },
    setLoaderCount: (state, action) => {
      state.loaderCount = state.loaderCount + action.payload;
    },
    setFullScreenLoaderCount: (state, action) => {
      state.fullScreenLoaderCount =
        state.fullScreenLoaderCount + action.payload >= 0
          ? state.fullScreenLoaderCount + action.payload
          : 0;
    },
    setSelectedDates: (state, action) => {
      state.selectedDates = action.payload;
    },
    setFiscalCalendarDetails: (state, action) => {
      state.fiscalCalendarDetails = action.payload;
    },
    setCompareWithSelectedDate: (state, action) => {
      state.compareWithSelectedDate = action.payload;
    },
    setCompareWithFiscalWeek: (state, action) => {
      state.compareWithFiscalWeek = action.payload;
    },
    setInventorypreAppliedFilters: (state, action) => {
      state.fiscalDates = action.payload.fiscalDates;
      state.historicActuals = action.payload.historicActuals;
      if (action.payload.product[0]?.values?.length) {
        state.product = action.payload.product;
      }
      if (action.payload.store[0]?.values?.length) {
        state.store = action.payload.store;
      }
    },
    resetState: (state) => {
      state.product = [];
      state.store = [];
      state.switchTimeLine = [TIMELINE_CONSTANTS[0]];
      state.fiscalDates = {
        start_date: "",
        end_date: "",
        start_fw: null,
        end_fw: null,
      };
      state.channel = [];
      state.productGroup = [];
      state.storeGroup = [];
      state.compareWith = COMPARE_WITH_CONSTANTS[0];
      state.historicActuals = {};
      state.exclusion = {
        product: [],
        store: [],
      };
      state.loaderCount = 0;
      state.selectedDates = {};
      state.exclusionFiscalWeek = [];
      state.exclusionFiscalMonth = [];
      state.compareWithSelectedDate = [];
      state.compareWithFiscalWeek = {};
      state.inventoryAdaPayload = {};
      state.isRedirectedFromInventory = false;
      state.selectedHistoricValue = [];
      state.historicalDataFiscalWeek = {};
      state.historicalDataFiscalWeekCompare = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFilters,
  setSwitch,
  setFiscalDates,
  resetState,
  setHistoricActuals,
  setExclusion,
  setAllExclusion,
  setLoaderCount,
  setSelectedDates,
  setFiscalCalendarDetails,
  setCompareWithSelectedDate,
  setCompareWithFiscalWeek,
  setInventorypreAppliedFilters,
  setFullScreenLoaderCount,
  setInventoryAdaPayload,
  setHistoricalFiscalData,
} = adaDashboardService.actions;

export default adaDashboardService.reducer;

export const getChartData = async (payload) => {
  const response = await axiosInstance({
    url: ADA_DASHBOARD_CHART_DATA,
    method: "POST",
    data: payload,
  });

  return response?.data?.data;
};

export const getAdaDashboardData = (postBody) => {
  return axiosInstance({
    url: ADA_DASHBOARD_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

// product group || store group
export const fetchGroupData = async (endpoint) => {
  const payload = { filters: [], range: [], sort: [], search: [] };

  let queryStr = `page=${1}&page_size=10000`;

  const response = await axiosInstance({
    url: `/core/group${endpoint}?${queryStr}`,
    method: "POST",
    data: payload,
  });

  return response?.data?.data.map(({ name, sg_code }) => ({
    value: name,
    label: name,
    id: sg_code,
  }));
};

export const getFiscalMonth = async (payload) => {
  if (!payload || !Object.keys(payload)?.length) return [];
  const options = await axiosInstance.post(
    ADA_DASHBOARD_GET_FISCAL_WEEKS_MONTH,
    {
      ...payload,
      key: "month",
    }
  );

  return configureMonthAttributeOptions(options.data.data);
};

const getFiltersValues = async (screen, postBody) => {
  return await axiosInstance({
    url: `${GET_FILTER_OPTIONS}/${screen}`,
    method: "POST",
    data: postBody,
  });
};

export const fetchFiscalWeeks = async (startDate, endDate) => {
  return axiosInstance({
    url: `${ADA_DASHBOARD_FISCAL_WEEKS}?start_date=${startDate}&end_date=${endDate}`,
    method: "GET",
  });
};

export const fetchCompareFiscalWeeks = async (postBody) => {
  return axiosInstance({
    url: ADA_DASHBOARD_COMPARE_FISCAL_WEEKS,
    method: "POST",
    data: postBody,
  });
};

export const fetchChannels = async () => {
  const body = {
    attribute_name: "channel",
    filter_type: "non-cascaded",
    filters: [],
  };
  const options = await getFiltersValues("store", body);
  return configureAttributeOptions(options.data.data.attribute);
};

// TO Do - remove it, once inventory team incorporates calendar changes
export const getFiscalCalendar = async () => {
  return axiosInstance.get(ADA_DASHBOARD_GET_FISCAL_CALENDAR);
};

export const getCoreFiscalCalendar = async () => {
  return axiosInstance.get(GET_FISCAL_CALENDAR);
};

export const getFiscalWeeks = async (payload) => {
  if (!payload || !Object.keys(payload)?.length) return [];
  const options = await axiosInstance.post(
    ADA_DASHBOARD_GET_FISCAL_WEEKS_MONTH,
    {
      ...payload,
      key: "week",
    }
  );

  return configureWeekAttributeOptions(options.data.data);
};

export const getDriverForecastColumns = async (payload, showIAData) => {
  const body = {
    start_week_id: payload.start_week_id,
    end_week_id: payload.end_week_id,
    module: "drivers_of_forecast",
    data_type: "percentage",
    forecast_source: showIAData ? "IA" : "adjusted",
    aggregation_level: payload.aggregation_level,
  };
  return axiosInstance({
    url: ADA_DASHBOARD_COLUMNS,
    method: "POST",
    data: body,
  });
};

export const getDriverForecastData = async (payload) => {
  const body = { filters: { ...payload.filters } };
  delete body.filters.graph;

  return axiosInstance({
    url: ADA_DASHBOARD_GET_DRIVER_FORECAST_DATA,
    method: "POST",
    data: body,
  });
};

export const getForecastColumns = async (payload) => {
  const body = {
    start_week_id: payload.start_week_id,
    end_week_id: payload.end_week_id,
    module: "forecast_multiplier",
    data_type: "float",
    forecast_source: "",
    aggregation_level: payload.aggregation_level,
  };
  return axiosInstance({
    url: ADA_DASHBOARD_COLUMNS,
    method: "POST",
    data: body,
  });
};

export const getForecastData = async (payload) => {
  const body = { filters: { ...payload.filters } };
  delete body.filters.graph;

  return axiosInstance({
    url: ADA_DASHBOARD_GET_FORECAST_MULTIPLIER_DATA,
    method: "POST",
    data: body,
  });
};

export const fetchEditHierarchyColumnData = async (
  payload,
  editHierarchyActionMap,
  allowEdit,
  showIAData,
  timeline
) => {
  const body = {
    start_week_id: payload.start_week_id,
    end_week_id: payload.end_week_id,
    module: "detail_table_1",
    data_type: "float",
    forecast_source: showIAData ? "IA" : "adjusted",
    aggregation_level: payload.aggregation_level,
  };

  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_COLUMNS,
    method: "POST",
    data: body,
  });
  data?.data?.forEach((column, index) => {
    // first column is link, so, it will always be editable
    if (index !== 0) {
      column.is_editable = allowEdit;

      // Revert this after, Lock cell Edge case fixed
      // column.is_lockable = allowEdit;
      column.is_lockable = false;

      column.label = `F${timeline}-${column.label}`;
    }
  });

  const formattedResponse = agGridColumnFormatter(
    data?.data,
    null,
    editHierarchyActionMap(data?.data?.[0]?.column_name)
  );

  return formattedResponse;
};

export const fetchHistoricEditHierarchyColumnData = async (
  payload,
  showIAData,
  timeline
) => {
  const body = {
    start_week_id: payload.start_week_id,
    end_week_id: payload.end_week_id,
    module: "detail_table_1",
    data_type: "float",
    forecast_source: showIAData ? "IA" : "adjusted",

    aggregation_level: payload.aggregation_level,
  };

  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_COLUMNS,
    method: "POST",
    data: body,
  });

  const updatedColumnData = data?.data?.slice(1).map((column) => ({
    ...column,
    disabled: true,
    label: `F${timeline}-${column.label}`,
  }));

  const formattedResponse = agGridColumnFormatter(updatedColumnData);

  return formattedResponse;
};

export const fetchEditHierarchyData = async (payload) => {
  const body = { filters: { ...payload.filters } };
  delete body.filters.graph;
  body.filters.agg_level = "l0";
  body.filters.agg_hierarchy = {};

  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_AGGREGATION_LEVEL_DATA,
    method: "POST",
    data: body,
  });

  // const data = editHierarchyRowdata;

  if (data?.data?.length > 0) {
    let formattedData = agGridRowFormatter(data?.data, null, "row");

    let updatedFormattedResponse = formattedData.map((el) => ({
      ...el,
      isEdited: false,
    }));

    return updatedFormattedResponse;
  }
  return [];
};

export const fetchEditHierarchyChildColumnData = async (
  payload,
  allowEdit,
  showIAData,
  timeline
) => {
  const body = {
    start_week_id: payload.start_week_id,
    end_week_id: payload.end_week_id,
    module: "detail_table_2",
    data_type: "float",
    forecast_source: showIAData ? "IA" : "adjusted",
    aggregation_level: payload.aggregation_level,
  };

  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_COLUMNS,
    method: "POST",
    data: body,
  });

  data?.data?.forEach((column, index) => {
    column.not_resizable = true;
    if (index !== 0) {
      column.is_editable = allowEdit;
      // Revert this after, Lock cell Edge case fixed
      // column.is_lockable = allowEdit;
      column.is_lockable = false;
      column.label = `F${timeline}-${column.label}`;
    }
  });

  const formattedResponse = agGridColumnFormatter(data?.data);

  // first column will always contain the level 1 details
  // hence, applying the grouping on that
  const updatedData = formattedResponse.map((column, i) => ({
    ...column,
    cellRenderer: i === 0 ? "agGroupCellRenderer" : column.cellRenderer,
  }));
  return updatedData;
};

export const fetchHistoricEditHierarchyChildColumnData = async (
  payload,
  showIAData,
  timeline
) => {
  const body = {
    start_week_id: payload.start_week_id,
    end_week_id: payload.end_week_id,
    module: "detail_table_2",
    data_type: "float",
    forecast_source: showIAData ? "IA" : "adjusted",

    aggregation_level: payload.aggregation_level,
  };

  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_COLUMNS,
    method: "POST",
    data: body,
  });

  const updatedColumnData = data?.data?.slice(1).map((column) => ({
    ...column,
    disabled: true,
    not_resizable: true,

    label: `F${timeline}-${column.label}`,
  }));

  const formattedResponse = agGridColumnFormatter(updatedColumnData);

  return formattedResponse;
};

export const fetchEditChildHierarchyData = async (payload, selected) => {
  const body = { filters: { ...payload.filters } };
  delete body.filters.graph;
  body.filters.agg_level = "l1";
  body.filters.agg_hierarchy = { l0: selected };

  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_AGGREGATION_LEVEL_DATA,
    method: "POST",
    data: body,
  });

  if (data?.data?.length > 0) {
    let formattedData = agGridRowFormatter(data?.data, null, "row");

    let updatedFormattedResponse = formattedData.map((el) => ({
      ...el,
      isEdited: false,
    }));

    updatedFormattedResponse.forEach((elem) => {
      for (let key in elem) {
        if (isNumber(key)) {
          elem[key] = {
            ...elem[key],
            IA: elem[key].IA || 0,
            adjusted: elem[key].adjusted || 0,
          };
        }
      }
    });

    return updatedFormattedResponse;
  }
  return [];
};

export const fetchEditHierarchyGrandChildColumnData = async (
  payload,
  allowEdit,
  showIAData
) => {
  const body = {
    start_week_id: payload.start_week_id,
    end_week_id: payload.end_week_id,
    module: "detail_table_3",
    data_type: "float",
    forecast_source: showIAData ? "IA" : "adjusted",
    aggregation_level: payload.aggregation_level,
  };

  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_COLUMNS,
    method: "POST",
    data: body,
  });

  data?.data?.forEach((column, index) => {
    if (index !== 0) {
      column.is_editable = allowEdit;
      // Revert this after, Lock cell Edge case fixed
      // column.is_lockable = allowEdit;
      column.is_lockable = false;
    }
  });

  const formattedResponse = agGridColumnFormatter(data?.data);

  return formattedResponse;
};

export const fetchHistoricalEditHierarchyGrandChildColumnData = async (
  payload
) => {
  const body = {
    start_week_id: payload.start_week_id,
    end_week_id: payload.end_week_id,
    module: "detail_table_3",
    data_type: "float",
    forecast_source: "adjusted",
    aggregation_level: payload.aggregation_level,
  };

  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_COLUMNS,
    method: "POST",
    data: body,
  });

  const updatedColumnData = data?.data?.slice(1).map((column) => ({
    ...column,
    disabled: true,
  }));

  const formattedResponse = agGridColumnFormatter(updatedColumnData);

  return formattedResponse;
};

export const fetchEditGrandChildHierarchyData = async (
  payload,
  l0Name,
  l1Name
) => {
  const body = { filters: { ...payload.filters } };
  delete body.filters.graph;
  body.filters.agg_level = "l2";
  body.filters.agg_hierarchy = { l0: l0Name, l1: l1Name };
  const { data } = await axiosInstance({
    url: ADA_DASHBOARD_AGGREGATION_LEVEL_DATA,
    method: "POST",
    data: body,
  });

  // const data = editHierarchyGrandChildRowdata;

  if (data?.data?.length > 0) {
    let formattedData = agGridRowFormatter(data?.data, null, "row");

    let updatedFormattedResponse = formattedData.map((el) => ({
      ...el,
      isEdited: false,
    }));

    updatedFormattedResponse.forEach((elem) => {
      for (let key in elem) {
        if (isNumber(key)) {
          elem[key] = {
            ...elem[key],
            IA: elem[key].IA || 0,
            adjusted: elem[key].adjusted || 0,
          };
        }
      }
    });

    return updatedFormattedResponse;
  }
  return [];
};

export const saveDetailForecast = async (payload) => {
  return axiosInstance({
    url: ADA_SAVE_DETAILED_FORECAST,
    method: "PUT",
    data: payload,
  });
};

export const saveDriverForecast = async (payload) => {
  return axiosInstance({
    url: ADA_SAVE_DRIVER_FORECAST,
    method: "PUT",
    data: payload,
  });
};

export const saveMultiplier = async (payload) => {
  return axiosInstance({
    url: ADA_SAVE_MULTIPLIER,
    method: "PUT",
    data: payload,
  });
};

export const getHistoricYears = async () => {
  const data = await axiosInstance({
    url: ADA_HISTORIC_YEARS,
    method: "GET",
  });
  return data.data.data;
};
