import { connect } from "react-redux";
import { forwardRef, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import "../groupTable.scss";
import { useHistory } from "react-router-dom";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import GroupName from "./productGroupName";
import RequestsTable from "./clusterRequestsTable";
import {
  fetchProdGrpFilteredProducts,
  setProdGroupFilteredProds,
  setSelectedProducts,
  ToggleLoader,
  fetchProductGroups,
  setGroupsCols,
  addSelectedGroups,
  addToExistingProds,
  setDeletedProdsInDefn,
  deletedRowsInEdit,
  newRowsInEdit,
  updateGrp,
  newGrpsInEdit,
  deletedGrpsInEdit,
  setStyleTableCols,
  setSelectedManualStyles,
  fetchStyleLevelData,
  setStyleTableData,
  setDeletedStylesInDefn,
  fetchRequestInfo,
  setInitialMetricFilters,
  setSelectedObjectiveMetric,
  setMetricStartDate,
  setMetricEndDate,
  setSelectedObjectiveTimeFormat,
  resetFilterProds,
  setResetFilterTable,
} from "pages/product-grouping/product-grouping-service";
import { setSelectedFilters } from "../../../../actions/filterAction";
import { addSnack } from "../../../../actions/snackbarActions";
import {
  getColumns,
  setTableState,
} from "../../../../actions/tableColumnActions";
import _ from "lodash";
import Switch from "../../../../Utils/switch";
import moment from "moment";
import globalStyles from "Styles/globalStyles";
import clsx from "clsx";
import ManualProductTable from "./manual-product-tables";
import ManualStyleTable from "./manual-style-table";
import ManualProdGroupTable from "./manual-product-group-table";
import { getTenantConfigApplicationLevel } from "actions/tenantConfigActions";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import { formatFiltersDependency } from "pages/store-grouping/components/common-functions";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    backgroundColor: "white",
    color: "#4F677B",
    borderColor: "#4F677B",
  },
  contentStyle: {
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontWeight: 500,
    fontSize: 18,
  },
  actionStyle: {
    backgroundColor: "#F7F7F7",
  },
  switchDiv: {
    display: "flex",
    marginLeft: 20,
    alignItems: "center",
  },
  switch: {
    marginLeft: 10,
    marginRight: 10,
  },
  checkBoxGrid: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.typography.pxToRem(10),
  },
}));
const FilteredProducts = forwardRef((props, ref) => {
  const classes = useStyles();
  const [selectedProducts, setselectedProducts] = useState([]);
  const [open, setopen] = useState(false);
  const history = useHistory();
  const [isIncludeGrpsChecked, setisIncludeGrpsChecked] = useState(false);
  const [selectedGrps, setselectedGrps] = useState([]);
  const [confirmPopUp, setconfirmPopUp] = useState(false);
  const [isStyleLevel, setisStylelevel] = useState(false);
  const [confirmDefnPopUp, setconfirmDefnPopUp] = useState(false);
  const [openRequests, setopenRequests] = useState(false);
  const [selectedCluster, setselectedCluster] = useState({});
  const [showStyleLevelData, setShowStyleLevelData] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const openReqFunc = () => {
    setopenRequests(true);
  };
  const closeReqFunc = async (reqData = null) => {
    setopenRequests(false);
    if (!_.isEmpty(reqData)) {
      props.ToggleLoader(true);
      try {
        const reqInfo = await props.fetchRequestInfo(reqData.id);
        const filters = reqInfo.data.data.metrics.filters.map((filter) => {
          return {
            ...filter,
            filter_id: filter.attribute_name,
          };
        });
        props.setInitialMetricFilters(filters);
        if (props.selectedGroupType === "objective") {
          props.setSelectedObjectiveMetric([
            {
              label: reqInfo.data.data.metrics.metrics.value[0],
              value: reqInfo.data.data.metrics.metrics.value[0],
            },
          ]);
        } else {
          props.setSelectedObjectiveMetric(
            reqInfo.data.data.metrics.metrics.value.map((metric) => {
              return {
                label: metric,
                value: metric,
                checked: true,
              };
            })
          );
        }
        props.setMetricStartDate(
          moment(reqInfo.data.data.metrics.metrics.start_date, "YYYY-MM-DD")
        );
        props.setMetricEndDate(
          moment(reqInfo.data.data.metrics.metrics.end_date, "YYYY-MM-DD")
        );
        props.setSelectedObjectiveTimeFormat({
          label: reqInfo.data.data.metrics.metrics.time_format,
          value: reqInfo.data.data.metrics.metrics.time_format,
        });
        setselectedCluster(reqInfo.data.data);
        ref.productLvlRef?.current?.api?.refreshServerSideStore({
          purge: false,
        });
        props.ToggleLoader(false);
      } catch (error) {
        props.addSnack({
          message: "something went wrong",
          options: {
            variant: "error",
          },
        });
        props.ToggleLoader(false);
      }
    }
  };

  const goBack = () => {
    const groupTypeExpression =
      props.selectedGroupType !== "manual" ||
      (props.selectedGroupType === "manual" &&
        props.selectedManualFilterType === "product_hierarchy");
    const isEditedExpression =
      props.deleteEditProds.length !== 0 || props.newEditProds.length !== 0;
    const isCreatedExpression =
      props.selectedProducts.length !== 0 || props.selectedGrps.length !== 0;
    const isDefinitionsProdAddedCheck = props.deletedDefnProds.length !== 0;
    if (groupTypeExpression && (isEditedExpression || isCreatedExpression)) {
      setconfirmPopUp(true);
      return;
    }
    if (props.isEdit && groupTypeExpression && !isEditedExpression) {
      props.resetFilterProds();
      const grpId = history.location.pathname.split("/")[3];
      history.push(`/product-grouping/view/${grpId}`);
      return;
    }

    if (!props.isEdit && groupTypeExpression && !isCreatedExpression) {
      props.resetFilterProds();
      history.push(`/product-grouping/`);
      return;
    }

    if (!groupTypeExpression && isDefinitionsProdAddedCheck) {
      setconfirmPopUp(true);
      return;
    }

    if (props.isEdit && !groupTypeExpression && !isDefinitionsProdAddedCheck) {
      props.resetFilterProds();
      const grpId = history.location.pathname.split("/")[3];
      history.push(`/product-grouping/view/${grpId}`);
      return;
    }

    if (!groupTypeExpression && !isDefinitionsProdAddedCheck) {
      props.resetFilterProds();
      history.push(`/product-grouping/`);
    }
  };

  useEffect(() => {
    const fetchStyleInfo = async () => {
      let showStyleLevelDataResp = await props.getTenantConfigApplicationLevel(
        3,
        {
          attribute_name: "core_show_style_level_info",
        }
      );

      if (showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"]) {
        setShowStyleLevelData(
          showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"].value
        );
      }
    };
    fetchStyleInfo();
  }, []);
  useEffect(() => {
    closeReqFunc(props.selectedCluster);
    setselectedCluster(props.selectedCluster);
  }, [props.selectedCluster]);
  useEffect(() => {
    if (props.resetFilterTable) {
      props.setResetFilterTable(!props.resetFilterTable);
    }
  }, [props.resetFilterTable]);
  useEffect(() => {
    props.setResetFilterTable(true);
  }, [props.selectedGroupType, props.selectedManualFilterType]);
  useEffect(() => {
    if (props.selectedManualFilterType !== "product_hierarchy") {
      setisIncludeGrpsChecked(false);
    }
  }, [props.selectedManualFilterType]);

  useEffect(() => {
    let filterObj = {};
    filterObj["product_hierarchy"] = [];
    props.setSelectedFilters(filterObj);
    return () => {
      let obj = {};
      obj["product_filters"] = {
        filters: [],
        pageIndex: 0,
        pageSize: 10,
      };
      obj["style_filters"] = {
        filters: [],
        pageIndex: 0,
        pageSize: 10,
      };
      props.setTableState(obj);
    };
  }, []);

  const toggleModalState = (status) => {
    if (props.isEdit) {
      onUpdate();
      return;
    }
    if (
      props.selectedManualFilterType === "grouping_definitions" &&
      props.deletedDefnProds.length !== 0
    ) {
      setconfirmDefnPopUp(true);
    } else {
      setopen(status);
    }
  };

  const onUpdate = async () => {
    let grpType = props.grpObj.special_classification;
    if (
      (grpType !== "manual" && props.newEditProds.length !== 0) ||
      props.deleteEditProds.length !== 0 ||
      props.newEditGrps.length !== 0 ||
      props.deleteEditGrps.length !== 0
    ) {
      grpType = "manual";
    }
    let body = {
      name: props.grpObj.name,
      group_type: grpType,
      ...(grpType !== "manual" && {
        objective_metrics: props.grpObj.selection_metadata.objective_metrics,
      }),
      definitions: Array.isArray(props.manualDefinitionFilter)
        ? props.manualDefinitionFilter.map((defn) => defn.pgd_code)
        : [props.manualDefinitionFilter.pgd_code],
    };
    body["product_ids"] = {
      filters: formatFiltersDependency(props.selectedFilters, "product", true),
      meta: {
        search: [],
        range: [],
        sort: [],
      },
      definitions: [],
      metrics: [],
      selection: {
        data: isStyleLevel
          ? ref.styleLvlRef?.current?.api?.checkConfiguration || []
          : [
              {
                searchColumns: {
                  is_mapped: {
                    filterType: "bool",
                    filter: true,
                  },
                },
                checkAll: true,
              },
              ...(ref.productLvlRef?.current?.api?.checkConfiguration || []),
            ],
        unique_columns: [isStyleLevel ? "style" : "product_code"],
      },
    };
    body["product_group_ids"] = {
      filters: formatFiltersDependency(props.selectedFilters, "product", true),
      meta: {
        search: [],
        range: [],
        sort: [],
      },
      metrics: [],
      definitions: [],
      selection: {
        data: [
          {
            searchColumns: {
              is_mapped: {
                filterType: "bool",
                filter: true,
              },
            },
            checkAll: true,
          },

          ...(ref.productGroupLvlRef?.current?.api?.checkConfiguration || []),
        ],
        unique_columns: ["pg_code"],
      },
    };
    try {
      props.ToggleLoader(true);
      const grpId = history.location.pathname.split("/")[3];
      await props.updateGrp(grpId, body, isStyleLevel);
      props.addSnack({
        message: "Updated successfully",
        options: {
          variant: "success",
          onClose: () => history.push(`/product-grouping/view/${grpId}`),
        },
      });

      props.ToggleLoader(false);
    } catch (error) {
      props.ToggleLoader(false);
      props.addSnack({
        message: "Update Failed",
        options: {
          variant: "error",
        },
      });
    }
  };

  const handleClose = () => {
    setconfirmPopUp(false);
  };

  const onDefnProceed = () => {
    setopen(true);
  };
  const handleDefnPopUpClose = () => {
    setconfirmDefnPopUp(false);
  };
  const onProceed = () => {
    setconfirmPopUp(false);
    if (props.isEdit) {
      const grpId = history.location.pathname.split("/")[3];
      history.push({
        pathname: `/product-grouping/view/${grpId}`,
      });
    } else {
      history.push("/product-grouping/");
    }
    props.resetFilterProds();
  };
  const toggleAggLevel = () => {
    setShowConfirmModal(true);
  };

  const onConfirmCallBack = (stateValue) => {
    if (stateValue) {
      setisStylelevel(!isStyleLevel);
    }
  };
  const globalClasses = globalStyles();
  return (
    <>
      <ConfirmPrompt
        title="Switch Hierarchy Level"
        message={`Are you sure of switching the hierarchy level \n Your selections will be lost`}
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        showModal={showConfirmModal}
        setConfirm={setShowConfirmModal}
        confirmCallback={onConfirmCallBack}
      />
      <Dialog
        id="productGrpingDefnFiltersDialog"
        open={confirmDefnPopUp}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent classes={{ root: classes.contentStyle }}>
          <Typography className={classes.textStyle}>
            Some of the products were unmapped. Do you wish to proceed?
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.actionStyle }}>
          <Button
            id="productGrpingDefnFiltersDialogCnclBtn"
            size="small"
            className={classes.btnStyle}
            onClick={handleDefnPopUpClose}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={onDefnProceed}
            color="primary"
            id="productGrpingDefnFiltersDialogCnfmBtn"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        id="productGrpingProdFiltersDialog"
        open={confirmPopUp}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent classes={{ root: classes.contentStyle }}>
          <Typography className={classes.textStyle}>
            Changes will be lost. Are you sure want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions classes={{ root: classes.actionStyle }}>
          <Button
            id="productGrpingProdFiltersDialogCnclBtn"
            size="small"
            className={classes.btnStyle}
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={onProceed}
            color="primary"
            id="productGrpingProdFiltersDialogCnfmBtn"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {open && (
        <GroupName
          ref={{
            productLvlRef: ref.productLvlRef,
            styleLvlRef: ref.styleLvlRef,
            productGroupLvlRef: ref.productGroupLvlRef,
          }}
          selectedFilters={props.selectedFilters}
          open={open}
          handleClose={() => toggleModalState(false)}
          currentScrSelection={selectedProducts}
          currentScrGrps={selectedGrps}
          clusterData={selectedCluster}
          isStyleLevel={isStyleLevel}
        />
      )}
      {openRequests && (
        <RequestsTable
          dimension="product"
          selectedGroupType={props.selectedGroupType}
          // key="request_table"
          open={openRequests}
          handleClose={closeReqFunc}
        />
      )}

      {props.columns.length !== 0 && (
        <>
          <Grid container>
            <Grid
              item
              xs={4}
              className={clsx(
                globalClasses.verticalAlignCenter,
                globalClasses.flexRow
              )}
            >
              <Typography variant="h5">{`Filtered ${dynamicLabelsBasedOnTenant(
                "product",
                "core"
              )}`}</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={clsx(
                globalClasses.verticalAlignCenter,
                globalClasses.flexRow
              )}
            >
              {props.selectedGroupType === "manual" && (
                <div className={classes.switchDiv}>
                  <Typography>{`${dynamicLabelsBasedOnTenant(
                    "product",
                    "core"
                  )} Level`}</Typography>
                  {showStyleLevelData && (
                    <>
                      <Switch
                        className={classes.switch}
                        checked={isStyleLevel}
                        onChange={toggleAggLevel}
                        id="productToggleBtn"
                      ></Switch>
                      Style level
                    </>
                  )}
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              className={clsx(
                globalClasses.verticalAlignCenter,
                globalClasses.flexRow,
                classes.checkBoxGrid
              )}
            >
              {props.selectedGroupType !== "manual" && (
                <Button
                  onClick={openReqFunc}
                  variant="contained"
                  color="primary"
                >
                  View Cluster Status
                </Button>
              )}
              {props.selectedGroupType === "manual" &&
                props.selectedManualFilterType === "product_hierarchy" && (
                  <FormControlLabel
                    id="productGroupingIncludeGrpsLabel"
                    control={
                      <Checkbox
                        id="productGroupingIncludeGrpsCheckbox"
                        color="primary"
                        checked={isIncludeGrpsChecked}
                        onChange={(event) =>
                          setisIncludeGrpsChecked(event.target.checked)
                        }
                      />
                    }
                    label={`Include ${dynamicLabelsBasedOnTenant(
                      "product",
                      "core"
                    )} Groups`}
                  />
                )}
            </Grid>
          </Grid>
          {!isStyleLevel && (
            <>
              <ManualProductTable
                ref={ref.productLvlRef}
                selectedFilters={props.selectedFilters}
                type={"product"}
                manualDefinitionFilter={props.manualDefinitionFilter}
                columns={props.columns}
                pathname={history.location.pathname}
                isEdit={props.isEdit}
                selectedCluster={selectedCluster}
              />
            </>
          )}
          {isStyleLevel && (
            <ManualStyleTable
              ref={ref.styleLvlRef}
              selectedFilters={props.selectedFilters}
              type={"style"}
              manualDefinitionFilter={props.manualDefinitionFilter}
              styleLevelCols={props.styleLevelCols}
              pathname={history.location.pathname}
              isEdit={props.isEdit}
              selectedCluster={selectedCluster}
            />
          )}

          {isIncludeGrpsChecked &&
            props.selectedManualFilterType === "product_hierarchy" && (
              <ManualProdGroupTable
                ref={ref.productGroupLvlRef}
                type={"product_group"}
                pathname={history.location.pathname}
                isEdit={props.isEdit}
                selectedFilters={props.selectedFilters}
                manualDefinitionFilter={props.manualDefinitionFilter}
                selectedCluster={selectedCluster}
              />
            )}
        </>
      )}
      <div
        className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
      >
        <Button
          variant="contained"
          color="primary"
          id={
            props.isEdit
              ? "productGrpingProdFiltersUpdBtn"
              : "productGrpingProdFiltersSaveBtn"
          }
          onClick={() => toggleModalState(true)}
        >
          {props.isEdit ? "Update" : "Save"}
        </Button>
        <Button
          variant="outlined"
          classes={{ root: classes.cancelBtn }}
          onClick={goBack}
          id="productGrpingProdFiltersCnclBtn"
        >
          {" "}
          Cancel
        </Button>
      </div>
    </>
  );
});

const getTabLevelSelectedFilters = (type, state) => {
  switch (type) {
    case "manual":
      return state.filterReducer.selectedFilters["product_hierarchy"];
    case "objective":
      return state.filterReducer.selectedFilters["product_metric"];
    case "custom":
      return state.filterReducer.selectedFilters["product_metric"];
    default:
      return;
  }
};
const mapStateToProps = (state) => {
  return {
    selectedProducts: state.productGroupReducer.selectedProducts,
    columns: state.productGroupReducer.manualFilteredProdsCols,
    selectedFilters: getTabLevelSelectedFilters(
      state.productGroupReducer.selectedGroupType,
      state
    ),
    selectedManualFilterType:
      state.productGroupReducer.selectedManualFilterType,
    selectedGrps: state.productGroupReducer.manualselectedGroups,
    manualDefinitionFilter: state.productGroupReducer.manualGroupDfnFilters,
    deletedDefnProds: state.productGroupReducer.deleteProdsInDefn,
    newEditProds: state.productGroupReducer.newProdsInEdit,
    deleteEditProds: state.productGroupReducer.deletedProdsInEdit,
    selectedGroupType: state.productGroupReducer.selectedGroupType,
    newEditGrps: state.productGroupReducer.newGrpsInEdit,
    deleteEditGrps: state.productGroupReducer.deletedGrpsInEdit,
    styleLevelCols: state.productGroupReducer.styleLevelTableCols,
    selectedStyles: state.productGroupReducer.selectedStyles,
    deletedDefnStyles: state.productGroupReducer.deletedDefnStyles,
    selectedCluster: state.productGroupReducer.selectedCluster,
    resetFilterTable: state.productGroupReducer.resetTable,
  };
};

const mapActionsToProps = {
  fetchProdGrpFilteredProducts,
  setProdGroupFilteredProds,
  setSelectedProducts,
  ToggleLoader,
  fetchProductGroups,
  setGroupsCols,
  getColumns,
  addSelectedGroups,
  addToExistingProds,
  setDeletedProdsInDefn,
  newRowsInEdit,
  deletedRowsInEdit,
  updateGrp,
  addSnack,
  setSelectedFilters,
  deletedGrpsInEdit,
  newGrpsInEdit,
  setStyleTableCols,
  setSelectedManualStyles,
  setTableState,
  fetchStyleLevelData,
  setStyleTableData,
  setDeletedStylesInDefn,
  fetchRequestInfo,
  setInitialMetricFilters,
  setSelectedObjectiveMetric,
  setMetricStartDate,
  setMetricEndDate,
  setSelectedObjectiveTimeFormat,
  resetFilterProds,
  setResetFilterTable,
  getTenantConfigApplicationLevel,
};

export default connect(mapStateToProps, mapActionsToProps, null, {
  forwardRef: true,
})(FilteredProducts);
