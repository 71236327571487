import React, { useEffect, useState } from "react";
import { uniqBy, isFunction } from "lodash";
import { ReactTableFeatures } from "../../../config/constants";
import { filterView } from "modules/react-demo/utils/utilityFunctions";
import { useStyles } from "modules/react-demo/pages/styles";

// Validates features and remove typo
function validateFeatures(keyRT, features = []) {
  features.forEach((feature) => {
    let find = ReactTableFeatures[features];
    if (!find) {
      throw new Error(
        `Feature should be valid : ${feature}, from keyRT: ${keyRT}`
      );
    }
  });
}

// Hook will be passed to table to get new instance, where instance is required in controller file
const useInstance = (instance) => {
  const {
    keyRT,
    settabledata,
    getInstanceOnMount,
    features,
    data,
    state: { selectedRowIds, setSelectedFilter },
  } = instance;
  // Call ValidateFeatures for find if there are any typos in a `features` prop
  validateFeatures(keyRT, features);
  const getSelectedRows = selectedRowIds && Object.keys(selectedRowIds);

  useEffect(() => {
    if (getInstanceOnMount) getInstanceOnMount(instance);
  }, [
    getInstanceOnMount,
    keyRT,
    instance,
    instance.data,
    selectedRowIds,
    setSelectedFilter,
  ]);

  const deleteRow = function deleteRow(acc, id) {
    let filteredData = data.filter((row, i) => {
      return row[acc] !== id;
    });

    settabledata(filteredData);

    return filteredData;
  };

  Object.assign(instance, {
    getSelectedRows,
    deleteRow,
  });
};

const SelectBox = ({ values = [], onChange, label, key, defaultInx }) => {
  const [selectValue, setSelectValue] = useState("");
  const classes = useStyles();

  const handleSelect = (value) => {
    setSelectValue(value);
    onChange(value.value);
  };

  useEffect(() => {
    if (defaultInx >= 0 && values.length > 0) {
      handleSelect(values[defaultInx]);
    }
  }, [defaultInx]);

  return filterView(
    label,
    key,
    values,
    handleSelect,
    selectValue,
    classes.assortMultiFilterView
  );
};

const filteredColumnsInstance = (instance) => {
  const filteredColumns =
    instance?.columnsToFilter?.map((col) => {
      const uniqueValues = uniqBy(instance.data, col.key);
      return {
        colName: col.label,
        key: col.key,
        defaultInx: col.defaultInx,
        divClassName: col.divClassName,
        values: uniqueValues.map((uniqueValue) => uniqueValue[col.key]),
      };
    }) || [];
  const handleSelect = (colName, value) => {
    instance.setFilter(colName, value);
  };
  const filteredColumnsWithDropdown = filteredColumns.map((col) => {
    return (
      <SelectBox
        label={col.colName}
        key={col.key}
        values={col.values.map((value) => ({ label: value, value: value }))}
        defaultInx={col.defaultInx}
        divClassName={col.divClassName}
        onChange={(value) => handleSelect(col.key, value)}
      />
    );
  });

  // Without SelectBox component
  instance.filterColumns = filteredColumns;

  // With SelectBox component
  instance.filteredColumnsWithDropdown = filteredColumnsWithDropdown;
};

// Hook used to get filtered columns with or without the select box. Required key columnsToFilter
export const useFilteredColumns = (hooks) => {
  hooks.useInstance.push(filteredColumnsInstance);
};

export const useGetInstance = (hooks) => {
  hooks.useInstance.push(useInstance);
};

// Checkbox Markup
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          style={{ width: "1rem", marginTop: "3px" }}
        />
      </>
    );
  }
);

const getCheckBoxColumn = () => {
  return {
    id: "selection",
    isFixed: true,
    disableSortBy: true,
    Header: (instance) => {
      const { features } = instance;
      let isChecked;
      let isInDeterminate;
      // checks if customRowSelection function was passed in prop
      const customRowSelectionAvailable = isFunction(
        instance.customRowSelection
      );

      // calls the customRowSelection instead of default row selection
      const setRowSelection = (event) => {
        //If manually controlled select all is enabled for a table
        if (instance.enableSelectAll) {
          instance.onSelectAll(event);
        }
        instance.customRowSelection(instance, event);
      };

      let selectableRowsInCurrentPage = 0;
      let selectedRowsInCurrentPage = 0;
      instance.rows.forEach((row) => {
        row.isSelected && selectedRowsInCurrentPage++;
        row.original && !row.original.disableDeleteSelection && selectableRowsInCurrentPage++;
      });

      // check for select all checkbox
      //If manually controlled select all is enabled and user clicks o
      isChecked = instance.enableSelectAll
        ? instance.manualIsAllRowsSelected
        : instance.isAllPageRowsSelected ||
          (selectableRowsInCurrentPage === selectedRowsInCurrentPage &&
            selectableRowsInCurrentPage != 0);
      //check for indeterminate state
      isInDeterminate = instance.enableSelectAll
        ? !instance.manualIsAllRowsSelected &&
          instance.unselectedRowsInSelectAll.length > 0
        : instance.getToggleAllRowsSelectedProps().indeterminate;
      return features && features.includes("CHECKBOX_LABEL_FOR_HEADER") ? (
        "Action"
      ) : customRowSelectionAvailable || instance.enableSelectAll ? (
        <div>
          <IndeterminateCheckbox
            {...instance.getToggleAllRowsSelectedProps()}
            disabled={instance.disabledRowCheckbox}
            checked={isChecked}
            indeterminate={isInDeterminate}
            onChange={setRowSelection}
          />
        </div>
      ) : (
        <div>
          <IndeterminateCheckbox
            {...instance.getToggleAllRowsSelectedProps()}
            disabled={instance.disabledRowCheckbox}
          />
        </div>
      );
    },
    checkBoxColumn: true,
    Cell: (instance) => {
      let showCheckBox = true;
      // checks if shouldShowCheckBoxHandler function was passed in prop
      const checkBoxHandlerAvailable = isFunction(
        instance.shouldShowCheckBoxHandler
      );
      if(checkBoxHandlerAvailable){
        showCheckBox = instance.shouldShowCheckBoxHandler(instance);
      }
      return (
        showCheckBox ?
        <div>
          <IndeterminateCheckbox
            {...instance.row.getToggleRowSelectedProps()}
            disabled={
              instance.disabledRowCheckbox
                ? true
                : instance.row.original && instance.row.original.disableDeleteSelection
                ? true
                : false
            }
          />
        </div> : null
      );
    },
    width: 60,
    minWidth: 50,
  };
};

// Based on `features prop` that is passed, It checks if checkox is needed for first column or firstColumn with header checkbox
export const useCheckBoxColumn = (hooks, rowSelection) => {
  hooks.visibleColumns.push((columns) => [
    { ...getCheckBoxColumn(), sticky: "left" },
    ...columns,
  ]);
};

export const useCheckBoxLastColumn = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    { ...getCheckBoxColumn(), sticky: "right" },
  ]);
};

// Hook for row spanning, use markup file for rendering - 'TableMarkup.js' as it has td based html layout
export const useRowSpan = (hooks) => {
  hooks.useInstance.push(function (instance) {
    const { allColumns } = instance;

    let rowSpanHeaders = [];

    allColumns.forEach((column, i) => {
      const { id, enableRowSpan } = column;

      if (enableRowSpan !== undefined) {
        rowSpanHeaders = [
          ...rowSpanHeaders,
          { id, topCellValue: null, topCellIndex: 0 },
        ];
      }
    });

    Object.assign(instance, { rowSpanHeaders });
  });
};
