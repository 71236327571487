import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Loader from "../../../Utils/Loader/loader";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import {
  getAllProductStores,
  getAllProductGroupStores,
  mapProductToStore,
  mapProductGroupToStore,
  validateProductMappingTimeBounds,
  editOrDelete,
  mapProductsToStoreGroup,
} from "../services-product-mapping/productMappingService";
import Exceptions from "./exception";
import {
  fetchAllStoreCodes,
  fetchAllStoreGroups,
} from "pages/store-grouping/services-store-grouping/custom-store-group-service";
import { getColumns } from "../../../actions/tableColumnActions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  RadioGroup,
  Radio,
  FormControlLabel,
  Container,
} from "@mui/material";
import ConfirmBox from "../../../Utils/reactTable/components/confirmPopup";
import { isEmpty } from "lodash";
import ModifyTable from "pages/storeMapping/components/modify-table";
import TimeBoundDialog from "pages/storeMapping/components/time-bound-dialog";
import {
  splitMappedAndUnmappedData,
  prepareCreatePayload,
  parseMappings,
} from "pages/storeMapping/components/common-mapping-functions";
import { addSnack } from "actions/snackbarActions";
import globalStyles from "Styles/globalStyles";
// New set of filters imports
import SetAllComponent from "pages/storeMapping/components/set-all-component";
import ModifyStoreGroupsTable from "./modifyStoreGroupsTable";
import { updateCellValueData } from "./common-functions";
import moment from "moment";
import { changeDateStringToOtherFormat } from "Utils/functions/utils";
import { DEFAULT_DATE_FORMAT, END_DATE } from "config/constants";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import { setFilterConfiguration } from "actions/filterAction";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const useStyles = makeStyles((theme) => ({
  extraLength: {
    backgroundColor: theme.palette.colours.disabledSelectBackground,
    borderRadius: theme.shape.borderRadius,
    padding: "0 0.2rem",
  },
  labelText: {
    color: theme.palette.text.disabled,
  },
  fieldText: {
    color: theme.palette.text.secondary,
  },
}));

function ProductsFilter(props) {
  const [showloader, setloader] = useState(true);
  const [defaultRowData, setDefaultRowData] = useState([]);
  const [filterDependency, setDependency] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [showException, updateShowException] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmBox, showConfirmBox] = useState(false);
  const [isLoaderActive, setLoaderActive] = useState(false);
  const [editedStoresData, setEditedStoresData] = useState([]);
  const [conflictProdMappingData, setconflictProdMappingData] = useState({});
  const classes = useStyles();
  const globalClasses = globalStyles();

  //To display set all multi row pop up
  const [showSetAllPopUp, setshowSetAllPopUp] = useState(false);
  const [setAllPopUpFields, setsetAllPopUpFields] = useState([]);
  const [commonSetAllProducts, setcommonSetAllProducts] = useState([]);

  /**
   * New Modify Table State variables
   */
  const [selectedStoreObjects, setselectedStoreObjects] = useState([]); //All the selected Product Ids in modify table
  const [selectedStoreGroupObjects, setSelectedStoreGroupObjects] = useState(
    []
  );
  const [storeGroupDatesAPIPayload, setStoreGroupDatesAPIPayload] = useState(
    {}
  );
  const modifyTableRef = useRef(null);
  const modifyStoreGroupsTableRef = useRef(null);
  const [
    displayTimeBoundMappingDialog,
    setdisplayTimeBoundMappingDialog,
  ] = useState(false);

  const [
    editOrDeleteEditedAPIPayload,
    seteditOrDeleteEditedAPIPayload,
  ] = useState([]);
  const [setAllConfirmBox, showsetAllConfirmBox] = useState(false);
  const [storeRadioType, setStoreRadioType] = useState("store");
  const [storeGroupStartDate, setStoreGroupStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [storeGroupEndDate, setStoreGroupEndDate] = useState(END_DATE);

  const exceptionsFields = [
    {
      label: "Select Products",
      field_type: "list",
      isMulti: true,
      required: true,
      options: props.selectedProducts.map((item) => {
        return {
          value: item.product_code,
          id: item.product_code,
          label: item.product_code,
        };
      }),
      accessor: "select_product",
    },
    {
      label: "Select Store",
      field_type: "list",
      isMulti: true,
      required: true,
      options: [],
      accessor: "select_store",
    },
  ];
  /**
   * @func
   * @desc Fetch filter Options and Store Groups and initialise filter options
   */
  useEffect(() => {
    const getInitialData = async () => {
      try {
        let columnsResp = await getColumns(
          "table_name=product_mapping_set_all_fields"
        )();
        setsetAllPopUpFields(columnsResp);

        if (isEmpty(props.productMappingModifyFilterDashboardConfiguration)) {
          let response = await fetchFilterFieldValues("store mapping");
          const filterConfigData = [
            {
              filterDashboardData: response,
              expectedFilterDimensions: ["store"],
              screenDimension: "store",
              isCrossDimensionFilter: false,
            },
          ];
          const filterConfig = formattedFilterConfiguration(
            "productMappingModifyFilterConfiguration",
            filterConfigData,
            "Product Mapping Modify"
          );
          props.setFilterConfiguration(filterConfig);
        } else {
          setDependency(
            props.productMappingModifyFilterDashboardConfiguration
              .appliedFilterData.dependencyData
          );
        }
        setloader(false);
      } catch (error) {
        console.log(error);
      }
    };

    getInitialData();
  }, []);
  /**
   * @func
   * @desc Return structure object to utilize as filter options
   * @param {Array} data Array of Objects
   * @returns {Array}
   */
  const generateFilterValues = (data) => {
    const filterValues = data.map((item) => {
      return {
        label: item.name,
        value: item.sg_code,
      };
    });
    return filterValues;
  };

  /**
   * @function
   * @description Set all states on save request.
   */
  const saveRequest = () => {
    if (
      (storeRadioType === "store" &&
        (storeData.length || editOrDeleteEditedAPIPayload.length)) ||
      (storeRadioType === "store_groups" && !isEmpty(storeGroupDatesAPIPayload))
    ) {
      setShowModal(true);
    } else {
      displaySnackMessages("There are no changes to save", "warning");
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const checkEditedRow = (arr) => {
    return arr.map((item) => {
      let editedRow = editedStoresData.filter(
        (row) => row.store_code === item.store_code
      );
      return editedRow.length > 0 ? editedRow[0] : item;
    });
  };

  const updateProductsData = async (body, pageIndex) => {
    let queryParams = pageIndex ? `?page=${pageIndex + 1}` : "";
    body = { ...body };
    body.meta.limit = { limit: 10, page: pageIndex + 1 };
    let storesRespObj = {};
    if (props.selectedDimension === "product") {
      const { data: stores } = await getAllProductStores(body, queryParams)();
      storesRespObj = stores;
    } else {
      const { data: stores } = await getAllProductGroupStores(
        body,
        queryParams
      )();
      storesRespObj = stores;
    }
    storesRespObj.data = storesRespObj.data.map((item) => {
      item.include_unmapped =
        item.num_products_mapped.split("/")[0] ===
        item.num_products_mapped.split("/")[1];
      item.is_calendar_disabled = true;
      //we disable the include unmapped as there are no include unmapped to perform
      item.is_include_unmapped_disabled =
        item.num_products_mapped.split("/")[0] ===
        item.num_products_mapped.split("/")[1];
      return item;
    });

    if (editedStoresData.length > 0) {
      storesRespObj.data = checkEditedRow(storesRespObj.data);
    }
    setDefaultRowData([...defaultRowData, ...storesRespObj.data]);
    setloader(false);
    return {
      data: storesRespObj.data,
      total: storesRespObj.total,
    };
  };

  /**
   * @func
   * @desc Update Chips dependency after Filter click and force update table
   */
  const onClickFilter = () => {
    modifyTableRef.current.api.deselectAll(true);
    modifyTableRef.current.api.refreshServerSideStore({ purge: true });
  };

  const onConfirm = async () => {
    if (storeRadioType === "store_groups") {
      storeGroupSave();
      return;
    }
    setLoaderActive(true);
    try {
      if (storeData.length > 0 || editOrDeleteEditedAPIPayload.length > 0) {
        if (!isEmpty(conflictProdMappingData)) {
          displaySnackMessages(
            "Please resolve the overlapping dates before saving",
            "error"
          );
          setloader(false);
          return;
        }
        if (props.selectedDimension === "product") {
          let [
            individualMappedObjects,
            individualUnMappedObjects,
          ] = splitMappedAndUnmappedData(storeData, "product_mapping");

          let includeUnmappedObjects = storeData.filter(
            (data) => data.include_unmapped
          );
          individualMappedObjects = prepareCreatePayload(
            [...individualMappedObjects, ...includeUnmappedObjects],
            props.selectedProducts,
            "product_mapping"
          );
          includeUnmappedObjects = individualMappedObjects.filter(
            (data) => data.include_unmapped
          );
          individualMappedObjects = individualMappedObjects.filter(
            (data) => !data.include_unmapped && data.select.length > 0
          );
          // this gives an object with dates as keys
          const payloadResp = individualMappedObjects.reduce(
            (payload, store) => {
              const date =
                store.valid_from +
                "+" +
                store.valid_to +
                "+" +
                store.store_code;
              if (!payload[date]) {
                payload[date] = [];
              }
              payload[date].push(store.select[0]);
              return payload;
            },
            {}
          );
          // Edit: to add it in the array format instead
          const storesArray = Object.keys(payloadResp).map((date) => {
            const KeyArr = date.split("+");
            return {
              store_code: KeyArr[2], //grp id
              include_unmapped: false,
              map: true,
              select: payloadResp[date], //["1900", "100"]
              unselect: [],
              valid_from: KeyArr[0], //from date
              valid_to: KeyArr[1], //to date
            };
          });
          if (storesArray.length > 0 || includeUnmappedObjects.length > 0) {
            let body = {
              stores: [...includeUnmappedObjects, ...storesArray],
              products: props.selectedProducts.map((item) => item.product_code),
              action: "conflict_combine",
            };
            await mapProductToStore(body)();
          }
          if (
            editOrDeleteEditedAPIPayload.length > 0 ||
            individualUnMappedObjects.length > 0
          ) {
            let body = {
              body: [
                ...parseMappings([...editOrDeleteEditedAPIPayload]),
                ...individualUnMappedObjects,
              ],
            };
            await props.editOrDeleteAPI(body);
          }
          setShowModal(false);
          displaySnackMessages("Products mapped successfully", "success");
          props.goBack();
        } else {
          let body = {
            stores: storeData,
            product_groups: props.selectedProducts.map((item) => item.pg_code),
          };
          await mapProductGroupToStore(body)();
          setShowModal(false);
          displaySnackMessages("Products mapped successfully", "success");
        }
        setStoreData([]);
      } else {
        displaySnackMessages("There are no changes to save", "warning");
      }
      setEditedStoresData([]);
      setLoaderActive(false);
    } catch (err) {
      setShowModal(false);
      displaySnackMessages("Something went wrong while modifying", "error");
      setLoaderActive(false);
    }
  };

  const onApplyExceptions = async (exceptions) => {
    setloader(true);
    let productBody = [];
    Object.keys(exceptions).forEach((key) => {
      const storecodes = exceptions[key];
      storecodes.forEach((store_code) => {
        let body = {
          product_code: key,
          store_code: store_code,
          valid_from: null,
          valid_to: null,
        };
        productBody.push(body);
      });
    });

    let postReq = {
      body: productBody,
    };

    await props.editOrDeleteAPI(postReq);
    setShowModal(false);
    displaySnackMessages("Product(s) unmapped successfully");
    onClickFilter();
    setloader(false);

    updateShowException(false);
  };

  /**
   * This function checks for common mapped stores presence and opens the pop up
   * if there are common mapped stores or else throws an error saying no common stores present
   */
  const openSetAllPopUp = () => {
    setcommonSetAllProducts(
      props.selectedProducts.map((product) => product.product_code)
    );
    setshowSetAllPopUp(true);
  };

  /**
   * Close the Time bound Dialog box
   */
  const onTimeBoundDialogClose = () => {
    setdisplayTimeBoundMappingDialog(false);
  };

  const onClickStoreGroupsSetDates = () => {
    if (isEmpty(selectedStoreGroupObjects)) {
      displaySnackMessages("Please select atleast one Store Group", "error");
      return;
    }
    setshowSetAllPopUp(true);
  };

  const getDefaultStoreGroupPayload = (startDate, endDate) => {
    return {
      store_groups: [],
      products: props.selectedProducts.map((product) => product.product_code),
      valid_from: startDate,
      valid_to: endDate,
    };
  };
  /**
   *
   * @param {*} storeGroupsPayload
   * clear the exisitng store group payload and make the time period node values to null
   */
  const clearExisitingStoreGroups = (storeGroupsPayload) => {
    if (!isEmpty(storeGroupsPayload)) {
      const exisitngStoreGroups = storeGroupsPayload.store_groups;
      exisitngStoreGroups.forEach((groupId) => {
        const rowNode = modifyStoreGroupsTableRef.current.api.getRowNode(
          groupId + ""
        );
        updateCellValueData("time_period", null, rowNode);
      });
      setStoreGroupDatesAPIPayload({});
    }
  };

  /**
   *
   * @param {Node} rowNode
   * @param {string} startDate
   * @param {string} endDate
   *
   * this function updates the row node values to start date and end date passed with default date format
   */
  const updateStoreGroupRowNodeDate = (rowNode, startDate, endDate) => {
    updateCellValueData(
      "time_period",
      `${changeDateStringToOtherFormat(
        startDate,
        "YYYY-MM-DD",
        DEFAULT_DATE_FORMAT
      )} to ${changeDateStringToOtherFormat(
        endDate,
        "YYYY-MM-DD",
        DEFAULT_DATE_FORMAT
      )}`,
      rowNode
    );
  };
  /**
   *
   * @param {*} values
   */
  const storeGroupSetAllApply = (values) => {
    const params = modifyStoreGroupsTableRef.current;
    //clear the exisiting records
    clearExisitingStoreGroups(storeGroupDatesAPIPayload);
    let rowNodes = params.api.getSelectedNodes();
    const startDate = values[0].start_date;
    const endDate = values[0].end_date;
    let newUpdatedDatesPayload = getDefaultStoreGroupPayload(
      startDate,
      endDate
    );
    //after clearing, update the row node time period with the start date and end date selected
    rowNodes.forEach((rowNode) => {
      newUpdatedDatesPayload.store_groups.push(rowNode.data.sg_code);
      updateStoreGroupRowNodeDate(rowNode, startDate, endDate);
    });
    params.api.flashCells({ rowNodes });
    setStoreGroupDatesAPIPayload(newUpdatedDatesPayload);
    setStoreGroupStartDate(startDate);
    setStoreGroupEndDate(endDate);
  };

  const storeGroupSave = async () => {
    try {
      setloader(true);
      await props.mapProductsToStoreGroup(storeGroupDatesAPIPayload);
      setloader(false);
      setShowModal(false);
      displaySnackMessages("Products mapped successfully", "success");
      props.goBack();
    } catch (error) {
      setloader(false);
      setShowModal(false);
      displaySnackMessages("Failed to map store groups to products", "error");
    }
  };

  const renderContent = () => {
    let productIdText;
    if (props.selectedDimension === "product") {
      productIdText = props.selectedProducts.map((item) => item.product_code);
      productIdText =
        props.selectedProducts.length > 3
          ? productIdText.slice(0, 3).join(" , ")
          : productIdText.join(" , ");
    } else {
      productIdText = props.selectedProducts.map((item) => item.pg_code);
      productIdText =
        props.selectedProducts.length > 3
          ? productIdText.slice(0, 3).join(" , ")
          : productIdText.join(" , ");
    }
    const getPromptStatus = (loc) => {
      const message = `Are you sure you want to go to ${loc.pathname}?`;
      if (storeData.length !== 0 || editOrDeleteEditedAPIPayload.length !== 0) {
        return message;
      }
      return true;
    };

    const onSetAllBtnClick = () => {
      if (storeRadioType === "store" && isEmpty(selectedStoreObjects)) {
        displaySnackMessages("Please select atleast one Store(s)", "error");
        return;
      }
      if (
        (storeRadioType === "store" && storeData.length > 0) ||
        editOrDeleteEditedAPIPayload.length > 0
      ) {
        showsetAllConfirmBox(true);
        return;
      }
      openSetAllPopUp();
    };

    const onFilterDashboardClick = (dependencyData) => {
      setDependency(dependencyData);
      onClickFilter();
    };

    return (
      <>
        <Container maxWidth={false}>
          <div
            className={`${globalClasses.marginTop}`}
            data-testid="filterContainer"
          >
            <Typography variant="body1" paragraph={true}>
              <Typography component="span" className={classes.labelText}>
                {" "}
                {props.selectedDimension === "product"
                  ? `Selected ${dynamicLabelsBasedOnTenant("product", "core")}`
                  : "Selected Product Group "}
                -
              </Typography>
              <Typography
                component="span"
                className={`${classes.labelText} ${globalClasses.marginLeft1rem}`}
              >
                {props.selectedDimension === "product"
                  ? `${dynamicLabelsBasedOnTenant("product", "core")} ID`
                  : "Product group ID"}
                : {""}
                <Typography
                  component="span"
                  className={`${classes.fieldText} ${globalClasses.marginHorizontal}`}
                >
                  {`${productIdText}`}
                </Typography>
                {props.selectedProducts.length > 3 && (
                  <Typography component="span" className={classes.extraLength}>
                    {`+${props.selectedProducts.length - 3}`}{" "}
                  </Typography>
                )}
              </Typography>
            </Typography>
            <CoreComponentScreen
              showFilterDashboard={true}
              filterConfigKey={"productMappingModifyFilterConfiguration"}
              onApplyFilter={onFilterDashboardClick}
            />

            {confirmBox && (
              <ConfirmBox
                onClose={() => showConfirmBox(false)}
                onConfirm={() => {
                  props.goBack();
                  showConfirmBox(false);
                }}
              />
            )}
            {setAllConfirmBox && (
              <ConfirmBox
                onClose={() => showsetAllConfirmBox(false)}
                onConfirm={() => {
                  setStoreData([]);
                  seteditOrDeleteEditedAPIPayload([]);
                  onClickFilter();
                  showsetAllConfirmBox(false);
                }}
              />
            )}
            <Dialog
              open={showModal}
              className={globalClasses.dialogConfirmBox}
              onClose={() => {
                if (!isLoaderActive) {
                  setShowModal(false);
                }
              }}
              id={"routePrompt"}
            >
              <Loader loader={isLoaderActive}>
                <DialogContent className={globalClasses.minHeightBody}>
                  <div className={globalClasses.dialogTitle}>
                    {" "}
                    Update Changes
                  </div>
                  <div className={globalClasses.dialogText}>
                    Are you sure to update all changes ?
                  </div>
                </DialogContent>
                <DialogActions className={globalClasses.dialogActionBox}>
                  <Button
                    id="routePromptYes"
                    onClick={() => setShowModal(false)}
                    color="primary"
                    autoFocus
                  >
                    Close
                  </Button>
                  <Button
                    id="routePromptYes"
                    onClick={() => onConfirm()}
                    color="primary"
                    autoFocus
                  >
                    Update
                  </Button>
                </DialogActions>
              </Loader>
            </Dialog>
            {showException && (
              <Exceptions
                filterDependency={filterDependency}
                fields={exceptionsFields}
                selectedProducts={props.selectedProducts}
                onApply={onApplyExceptions}
                toggleError={(errMsg) => {
                  displaySnackMessages(errMsg, "error");
                }}
                handleModalClose={() => {
                  updateShowException(false);
                }}
              ></Exceptions>
            )}
          </div>

          <Loader loader={showloader || props.fetchIdsLoader}>
            <div data-testid="resultContainer">
              <Paper elevation={0}>
                <div
                  className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter}`}
                >
                  <div>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="controlled-radio-buttons-group"
                      value={storeRadioType}
                      onChange={(event) =>
                        setStoreRadioType(event.target.value)
                      }
                    >
                      <FormControlLabel
                        value="store"
                        control={<Radio color="primary" id="StoreRadioBtn" />}
                        label="Store"
                      />
                      <FormControlLabel
                        value="store_groups"
                        control={
                          <Radio color="primary" id="StoreGroupRadioBtn" />
                        }
                        label="Store groups"
                      />
                    </RadioGroup>
                    <Typography variant="h6" gutterBottom>
                      Review Status
                    </Typography>
                  </div>
                  <div>
                    {storeRadioType === "store_groups" && (
                      <Button
                        variant="contained"
                        color="primary"
                        id="storeGroupsSetDatesBtn"
                        onClick={onClickStoreGroupsSetDates}
                      >
                        Set Dates
                      </Button>
                    )}
                    {storeRadioType === "store" && (
                      <Button
                        variant="contained"
                        color="primary"
                        id="productSetAllBtn"
                        onClick={onSetAllBtnClick}
                      >
                        Set All
                      </Button>
                    )}
                    {storeRadioType === "store" && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={globalClasses.marginLeft1rem}
                        onClick={() => {
                          updateShowException(true);
                        }}
                      >
                        Add Exceptions
                      </Button>
                    )}
                  </div>
                </div>

                <SetAllComponent
                  ref={modifyTableRef}
                  screenName={
                    storeRadioType === "store"
                      ? "product_mapping"
                      : "product_mapping_store_group"
                  }
                  showSetAllPopUp={showSetAllPopUp}
                  commonSetAllStores={commonSetAllProducts}
                  selectedProducts={props.selectedProducts}
                  setAllPopUpFields={setAllPopUpFields}
                  setshowSetAllPopUp={setshowSetAllPopUp}
                  {...(storeRadioType === "store" && {
                    selectedStoreObjects: selectedStoreObjects,
                  })}
                  {...(storeRadioType === "store_groups" && {
                    selectedStoreGroupObjects: selectedStoreGroupObjects,
                  })}
                  onClickFilter={async () => {
                    onClickFilter();
                  }}
                  storeGroupSetAllApply={storeGroupSetAllApply}
                  filterDependency={filterDependency}
                />

                {displayTimeBoundMappingDialog && (
                  <TimeBoundDialog
                    ref={modifyTableRef}
                    screenName="product_mapping"
                    displayDialog={displayTimeBoundMappingDialog}
                    selectedProducts={props.selectedProducts}
                    onCloseDialog={onTimeBoundDialogClose}
                    selectedStoreObjects={selectedStoreObjects}
                    editedAPIPayload={storeData}
                    setEditedAPIPayload={setStoreData}
                    setdisplayTimeBoundMappingDialog={
                      setdisplayTimeBoundMappingDialog
                    }
                    editOrDeleteEditedAPIPayload={editOrDeleteEditedAPIPayload}
                    seteditOrDeleteEditedAPIPayload={
                      seteditOrDeleteEditedAPIPayload
                    }
                    setAllPopUpFields={setAllPopUpFields}
                    modifyTableLoader={showloader}
                    conflictObject={conflictProdMappingData}
                    updateConflictObject={setconflictProdMappingData}
                  />
                )}
                {storeRadioType === "store_groups" && (
                  <ModifyStoreGroupsTable
                    ref={modifyStoreGroupsTableRef}
                    filterDependency={filterDependency}
                    setSelectedStoreGroupObjects={setSelectedStoreGroupObjects}
                    setStoreGroupDatesAPIPayload={setStoreGroupDatesAPIPayload}
                    storeGroupDatesAPIPayload={storeGroupDatesAPIPayload}
                    clearExisitingStoreGroups={clearExisitingStoreGroups}
                    selectedProducts={props.selectedProducts}
                    getDefaultStoreGroupPayload={getDefaultStoreGroupPayload}
                    defaultStartDate={storeGroupStartDate}
                    defaultEndDate={storeGroupEndDate}
                    updateStoreGroupRowNodeDate={updateStoreGroupRowNodeDate}
                  />
                )}
                {storeRadioType === "store" && (
                  <ModifyTable
                    ref={{ modifyTableRef: modifyTableRef }}
                    screenName={"product_mapping"}
                    fetchData={updateProductsData}
                    filterDependency={filterDependency}
                    selectedProducts={props.selectedProducts}
                    setloader={setloader}
                    selectedDimension={props.selectedDimension}
                    updateProductsData={updateProductsData}
                    selectedStoreObjects={selectedStoreObjects}
                    toggleTimeBoundDialogView={setdisplayTimeBoundMappingDialog}
                    setselectedStoreObjects={setselectedStoreObjects}
                    defaulttableData={defaultRowData}
                    editedAPIPayload={storeData}
                    setEditedAPIPayload={setStoreData}
                    displayTimeBoundMappingDialog={
                      displayTimeBoundMappingDialog
                    }
                    editOrDeleteEditedAPIPayload={editOrDeleteEditedAPIPayload}
                    seteditOrDeleteEditedAPIPayload={
                      seteditOrDeleteEditedAPIPayload
                    }
                    conflictObject={conflictProdMappingData}
                    updateConflictObject={setconflictProdMappingData}
                  />
                )}
              </Paper>
            </div>
            <div
              className={`${globalClasses.centerAlign} ${globalClasses.evenPaddingAround}`}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => saveRequest()}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                className={globalClasses.marginLeft1rem}
                onClick={() => {
                  if (storeData.length) {
                    showConfirmBox(true);
                  } else {
                    props.goBack();
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </Loader>
        </Container>
      </>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
}

const mapStateToProps = (store) => {
  return {
    productMappingModifyFilterDashboardConfiguration:
      store.filterReducer.filterDashboardConfiguration[
        "productMappingModifyFilterConfiguration"
      ],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validateProductMappingTimeBounds: (payload) =>
      dispatch(validateProductMappingTimeBounds(payload)),
    editOrDeleteAPI: (payload) => dispatch(editOrDelete(payload)),
    addSnack: (snackObj) => dispatch(addSnack(snackObj)),
    mapProductsToStoreGroup: (body) => dispatch(mapProductsToStoreGroup(body)),
    setFilterConfiguration: (body) => dispatch(setFilterConfiguration(body)),
    fetchAllStoreCodes,
    fetchAllStoreGroups,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFilter);
