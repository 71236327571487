import React from "react";
import withStyles from "@mui/styles/withStyles";
import Switch from "@mui/material/Switch";

const SwitchButton = withStyles(() => ({}))(({ ...props }) => {
  return (
    <Switch
      disableRipple
      checked={props.defaultChecked}
      color="primary"
      {...props}
      onChange={(event) => props.onChange(event)}
    />
  );
});
export default SwitchButton;
