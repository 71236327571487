import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTransaction } from "@elastic/apm-rum-react";
import { addSnack } from "actions/snackbarActions";
import Loader from "Utils/Loader/loader";

import {
  getRolesMappedToUserData,
  setRolesMappedToUserData,
  getFilterConfiguration,
  setFilterConfiguration,
  getUserFilterAttributes,
  setUserRoleMgmtLoader,
  getTableConfig,
  getFilteredAttributesToAssignRole,
  deleteRolesAssigned,
  clearTableHierarchyOnAssignRole,
  clearUserRoleConfigStates,
} from "../../services/TenantManagement/User-Role-Management/user-role-management-service";
import FilterHeader from "./filter-header";
import ManageUserRoleDashboardTable from "./manage-user-role-dashboard-table";

const UserRoleConfig = (props) => {
  useEffect(() => {
    props.clearTableHierarchyOnAssignRole();
    return () => {
      props.clearUserRoleConfigStates();
    };
  }, []);

  return (
    <Loader loader={props.loaderUserRoleMgmt}>
      <FilterHeader
        setUserRoleMgmtLoader={props.setUserRoleMgmtLoader}
        getFilterConfiguration={props.getFilterConfiguration}
        setFilterConfiguration={props.setFilterConfiguration}
        filtersList={props.filtersList}
        getUserFilterAttributes={props.getUserFilterAttributes}
        getRolesMappedToUserData={props.getRolesMappedToUserData}
        setRolesMappedToUserData={props.setRolesMappedToUserData}
        addSnack={props.addSnack}
        getFilteredAttributesToAssignRole={
          props.getFilteredAttributesToAssignRole
        }
      />
      <ManageUserRoleDashboardTable
        getRolesMappedToUserData={props.getRolesMappedToUserData}
        setRolesMappedToUserData={props.setRolesMappedToUserData}
        roleTableData={props.roleTableData}
        setUserRoleMgmtLoader={props.setUserRoleMgmtLoader}
        getTableConfig={props.getTableConfig}
        addSnack={props.addSnack}
        deleteRolesAssigned={props.deleteRolesAssigned}
        clearTableHierarchyOnAssignRole={props.clearTableHierarchyOnAssignRole}
      />
    </Loader>
  );
};

const mapStateToProps = (store) => {
  const { tenantUserRoleMgmtReducer } = store;
  return {
    loaderUserRoleMgmt:
      tenantUserRoleMgmtReducer.userRoleManagementReducer.loaderUserRoleMgmt,
    filtersList:
      tenantUserRoleMgmtReducer.userRoleManagementReducer.filtersList,
    roleTableData:
      tenantUserRoleMgmtReducer.userRoleManagementReducer.userRoleTableData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (body) => dispatch(addSnack(body)),
    setUserRoleMgmtLoader: (body) => dispatch(setUserRoleMgmtLoader(body)),
    getRolesMappedToUserData: (body) =>
      dispatch(getRolesMappedToUserData(body)),
    setRolesMappedToUserData: (body) =>
      dispatch(setRolesMappedToUserData(body)),
    getFilterConfiguration: () => dispatch(getFilterConfiguration()),
    setFilterConfiguration: (body) => dispatch(setFilterConfiguration(body)),
    getUserFilterAttributes: (body) => dispatch(getUserFilterAttributes(body)),
    getTableConfig: (body) => dispatch(getTableConfig(body)),
    getFilteredAttributesToAssignRole: (body) =>
      dispatch(getFilteredAttributesToAssignRole(body)),
    deleteRolesAssigned: (body) => dispatch(deleteRolesAssigned(body)),
    clearTableHierarchyOnAssignRole: (body) =>
      dispatch(clearTableHierarchyOnAssignRole(body)),
    clearUserRoleConfigStates: (body) =>
      dispatch(clearUserRoleConfigStates(body)),
  };
};

export default withTransaction(
  "UserRoleConfig",
  "component"
)(connect(mapStateToProps, mapDispatchToProps)(UserRoleConfig));
