import { GRAPH_MENU_LIST } from "./constants";

export const multiLineChartOptions = (props) => {
  return {
    chart: {
      type: "line",
    },
    title: {
      useHTML: props.chartTitle ? false : true,
      text: props.chartTitle ? props.chartTitle : "&nbsp",
    },
    xAxis: {
      title: {
        text: props.axisLegends.xaxis.title,
        style: {
          fontSize: "14px",
        },
      },
      categories: props.axisLegends.xaxis.categories,
      crosshair: props.axisLegends.xaxis.crosshair || false
    },
    yAxis: {
      title: {
        text: props.axisLegends.yaxis.title,
        style: {
          fontSize: "14px",
        },
      },
    },
    legend: {
      itemStyle: {
        fontSize: "14px",
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          format: props.labelFormatter || null 
        },
      },
    },
    exporting: GRAPH_MENU_LIST,
    series: props.series.map((element) => {
      return {
        ...element,
        marker: {
          symbol: "circle",
        },
        dataLabels: {
          formatter: function () {
            return props.isBudgetLabel
              ? this.key + "-" + this.y + "k"
              : this.key + "-" + this.y;
          },
          color: "Black",
          distance: 30,
        },
      };
    }),
    tooltip: props?.tooltip || {} 
  };
};
