export const PLAN_SMART_PRE_SEASON_DASHBOARD = "/plan-smart/pre-season";
export const PLAN_CREATE_NEW_PLAN = "/plan-smart/create-new-plan";
export const PLAN_SMART_PLAN_DETAILS = "/plan-smart/plan";
export const PLAN_SMART_MASTER_PLAN = "/plan-smart/master-plan";
export const PLAN_SMART_REPORT = "/plan-smart/report";
export const PLAN_SMART_IN_SEASON_DASHBOARD = "/plan-smart/in-season";

export const PRE_SEASON = "/plan-smart/pre-season";
export const REPORTS = "/plan-smart/report";
export const IN_SEASON = "/plan-smart/in-season";
export const BUSINESS_PERFORMANCE = "/plan-smart/business-performance";
export const TRACKING = "/track-smart/tracking";
export const ALRT_CONFIGURATION = "/track-smart/alrt-config";
