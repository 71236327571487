import React from "react";

import globalStyles from "Styles/globalStyles";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";

const OrderManagementPage = () => {
  const globalClasses = globalStyles();

  const homeIcon = [
    {
      label: "Order management",
      id: 1,
    },
  ];

  return (
    <>
      <HeaderBreadCrumbs options={homeIcon}></HeaderBreadCrumbs>
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "100%" }}
          // data={`https://bkp.products.impactsmartsuite.com${window.location.pathname}`}
          data={`https://sandbox.inventory.impactsmartsuite.com/inventory-smart/order-management?queryPath=${window.location.pathname}`}
        ></object>
      </div>
    </>
  );
};

export default OrderManagementPage;
