import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import CloseIcon from "@mui/icons-material/Close";
import ConfirmBox from "../../../Utils/reactTable/components/confirmPopup";
import SuccessBox from "../../../Utils/reactTable/components/successPopup";
import Form from "../../../Utils/form";
import Loader from "../../../Utils/Loader/loader";
import { getProductStatusData } from "../../../actions/productStoreStatusActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "500px",
      //   maxHeight:'50vh',
      borderRadius: "0.6rem",
    },
  },
  newException: {
    marginTop: "2rem",
  },
  subHeader: {
    paddingTop: "0.8rem",
    paddingLeft: "1.28rem",
  },
  confirmBox: {
    "& .MuiDialog-paper": {
      borderRadius: "10px 10px 6px 6px",
    },
  },
}));

function ExceptionList(props) {
  const classes = useStyles();
  const [confirmBox, showConfirmBox] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [formFields, setformFields] = useState(props.fields);
  const [defaultFields, setDefaultFields] = useState(props.fields);
  const [successBox, showSuccessBox] = useState(false);
  const [flagEdit, setFlagEdit] = useState(false);
  const [counter, setCounter] = useState(2);
  const handleChange = (data) => {
    setFormData(data);
    if (!flagEdit) {
      setFlagEdit(true);
    }
  };

  const getStoreName = (storeInfo) => {
    //If exceptions is happening at a storegroup level,
    //From the previous dashboard, we only get the ids present in the store group
    //To get the labels, we need to make use of ref-stores api result
    //The above result data is being passed as allStoresInfo to the component
    if (
      typeof storeInfo === "object" &&
      storeInfo !== null &&
      storeInfo.label
    ) {
      return storeInfo.store_name;
    } else {
      const currentStore = props.allStoresInfo.filter(
        (store) => store.value === storeInfo.store_code
      );
      if (currentStore.length > 0) {
        return currentStore[0].label;
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      let body = {
        filters: [],
        meta: {
          range: [],
          sort: [],
          search: [],
          limit: { limit: 1000, page: 1 },
        },
        headers: [],
      };

      const { data: details } = await getProductStatusData("product", body)();
      let options = details.data.map((item) => {
        return {
          label: item.product_name,
          id: item.product_code,
          value: item.product_code,
        };
      });
      const storeOptions = props.selectedStoreOrGrps.map((item) => {
        return {
          value: item.store_code,
          id: item.store_code,
          label: getStoreName(item),
        };
      });

      let fields = [...formFields];
      fields[0].options = options;
      fields[1].options = storeOptions;
      setformFields(fields);
      setDefaultFields(fields);
      setLoading(false);
    };
    fetchData();
  }, []);
  const onCancel = () => {
    if (flagEdit) {
      showConfirmBox(true);
    } else {
      props.handleModalClose();
    }
  };

  const onApply = async () => {
    try {
      let requiredFieldsError = false;
      [...formFields].forEach((item) => {
        if (item.required && !formData[item.accessor]) {
          requiredFieldsError = true;
        }
      });
      if (requiredFieldsError) {
        props.toggleError("Please Enter the data in all the required fields");
        requiredFieldsError = false;
      } else {
        let finalExceptions = {};

        formData[`select_product`].forEach((item) => {
          finalExceptions[item] = formData[`select_store`];
        });

        for (let i = 2; i < counter; i++) {
          formData[`select_product-${i}`].forEach((item) => {
            finalExceptions[item] = finalExceptions[item]
              ? [...finalExceptions[item], ...formData[`select_store-${i}`]]
              : formData[`select_store-${i}`];
          });
        }
        let closeModal = await props.onApply(finalExceptions);
        requiredFieldsError = false;
        if (closeModal) {
          showSuccessBox(true);
          props.handleModalClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addExceptions = () => {
    let newException = JSON.parse(JSON.stringify(defaultFields));
    newException = newException.map((item) => {
      item.accessor = `${item.accessor}-${counter}`;
      return item;
    });
    let fields = [...formFields, ...newException];
    setCounter((old) => old + 1);
    setformFields(fields);
  };
  const getDefaultValues = () => {
    let defaultValues = {};
    props.fields.forEach((item) => {
      defaultValues[item.accessor] = "";
    });
    return defaultValues;
  };

  return (
    <Dialog
      onClose={() => onCancel()}
      className={classes.root}
      maxWidth={"sm"}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth={true}
      disableEscapeKeyDown={true}
    >
      {confirmBox && (
        <ConfirmBox
          onClose={() => showConfirmBox(false)}
          onConfirm={() => {
            showConfirmBox(false);
            props.handleModalClose();
          }}
        />
      )}
      {successBox && (
        <SuccessBox
          onClose={() => showSuccessBox(false)}
          onConfirm={() => {
            showSuccessBox(false);
            props.handleModalClose();
          }}
        />
      )}
      <Loader loader={loading}>
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            Exception List
            <IconButton
              aria-label="close"
              onClick={() => onCancel()}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Form
            maxFieldsInRow={1}
            handleChange={handleChange}
            fields={formFields}
            updateDefaultValue={true}
            defaultValues={getDefaultValues}
          ></Form>
          <Button
            className={classes.newException}
            variant="contained"
            onClick={() => {
              addExceptions();
            }}
            color="primary"
          >
            + Add Exception
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            id="exceptionCancelBtn"
            onClick={() => {
              onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onApply}
            id="exceptionSaveBtn"
            color="primary"
          >
            Apply
          </Button>
        </DialogActions>
      </Loader>
    </Dialog>
  );
}

export default ExceptionList;
