import React from "react";

export const Pagination = ({
  page,
  pageIndex,
  pageSize,
  data,
  pageCount,
  gotoPage,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  setPageSize,
  totalCount,
  hidePageSelection,
  style,
}) => {
  return (
    <div style={style} className="pagination-container">
      <div className="pagination-text">
        <span>
          Showing
          <strong>
            {" "}
            {pageIndex * pageSize + 1} to {pageIndex * pageSize + page.length}{" "}
            of {totalCount ? totalCount : data.length}
          </strong>{" "}
        </span>
      </div>
      <div className="pagination-btns">
        <button
          className="btn btn-style"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="btn btn-style"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        <button
          className="btn btn-style"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>
        <button
          className="btn btn-style"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
      </div>
      {!hidePageSelection && (
        <div className="col-md-2 pagination-slct-pgsize">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};
