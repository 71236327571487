import { createSelector, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../Utils/axios";
import {
  GET_PLAN_DETAILS,
  GET_CREATED_PLANS,
  DELETE_PLAN,
  CREATE_PLAN,
  GET_PLAN_LEVELS,
  DOWNLOAD_BUY_ROLLUP,
  GET_DASHBOARD_FILTERS,
  GET_DASHBOARD_PLANS_TABLE_CONFIG,
  COPY_ASSORT_PLAN,
  FINALIZE_FOR_PO,
  GET_SEASON_YEAR,
  IMPORT_CLUSTER_PLAN,
  GET_SEASON_OPTIONS,
  OMNI_HINDSIGHT_DASHBOARD_TABLE_CONFIG,
  DASHBOARD_FILTER,
} from "modules/react-demo/constants/apiConstants";
export const dashboardService = createSlice({
  name: "dashboardService",
  initialState: {
    planDetails: [],
    dashboard_loader: false,
    plansTableData: [],
    plansTotalCount: 0,
    plansTableCols: [],
    hindsightTableCols: [],
    planLevels: [],
    levelsJson: {},
    columnHeaderJson: {},
    dashboardFilters: [],
    bopTagData: [],
    import_cluster_loader: false,
    importCluster: false,
  },
  reducers: {
    setPlanDetails: (state, action) => {
      state.planDetails = action.payload;
    },
    setDashboardLoader: (state, action) => {
      state.dashboard_loader = action.payload;
    },
    setPlansData: (state, action) => {
      state.plansTableData = action.payload.data;
      state.plansTotalCount = action.payload.count;
    },
    setPlansTableCols: (state, action) => {
      state.plansTableCols = action.payload;
    },
    setHindsightTableCols: (state, action) => {
      state.hindsightTableCols = action.payload;
    },
    setPlanLevels: (state, action) => {
      state.planLevels = action.payload;
    },
    setLevelsJson: (state, action) => {
      state.levelsJson = action.payload;
    },
    setColumnHeaderJson: (state, action) => {
      state.columnHeaderJson = action.payload;
    },
    setDashboardFilters: (state, action) => {
      state.dashboardFilters = action.payload;
    },
    setBopTagData: (state, action) => {
      state.bopTagData = action.payload;
    },
    setImportClusterLoader: (state, action) => {
      state.import_cluster_loader = action.payload;
    },
    setImportClusterData: (state, action) => {
      state.importCluster = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPlanDetails,
  setLoader,
  setPlansData,
  setPlansTableCols,
  setHindsightTableCols,
  setPlanLevels,
  setLevelsJson,
  setColumnHeaderJson,
  setBopTagData,
  setImportClusterLoader,
  setDashboardLoader,
  setImportClusterData,
} = dashboardService.actions;
export const getPlanDetails = (planCode) => () => {
  return axiosInstance({
    url: `${GET_PLAN_DETAILS}/` + planCode,
    method: "GET",
  });
};
//API to fetch Plans Table Data in Dashboard
export const fetchAssortDashboardTableData = (
  body,
  page = 0,
  limit = 10
) => () => {
  return axiosInstance({
    url: `${GET_CREATED_PLANS}?page=${page + 1}&limit=${limit}`,
    method: "POST",
    data: body,
  });
};
export const deletePlans = (body) => () => {
  return axiosInstance({
    url: `${DELETE_PLAN}`,
    method: "POST",
    data: body,
  });
};
//API to create a new Plan
export const createPlanAPI = (body) => () => {
  return axiosInstance({
    url: CREATE_PLAN,
    method: "POST",
    data: body,
  });
};

//API to download a rolled-up version of the plan
export const downloadBuyRollupData = (body) => () => {
  return axiosInstance({
    url: DOWNLOAD_BUY_ROLLUP,
    method: "POST",
    data: body,
  });
};

//API to retrieve all the hierarchy levels required for filters
export const getPlanLevels = () => () => {
  return axiosInstance({
    url: GET_PLAN_LEVELS,
    method: "GET",
  });
};

//API to retrieve the dashboard filters
export const getDashboardFilters = () => () => {
  return axiosInstance({
    url: GET_DASHBOARD_FILTERS,
    method: "GET",
  });
};

//API to retrieve the table config of dashboard
export const getDashboardPlansTableConfig = () => () => {
  return axiosInstance({
    url: GET_DASHBOARD_PLANS_TABLE_CONFIG,
    method: "GET",
  });
};

//API to retrive the table config of hindsight/omni dashboard
export const getTableConfig = (pathname) => {
  return axiosInstance({
    url:
      pathname === "hindsight"
        ? `${OMNI_HINDSIGHT_DASHBOARD_TABLE_CONFIG}/hindsight-dashboard`
        : pathname === "clusterdashboard"
        ? `${OMNI_HINDSIGHT_DASHBOARD_TABLE_CONFIG}/cluster-plan-dashboard`
        : `${OMNI_HINDSIGHT_DASHBOARD_TABLE_CONFIG}/omni-dashboard`,
    method: "GET",
  });
};

//API to copy assort plan
export const copyAssortPlan = (copyBody) => () => {
  return axiosInstance({
    url: COPY_ASSORT_PLAN,
    method: "POST",
    data: copyBody,
  });
};

//API to make a plan finalize for Purchase Order
export const finalizeForPO = (finalizeForPOBody) => () => {
  return axiosInstance({
    url: FINALIZE_FOR_PO,
    method: "PATCH",
    data: finalizeForPOBody,
  });
};

export const getBopTagData = (body) => () => {
  return axiosInstance({
    url: GET_SEASON_YEAR,
    method: "POST",
    data: body,
  });
};

export const getImportClusterPlan = (body) => {
  return axiosInstance({
    url: `${IMPORT_CLUSTER_PLAN}`,
    method: "POST",
    data: body,
  });
};

export const getSeasonOptions = (body) => () => {
  return axiosInstance({
    url: GET_SEASON_OPTIONS,
    method: "POST",
    data: body,
  });
};

//Get dashboard filters
export const getDashboardFilterLevels = (param) => () => {
  return axiosInstance({
    url: `${DASHBOARD_FILTER}/${param}`,
    method: "GET",
  });
};

// Selectors

export const planDashboardReducerSelector = createSelector(
  (state) => state,
  (state) => state.assortsmartReducer.planDashboardReducer
);

export const levelsJsonDataSelector = createSelector(
  planDashboardReducerSelector,
  (state) => state.levelsJson
);

export const planDetailsDataSelector = createSelector(
  planDashboardReducerSelector,
  (state) => state.planDetails
);

export const columnHeaderJsonSelector = createSelector(
  planDashboardReducerSelector,
  (state) => state.columnHeaderJson
);

export const planLevelsDataSelector = createSelector(
  planDashboardReducerSelector,
  (state) => state.planLevels
);

export default dashboardService.reducer;

