import React, { useEffect, useState } from "react";
import LoadingOverlay from "../../Utils/Loader/loader";
import { Redirect, Route } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { ApmRoute } from "@elastic/apm-rum-react";
import Cookies from "js-cookie";
import { setActiveScreenName } from "modules/react-demo/services/common-assort-service";
import jwt_decode from "jwt-decode";
import {
  setUserPreferClient,
  getUserPreference,
  setUserPreferences,
} from "actions/authActions";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [verified, setVerified] = useState(false);
  const [goLogin, setGoLogin] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookies.get("authToken", {
      domain:
        window.location.hostname === "localhost"
          ? "localhost"
          : "impactsmartsuite.com",
    }); //move this inside if condition

    dispatch(setUserPreferClient(window.location?.pathname.split("/")[1]));
    if (token) {
      const verify = jwt_decode(token);
      if (verify?.email_verified && verify?.hd === "impactanalytics.co") {
        setVerified(true);
      }
      dispatch(setUserPreferClient(window.location.pathname.split("/")[1]));
    } else {
      console.log("token out");
      setGoLogin(true);
    }
  }, []);

  return (
    <ApmRoute
      {...rest}
      render={(props) => {
        if (rest.isTenantFetchFailed) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
        if (verified) {
          const allProps = { ...props, ...rest };
          allProps.setActiveScreenName(allProps.screenName);
          return <Component {...allProps} />;
        } else {
          if (goLogin) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return (
              <LoadingOverlay
                loader={true}
                spinner
                text={rest.loaderText}
              ></LoadingOverlay>
            );
          }
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.isAuthenticated,
    isTokenVerified: state.authReducer.isTokenVerified,
    isTenantFetchFailed: state.authReducer.isTenantFetchFailed,
    loaderText: state.authReducer.alertProperties.Text,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveScreenName: (data) => dispatch(setActiveScreenName(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
