/* eslint-disable jsx-a11y/alt-text */
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import { useHistory } from "react-router";
import { DASHBOARD } from "../../constants-inventorysmart/routesConstants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setInventorysmartFilterLoader,
  setSelectedFilters,
  setInventorysmartDashboardFilterElements,
  setInventorysmartDashboardFilterDependency,
  setIsFiltersValid,
  resetDashboardStore,
} from "modules/inventorysmart/services-inventorysmart/Decision-Dashboard/decision-dashboard-services";
import {
  fetchFilterConfig,
  fetchFilterOptions,
  filtersPayload,
} from "../inventorysmart-utility";
import {
  ERROR_MESSAGE,
  INVENTORY_DASHBOARD_TAB_OPTIONS,
  SCREENS_SUBCOMPONENT_LIST_MAP,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import { useStyles } from "modules/inventorysmart/pages-inventorysmart/styles-inventorysmart";
import globalStyles from "Styles/globalStyles";
import InventoryDashboardForecastData from "./components/InventoryDashboardForecastData";
import InventoryDashboardDetails from "./components/InventoryDashboardDetails";
import { addSnack } from "actions/snackbarActions";
import classNames from "classnames";
import Filters from "commonComponents/filters/filterGroup";
import { cloneDeep } from "lodash";
import LoadingOverlay from "Utils/Loader/loader";
import { Button, Grid, Paper, Tab, Tabs } from "@mui/material";
import CustomAccordion from "commonComponents/Custom-Accordian";
import FilterModal from "commonComponents/filterModal/FilterModal";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import { resetStoreInventoryState } from "modules/inventorysmart/services-inventorysmart/Decision-Dashboard/store-inventory-services";

const InventoryDashboard = (props) => {
  const history = useHistory();
  const globalClasses = globalStyles();
  const classes = useStyles();

  const [tabValue, setTabValue] = useState("forecast");
  const [filters, setFilters] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const updateFilters = async (selected, current) => {
    await getFiltersOptions(selected, current);
    props.setInventorysmartDashboardFilterDependency(selected);
  };

  const applyFilters = (filterElements, filterDependency) => {
    const payload = filtersPayload(
      filterElements || props.inventorysmartDashboardFilterElements,
      filterDependency || props.inventorysmartDashboardFilterDependency,
      true
    );

    props.setIsFiltersValid(payload.isValid);
    props.setSelectedFilters(payload.reqBody);
    setOpenModal(false);
  };

  const resetSelectedFilterValues = async () => {
    props.setInventorysmartDashboardFilterDependency([]);
    setOpenModal(false);
    applyFilters(filterData, []);
  };

  const getFiltersOptions = async (selected, current) => {
    try {
      props.setInventorysmartFilterLoader(true);
      const response = await fetchFilterOptions(
        filters || [],
        selected,
        current
      );

      let filterElements = cloneDeep(response);
      setFilterData(response);
      props.setInventorysmartDashboardFilterElements(filterElements);
    } catch (error) {
      //displaySnackMessages(ERROR_MESSAGE, "error");
    } finally {
      props.setInventorysmartFilterLoader(false);
    }
  };

  useEffect(() => {
    if (!filters || filters?.length === 0) {
      return;
    }
    getFiltersOptions();
  }, [filters]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        props.setInventorysmartFilterLoader(true);
        const response = await fetchFilterConfig("InventoryDashboard");
        setFilters(response);
      } catch (error) {
        props.setInventorysmartFilterLoader(false);
        //displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };

    fetchFilters();

    return () => {
      props.resetDashboardStore();
      props.resetStoreInventoryState();
    };
  }, []);

  useEffect(() => {
    /** Checks the config for a particular client and sets default value to Store Inventory Tab if no other tabs are present */
    if (
      props?.inventorysmartScreenConfig?.dashboard?.subComponent ===
      SCREENS_SUBCOMPONENT_LIST_MAP.INVENTORYSMART_DASHBOARD_WITH_STORE_INVENTORY
    ) {
      setTabValue("store_inventory");
    }
  }, [props?.inventorysmartScreenConfig]);

  const displaySnackMessages = (message, variance, onClose) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
        ...(onClose && { onClose: onClose }),
      },
    });
  };

  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChangeTabValue = (_event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <HeaderBreadCrumbs
        options={[
          {
            label: "Decision dashboard",
            id: 1,
            action: () => {
              history.push(DASHBOARD);
            },
          },
        ]}
      ></HeaderBreadCrumbs>
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "100%" }}
          data={`https://bkp.products.impactsmartsuite.com${window.location.pathname}`}
          // data="https://sandbox.inventory.impactsmartsuite.com/inventory-smart/decision-dashboard"
        ></object>
      </div>
      {/* <div className={globalClasses.filterWrapper}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {props.inventorysmartDashboardFilterDependency.length > 0 && (
          <FilterChips
            filterConfig={props.inventorysmartDashboardFilterDependency}
          ></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <LoadingOverlay loader={props.inventorysmartFilterLoader}>
            <Paper elevation={3} className={globalClasses.paperWrapper}>
              <Filters
                filters={filterData}
                update={updateFilters}
                onFilter={applyFilters}
                onReset={resetSelectedFilterValues}
                filterElevation={3}
                showBorderedWrapper={false}
                screen="inventory-smart-dashboard"
                doNotUpdateDefaultValue
              />
            </Paper>
          </LoadingOverlay>
        </CustomAccordion>
      </FilterModal>
      {props.isFiltersValid && (
        <div
          className={classNames(
            globalClasses.filterWrapper,
            globalClasses.marginVertical1rem
          )}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="product-profile-tabs"
          >
            {props.inventorysmartScreenConfig?.dashboard?.subComponent
              ? INVENTORY_DASHBOARD_TAB_OPTIONS[
                  props.inventorysmartScreenConfig?.dashboard?.subComponent
                ].map((option, index) => (
                  <Tab
                    label={option.label}
                    value={option.value}
                    {...tabProps(index)}
                  />
                ))
              : INVENTORY_DASHBOARD_TAB_OPTIONS.dashboardWithForecastAndStoreInventory.map(
                  (option, index) => (
                    <Tab
                      label={option.label}
                      value={option.value}
                      {...tabProps(index)}
                    />
                  )
                )}
          </Tabs>

          {tabValue === "forecast" ? (
            <InventoryDashboardForecastData />
          ) : (
            tabValue === "store_inventory" && <InventoryDashboardDetails />
          )}
        </div>
      )} */}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    inventorysmartFilterLoader:
      store.inventorysmartReducer.inventorySmartDashboardService
        .inventorysmartFilterLoader,
    isFiltersValid:
      store.inventorysmartReducer.inventorySmartDashboardService.isFiltersValid,
    inventorysmartDashboardFilterElements:
      store.inventorysmartReducer.inventorySmartDashboardService
        .inventorysmartDashboardFilterElements,
    inventorysmartDashboardFilterDependency:
      store.inventorysmartReducer.inventorySmartDashboardService
        .inventorysmartDashboardFilterDependency,
    inventorysmartScreenConfig:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInventorysmartFilterLoader: (payload) =>
    dispatch(setInventorysmartFilterLoader(payload)),
  setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
  setIsFiltersValid: (payload) => dispatch(setIsFiltersValid(payload)),
  setInventorysmartDashboardFilterElements: (payload) =>
    dispatch(setInventorysmartDashboardFilterElements(payload)),
  setInventorysmartDashboardFilterDependency: (payload) =>
    dispatch(setInventorysmartDashboardFilterDependency(payload)),
  resetStoreInventoryState: (payload) =>
    dispatch(resetStoreInventoryState(payload)),
  resetDashboardStore: (payload) => dispatch(resetDashboardStore(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDashboard);
