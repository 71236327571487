import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loaderReducer from "./loaderReducer";
import layoutReducer from "./layoutReducer";
import adminReducer from "./adminReducer";
import filterReducer from "./filterReducer";
import tenantReducer from "./tenantReducer";
import notificationReducer from "./notificationReducer";
import workflowReducer from "./workflowReducer";
import snackbarReducer from "./snackbarReducer";
import ticketingReducer from ".//ticketingReducer";
import filterElementsReducer from "./filterElementsReducer";
import UnitDefnReducer from "pages/product-unit-definition/product-unit-definition-service";
import productGroupReducer from "pages/product-grouping/product-grouping-service";
import productStatusReducer from "./productStatusReducer";
import tableReducer from "./tableReducer";
import storeGroupReducer from "pages/store-grouping/services-store-grouping/custom-store-group-service";
import eventConfigurationReducer from "./eventConfigReducer";
import dcStatusReducer from "pages/dc-status/dc-status-service";
import tenantConfigReducer from "./tenantConfigReducer";
import sideBarReducer from "./sideBarReducer";
import homePageReducer from "./homePageReducer";
import commentBarReducer from "./commentBarReducer";
import createGradeReducer from "pages/store-grading/grading-services";
import { assortsmartReducer } from "../modules/react-demo/services/CombinedService";
import { plansmartReducer } from "../modules/plansmart/services-plansmart/plansmart-combined-services";
import { inventorysmartReducer } from "../modules/inventorysmart/services-inventorysmart/inventorysmart-combined-services";
import { tenantUserRoleMgmtReducer } from "pages/tenant-config/access-user-management/services/TenantManagement/combinedService";
import { LOGOUT_CURRENT_USER } from "../actions/types";
import { marksmartReducer } from "modules/marksmart/services-marksmart/marksmart-combined-service";
import { adaReducer } from "modules/ada/services-ada/ada-combined-services";
import productMappingReducerService from "pages/product-mapping/services-product-mapping/productMappingService";
import storeMappingReducerService from "pages/storeMapping/services/storeMappingService";
import dcMappingReducerService from "pages/dcmapping/services-dc-mapping/dc-mapping-service";
import { clustersmartReducer } from "modules/clustersmart/services-clustersmart/CombinedService";
const appReducer = combineReducers({
  adminReducer,
  authReducer,
  layoutReducer,
  loaderReducer,
  filterReducer,
  tenantReducer,
  notificationReducer,
  workflowReducer,
  snackbarReducer,
  ticketingReducer,
  filterElementsReducer,
  UnitDefnReducer,
  eventConfigurationReducer,
  productGroupReducer,
  productStatusReducer,
  tableReducer,
  storeGroupReducer,
  dcStatusReducer,
  tenantConfigReducer,
  assortsmartReducer,
  plansmartReducer,
  inventorysmartReducer,
  tenantUserRoleMgmtReducer,
  sideBarReducer,
  homePageReducer,
  marksmartReducer,
  adaReducer,
  commentBarReducer,
  createGradeReducer,
  productMappingReducerService,
  storeMappingReducerService,
  dcMappingReducerService,
  clustersmartReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_CURRENT_USER) {
    const { authReducer } = state;
    state = { authReducer };
  }
  return appReducer(state, action);
};

export default rootReducer;
