// Delete Icon cell renderer
/*

    In the parent comp, defining getRowID is Required
    Props:  field: unique field (key), deletetion of row is done when row.id === data.field
            instance: From coldefs.Cell 

 */
import React from "react";
import { Delete } from "@mui/icons-material"
import { useStyles } from "modules/react-demo/pages/styles";



const DeleteCell = ({ field, ...instance }) => {
  const {
    deleteRow,
    row: { id },
  } = instance;
  const classes = useStyles()
  const onDeleteConfirmation = () => {
    deleteRow(field, id);
    instance.onDeleteClick();
  };

  return (
    <>
      <div
        onClick={() => {
          onDeleteConfirmation();
        }}
        className={classes.iconBlue}
      >
        <Delete />
      </div>
    </>
  );
};

export default DeleteCell;
