import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Table from "../../../Utils/reactTable";
import { getColumns } from "../../../actions/tableColumnActions";
import {
  updateProductAsnMapping,
  setProductAsnMappings,
  getProductAsnMapping,
} from "../services-product-mapping/productMappingService";
import { IconButton, Snackbar } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditASNMapping from "./edit-asn-mapping";
import Loader from "../../../Utils/Loader/loader";
import MuiAlert from "@mui/material/Alert";
import { setActiveScreenName } from "modules/react-demo/services/common-assort-service";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ProductToASN = (props) => {
  const [columns, setColumns] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [mappingData, setMappingData] = useState(props.productASNMapping);
  const [snack, setSnack] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [showloader, setLoader] = useState(false);

  useEffect(() => {
    props.setActiveScreenName("Product to ASN");
  }, []);

  useEffect(async () => {
    if (props.filtersSelection.length) {
      const asnMappingData = await props.getProductAsnMapping({
        product_attributes: props.filtersSelection,
        filters: {
          search: [],
          sort: [],
          range: [],
          limit: {
            limit: 10,
            page: 1,
          },
        },
      });
      props.setProductAsnMappings(asnMappingData.data.data);
    } else {
      setMappingData([]);
      updateProductAsnMapping([]);
    }
  }, [props.filtersSelection]);

  useEffect(async () => {
    let cols = await getColumns("table_name=product_asn")();
    let attributes = ["expected_receive_date", "issue_date", "vendor_name"];
    cols = cols.map((col) => {
      if (attributes.includes(col.column_name)) {
        col.accessor = `attributes.${col.accessor}`;
      }
      return col;
    });
    setColumns([
      ...cols,
      {
        Header: "Action",
        sticky: "right",
        disableSortBy: true,
        isFixed: true,
        Cell: (tableInfo) => {
          return (
            <>
              <IconButton size="large">
                <EditIcon
                  onClick={() => {
                    editMapping(tableInfo.row.original.product_code);
                  }}
                />
              </IconButton>
            </>
          );
        },
      },
    ]);
    setMappingData(props.productASNMapping);
  }, [props.productASNMapping]);

  const editMapping = (product_code) => {
    setShowEdit(true);
    setCurrentProduct(product_code);
  };

  const closeDialog = () => {
    setCurrentProduct(null);
    setShowEdit(false);
  };
  const updateMapping = async (product) => {
    setLoader(true);
    try {
      let res = await props.updateProductAsnMapping(product);
      if (res.data.message === "Successful") {
        let data = mappingData.map((prod) => {
          if (prod.product_code === product.product_code) {
            return product;
          }
          return prod;
        });
        setMappingData(data);
        setSnack(true);
        setSnackMsg("Updated successfully!");
        setSnackSeverity("success");
      }
    } catch (error) {
      setSnack(true);
      setSnackMsg("An error occured. Please try again");
      setSnackSeverity("error");
    }
    setLoader(false);
  };

  const manualCallBack = async (manualbody, pageIndex) => {
    const asnMappingData = await props.getProductAsnMapping({
      product_attributes: props.filtersSelection,
      filters: { ...manualbody, limit: { limit: 10, page: 1 } },
    });
    props.setProductAsnMappings(asnMappingData.data.data);
  };

  const handleWarningClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
  };

  return (
    <>
      <Snackbar
        open={snack}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleWarningClose}
      >
        <Alert
          onClose={handleWarningClose}
          severity={snackSeverity}
          sx={{ width: "100%" }}
        >
          {snackMsg}
        </Alert>
      </Snackbar>
      <Loader loader={showloader}>
        <EditASNMapping
          visible={showEdit}
          currentProduct={currentProduct}
          closeDialog={closeDialog}
          updateMapping={updateMapping}
          mappingData={mappingData}
        />
        {Boolean(columns.length) && (
          <Table
            style={{ height: "60vh" }}
            tableId="product-asn"
            rowdata={mappingData || []}
            columns={columns}
            tableName={"product-asn"}
            fieldChooser={true}
            showPagination={true}
            paginatorCallback={(page) => null}
            manualFilters={true}
            manualSortBy={true}
            manualCallBack={(body, pageIndex) =>
              manualCallBack(body, pageIndex)
            }
          />
        )}
      </Loader>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    productASNMapping: state.productMappingReducerService.productASNMapping,
  };
};
const mapActionsToProps = (dispatch) => {
  return {
    updateProductAsnMapping: (data) => dispatch(updateProductAsnMapping(data)),
    setActiveScreenName: (data) => dispatch(setActiveScreenName(data)),
    setProductAsnMappings,
    getProductAsnMapping,
  };
};
export default connect(mapStateToProps, mapActionsToProps)(ProductToASN);
