import React from "react";
import Tabs from "commonComponents/tabs";
import TabLayout from "./components/tab-layout";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import globalStyles from "Styles/globalStyles";

export default function Configuration() {
  const globalClasses = globalStyles();
  const tabsData = [
    {
      label: "Product",
      id: "product",
      TabPanel: <TabLayout dimension="product" />,
    },
    {
      label: "Store",
      id: "store",
      TabPanel: <TabLayout dimension="store" />,
    },
    {
      label: "DC",
      id: "dc",
      TabPanel: <TabLayout dimension="dc" />,
    },
  ];

  return (
    <div>
      <HeaderBreadCrumbs
        options={[
          {
            label: "Configuration",
            id: 1,
          },
        ]}
      ></HeaderBreadCrumbs>
      <div className={`${globalClasses.filterWrapper} page-content`}>
      <iframe width="1440px" height="840" src="https://xd.adobe.com/embed/26fd8c49-c9a1-4fa8-bf8c-b9b1c477da5e-73ae/" frameBorder="0" allowFullScreen></iframe>
      </div>
    </div>
  );
}
