import {
  NUMERIC_FIELDS,
  TEXT_FIELDS,
  EMPTY_ON_ZERO_COLUMNS,
  EMPTY_ON_NULL_COLUMNS,
} from "../../../config/constants";
import {
  percentFormatter,
  numbersWithComma,
  dollarFormatter,
  decimalsFormatter,
  setAllLabelFormatter,
  arrayToCommaFormatter,
  fileLabelFormatter,
} from "../../formatter";
import CellRenderer from "./cellRenderer";
import {
  getEmptyFooter,
  getTotalForFooter,
} from "Utils/functions/helpers/table-helpers";
import { attributeFormatter } from "modules/react-demo/utils/utilityFunctions";
import { cloneDeep } from "lodash";
import moment from "moment";

const levelsArray = ["l0_name", "l1_name", "l2_name", "l3_name", "l4_name"];
const getParseKey = (item) => {
  if (
    item.type === "percenatge" ||
    item.type === "dollar" ||
    item.type === "float"
  ) {
    return "float";
  } else if (
    item.type === "int" &&
    item.formatter === "roundOfftoTwoDecimals"
  ) {
    return "textToNumWithDecimal";
  } else if (item.type === "int") {
    return "textToNum";
  }
};
const getFooterforItem = (item) => {
  //based on the footer type it returns footer
  let parseKey = getParseKey(item);
  if (item.footer && item.footer === "sum") {
    item.Footer = getTotalForFooter(
      item.column_name,
      parseKey,
      nonEditableCell(item, true) // we are calling from footer so passing true
    )?.Footer;
  } else if (item.footer && item.footer === "avg") {
    item.Footer = getTotalForFooter(
      item.column_name,
      parseKey,
      nonEditableCell(item, true), // we are calling from footer so passing true
      true
    )?.Footer;
  } else if (item.footer === "name") {
    item.Footer = getEmptyFooter(true)?.Footer;
  } else {
    item.Footer = getEmptyFooter()?.Footer;
  }
  return item;
};

const generateHeader = (item, levelsJson) => {
  //For levels column label will be dynamic so fetching it from levels api
  if (levelsArray.includes(item.column_name)) {
    return levelsJson?.[item?.column_name] || item.label;
  } else {
    if (item.label === "LY") {
      return levelsJson?.[item.label] || item.label;
    }
    return item.label;
  }
};

const format = (item, levelsJson, formatSetAllLabel) => {
  item.isFixed = true;
  item.disableSortBy = item.disableSortBy || !item.is_sortable ? true : false;
  item.Header = generateHeader(item, levelsJson);
  item.sticky = item.is_frozen ? "left" : null;
  item.width = 150;
  item.showTooltip = item.is_editable ? false : true;
  item.sub_headers = item.sub_headers || [];
  item.required = item.is_required;
  item.accessor = item.column_name;
  item.showRangeFilter = item.showRangeFilter
    ? !item.sub_headers?.length && NUMERIC_FIELDS.includes(item.type)
    : false;
  item.showFilter =
    item.is_searchable &&
    !item.sub_headers?.length &&
    TEXT_FIELDS.includes(item.type);
  item.enableRowSpan = item.is_row_span || undefined;
  item.rowSpan = 1;
  item.showEmptyOnZero = EMPTY_ON_ZERO_COLUMNS.includes(item.column_name);
  item.showEmptyOnNull = EMPTY_ON_NULL_COLUMNS.includes(item.column_name);
  if (item.sub_headers?.length) {
    item.Footer = getEmptyFooter()?.Footer;
    item.rowSpan = 0;
    item.setAllLabel = item.Header;
    item.columns = item.sub_headers.map((data) => {
      return format(data, levelsJson, formatSetAllLabel);
    });
  } else {
    item.setAllLabel = formatSetAllLabel
      ? setAllLabelFormatter(item.accessor)
      : item.Header;
    item = getFooterforItem(item);
  }
  if (!item.is_editable) {
    //if the column is not editable
    item.Cell = item.Cell ? item.Cell : nonEditableCell(item);
    if (item.is_aggregated && item.type != "link") {
      item.Aggregated = (ins) =>
        aggregateRender(ins, item, nonEditableCell(item));
    }
  } else {
    item.Cell = (cellProps, extraProps) => {
      //if the column is editable calling the cellRenderer
      let showConditionalCell = cellProps.customCellRenderer
        ? cellProps.customCellRenderer(cellProps)
        : false;
      if (showConditionalCell) {
        return showConditionalCell;
      }
      return (
        <CellRenderer
          cellData={cellProps}
          column={item}
          extraProps={extraProps}
        ></CellRenderer>
      );
    };
  }
  return item;
};

const nonEditableCell = (item, forFooter) => {
  //it returns the formatter to cell based on type
  const isDecimal = item.formatter === "roundOfftoTwoDecimals" ? true : false;
  switch (item.type) {
    case "percentage":
      return (ins) =>
        percentFormatter(
          ins,
          isDecimal,
          forFooter && item.is_editable ? true : false //for footer we don't want to multiply it by 100 so passing true
        );
    case "int":
      return (ins) => numbersWithComma(ins, isDecimal);
    case "float":
      return (ins) => decimalsFormatter(ins, isDecimal);
    case "dollar":
      return (ins) => dollarFormatter(ins, isDecimal);
    case "attribute":
      return (ins) => attributeFormatter(ins.value, false);
    case "bool":
      return (ins) => ins?.value || "";
    case "array":
      return (ins) => arrayToCommaFormatter(ins.value);
    case "file":
      return (ins) => fileLabelFormatter(ins.value);
    case "date":
    case "DateTimeField":
      return (ins) => {
        return ins?.column?.dateFormatter
          ? moment(ins.value).format(ins?.column?.dateFormatter)
          : ins.value;
      };

    default:
      return (ins) => {
        if (ins.value === 0 || ins.value === false || ins.value) {
          //This condition will work for not undefined and not null values
          return ins.value;
        }
        return "";
      };
  }
};

const aggregateRender = (instance, item, formater) => {
  //calculating average from sum, item is  json, isRound is used for round off value
  let key = item.column_name;
  if (instance.row.subRows.length > 0) {
    let average = [];
    instance.row.subRows.map((subrow) => {
      if (average[key] === undefined) average[key] = 0;
      average[key] = parseFloat(subrow?.original?.[key]) + average[key];
    });
    // average of particular columns
    let tempAvg = average[key] / instance.row.subRows.length;
    switch (item.aggregate) {
      case "average":
        return formater({ value: tempAvg }) || "-";
      case "sum":
        return formater({ value: average[key] }) || "-";
      default:
        return (
          formater(
            key === "store_name"
              ? { value: `${instance.value} Stores` }
              : instance
          ) || "-"
        );
    }
  }
  return formater(instance) || "-";
};

const formatSubheader = (columnsData) => {
  let formatedCols = columnsData.map((item) => {
    if (item.sub_headers.length === 0) {
      let obj = cloneDeep(item);
      delete obj.Header;
      obj.Header = " ";
      item.columns = [];
      item.columns.push(obj);
    }
    return item;
  });
  formatedCols = cloneDeep(formatedCols);
  return formatedCols.map((col, index) => {
    if (columnsData[index].sub_headers.length === 0) {
      delete col.columns[0].Header;
      col.disableSortBy = true;
      col.columns[0].Header = " ";
      col.columns[0].disableSortBy = true;
      col.rowSpan = 0;
    }
    col.columns[0].isFixed = true;
    return col;
  });
};

const getMaxDepth = (formattedCols) => {
  let depth = 0;
  formattedCols.forEach((item) => {
    let level = 0;
    if (item.sub_headers.length > 0) {
      level = level + 1;
      let newLevel = getMaxDepth(item.sub_headers);
      level = level + newLevel;
    }
    depth = depth > level ? depth : level;
  });
  return depth;
};

const formatSubheaderTwoLevelDeep = (columnsData, colLevel) => {
  let columnArray = cloneDeep(columnsData);

  columnArray = columnArray.map((item) => {
    if (item.sub_headers.length === 0) {
      let obj = cloneDeep(item);
      delete obj.Header;
      let subColumns = [];
      subColumns.push(obj);
      delete subColumns[0].Header;
      subColumns[0].Header = " ";
      subColumns[0].disableSortBy = true;
      subColumns[0].isFixed = true;
      subColumns[0].columns = [];
      if (colLevel === 2) {
        let col = cloneDeep(item);
        delete col.Header;
        col.Header = " ";
        col.disableSortBy = true;
        col.isFixed = true;
        subColumns[0].columns.push(col);
        item.rowSpan = 0;
      }
      item.columns = subColumns;
    }

    if (item.sub_headers.length > 0) {
      item.columns = item.sub_headers.map((subHeader) => {
        if (subHeader.sub_headers.length === 0) {
          let obj = cloneDeep(subHeader);
          delete obj.Header;
          let subColumns = [];
          subColumns.push(obj);
          delete subColumns[0].Header;
          subColumns[0].Header = " ";
          subColumns[0].disableSortBy = true;
          subColumns[0].isFixed = true;
          subHeader.columns = subColumns;
          subHeader.rowSpan = 0;
        }
        return subHeader;
      });
    }

    return item;
  });

  return columnArray;
};

export default function columnFormatter(
  data,
  levelsJson,
  formatSetAllLabel = false
) {
  let formattedCols = data.filter(async (item) => {
    if (!item.special_field) {
      await format(item, levelsJson, formatSetAllLabel);
      if (item?.columns) {
        item.columns = await Promise.all(
          item.columns.map((innerItem) =>
            format(innerItem, levelsJson, formatSetAllLabel)
          )
        );
      }
      return item;
    }
  });
  let isSubHeaderPresent = formattedCols.some(
    (item) => item.sub_headers && item.sub_headers.length > 0
  );

  let finalDepth = getMaxDepth(formattedCols);
  return isSubHeaderPresent
    ? finalDepth === 2
      ? formatSubheaderTwoLevelDeep(formattedCols, finalDepth)
      : formatSubheader(formattedCols)
    : formattedCols;
  // patch fix for column header
  // return formattedCols;
}
