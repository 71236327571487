// CUSTOM HOOK TO COLLAPSE AND EXPAND COLUMNS
import React from "react";

let showFirstColumn = false;
let showLastColumn = false;
// CUSTOM REDUCER
function reducer(state, action, previousState, instance) {
  const { features } = instance;
  if (features && features.includes("COLLAPSE_TO_FIRSTCOLUMN")) {
    showFirstColumn = true;
  }
  if (features && features.includes("COLLAPSE_TO_LASTCOLUMN")) {
    showLastColumn = true;
  }

  return { ...state };
}

const useInstance = (instance) => {
  const { dispatch, columns } = instance;

  const toggleColumnExpandedProps = React.useCallback(
    ({ column }) => {
      let LIMITER = 0;
      if (showLastColumn) {
        LIMITER = column.headers.length - 1;
      }
      for (let i = 0; i < column.headers.length; i++) {
        if (i !== LIMITER) {
          column.headers[i].toggleHidden();
        }
      }
    },
    [dispatch, columns]
  );

  Object.assign(instance, {
    toggleColumnExpandedProps,
  });
};

export const useColumnExpander = (hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};
