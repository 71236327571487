import { SAVE_TABLE_STATE } from "../actions/types";

const initialState = {
  tableState: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_TABLE_STATE:
      return {
        ...state,
        tableState: { ...state.tableState, ...action.payload },
      };
    default:
      return state;
  }
}
