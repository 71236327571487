import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Optional theme CSS

import { NUMERIC_FIELDS } from "config/constants";
import { nonEditableCell, generateHeader } from "./table-functions";
import CellRenderers from "./cellRenderer";
import SortComponent from "./column-component/sortComponent";
import { columnActionTypes, actionTypesToNotEdit } from "./constants";
import { setAllLabelFormatter } from "Utils/formatter/index";
import { isUndefined, isFunction } from "lodash";
import { STORE_INVENTORY_LINK_COLUMNS_RIGHT_ALIGNED } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

const formatColumns = (item, levelsJson, actions, formatSetAllLabel) => {
  // To set keys for rowgrouping and hide once set from backend
  let l_isMulti = item?.extra?.is_multi;
  let l_commonOptions = item?.extra?.options;

  item.field = item.column_name;
  item.accessor = item.column_name;
  item.id = item.column_name;
  // default sorting for a column, in column config add key defaultSortBy inside extra key
  // possible values: "asc" and "desc"
  item.sort = item?.extra?.defaultSortBy;
  item.disablePast = item?.extra?.disablePast;
  item.headerName = generateHeader(item, levelsJson);
  // to set from backend as left or right instead of boolean is_frozen
  item.pinned = item.is_frozen ? "left" : null;
  item.required = item.is_required;

  // to turn off resize column
  item.resizable = !item.not_resizable;

  item.showRangeFilter = item.showRangeFilter
    ? !item.sub_headers?.length && NUMERIC_FIELDS.includes(item.type)
    : false;
  item.filter = true;
  // To not include search on action columns
  if (item.is_searchable) {
    item.floatingFilter = true;
    item.filter = "agTextColumnFilter";
    /* 
      Enabling multi search based on comma separated values on Client side row model.
      AgGrid converts row data and search i/p field data into lower case and checks (Not case sensitive).
      Search list is a list of comma separated values w/o space after comma - data1,data2.
    */
    item.filterParams = {
      filterOptions: ["contains"],
      textCustomComparator: (_filter, value, filterText) => {
        // get array of comma separated values from filterText(input values of search field)
        // value - cell value of a particular column and row
        const filterValues = filterText.split(",");
        // loop through filterValues and see if the value contains any of them
        return filterValues.some((item) => {
          return value.indexOf(item) >= 0;
        });
      },
      debounceMs: 2000,
    };
    item.floatingFilterComponentParams = { suppressFilterButton: true }; // to hide the filter icon on columns (beside search bar)
  }
  // To not include sort on action columns
  if (!columnActionTypes.includes(item.type)) {
    if (item.is_sortable || item.disableSortBy === false) {
      item.headerComponent = SortComponent;
    } else {
      item.headerComponent = null;
    }
    item.tooltipField = item.column_name;
  }
  if (item.sub_headers?.length) {
    item.setAllLabel = item.Header;
    item.children = item.sub_headers.map((data) => {
      return formatColumns(data, levelsJson, actions, formatSetAllLabel);
    });
  } else {
    item.setAllLabel = formatSetAllLabel
      ? setAllLabelFormatter(item.accessor)
      : item.Header;
  }
  l_isMulti && (item.is_multi = l_isMulti);
  l_commonOptions && (item.options = l_commonOptions);
  item.min = item?.extra?.min;
  item.max = item?.extra?.max;
  item.dynamicMinKey = item?.extra?.dynamicMinKey;
  item.dynamicMaxKey = item?.extra?.dynamicMaxKey;

  if (["int", "percentage", "float", "dollar"].indexOf(item.type) > -1) {
    item.cellStyle = { ...item.cellStyle, textAlign: "right" };
  }

  if (["int", "float", "dollar"].indexOf(item.type) > -1) {
    item.floatingFilter = true;
    item.filter = "agTextColumnFilter";
    item.floatingFilterComponentParams = { suppressFilterButton: false }; // to show the filter icon on columns (beside search bar)
    item.filterParams = {
      filterOptions: ["contains", "startsWith", "endsWith"],
    };
  }

  // Specifically right aligning link columns for certain tables
  if (
    item.type === "link" &&
    STORE_INVENTORY_LINK_COLUMNS_RIGHT_ALIGNED.indexOf(item.column_name) > -1
  ) {
    item.cellStyle = { ...item.cellStyle, textAlign: "right" };
  }

  if (item.is_aggregated) {
    callAGGridAggregateFunc(item);
  }
  if (!item.is_editable) {
    //if the column is not editable
    item.cellRenderer = (cellProps) => {
      let noEditableCustomCellRender = cellProps?.api?.gridOptionsWrapper
        ?.gridOptions?.noEditableCustomCellRender
        ? cellProps?.api?.gridOptionsWrapper?.gridOptions?.noEditableCustomCellRender(
            cellProps
          )
        : false;
      if (noEditableCustomCellRender) {
        return noEditableCustomCellRender;
      }
      return nonEditableCell(item)(cellProps);
    };
  } else {
    if (actionTypesToNotEdit.includes(item.type)) item.editable = false;
    else {
      item.editable = true;
    }
    // pass in boolean values based on isDecimal, like in nonEditableCell func
    // depends on usecase- to confirm later
    if (item.type === "int") {
      item.disabled = (params) => actions?.[item.column_name]?.(params);
    }
    if (item.type === "_") {
      item.cellEditor = CellRenderers;
      item.cellEditorPopup = true;
      item.cellEditorParams = (cellProps) => {
        return {
          cellData: cellProps,
          column: item,
        };
      };
    } else {
      // pass function from component if needs params for conditional cellstyle otherwise
      // as a plain object for cellStyle
      if (typeof item.cellStyle !== "function") {
        item.cellStyle = {
          ...item.cellStyle,
        };
      }
      item.cellRenderer = (cellProps, extraProps) => {
        //if the column is editable calling the cellRenderer
        let showConditionalCell = cellProps?.api?.gridOptionsWrapper
          ?.gridOptions?.customCellRenderer
          ? cellProps?.api?.gridOptionsWrapper?.gridOptions?.customCellRenderer(
              cellProps
            )
          : false;
        if (showConditionalCell) {
          return showConditionalCell;
        }
        return (
          <CellRenderers
            cellData={cellProps}
            column={item}
            extraProps={extraProps}
            actions={actions}
          ></CellRenderers>
        );
      };
    }
  }
  return item;
};

const callAGGridAggregateFunc = (item) => {
  const aggregator = {
    average: () => {
      item.aggFunc = (params) => {
        // the average will be the sum / count
        let sum = 0;
        let count = 0;
        let avg = null;
        params.values.forEach((value) => {
          if (value !== undefined && value !== null) {
            // to Int or float as table values are interpreted as strigs.
            // avg and sum are done oly on columns with numbers hence float and integer type would do
            let num =
              typeof value === "number" ? parseInt(value) : parseFloat(value);
            sum += num;
            count++;
          }
        });
        // avoid dividing by 0
        if (count !== 0) {
          avg = sum / count;
        }
        return avg;
      };
    },
    sum: () => {
      item.aggFunc = (params) => {
        let sum = 0;
        params.values.forEach((value) => {
          if (value !== undefined && value !== null) {
            let num =
              typeof value === "number" ? parseInt(value) : parseFloat(value);
            sum += num;
          }
        });
        return sum;
      };
    },
    count: () => {
      item.aggFunc = (params) => {
        let count = 0;
        params.values.forEach((value) => {
          if (value !== undefined && value !== null) {
            count++;
          }
        });
        return count;
      };
    },
  };

  if (isFunction(aggregator[item.aggregate_type])) {
    aggregator[item.aggregate_type]();
  }
};

export default function agGridColumnFormatter(
  data,
  levelsJson,
  actions,
  formatSetAllLabel
) {
  return (
    data &&
    data.filter(async (item) => {
      if (!item.special_field) {
        await formatColumns(item, levelsJson, actions, formatSetAllLabel);
        if (item?.columns) {
          item.columns = await Promise.all(
            item.columns.map((innerItem) =>
              formatColumns(innerItem, levelsJson, actions, formatSetAllLabel)
            )
          );
        }
        return item;
      }
    })
  );
}
