export const USER_FORM_FIELDS = [
  {
    label: "Add User Name",
    field_type: "TextField",
    required: true,
    accessor: "user_name",
    value_type: "text",
    helperText: "Enter alphabets only",
    error: false,
  },
  {
    label: "Add User Id",
    field_type: "TextField",
    required: true,
    accessor: "email",
    value_type: "email",
    helperText: "Enter a valid email id",
    error: false,
  },
];

export const USER_ROLE_FILTERS = [
  {
    column_name: "application_name",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "application_name",
    initialData: [],
    is_mandatory: false,
    is_multiple_selection: true,
    label: "User Application",
    level: 1,
    range_max: null,
    range_min: null,
    type: "cascaded",
    is_clearable: true,
  },
  {
    column_name: "role_name",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "role_name",
    initialData: [],
    is_mandatory: false,
    is_multiple_selection: true,
    label: "User Role",
    level: 2,
    range_max: null,
    range_min: null,
    type: "cascaded",
    is_disabled: true,
    is_clearable: true,
  },
];

export const USER_FORM = [
  {
    // state var name accessor
    accessor: "user_email",
    field_type: "list",
    label: "User Email Id",
    required: true,
    isMulti: true,
    isSearchable: true,
    options: [],
  },
];

export const ASSIGN_ROLE_USER_DETAILS_FILTERS = [
  {
    column_name: "user_email",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "user_email",
    initialData: [],
    is_mandatory: false,
    is_multiple_selection: true,
    label: "User Email",
    level: 1,
    range_max: null,
    range_min: null,
    type: "cascaded",
    is_clearable: true,
    display_order: 1,
  },
  {
    column_name: "application_name",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "application_name",
    initialData: [],
    is_mandatory: false,
    is_multiple_selection: false,
    label: "Assign Application",
    level: 2,
    range_max: null,
    range_min: null,
    type: "cascaded",
    is_clearable: true,
    display_order: 2,
  },
  {
    column_name: "role_name",
    default_value: null,
    dimension: "user",
    display_type: "dropdown",
    filter_keyword: "role_name",
    initialData: [],
    is_mandatory: false,
    is_multiple_selection: false,
    label: "Assign Role",
    level: 3,
    range_max: null,
    range_min: null,
    type: "cascaded",
    is_clearable: true,
    display_order: 3,
  },
];
