import { createSlice } from "@reduxjs/toolkit";
import {
  STORE_INVENTORY_TABLE_CONFIG,
  ARTICLE_INVENTORY_TABLE_CONFIG,
  STORE_INVENTORY_TABLE_DATA,
  ARTICLE_INVENTORY_TABLE_DATA,
} from "modules/inventorysmart/constants-inventorysmart/apiConstants";
import axiosInstance from "../../../../Utils/axios/index";

export const inventorySmartStoreInventoryService = createSlice({
  name: "inventorySmartStoreInventoryService",
  initialState: {
    storeInventoryLoader: false,
    articleInventoryLoader: false,
    storeInventoryTableConfigLoader: false,
    articleInventoryTableConfigLoader: false,
    storeInventoryTableConfig: [],
    articleInventoryTableConfig: [],
    storeInventoryTableData: [],
    articleInventoryTableData: [],
  },
  reducers: {
    setStoreInventoryLoader: (state, action) => {
      state.storeInventoryLoader = action.payload;
    },
    setArticleInventoryLoader: (state, action) => {
      state.articleInventoryLoader = action.payload;
    },
    setStoreInventoryTableConfigLoader: (state, action) => {
      state.storeInventoryTableConfigLoader = action.payload;
    },
    setArticleInventoryTableConfigLoader: (state, action) => {
      state.articleInventoryTableConfigLoader = action.payload;
    },
    setStoreInventoryTableConfiguration: (state, action) => {
      state.storeInventoryTableConfig = action.payload;
    },
    setArticleInventoryTableConfiguration: (state, action) => {
      state.articleInventoryTableConfig = action.payload;
    },
    setStoreInventoryTableData: (state, action) => {
      state.storeInventoryTableData = action.payload;
    },
    setArticleInventoryTableData: (state, action) => {
      state.articleInventoryTableData = action.payload;
    },
    resetStoreInventoryState: (state, _action) => {
      state.storeInventoryLoader = false;
      state.articleInventoryLoader = false;
      state.storeInventoryTableConfigLoader = false;
      state.articleInventoryTableConfigLoader = false;
      state.storeInventoryTableConfig = [];
      state.articleInventoryTableConfig = [];
      state.storeInventoryTableData = [];
      state.articleInventoryTableData = [];
    },
  },
});

export const {
  setStoreInventoryLoader,
  setArticleInventoryLoader,
  setStoreInventoryTableConfigLoader,
  setArticleInventoryTableConfigLoader,
  setStoreInventoryTableConfiguration,
  setArticleInventoryTableConfiguration,
  setStoreInventoryTableData,
  setArticleInventoryTableData,
  resetStoreInventoryState,
} = inventorySmartStoreInventoryService.actions;

export const getStoreInventoryTableConfiguration = () => () => {
  return axiosInstance({
    url: STORE_INVENTORY_TABLE_CONFIG,
    method: "GET",
  });
};

export const getStoreInventoryTableData = (postBody) => () => {
  return axiosInstance({
    url: STORE_INVENTORY_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const getArticleInventoryTableConfiguration = () => () => {
  return axiosInstance({
    url: ARTICLE_INVENTORY_TABLE_CONFIG,
    method: "GET",
  });
};

export const getArticleInventoryTableData = (postBody) => () => {
  return axiosInstance({
    url: ARTICLE_INVENTORY_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export default inventorySmartStoreInventoryService.reducer;
