export const WORKBENCH = "/price-smart/workbench";
export const PRICE_RULES = "/price-smart/price-rules";
export const PLAN_GROUP_CONFIGURATIONS = "/price-smart/plan-group-configurations";
export const PRICE_LIST_CONFIGURATIONS = "/price-smart/price-list-configurations";
export const MARKET_CONFIGURATIONS = "/price-smart/market-configurations";
export const ALERT_CONFIGURATIONS = "/price-smart/alert-configurations";
export const EXCEPTIONS_CONFIGURATIONS = "/price-smart/exceptions-configurations";
export const DECISION_DASHBOARD = "/price-smart/decision-dashboard";
export const ITEM_GROUP_CONFIG = "/price-smart/item-group-config";
export const STORE_CONFIG = "/price-smart/store-config";
export const CLEARANCE_REVIEW = "/price-smart/clearance-review";
export const MARKETING_CALENDAR = "/price-smart/marketing-calendar";
export const BASE_PRICE_STRATEGY = "/price-smart/base-price-strategies";
export const PROMOTION_STRATEGY = "/price-smart/promotion-strategy";
export const RULES = "/price-smart/rules";
