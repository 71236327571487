import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Loader from "../../../Utils/Loader/loader";
import "./filter.scss";
import Switch from "../../../Utils/switch";
import { setProductStatusData } from "../../../actions/productStoreStatusActions";
import { mapProductToDC } from "pages/product-mapping/services-product-mapping/productMappingService";
import {
  getAllDCData,
  getAllProductDC,
  getAllStyleDC,
} from "../services-dc-mapping/dc-mapping-service";
import ConfirmBox from "../../../Utils/reactTable/components/confirmPopup";
import { Prompt } from "react-router";
import { setActiveScreenName } from "modules/react-demo/services/common-assort-service";
import { Typography, Container, Button } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import AgGridComponent from "Utils/agGrid";
import { customSetAllField } from "commonComponents/coreComponentScreen/constants";
import { difference, isEmpty } from "lodash";
import CellRenderers from "Utils/agGrid/cellRenderer";
import { addSnack } from "actions/snackbarActions";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import { setFilterConfiguration } from "actions/filterAction";
import { getTenantConfigApplicationLevel } from "actions/tenantConfigActions";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

import { isActionAllowedOnSubModule } from "modules/inventorysmart/pages-inventorysmart/inventorysmart-utility";
import { INVENTORY_SUBMODULES_NAMES } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

function DCtoProduct(props) {
  const [showloader, setloader] = useState(true);
  const [columns, setColumns] = useState([]);
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [dcList, setdcList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [confirmBox, showConfirmBox] = useState(false);
  const [dimension, changeDimension] = useState("product");
  const [setAllData, updateSetAllData] = useState([]);
  const [productCols, setproductCols] = useState([]);
  const [styleCols, setstyleCols] = useState([]);
  const [flag_edit, setFlag_edit] = useState(false);
  const globalClasses = globalStyles();
  const onFilterDependency = useRef([]);
  const onFilterSectionApplied = useRef(0);
  const columnFiltersApplied = useRef([]);
  const tableInstance = useRef({});
  const [showStyleLevelData, setShowStyleLevelData] = useState(true);

  const setNewTableInstance = (params) => {
    tableInstance.current = params;
  };

  const getData = async (inputdata, final_cols, dim) => {
    // this function processes the input data which can be displayed on the table
    // inputs are array objects returned from the backend, column names and dimension()product or style

    let final_data = [];
    for (const data of inputdata) {
      let temp = {};
      if (data.dc_map !== null) {
        for (const col_key of final_cols) {
          temp[col_key.accessor] = false;
          for (const dc_key of data.dc_map) {
            if (dc_key.name === col_key.accessor) {
              temp[col_key.accessor] = true;
            }
          }
        }
      } else {
        for (const col_key of final_cols) {
          temp[col_key.accessor] = false;
        }
      }
      if (dim === "product") {
        temp["product_code"] = data.product_code;
        temp["product_name"] = data.product_name;
      } else {
        temp["style"] = data.style;
        temp["name"] = data.name;
      }

      final_data.push(temp);
    }

    return final_data;
  };

  const setdcData = async (dccolsdata) => {
    let dcdata = [];
    for (const item of dccolsdata) {
      let newdata = {};
      newdata["id"] = item["dc_code"];
      newdata["value"] = item["dc_code"];
      newdata["label"] = item["accessor"];
      if (item["dc_code"]) {
        dcdata.push(newdata);
      }
    }

    setdcList(dcdata);
    return dcdata;
  };

  const columnUpdate = (final_cols) => {
    let editPermission = canTakeActionOnModules(
      INVENTORY_SUBMODULES_NAMES.INVENTORY_DC_MAPPING,
      "edit"
    );
    final_cols = final_cols.map((item) => {
      if (
        item.column_name !== "product_code" &&
        item.column_name !== "style" &&
        item.column_name !== "product_name"
      ) {
        item.type = "bool";
        item.is_editable = props.isSuperUser ? true : false;
      } else {
        item.showTooltip = true;
      }
      item.disabled = !editPermission;
      item.cellRenderer = (params, extraProps) => {
        return (
          <CellRenderers
            cellData={params}
            column={item}
            extraProps={extraProps}
            actions={null}
          ></CellRenderers>
        );
      };

      return item;
    });
    return final_cols;
  };

  const processStyle = async (inpstyledata, dccols) => {
    /*
        This function will take style data as input and will decide 
        if a style be mapped to a dc or custom. If all product in a 
        style belongs to same Dc then its mapped to that dc else it is mapped as custom
    
    */

    let stylemappings = [];
    for (const data of inpstyledata) {
      let prodData = data.dc_map;
      const first_product = prodData[0].dc_map;
      let tempstyledata = {};
      tempstyledata["style"] = data["style"];
      for (const dccon of dccols) {
        if (dccon["accessor"] !== "style") {
          tempstyledata[dccon["accessor"]] = false;
        }
      }
      // check the product's dc mapping if each is same as the first product's mapping
      // first_product contains first product's dc mapping
      prodData.forEach((item) => {
        if (item["dc_map"] !== null) {
          item["dc_map"].forEach((mapped) => {
            tempstyledata[mapped[" name"]] = true;
            first_product &&
              first_product.forEach((tmpitem) => {
                if (tmpitem && tmpitem[" name"] !== mapped[" name"]) {
                  tempstyledata["custom"] = true;
                }
              });
          });
        }
      });
      for (const dccon of dccols) {
        if (
          !["style", "custom"].includes(dccon["accessor"]) &&
          tempstyledata["custom"] === true
        ) {
          tempstyledata[dccon["accessor"]] = false;
        }
      }

      stylemappings = [...stylemappings, tempstyledata];
    }
    return stylemappings;
  };

  useEffect(() => {
    const getInitialData = async () => {
      try {
        let dcbody = {
          filters: [],
        };

        let stylecols = await getAllDCData(dcbody, "style");
        let productcols = await getAllDCData(dcbody, "product");
        let updatedcols = columnUpdate(stylecols);
        let updatedprdcols = columnUpdate(productcols);
        setstyleCols(updatedcols);
        setproductCols(updatedprdcols);
        setColumns(productcols);
        await setdcData(productcols);

        if (isEmpty(props.filterDashboardConfiguration)) {
          const defaultValues = props?.roleBasedAccess
            ? await fetchFilterFieldValues(
                "dc to product",
                [],
                true,
                props.screenName,
                1
              )
            : await fetchFilterFieldValues("dc to product");

          const dcdefaultValues = await fetchFilterFieldValues("dc mapping");

          let filterConfigData = [
            {
              filterSectionHeader: `${dynamicLabelsBasedOnTenant(
                "product",
                "core"
              )} Filter`,
              filterDashboardData: [...defaultValues],
              expectedFilterDimensions: ["product"],
              screenDimension: "product",
              isCrossDimensionFilter: false,
            },
            {
              filterSectionHeader: "DC Filter",
              filterDashboardData: [...dcdefaultValues],
              expectedFilterDimensions: ["store"],
              screenDimension: "store",
              isCrossDimensionFilter: false,
            },
          ];
          if (props?.roleBasedAccess) {
            filterConfigData = filterConfigData.map((item) => {
              return {
                ...item,
                is_urm_filter: true,
                screen_name: props.screenName,
                application_code: 1,
              };
            });
          }
          const filterConfig = formattedFilterConfiguration(
            "dcToProductMappingFilterConfiguration",
            filterConfigData,
            "Dc To Product Mapping"
          );
          props.setFilterConfiguration(filterConfig);
        } else {
          onFilterDependency.current =
            props.filterDashboardConfiguration.appliedFilterData.dependencyData;
        }

        let showStyleLevelDataResp = await props.getTenantConfigApplicationLevel(
          3,
          {
            attribute_name: "core_show_style_level_info",
          }
        );

        if (showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"]) {
          setShowStyleLevelData(
            showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"].value
          );
        }

        setloader(false);
      } catch (error) {
        setloader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };

    getInitialData();

    props.setActiveScreenName("dc to product");
  }, []);

  const formatData = (data, selectedIds) => {
    const mappedIds = dcList.map((item) => item.id);

    const output = selectedIds.map((item) => {
      let dc = {
        map: data.dc ? data.dc : [],
        unmap: difference(mappedIds, data.dc),
      };

      if (dimension === "product") {
        return {
          product_code: item.product_code,
          dc,
        };
      } else {
        return {
          style: item.style,
          dc,
        };
      }
    });

    return output;
  };

  // updating ag-grid data
  const onSetAllApply = (data, agGrid) => {
    const dataSet = formatData(data, selectedRowsIDs);
    updateSetAllData([...setAllData, ...dataSet]);
    let rowNodes = agGrid.api.getSelectedNodes();
    rowNodes.forEach((item) => {
      const rowNode = agGrid.api.getRowNode(item.id);
      let dcOptions = [];
      data?.dc_options?.forEach((item) => {
        dcOptions = [...dcOptions, item.label];
      });
      let updatedNodeData = { ...rowNode.data };
      // setting all dc options to false
      Object.keys(updatedNodeData).forEach((key) => {
        if (typeof updatedNodeData[key] === "boolean") {
          if (dcOptions.includes(key)) {
            updatedNodeData[key] = true;
          } else {
            updatedNodeData[key] = false;
          }
        }
      });
      rowNode.setData(updatedNodeData);
    });
    setFlag_edit(true);
    agGrid.api.flashCells({ rowNodes });
  };

  const onConfirm = async () => {
    try {
      setloader(true);
      setShowModal(false);
      await mapProductToDC({ elements: setAllData })();
      tableInstance.current.api.deselectAll(true);
      updateSetAllData([]);
      setFlag_edit(false);
      setloader(false);
      tableInstance.current.api?.refreshServerSideStore({ purge: false });
      displaySnackMessages("Mapping Data Updated Successfully", "success");
    } catch (err) {
      const errMsg = !isEmpty(err.response.data.message)
        ? err.response.data.message
        : "Something went wrong";
      displaySnackMessages(errMsg, "error");
      setloader(false);
    }
  };

  const onFilter = async () => {
    try {
      setloader(true);
      let dcbody = {
        filters: columnFiltersApplied.current,
      };
      if (dimension === "product") {
        if (onFilterSectionApplied.current == 0) {
          // table filter applied trigger manual callback

          tableInstance.current.api?.refreshServerSideStore({ purge: true });
        } else if (onFilterSectionApplied.current == 1) {
          // column filter applied, no need to trigger callback
          let productcols = await getAllDCData(dcbody, "product");
          let updatedprdcols = columnUpdate(productcols);
          setproductCols(updatedprdcols);
        }
      } else {
        if (onFilterSectionApplied.current == 0) {
          // table filter applied trigger manual callback

          tableInstance.current.api?.refreshServerSideStore({ purge: true });
        } else if (onFilterSectionApplied.current == 1) {
          // column filter applied, no need to trigger callback

          let stylecols = await getAllDCData(dcbody, "style");
          let updatedcols = columnUpdate(stylecols);
          setstyleCols(updatedcols);
        }
      }
      setloader(false);
    } catch (error) {
      setloader(false);
    }
  };

  const onFilterDashboardClick = (
    dependencyData,
    filterData,
    filterSectionApplied
  ) => {
    onFilterSectionApplied.current = filterSectionApplied;
    if (filterSectionApplied == 1) {
      // if column filter is applied only update column filter ref
      columnFiltersApplied.current = dependencyData;
    } else {
      // if table filter is applied only update onFilterDependency filter ref
      onFilterDependency.current = dependencyData;
    }
    onFilter();
  };

  const dcProductMappingManualCallBack = async (
    manualbody,
    pageIndex,
    pageSize
  ) => {
    setloader(true);
    try {
      let body = {
        filters: onFilterDependency.current,
        meta: {
          ...manualbody,
          limit: { limit: 10, page: pageIndex + 1 },
        },
      };
      let dcbody = {
        filters: columnFiltersApplied.current,
      };

      if (dimension === "product") {
        let productcols = await getAllDCData(dcbody, "product");
        let updatedprdcols = columnUpdate(productcols);
        setproductCols(updatedprdcols);
        let { data: product } = await getAllProductDC(body);
        let productdata = await getData(product.data, productcols, "product");
        setloader(false);

        return {
          data: productdata,
          totalCount: product.total,
        }; // returning for server side pagination on ag grid
      } else {
        let stylecols = await getAllDCData(dcbody, "style");
        let updatedcols = columnUpdate(stylecols);
        setstyleCols(updatedcols);
        let allstyledata = await getAllStyleDC(body);
        let processedData = await processStyle(
          allstyledata.data.data,
          stylecols
        );
        setloader(false);

        return {
          data: processedData,
          totalCount: allstyledata.data.total,
        }; // returning for server side pagination on ag grid
      }
    } catch (err) {
      setloader(false);
    }
  };

  const saveRequest = () => {
    if (setAllData.length) {
      setShowModal(true);
    } else {
      displaySnackMessages("There is no change to save.", "warning");
    }
  };

  const handleChangeDimension = () => {
    if (dimension === "style") {
      setColumns(productCols);
      changeDimension("product");
    } else {
      setColumns(styleCols);
      changeDimension("style");
    }
    setSelectedRowsIDs([]);
  };

  // const onReset = () => {
  //   updateData([]);
  // };

  const onCancel = () => {
    tableInstance.current.api?.refreshServerSideStore({ purge: false });
    tableInstance.current.api.deselectAll(true);
    updateSetAllData([]);
    setFlag_edit(false);
  };

  const displaySnackMessages = (message, variance, onClose) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
        ...(onClose && { onClose: onClose }),
      },
    });
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows().map((item) => {
      if (dimension === "product") {
        return {
          product_code: item.product_code,
        };
      } else {
        return {
          style: item.style,
        };
      }
    });
    setSelectedRowsIDs(selections);
  };

  // function is called on edit action in table
  const onCellValueChanged = (params) => {
    let dataSet = {};
    if (dimension === "product") {
      dataSet = {
        product_code: params.data.product_code,
        dc: {
          map: params.newValue ? [params.colDef.dc_code] : [],
          unmap: !params.newValue ? [params.colDef.dc_code] : [],
        },
      };
    } else {
      dataSet = {
        style: params.data.style,
        dc: {
          map: params.newValue ? [params.colDef.dc_code] : [],
          unmap: !params.newValue ? [params.colDef.dc_code] : [],
        },
      };
    }

    // Check for duplicate updation and filter unecessary changes
    const newDataSet = setAllData.filter((item) => {
      if (dimension === "product") {
        return dataSet.product_code != item.product_code;
      } else {
        return dataSet.style != item.style;
      }
    });
    if (newDataSet.length === setAllData.length) {
      updateSetAllData([...setAllData, dataSet]);
    } else {
      updateSetAllData(newDataSet);
    }
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props.inventorysmartModulesPermission,
      props.module,
      subModuleName,
      action
    );
  };

  const renderContent = () => {
    let formData = [
      {
        ...customSetAllField("dc", "list", "Map DCs"),
        options: dcList,
        isMulti: true,
        is_required: false,
        required: false,
      },
    ];

    return (
      <CoreComponentScreen
        showPageRoute={false}
        // Filter dashboard props
        showFilterDashboard={true}
        filterConfigKey={"dcToProductMappingFilterConfiguration"}
        onApplyFilter={onFilterDashboardClick}
      >
        <Prompt when={flag_edit} message={""} />
        <div data-testid="filterContainer">
          {confirmBox && (
            <ConfirmBox
              onClose={() => showConfirmBox(false)}
              onConfirm={() => {
                onCancel();
                showConfirmBox(false);
              }}
            />
          )}
          <ConfirmPrompt
            showModal={showModal}
            title="Confirm Changes"
            message="Are you sure to save all your changes?"
            ariaLabeledBy="confirm-changes-dialog"
            primaryBtnText="Update"
            secondaryBtnText="Close"
            showCloseIcon={true}
            setConfirm={setShowModal}
            confirmCallback={(val) => {
              if (val) {
                onConfirm();
              }
            }}
          />
          <Loader loader={showloader}>
            <Container data-testid="resultContainer" maxWidth={false}>
              <div
                className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
              >
                <Typography variant="h6" gutterBottom>
                  Filtered Table
                </Typography>

                <div className={globalClasses.centerAlign}>
                  {dynamicLabelsBasedOnTenant("product", "core")}
                  &nbsp;level
                  {showStyleLevelData && (
                    <>
                      <Switch
                        className="switch"
                        onChange={handleChangeDimension}
                      ></Switch>
                      Style level
                    </>
                  )}
                </div>

                {/* {showStyleLevelData && (
                  <div
                    className={`${globalClasses.flexRow} ${globalClasses.verticalAlignCenter}`}
                  >
                    Product level
                    <Switch
                      className="switch"
                      onChange={handleChangeDimension}
                    ></Switch>
                    Style level
                  </div>
                )} */}
                <div
                  className={`${globalClasses.flexRow} ${globalClasses.verticalAlignCenter} ${globalClasses.gap}`}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      if (selectedRowsIDs.length > 0) {
                        tableInstance.current.trigerSetAll(true);
                      } else {
                        displaySnackMessages(
                          "Please select atleast one Product",
                          "error"
                        );
                      }
                    }}
                    // disabled={!props.isSuperUser}
                  >
                    Set All
                  </Button>
                </div>
              </div>
              {dimension === "product" && (
                <AgGridComponent
                  columns={columns}
                  selectAllHeaderComponent={true}
                  uniqueRowId={"product_code"}
                  sizeColumnsToFitFlag
                  onSelectionChanged={onSelectionChanged}
                  onGridChanged
                  manualCallBack={(body, pageIndex, params) =>
                    dcProductMappingManualCallBack(body, pageIndex, params)
                  }
                  rowModelType="serverSide"
                  serverSideStoreType="partial"
                  cacheBlockSize={10}
                  loadTableInstance={setNewTableInstance}
                  hideSelectAllRecords={true}
                  onSetAllApply={onSetAllApply}
                  customSetAllFields={formData}
                  onCellValueChanged={onCellValueChanged}
                />
              )}
              {dimension === "style" && (
                <AgGridComponent
                  columns={columns}
                  selectAllHeaderComponent={true}
                  uniqueRowId={"style"}
                  sizeColumnsToFitFlag
                  onSelectionChanged={onSelectionChanged}
                  onGridChanged
                  manualCallBack={(body, pageIndex, params) =>
                    dcProductMappingManualCallBack(body, pageIndex, params)
                  }
                  rowModelType="serverSide"
                  serverSideStoreType="partial"
                  cacheBlockSize={10}
                  loadTableInstance={setNewTableInstance}
                  hideSelectAllRecords={true}
                  onSetAllApply={onSetAllApply}
                  customSetAllFields={formData}
                  onCellValueChanged={onCellValueChanged}
                />
              )}
            </Container>
            <div
              className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  saveRequest();
                }}
                disabled={
                  !canTakeActionOnModules(
                    INVENTORY_SUBMODULES_NAMES.INVENTORY_DC_MAPPING,
                    "edit"
                  )
                }
              >
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  if (setAllData.length) {
                    showConfirmBox(true);
                  } else {
                    displaySnackMessages(
                      "There is no change to save.",
                      "warning"
                    );
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </Loader>
        </div>
      </CoreComponentScreen>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
}
const mapStateToProps = (state) => {
  return {
    selectedFilters: state.filterReducer.selectedFilters["dctoproduct"],
    selecteddcFilters: state.filterReducer.selectedFilters["dctofilter"],
    isSuperUser:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer.isSuperUser,
    filterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "dcToProductMappingFilterConfiguration"
      ],
    inventorysmartModulesPermission:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (body) => dispatch(addSnack(body)),
    setProductStatusData: (data) => dispatch(setProductStatusData(data)),
    setActiveScreenName: (data) => dispatch(setActiveScreenName(data)),
    setFilterConfiguration: (data) => dispatch(setFilterConfiguration(data)),
    getTenantConfigApplicationLevel: (dynamicRoute, queryParam) =>
      dispatch(getTenantConfigApplicationLevel(dynamicRoute, queryParam)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DCtoProduct);
