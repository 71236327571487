import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Loader from "../../Utils/Loader/loader";
import "./index.scss";
import { Container, Button, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  setProductStatusData,
  getProductStatusData,
  setStatusData,
  createNewProduct,
  setUpdateStatusData,
  getStatusData,
} from "../../actions/productStoreStatusActions";
import AddIcon from "@mui/icons-material/Add";
import { getColumnsAg } from "../../actions/tableColumnActions";
import CreateStoreModal from "./components/create-store";
import ConfirmBox from "../../Utils/reactTable/components/confirmPopup";
import { Prompt } from "react-router";
import { END_DATE } from "../../config/constants";
import globalStyles from "Styles/globalStyles";
import SetAllMultiRow from "Utils/reactTable/components/setall-multirow-form";
import { uniq, isEmpty, capitalize, isEqual, cloneDeep } from "lodash";
import {
  dateValidationMessage,
  isDateRangeConflict,
} from "Utils/functions/helpers/validation-helpers";
import AgGridComponent from "Utils/agGrid";
import { addSnack } from "actions/snackbarActions";
import ConflictResolutionModal from "./components/conflict-resolution-modal";
import CellRenderers from "Utils/agGrid/cellRenderer";
import Delete from "@mui/icons-material/Delete";
import colours from "Styles/colours";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import {
  formatAttribute,
  checkConflictInAttributeType,
  getUpdatedSetAllData,
  getListOptions,
} from "./utils";
import { setDisabledDates } from "Utils/functions/utils";
import { getTenantConfigApplicationLevel } from "actions/tenantConfigActions";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import { setFilterConfiguration } from "actions/filterAction";

import { isActionAllowedOnSubModule } from "modules/inventorysmart/pages-inventorysmart/inventorysmart-utility";
import { INVENTORY_SUBMODULES_NAMES } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    padding: "0px",
  },
}));

function StoreFilter(props) {
  const [filterData, setFilterData] = useState([]);
  const [showloader, setloader] = useState(true);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [createStore, showCreateStore] = useState(false);
  const [setAllData, updateSetAllData] = useState([]);
  const [setAll, toggleSetAll] = useState(false);
  const [confirmBox, showConfirmBox] = useState(false);
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [flag_edit, setFlag_edit] = useState(false);
  const onFilterDependency = useRef([]);
  const tableInstance = useRef({});
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [resolutionType, setResolutionType] = useState("hard_reset");
  const [deleteActionObj, setDeleteActionObj] = useState({});
  const [editActionObj, setEditActionObj] = useState({});
  const [showCreateStoreBtn, setShowCreateStoreBtn] = useState(true);

  const setNewTableInstance = (params) => {
    tableInstance.current = params;
  };

  // called when delete icon is clicked and delete object is set
  useEffect(() => {
    if (!isEmpty(deleteActionObj)) {
      updateSetAllDataHandler(deleteActionObj, "delete");
    }
  }, [deleteActionObj]);

  // called when edit icon is clicked and edit object is set
  useEffect(() => {
    if (!isEmpty(editActionObj)) {
      updateSetAllDataHandler(editActionObj, "edit");
    }
  }, [editActionObj]);

  // handles edit/delete operations in setAllData
  const updateSetAllDataHandler = (inputActionObj, updateActionType) => {
    const { isEdited, updatedSetAllData } = getUpdatedSetAllData(
      inputActionObj,
      updateActionType,
      setAllData
    );

    if (isEdited) {
      // if attribute present then update in existing object
      updateSetAllData(updatedSetAllData);
    } else {
      //else add new attribute object
      updateSetAllData([...setAllData, inputActionObj]);
    }
  };

  const onFilterDashboardClick = (dependencyData) => {
    onFilterDependency.current = dependencyData;
    fetchValuesBasedOnFilterApplied(true);
  };

  const fetchValuesBasedOnFilterApplied = (unsavedCheck) => {
    if (unsavedCheck && setAllData.length) {
      showConfirmBox(true);
      throw Error("Unsaved changes");
    }
    let body = {
      meta: {
        range: [],
        sort: [],
        search: [],
        limit: { limit: 10, page: 0 },
      },
    };
    tableInstance.current.api?.refreshServerSideStore({ purge: true });
    storeStatusManualCallBack(body.meta, 0);
    tableInstance.current.api.deselectAll(true);
  };

  const editableFieldsList = React.useMemo(() => {
    const cloneStoreColumns = cloneDeep(columns);
    let editableFields = cloneStoreColumns.filter(
      (item) => !item.is_hidden && item.is_editable && !item.system_field
    );

    editableFields = editableFields.map((item) => {
      if (item.type === "datetime") {
        item.type = "DateTimeField";
        item.disablePast = true;
      }
      return item;
    });
    return [
      {
        fields: editableFields,
        addRowLabel: "Add Status",
        id: "status",
        rowCount: 0,
      },
    ];
  }, [columns]);

  const multiValueAggregate = (value) => {
    value = value.filter((val) => val !== null);
    return value.length > 0 ? value.join(`${","}${" "}`) : "-";
  };

  useEffect(() => {
    const getInitialData = async () => {
      try {
        let cols = await getColumnsAg("table_name=store_status")();
        const statusValues = await getStatusData("store");
        // updating column properties

        let permissionCheckToDisable = canTakeActionOnModules(
          INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_STATUS,
          "edit"
        );
        cols = cols.map((item) => {
          if (item.column_name === "status") {
            item.showFilter = true;
            item.options = getListOptions(statusValues);
          }
          if (item.type === "datetime") {
            item.disablePast = false;
            item.shouldDisableDate = setDisabledDates;
          }
          if (item.column_name === "store_code") {
            item.cellRenderer = "agGroupCellRenderer";
          }
          return item;
        });
        // adding custom renderer to aggregate status and and display date field in only sub rows
        cols = cols.map((item) => {
          if (item.column_name === "status") {
            item.disabled = !permissionCheckToDisable;
            item.cellRenderer = (params, extraProps) => {
              if (params.node.level === 0) {
                const valueSet = params.data.status_obj
                  ? uniq(
                      params.data.status_obj.map((value) =>
                        capitalize(value.status)
                      )
                    )
                  : [];
                return multiValueAggregate(valueSet);
              } else {
                return (
                  <CellRenderers
                    cellData={params}
                    column={item}
                    extraProps={extraProps}
                    actions={null}
                  ></CellRenderers>
                );
              }
            };
          } else if (item.type === "datetime") {
            item.disabled = !permissionCheckToDisable;
            item.cellRenderer = (params, extraProps) => {
              if (params.node.level !== 0) {
                return (
                  <CellRenderers
                    cellData={params}
                    column={item}
                    extraProps={extraProps}
                    actions={null}
                  ></CellRenderers>
                );
              } else return "";
            };
          }
          return item;
        });
        // pushing a column for delete action
        cols.push({
          headerName: "Delete",
          maxWidth: 95,
          cellRenderer: (params, extraProps) => {
            if (params.node.level !== 0) {
              return (
                <div>
                  <IconButton
                    variant="text"
                    color="primary"
                    className={classes.actionButton}
                    onClick={() => onDeleteClick(params)}
                    disabled={
                      !canTakeActionOnModules(
                        INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_STATUS,
                        "delete"
                      )
                    }
                    title="Delete"
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </div>
              );
            } else {
              return null;
            }
          },
          editable: false,
          colId: "action",
        });

        const data = props?.roleBasedAccess
          ? await fetchFilterFieldValues(
              "store status",
              [],
              true,
              props.screenName,
              1
            )
          : await fetchFilterFieldValues("store status");

        if (isEmpty(props.filterDashboardConfiguration)) {
          let filterConfigData = [
            {
              filterDashboardData: data,
              expectedFilterDimensions: ["store"],
              screenDimension: "store",
              isCrossDimensionFilter: false,
            },
          ];
          if (props?.roleBasedAccess) {
            filterConfigData[0] = {
              ...filterConfigData[0],
              is_urm_filter: true,
              screen_name: props.screenName,
              application_code: 1,
            };
          }
          const filterConfig = formattedFilterConfiguration(
            "storeStatusFilterConfiguration",
            filterConfigData,
            "Store Status"
          );
          props.setFilterConfiguration(filterConfig);
        } else {
          onFilterDependency.current =
            props.filterDashboardConfiguration.appliedFilterData.dependencyData;
        }

        setColumns(cols);
        setFilterData(data);
        setloader(false);

        let showCreateStoreBtnResp =
          await props.getTenantConfigApplicationLevel(3, {
            attribute_name: "core_show_create_product_store_dc",
          });

        if (showCreateStoreBtnResp?.data?.data?.[0]?.["attribute_value"]) {
          setShowCreateStoreBtn(
            showCreateStoreBtnResp?.data?.data?.[0]?.["attribute_value"]?.value
          );
        }
      } catch (error) {
        setloader(false);
      }
    };

    getInitialData();
  }, []);

  const storeStatusManualCallBack = async (manualbody, pageIndex, params) => {
    setloader(true);
    let body = {
      filters: onFilterDependency.current,
      meta: {
        ...manualbody,
        search: manualbody.search,
        limit: { limit: 10, page: pageIndex + 1 },
      },
      headers: [],
      selection: {
        data: tableInstance?.current?.api?.checkConfiguration,
        unique_columns: ["store_code"],
      },
    };
    try {
      let response = await getProductStatusData("store", body)();
      response.data.data = response.data.data.map((item) => {
        item.isDisabled = false;
        item.hasConflict = false;

        return item;
      });
      setloader(false);
      return {
        data: response.data.data,
        totalCount: response.data.total,
      }; // returning for server side pagination on ag grid
    } catch (err) {
      setloader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const dateValidation = (startDate, endDate) => {
    const startdate = new Date(startDate);
    const enddate = new Date(endDate);
    const errMessage = dateValidationMessage(startdate, enddate);
    if (errMessage) {
      displaySnackMessages(errMessage, "error");
      throw Error("Date is not correct");
    }
  };

  // set all, on apply action
  const setAllChanges = async (formattedAttributes) => {
    let setAllBody = {
      attributes: formattedAttributes,
      conflict_resolution: resolutionType,
      codes: {
        filters: onFilterDependency.current,
        meta: {
          range: [],
          sort: [],
          search: [],
        },
        headers: [],
        selection: {
          data: tableInstance?.current?.api?.checkConfiguration,
          unique_columns: ["store_code"],
        },
      },
    };
    toggleSetAll(false);
    // patch api call for set all action
    await onConfirm(setAllBody, true);
  };

  const formatSetAllData = (input, fieldRowId) => {
    let setAllOutput = [];
    for (let i = 0; i <= fieldRowId; i++) {
      const str = "_" + i;

      if (input["status" + str]) {
        const row = {
          attribute_name: "status",
          attribute_value: input["status" + str],
          start_time: input["status_start_time" + str],
          end_time:
            input["status_end_time" + str] &&
            input["status_end_time" + str] !== "Invalid date"
              ? input["status_end_time" + str]
              : END_DATE,
        };
        dateValidation(row.start_time, row.end_time);
        setAllOutput.push(row);
      }
    }

    let inputList = setAllOutput.map((item) => {
      return {
        start_time: item.start_time,
        end_time: item.end_time,
      };
    });

    // checking if conflict exists in date ranges
    const hasConflict = isDateRangeConflict(inputList, "YYYY-MM-DD", "[]");
    if (hasConflict) {
      displayConflictError();
    }

    return setAllOutput;
  };

  const saveRequest = () => {
    if (setAllData.length) {
      setShowModal(true);
    } else {
      displaySnackMessages("There is no change to save.", "warning");
    }
  };

  const displayConflictError = () => {
    displaySnackMessages("Please resolve overlapping date ranges", "error");
    throw Error("Date is overlapping");
  };

  // checks if conflict error is present in tabledata after edit/delete
  const isDateRangeConflictInData = () => {
    let hasConflictFlag = false;
    tableInstance.current.api.getRenderedNodes().forEach((item) => {
      if (item.data.hasConflict) {
        hasConflictFlag = true;
      }
    });
    if (hasConflictFlag) {
      displayConflictError();
    }
  };

  const onConfirm = async (payloadData, isSetAllAction = false) => {
    setloader(true);
    try {
      setShowModal(false);
      // set all patch api call
      isSetAllAction && (await setStatusData("store", payloadData)());
      // edit/delete patch api call
      if (!isSetAllAction) {
        // check if conflicts present in data
        isDateRangeConflictInData();
        await setUpdateStatusData("store", { body: payloadData })();
      }
      setFlag_edit(false);
      tableInstance.current.api.deselectAll(true);
      updateSetAllData([]);
      setloader(false);
      tableInstance.current.api?.refreshServerSideStore({ purge: false });
      displaySnackMessages("Store Data Updated Successfully", "success");
    } catch (err) {
      const errMsg = !isEmpty(err.response?.data.message)
        ? err.response.data.message
        : "Unsuccessfull at updating store status attributes";
      displaySnackMessages(errMsg, "error");
      setloader(false);
    }
  };

  const createNewStoreFunc = async (dataObj, newSetAllData) => {
    setloader(true);
    try {
      let body = [];
      let setAllBody = {
        attributes: [
          {
            attribute_name: "status",
            attribute_value: newSetAllData.status,
            start_time: moment(newSetAllData.start_time).format("YYYY-MM-DD"),
            end_time: moment(newSetAllData.end_time).format("YYYY-MM-DD"),
          },
        ],
        codes: [dataObj.store_code],
      };
      Object.keys(dataObj).forEach((key) => {
        if (key !== "store_code" && dataObj[key]) {
          let obj = {
            attribute_name: key,
            attribute_value: dataObj[key],
          };
          body.push(obj);
        }
      });
      let reqBody = {
        attributes: body,
        code: dataObj.store_code,
      };
      dateValidation(
        setAllBody.attributes[0].start_time,
        setAllBody.attributes[0].end_time
      );
      if (dataObj.store_code) {
        await createNewProduct("store", reqBody)();

        if (Object.keys(newSetAllData).length > 0)
          await setStatusData("store", setAllBody)();
        displaySnackMessages("Store Data Updated Successfully", "success");
        // onFilter();
        return true;
      } else {
        displaySnackMessages("Please enter Store Id", "error");
      }
      setloader(false);
    } catch (err) {
      displaySnackMessages("Failed to create a store.", "error");
      setloader(false);
    }
  };

  const handleConfirmBox = () => {
    tableInstance.current.api?.refreshServerSideStore({ purge: false });
    tableInstance.current.api.deselectAll(true);
    updateSetAllData([]);
    setFlag_edit(false);
    showConfirmBox(false);
  };

  const createStoreFormFields = () => {
    let formData = filterData
      .filter((item) => item.column_name !== "status")
      .map((item) => {
        if (item.column_name === "special_classification") {
          return {
            label: item.label,
            field_type: "list",
            filter_type: item.type,
            options: item.initialData,
            required: false,
            accessor: item.column_name,
          };
        } else {
          return {
            label: item.label,
            field_type: "list",
            filter_type: item.type,
            options: item.initialData,
            required: true,
            accessor: item.column_name,
          };
        }
      });
    let Additionalfields = [
      {
        label: "Store ID",
        field_type: "TextField",
        required: true,
        accessor: "store_code",
      },
      {
        label: "Store Name",
        field_type: "TextField",
        required: true,
        accessor: "store_name",
      },
    ];
    return [...formData, ...Additionalfields];
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows().map((item) => {
      return {
        store_code: item.store_code,
      };
    });
    setSelectedRowsIDs(selections);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  // function is called on delete action in table
  const onDeleteClick = (event) => {
    const statusId = event.data.time_attr_id;
    const parentRowNode = event.node.parent;
    // removing deleted status
    const updated_status_obj = parentRowNode.data.status_obj.filter(
      (item) => item.time_attr_id != statusId
    );
    // updating parent row sub rows data
    const updated_data = {
      ...parentRowNode.data,
      status_obj: isEmpty(updated_status_obj) ? null : updated_status_obj,
    };
    parentRowNode.setData(updated_data);
    conflictResolutionCheck(parentRowNode);
    const setAllObject = formatAttribute(
      parentRowNode.id,
      event,
      "status",
      "delete"
    );
    setDeleteActionObj(setAllObject);
    setFlag_edit(true);
    // table actions
    // tableInstance.current.api.collapseAll();
    parentRowNode.setExpanded(false);
    tableInstance.current.api.flashCells({ rowNodes: [parentRowNode] });
    if (updated_data.status_obj) parentRowNode.setExpanded(true);
  };

  // checks and manages conflict error in store status data
  const conflictResolutionCheck = (parentRowNode) => {
    const currentDateRangeConflict = parentRowNode.data.hasConflict;
    const statusObj = cloneDeep(parentRowNode.data.status_obj);

    if (statusObj) {
      const newDateRangeConflict = checkConflictInAttributeType(
        statusObj,
        "status"
      );
      if (currentDateRangeConflict === false && newDateRangeConflict) {
        parentRowNode.setData({ ...parentRowNode.data, hasConflict: true });
        displaySnackMessages("Please resolve overlapping date ranges", "error");
      } else if (currentDateRangeConflict === true && !newDateRangeConflict) {
        parentRowNode.setData({ ...parentRowNode.data, hasConflict: false });
        displaySnackMessages(
          "Successfully resolved overlapping date ranges",
          "success"
        );
      }
    }
  };

  // function is called on edit action in table
  const onCellValueChanged = (params) => {
    const parentRowNode = params.node.parent;
    let isInputValueSame = false;

    if (moment.isMoment(params.newValue)) {
      isInputValueSame = moment(params.newValue).isSame(params.oldValue);
    } else {
      isInputValueSame = isEqual(params.oldValue, params.newValue);
    }

    if (!isInputValueSame) {
      conflictResolutionCheck(parentRowNode);
      const setAllObject = formatAttribute(
        parentRowNode.id,
        params,
        "status",
        "edit"
      );
      setEditActionObj(setAllObject);
      // table actions
      tableInstance.current.api.refreshCells({
        force: true,
        suppressFlash: false,
        rowNodes: [parentRowNode],
      });
      setFlag_edit(true);
      tableInstance.current.api.flashCells({ rowNodes: [parentRowNode] });
    }
  };

  // row highlighting based on conflict present in status
  const getRowStyle = (params) => {
    if (params.node.level === 0 && params.node.data) {
      if (params.node.data.hasConflict) return { background: colours.wispPink };
    }

    return null;
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props?.inventorysmartModulesPermission,
      props?.module,
      subModuleName,
      action
    );
  };

  const renderContent = () => {
    return (
      <CoreComponentScreen
        pageLabel={"Store Status"}
        showPageRoute={props.hideBreadCrumbs ? false : true}
        showPageHeader={true}
        // Filter dashboard props
        showFilterDashboard={true}
        filterConfigKey={"storeStatusFilterConfiguration"}
        onApplyFilter={onFilterDashboardClick}
      >
        <div data-testid="filterContainer">
          <Prompt when={flag_edit} message={""} />
          {confirmBox && (
            <ConfirmBox
              onClose={() => {
                showConfirmBox(false);
              }}
              onConfirm={() => handleConfirmBox()}
            />
          )}
          <ConfirmPrompt
            showModal={showModal}
            title="Confirm Changes"
            message="Are you sure to save all your changes?"
            ariaLabeledBy="confirm-changes-dialog"
            primaryBtnText="Update"
            secondaryBtnText="Close"
            showCloseIcon={true}
            setConfirm={setShowModal}
            confirmCallback={(val) => {
              if (val) {
                onConfirm(setAllData);
              }
            }}
          />
          {createStore && (
            <CreateStoreModal
              fields={createStoreFormFields()}
              onApply={createNewStoreFunc}
              toggleError={(errMsg) => {
                displaySnackMessages(errMsg, "error");
              }}
              handleModalClose={() => showCreateStore(false)}
            ></CreateStoreModal>
          )}
          <Loader loader={showloader}>
            <div data-testid="resultContainer">
              <Container maxWidth={false}>
                <div
                  className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
                >
                  <Typography variant="h6" gutterBottom>
                    Filtered Store
                  </Typography>
                  <div
                    className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.verticalAlignCenter}`}
                  >
                    {showCreateStoreBtn && (
                      <Button
                        variant="contained"
                        color="primary"
                        id="createStoreBtn"
                        onClick={() => showCreateStore(true)}
                        disabled={false}
                      >
                        <AddIcon fontSize="small"></AddIcon>{" "}
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      id="storeSetAllBtn"
                      onClick={async () => {
                        if (setAllData.length) {
                          showConfirmBox(true);
                        } else if (selectedRowsIDs.length > 0) {
                          toggleSetAll(true);
                        } else {
                          displaySnackMessages(
                            "Please select atleast one Store",
                            "error"
                          );
                        }
                      }}
                      disabled={
                        !props.isSuperUser ||
                        !canTakeActionOnModules(
                          INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_STATUS,
                          "edit"
                        )
                      }
                    >
                      Set All
                    </Button>
                  </div>
                </div>
                {setAll && (
                  <SetAllMultiRow
                    onApply={setAllChanges}
                    handleModalClose={() => toggleSetAll(false)}
                    formatMultiRowData={formatSetAllData}
                    fieldList={editableFieldsList}
                    additionalContainer={
                      <ConflictResolutionModal
                        resolutionType={resolutionType}
                        setResolutionType={setResolutionType}
                      />
                    }
                  />
                )}
                {columns.length > 0 && (
                  //SERVER SIDE
                  <AgGridComponent
                    columns={columns}
                    selectAllHeaderComponent={true}
                    onSelectionChanged={onSelectionChanged}
                    sizeColumnsToFitFlag
                    onGridChanged
                    manualCallBack={(body, pageIndex, params) =>
                      storeStatusManualCallBack(body, pageIndex, params)
                    }
                    rowModelType="serverSide"
                    serverSideStoreType="partial"
                    cacheBlockSize={10}
                    uniqueRowId={"store_code"}
                    childKey={"status_obj"}
                    hideChildSelection={true}
                    loadTableInstance={setNewTableInstance}
                    showSetAll={false}
                    purgeClosedRowNodes={true}
                    suppressAggFuncInHeader={true}
                    //disabledRowCheckbox={!props.isSuperUser}
                    suppressClickEdit={true}
                    onCellValueChanged={onCellValueChanged}
                    getRowStyle={getRowStyle}
                    groupDisplayType={"custom"}
                    treeData={true}
                    onRowSelected
                  />
                )}
              </Container>
            </div>
            <div
              className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
            >
              <Button
                variant="contained"
                color="primary"
                id="storeSaveBtn"
                onClick={() => {
                  saveRequest();
                }}
                disabled={
                  !canTakeActionOnModules(
                    INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_STATUS,
                    "edit"
                  )
                }
              >
                Save
              </Button>
              <Button
                variant="outlined"
                id="storeCancelBtn"
                onClick={() => {
                  if (setAllData.length) {
                    showConfirmBox(true);
                  } else {
                    tableInstance.current?.api.deselectAll(true);
                    displaySnackMessages(
                      "There is no change to save.",
                      "warning"
                    );
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </Loader>
        </div>
      </CoreComponentScreen>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
}
const mapStateToProps = (state) => {
  return {
    selectedFilters: state.filterReducer.selectedFilters["StoreStatus"],
    isSuperUser: true,
    userAccessList:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer.userAccessList,
    filterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "storeStatusFilterConfiguration"
      ],
    inventorysmartModulesPermission:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProductStatusData: (data) => dispatch(setProductStatusData(data)),
    addSnack: (snack) => dispatch(addSnack(snack)),
    getTenantConfigApplicationLevel: (dynamicRoute, queryParam) =>
      dispatch(getTenantConfigApplicationLevel(dynamicRoute, queryParam)),
    setFilterConfiguration: (filterConfiguration) =>
      dispatch(setFilterConfiguration(filterConfiguration)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreFilter);
