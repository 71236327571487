import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Paper, Typography, Button } from "@mui/material";
import moment from "moment";
import { isEmpty } from "lodash";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import Form from "Utils/form";
import Switch from "Utils/switch";
import Filters from "commonComponents/filters/filterGroup";
import { addSnack } from "actions/snackbarActions";
import { getAllFilters } from "actions/filterAction";
import Loader from "Utils/Loader/loader";
import globalStyles from "Styles/globalStyles";
import { checkForSpecialCharacters } from "Utils/functions/utils";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import colours from "Styles/colours";
import {
  getModuleLevelAccess,
  setInventorySmartModulesPermissions,
  setInventorySmartPermissionLoader,
} from "modules/inventorysmart/services-inventorysmart/common/inventory-smart-common-services";
import NotFound from "commonComponents/notFound/NotFound";

import {
  CREATE_PRODUCT_PROFILE_FORM,
  CREATE_PRODUCT_PROFILE_TIME_PERIOD_DROP_DOWN,
  CREATE_PRODUCT_PROFILE_TIME_PERIOD_DATE_PICKER,
  CREATE_PRODUCT_PROFILE_SALE_ATTRIBUTE,
  CREATE_PRODUCT_PROFILE_PRODUCT_ATTRIBUTE,
  ERROR_MESSAGE,
  ROLES_ACCESS_MODULES_MAPPING,
  APP_NAME,
  FULL_ACCESS_PERMISSIONS_LIST,
  INVENTORY_SUBMODULES_NAMES,
} from "../../../constants-inventorysmart/stringConstants";
import { PRODUCT_PROFILE } from "../../../constants-inventorysmart/routesConstants";
import {
  setNewProductProfileFilterConfiguration,
  setNewProductProfileLoader,
  getCrossFiltersData,
  setProductsToSelectForProductProfile,
  getProductsToSelectForProductProfile,
  setProductsStoreSizePenetration,
  getProductsStoreSizePenetration,
  saveProductProfile,
  resetCreateProductProfile,
  saveEditedProductProfile,
} from "../../../services-inventorysmart/Product-Profile/create-product-profile-service";
import SelectProductTableComponent from "./select-product-table";
import StorePriceContributionComponent from "./store-price-contribution-table";

import { isActionAllowedOnSubModule } from "../../inventorysmart-utility";

const useStyles = makeStyles((theme) => ({
  contentPadding: {
    padding: "1rem",
    width: "30%",
  },
  contentPaddingProductAttributes: {
    padding: "1rem",
    width: "30%",
  },
  timePeriodFormStyle: {
    padding: "1rem",
    display: "flex",
    alignItems: "center",
  },
  timePeriodFormContainer: {
    width: "20%",
    marginRight: "1rem",
  },
  setMarginForButtons: {
    marginRight: "1rem",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    margin: "1rem",
  },
  marginRightText: {
    marginRight: "0.5rem",
  },
  warningText: {
    color: theme.palette.error.main,
    marginTop: "0.5rem",
  },
}));

const CreateProductProfileFilters = (props) => {
  let maxLimitValue =
    props.dynamicLabels?.article === "Product" ? "9999" : "999999";
  const [productProfileForm, setProductProfileForm] = useState({
    profileName: "",
    profileDescription: "",
  });
  const [
    productProfileSaleAttribute,
    setProductProfileSaleAttribute,
  ] = useState({});
  const [
    productProfileProductAttribute,
    setProductProfileProductAttribute,
  ] = useState({
    min: "1",
    max: maxLimitValue,
  });
  const [productProfileTimePeriod, setProductProfileTimePeriod] = useState({});
  const [productProfileRangePeriod, setProductProfileRangePeriod] = useState(
    {}
  );
  const [timePeriodToggle, setTimePeriodToggle] = useState(true);
  const [filterConfig, setFilterConfig] = useState([]);
  const [initialDependencyValue, setInitialDependencyValue] = useState([]);
  const [displaySelectProductsTable, setDisplaySelectProductsTable] = useState(
    false
  );
  const [
    displayStorePriceContributionTable,
    setDisplayStorePriceContributionTable,
  ] = useState(false);

  const [productFilterConfig, setProductFilterConfig] = useState([]);
  const [storeFilterConfig, setStoreFilterConfig] = useState([]);
  const [selectedArticleList, setSelectedArticleList] = useState([]);
  const [
    displaySaleAttributesDynamically,
    setDisplaySaleAttributesDynamically,
  ] = useState();

  const classes = useStyles();
  const globalClasses = globalStyles();

  const homeIcon = [
    {
      label: `Create ${dynamicLabelsBasedOnTenant("article")} Profile`,
      id: 1,
    },
  ];

  useEffect(() => {
    if (props.inventorysmartScreenConfig) {
      const fetchModulesAccess = async () => {
        try {
          props.setInventorySmartPermissionLoader(true);
          // props.module is fetched  from routes
          const moduleName = props?.module;
          const subModules = ROLES_ACCESS_MODULES_MAPPING[props?.module];

          let rolesBasedModulesPermission = {};
          if (props.inventorysmartScreenConfig.roleBasedAccess) {
            await Promise.all(
              subModules.map(async (module) => {
                const accessDataResponse = await props?.getModuleLevelAccess({
                  app: APP_NAME,
                  module,
                });

                rolesBasedModulesPermission[module] = Object.keys(
                  accessDataResponse.data.data
                );
              })
            );
          } else {
            subModules.map(async (subModule) => {
              rolesBasedModulesPermission[
                subModule
              ] = FULL_ACCESS_PERMISSIONS_LIST;
            });
          }

          props?.setInventorySmartModulesPermissions({
            [moduleName]: rolesBasedModulesPermission,
          });
        } catch (error) {
          displaySnackMessages(ERROR_MESSAGE, "error");
        } finally {
          props.setInventorySmartPermissionLoader(false);
        }
      };
      fetchModulesAccess();
      return () => {
        props.resetProductProfile();
      };
    }
  }, [props.inventorysmartScreenConfig]);

  useEffect(() => {
    const getInitialFilterConfiguration = async () => {
      try {
        props.setNewProductProfileLoader(true);
        let response = await getAllFilters("create product profile")();
        props.setNewProductProfileFilterConfiguration(response.data.data);
        if (!response.data.data?.length)
          props.setNewProductProfileLoader(false);
      } catch (e) {
        props.setNewProductProfileLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    getInitialFilterConfiguration();
    return () => {
      props.location.state = {};
      props.resetCreateProductProfile();
    };
  }, []);

  useEffect(() => {
    if (props.inventorysmartScreenConfig) {
      props.setNewProductProfileLoader(true);
      const getFilterValues = async () => {
        try {
          if (props.newProductProfileFilterConfiguration.length) {
            let storeTypeFilters = props.newProductProfileFilterConfiguration.filter(
              (item) => item.dimension === "store"
            );
            let productTypeFilters = props.newProductProfileFilterConfiguration.filter(
              (item) => item.dimension === "product"
            );
            const filterElements = [
              ...storeTypeFilters,
              ...productTypeFilters,
            ].map(async (key) => {
              let body = {
                attribute_name: key.column_name,
                filter_type: key.type,
                filters: [],
                meta: {},
                dimension: key.dimension,
                application_code: 1,
              };
              if (props.inventorysmartScreenConfig.roleBasedAccess) {
                body.is_urm_filter = true;
                body.screen_name = props.screenName;
              }
              const configurationOptions = await props.getCrossFiltersData(
                body
              );
              return {
                ...key,
                filter_keyword: key.column_name,
                is_disabled: !isEmpty(props.location.state) && key.is_mandatory,
                initialData: configurationOptions.data.data.attribute.map(
                  (item) => {
                    return {
                      value: item.attribute,
                      label: item.attribute,
                      id: item.attribute,
                    };
                  }
                ),
              };
            });
            let filterData = await Promise.all(filterElements);
            setFilterConfig(filterData);
            props.setNewProductProfileLoader(false);
          }
        } catch (err) {
          displaySnackMessages(ERROR_MESSAGE, "error");
          props.setNewProductProfileLoader(false);
        }
      };
      getFilterValues();
    }
  }, [
    props.newProductProfileFilterConfiguration,
    props.inventorysmartScreenConfig,
  ]);

  useEffect(async () => {
    if (!isEmpty(props.location.state)) {
      let name = props.location.state.name;
      let desc = props.location.state.description;
      setDateTypeValeOnEdit(props.location.state);
      // setting filters
      if (props.newProductProfileFilterConfiguration.length) {
        setPreSelectedFilters(props.location.state);
      }
      setProductProfileForm({
        ...productProfileForm,
        profileName: name,
        profileDescription: desc,
      });
      let salesAttributes = {};
      if (props.dynamicLabels?.article !== "Product") {
        salesAttributes = Object.assign({}, productProfileSaleAttribute);
      } else {
        let parseData = JSON.parse(
          props.location.state?.sales_attribute.join(",")
        );
        salesAttributes = Object.assign({}, parseData);
      }
      setProductProfileSaleAttribute(salesAttributes);
      let minMaxValOnEdit = {
        min: props.location.state.min_price,
        max: props.location.state.max_price,
      };
      setProductProfileProductAttribute(minMaxValOnEdit);
    }
  }, [props.location.state, props.newProductProfileFilterConfiguration]);

  useEffect(() => {
    let saleAttributes = [...CREATE_PRODUCT_PROFILE_SALE_ATTRIBUTE];
    // removing the additional sales attribute on basis of tenant
    if (props.dynamicLabels?.article === "Product") {
      // vb
      let filteredSaleAttributes = saleAttributes.filter(
        (item) => item.accessor !== "promo"
      );
      setDisplaySaleAttributesDynamically(filteredSaleAttributes);
    } else {
      // signet
      let filteredSaleAttributes = saleAttributes.filter(
        (item) => item.accessor !== "retirement"
      );
      setDisplaySaleAttributesDynamically(filteredSaleAttributes);
    }
  }, [props.dynamicLabels]);

  useEffect(() => {
    if (!isEmpty(props.inventorysmartScreenConfig)) {
      let salesAttrsKeys = props.inventorysmartScreenConfig?.inventorysmart_create_product_profile?.drillDown?.salesAttributes?.map(
        (item) => {
          return {
            [item]: true,
          };
        }
      );
      let salesAttrsKeysFormatted = Object.assign({}, ...salesAttrsKeys);
      setProductProfileSaleAttribute(salesAttrsKeysFormatted);
    }
  }, [props.inventorysmartScreenConfig]);

  const setDateTypeValeOnEdit = (editState) => {
    let dateType = "";
    if (editState.date_type === "dynamic") {
      dateType = {
        productProfileTimePeriod: editState.date_value,
      };
      setProductProfileTimePeriod(dateType);
      setTimePeriodToggle(true);
    } else {
      let splitDate = editState.date_value.split(" to ");
      let start = moment(splitDate[0]);
      let end = moment(splitDate[1]);
      dateType = {
        productProfileRangePeriod: [start, end],
      };
      setProductProfileRangePeriod(dateType);
      setTimePeriodToggle(false);
    }
  };

  const setPreSelectedFilters = (editState) => {
    let filtersSelected = [];
    props.newProductProfileFilterConfiguration.forEach((key) => {
      if (Object.keys(editState).includes(key.column_name)) {
        let filterValues = {
          filter_id: key.column_name,
          filter_type: "cascaded",
          values: mapProductProfileFilterValues(editState[key.column_name]),
          dimension: key.dimension,
        };
        filtersSelected.push(filterValues);
      }
    });
    setInitialDependencyValue(filtersSelected);
    let productFilterArrays = [];
    filtersSelected.forEach((item) => {
      if (item.filter_id === "channel") {
        let storeTypeFilterConfig = [
          {
            attribute_name: item.filter_id,
            operator: "in",
            values: Array.isArray(item.values)
              ? item.values.map((option) => option.value)
              : item.values,
            filter_type: item.filter_type,
          },
        ];
        setStoreFilterConfig(storeTypeFilterConfig);
        if (props.dynamicLabels?.article === "Product") {
          renderSalesAttributesBasedOnChannel(storeTypeFilterConfig);
        }
      } else {
        let productTypeFilterConfig = {
          attribute_name: item.filter_id,
          operator: "in",
          values: Array.isArray(item.values)
            ? item.values.map((option) => option.value)
            : item.values,
          filter_type: item.filter_type,
        };
        productFilterArrays.push(productTypeFilterConfig);
        setProductFilterConfig(productFilterArrays);
      }
    });
  };

  const mapProductProfileFilterValues = (data) => {
    if (Array.isArray(data))
      return data.map((obj) => {
        return {
          value: obj,
          label: obj,
          id: obj,
        };
      });
    else return [{ value: data, label: data, id: data }];
  };

  const handleChange = (updatedFormData) => {
    setProductProfileForm(updatedFormData);
    if (displaySelectProductsTable) {
      flushTableValues();
    }
  };

  const handleChangeProductAttributes = (updatedFormData) => {
    setProductProfileProductAttribute(updatedFormData);
    if (displaySelectProductsTable) {
      flushTableValues();
    }
  };

  const handleChangeSaleAttributes = (updatedFormData) => {
    setProductProfileSaleAttribute(updatedFormData);
    if (displaySelectProductsTable) {
      flushTableValues();
    }
  };

  const handleChangeProductProfileTimePeriod = (updatedFormData) => {
    setProductProfileTimePeriod(updatedFormData);
    if (displaySelectProductsTable) {
      flushTableValues();
    }
  };

  const handleChangeProductProfileRangePeriod = (updatedFormData) => {
    setProductProfileRangePeriod(updatedFormData);
    if (displaySelectProductsTable) {
      flushTableValues();
    }
  };

  const displaySnackMessages = (message, variance, onClose) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
        ...(onClose && { onClose: onClose }),
      },
    });
  };

  const updateCreateProductProfileFilters = async (dependency, filter) => {
    setInitialDependencyValue(dependency);
    props.setNewProductProfileLoader(true);
    let selectionValueDependency =
      dependency.length > 0
        ? dependency.map((opt) => {
            return {
              attribute_name: opt.filter_id,
              operator: "in",
              values: Array.isArray(opt.values)
                ? opt.values.map((option) => option.value)
                : opt.values,
              filter_type: opt.filter_type,
              // fetching dimension from the config api
              dimension: props.newProductProfileFilterConfiguration.filter(
                (item) => opt.filter_id === item.column_name
              )[0].dimension,
            };
          })
        : [];
    try {
      if (!filter || filter.filter_type === "cascaded") {
        let initialFilterElements = [...filterConfig];
        const filterElements = initialFilterElements.map(async (key) => {
          if (key.type === "cascaded") {
            let body = {
              attribute_name: key.column_name,
              filter_type: key.type,
              filters: selectionValueDependency,
              meta: {},
              dimension: key.dimension,
              application_code: 1,
            };
            if (props.inventorysmartScreenConfig.roleBasedAccess) {
              body.is_urm_filter = true;
              body.screen_name = props.screenName;
            }
            const options = await props.getCrossFiltersData(body);
            key.initialData = options.data.data.attribute.map((opt) => {
              return {
                value: opt.attribute,
                label: opt.attribute,
                id: opt.attribute,
              };
            });
          }
          return key;
        });
        let data = await Promise.all(filterElements);
        setFilterConfig(data);
        // Change sale attributes options to display on UI based on the selected channel value, for vb - tenant
        if (
          props.dynamicLabels?.article === "Product" &&
          filter.filter_id === "channel"
        ) {
          renderSalesAttributesBasedOnChannel(selectionValueDependency);
        }
      }
      let productFilters = selectionValueDependency.filter(
        (item) => item.dimension === "product"
      );
      let storeFilters = selectionValueDependency.filter(
        (item) => item.dimension === "store"
      );
      setDisplaySelectProductsTable(false);
      setDisplayStorePriceContributionTable(false);
      setProductFilterConfig(productFilters);
      setStoreFilterConfig(storeFilters);
      props.setNewProductProfileLoader(false);
      if (displaySelectProductsTable) {
        flushTableValues();
      }
    } catch (err) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setNewProductProfileLoader(false);
    }
  };

  const renderSalesAttributesBasedOnChannel = (selectionValueDependency) => {
    let channelValue = selectionValueDependency.filter(
      (item) => item.attribute_name === "channel"
    )[0].values[0];
    let saleAttributes = [...CREATE_PRODUCT_PROFILE_SALE_ATTRIBUTE];
    let filteredSaleAttributes = [];
    if (channelValue === "Factory Line Retail") {
      filteredSaleAttributes = saleAttributes.filter(
        (item) => item.accessor !== "promo" && item.accessor !== "retirement"
      );
      // to avoid sending this sale attribute value that is hidden
      setProductProfileSaleAttribute({
        ...productProfileSaleAttribute,
        retirement: false,
      });
    } else {
      filteredSaleAttributes = saleAttributes.filter(
        (item) => item.accessor !== "promo" && item.accessor !== "clearance"
      );
      setProductProfileSaleAttribute({
        ...productProfileSaleAttribute,
        clearance: false,
      });
    }
    setDisplaySaleAttributesDynamically(filteredSaleAttributes);
  };

  const flushTableValues = () => {
    setDisplayStorePriceContributionTable(false);
    setDisplaySelectProductsTable(false);
    setSelectedArticleList([]);
  };

  const onProceed = async () => {
    let mandatoryFieldsFilled = checkForMandatoryFields();
    if (mandatoryFieldsFilled.mandatoryCheck) {
      displaySnackMessages(
        "Please enter/select mandatory field values",
        "error"
      );
    } else if (mandatoryFieldsFilled.expressionCheck) {
      displaySnackMessages(
        "Profile name and profile description cannot have special characters",
        "warning"
      );
    } else {
      if (isEmpty(selectedArticleList)) {
        if (!displaySelectProductsTable) callOnProceed();
        else displaySnackMessages("Select at-least one product", "error");
      }
      // call store size contribution api once table has some selections
      else {
        fetchStoreSizeContribution();
      }
    }
  };

  const fetchDateValues = () => {
    let startDate = "",
      endDate = "";
    if (timePeriodToggle) {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      // will use this once we have data for the selected range
      endDate = yyyy + "-" + mm + "-" + dd;

      // to fetch the value, i.e number of days based on the string.
      // fetch 30 from Last 30 days

      let numOfDays = productProfileTimePeriod.productProfileTimePeriod
        .slice(5, 8)
        .trim();
      today.setDate(today.getDate() - numOfDays);
      let dateBackDD = String(today.getDate()).padStart(2, "0");
      let dateBackMM = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let dateBackYYYY = today.getFullYear();
      // will use this once we have data for the selected range
      startDate = dateBackYYYY + "-" + dateBackMM + "-" + dateBackDD;
    } else {
      let formattedDate = productProfileRangePeriod.productProfileRangePeriod?.map(
        (item) => moment(item).format("YYYY-MM-DD")
      );
      // will use this once we have data for the selected range
      startDate = formattedDate[0];
      endDate = formattedDate[1];
    }
    return { startDateValue: startDate, endDateValue: endDate };
  };

  const callOnProceed = async () => {
    props.setNewProductProfileLoader(true);
    // call select prod table after all mandatory fields are selected
    let startEndDates = fetchDateValues();
    try {
      let postBody = generateRequestBody("select_products", startEndDates);
      let response = await props.getProductsToSelectForProductProfile(postBody);
      props.setProductsToSelectForProductProfile(response.data.data);
      setDisplaySelectProductsTable(response.data.status);
      props.setNewProductProfileLoader(false);
    } catch (e) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setNewProductProfileLoader(false);
    }
  };

  const fetchStoreSizeContribution = async () => {
    props.setNewProductProfileLoader(true);
    let startEndDates = fetchDateValues();
    try {
      let postBodyOnProceed = generateRequestBody(
        "store_size_contribution",
        startEndDates
      );
      let response = await props.getProductsStoreSizePenetration(
        postBodyOnProceed
      );
      props.setProductsStoreSizePenetration(response.data.data);
      setDisplayStorePriceContributionTable(response.data.status);
      props.setNewProductProfileLoader(false);
    } catch (e) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setNewProductProfileLoader(false);
    }
  };

  // post body creation
  const generateRequestBody = (requestType, dateValues) => {
    return {
      product_attributes:
        requestType === "store_size_contribution"
          ? [
              ...productFilterConfig,
              {
                attribute_name: "article",
                operator: "in",
                values: selectedArticleList,
                filter_type: "cascaded",
              },
            ]
          : productFilterConfig,
      store_attributes: storeFilterConfig,
      other_attributes: [
        {
          attribute_name: "start_date",
          attribute_value: dateValues?.startDateValue
            ? dateValues?.startDateValue
            : "2021-03-23",
        },
        {
          attribute_name: "end_date",
          attribute_value: dateValues?.endDateValue
            ? dateValues?.endDateValue
            : "2022-03-23",
        },
        {
          attribute_name: "sales_attribute",
          attribute_value:
            props.dynamicLabels?.article === "Product"
              ? productProfileSaleAttribute
              : {},
        },
        {
          attribute_name: "date_type",
          attribute_value: timePeriodToggle ? "dynamic" : "static",
        },
        {
          attribute_name: "date_value",
          attribute_value: timePeriodToggle
            ? productProfileTimePeriod.productProfileTimePeriod
            : productProfileRangePeriod.productProfileRangePeriod
                ?.map((item) => moment(item).format("YYYY-MM-DD"))
                ?.join(" to "),
        },
        {
          attribute_name: "min_price",
          attribute_value: productProfileProductAttribute.min,
        },
        {
          attribute_name: "max_price",
          attribute_value: productProfileProductAttribute.max,
        },
        {
          attribute_name: "name",
          attribute_value: productProfileForm.profileName,
        },
        {
          attribute_name: "description",
          attribute_value: productProfileForm.profileDescription,
        },
      ],
    };
  };

  const checkForMandatoryFields = () => {
    let productProfileDetailsEmptyCondition =
      isEmpty(productProfileForm.profileName) ||
      isEmpty(productProfileForm.profileDescription);
    let filterConfigEmptyCondition =
      isEmpty(productFilterConfig) || isEmpty(storeFilterConfig);
    let mandatoryProductFiltersConfig = productFilterConfig.filter((item) =>
      filterConfig.some(
        (obj) =>
          obj.dimension === "product" &&
          obj.is_mandatory &&
          obj.column_name === item.attribute_name
      )
    );
    let mandatoryStoreFiltersConfig = storeFilterConfig.filter(
      (item) => item.attribute_name === "channel"
    );
    let salesAttributeEmptyCondition =
      !isEmpty(productProfileSaleAttribute) &&
      Object.values(productProfileSaleAttribute).every((val) => !val); // if all are false(unselected)
    let productAttributeEmptyCondition = isEmpty(
      productProfileProductAttribute.max
    );
    let productProfileTimePeriodEmptyCondition = timePeriodToggle
      ? isEmpty(productProfileTimePeriod.productProfileTimePeriod)
      : isEmpty(productProfileRangePeriod.productProfileRangePeriod);

    let mandatoryCheck = false,
      expressionCheck = false;
    if (
      productProfileDetailsEmptyCondition ||
      filterConfigEmptyCondition ||
      salesAttributeEmptyCondition ||
      productAttributeEmptyCondition ||
      productProfileTimePeriodEmptyCondition ||
      mandatoryProductFiltersConfig.length < 1 || // one fields of product dimension are mandatory
      mandatoryStoreFiltersConfig.length < 1 // one fields of store dimension are mandatory
    ) {
      mandatoryCheck = true;
    } else if (
      !checkForSpecialCharacters(productProfileForm.profileName) ||
      !checkForSpecialCharacters(productProfileForm.profileDescription)
    ) {
      expressionCheck = true;
    }
    return { mandatoryCheck: mandatoryCheck, expressionCheck: expressionCheck };
  };

  const createProductProfile = async () => {
    props.setNewProductProfileLoader(true);
    let startEndDates = fetchDateValues();
    try {
      let reqBodyOnSave = {};
      if (!isEmpty(props.location.state)) {
        reqBodyOnSave = generateRequestBody(
          "store_size_contribution",
          startEndDates
        );
        reqBodyOnSave.entity = "product profile";
        reqBodyOnSave.entity_id = props.location.state.pp_code.toString();
      } else {
        reqBodyOnSave = generateRequestBody(
          "store_size_contribution",
          startEndDates
        );
      }
      if (isEmpty(props.location.state)) {
        await props.saveProductProfile(reqBodyOnSave);
        displaySnackMessages("Product profile saved", "success", () =>
          props.history.push(PRODUCT_PROFILE)
        );
      } else {
        await props.saveEditedProductProfile(reqBodyOnSave);
        displaySnackMessages("Product profile updated", "success", () =>
          props.history.push(PRODUCT_PROFILE)
        );
      }
      // to make the loader visible
      window.scroll({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
      props.setNewProductProfileLoader(false);
    } catch (e) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setNewProductProfileLoader(false);
    }
  };

  const setSelectedProducts = (val) => {
    setSelectedArticleList(val);
    // to flush out the pen% table on selection or deselection of values
    setDisplayStorePriceContributionTable(false);
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props.inventorysmartModulesPermission,
      props.module,
      subModuleName,
      action
    );
  };

  return (
    // Display not found page if user has only view access and tries to navigate via the URL
    <>
      {!canTakeActionOnModules(
        INVENTORY_SUBMODULES_NAMES.INVENTORY_CREATE_PRODUCT_PROFILE_FORM,
        "create"
      ) ? (
        <NotFound />
      ) : (
        <Loader loader={props.newProductProfileLoader}>
          <HeaderBreadCrumbs options={homeIcon}></HeaderBreadCrumbs>
          <div className={globalClasses.marginAround}>
            <Paper>
              <div className={globalClasses.evenPaddingAround}>
                <Form
                  layout={"vertical"}
                  maxFieldsInRow={5}
                  handleChange={handleChange}
                  fields={CREATE_PRODUCT_PROFILE_FORM}
                  updateDefaultValue={false}
                  defaultValues={productProfileForm}
                ></Form>
              </div>
            </Paper>

            <Paper>
              <div className={classes.timePeriodFormStyle}>
                <div className={classes.timePeriodFormContainer}>
                  {timePeriodToggle ? (
                    <Form
                      layout={"vertical"}
                      maxFieldsInRow={1}
                      handleChange={handleChangeProductProfileTimePeriod}
                      fields={CREATE_PRODUCT_PROFILE_TIME_PERIOD_DROP_DOWN}
                      updateDefaultValue={false}
                      defaultValues={productProfileTimePeriod}
                      labelWidthSpan={2}
                      fieldTypeWidthSpan={2}
                    ></Form>
                  ) : (
                    <Form
                      layout={"vertical"}
                      maxFieldsInRow={1}
                      handleChange={handleChangeProductProfileRangePeriod}
                      fields={CREATE_PRODUCT_PROFILE_TIME_PERIOD_DATE_PICKER}
                      updateDefaultValue={false}
                      defaultValues={productProfileRangePeriod}
                      labelWidthSpan={2}
                      fieldTypeWidthSpan={2}
                    ></Form>
                  )}
                </div>
                <>
                  Static
                  <Switch
                    defaultChecked={timePeriodToggle}
                    className="switch"
                    id="storetoDcfcToggleBtn"
                    onChange={(event) => {
                      setTimePeriodToggle(event.target.checked);
                    }}
                  ></Switch>
                  Dynamic
                </>
              </div>
            </Paper>
            <Paper>
              <Filters
                filters={filterConfig}
                update={updateCreateProductProfileFilters}
                inititalSelection={initialDependencyValue}
                customFilter={true} // to hide reset and filter button
              />
            </Paper>
          </div>

          <div className={globalClasses.marginAround}>
            <Paper>
              <Typography variant="h5" className={globalClasses.paperHeader}>
                Set Attributes
              </Typography>
              {/* Hiding sales attributes for signet */}
              {props.dynamicLabels?.article === "Product" && (
                <div className={classes.contentPadding}>
                  <div className={globalClasses.flexRow}>
                    <Typography
                      variant="h6"
                      className={globalClasses.marginVertical}
                    >
                      Sale Attributes
                    </Typography>
                    <Typography variant="h5" color={colours.valencia}>
                      *
                    </Typography>
                  </div>
                  <Form
                    layout={"horizontal"}
                    maxFieldsInRow={3}
                    handleChange={handleChangeSaleAttributes}
                    fields={displaySaleAttributesDynamically}
                    updateDefaultValue={false}
                    defaultValues={productProfileSaleAttribute}
                    labelWidthSpan={10} // longer label width
                    fieldTypeWidthSpan={2} // short width as its a checkbox
                  ></Form>
                </div>
              )}
              <div className={classes.contentPaddingProductAttributes}>
                <Typography
                  variant="h6"
                  className={globalClasses.marginVertical}
                >
                  {`${dynamicLabelsBasedOnTenant(
                    "product",
                    "core"
                  )} Attributes`}
                </Typography>
                <div className={globalClasses.centerAlign}>
                  <Typography
                    variant="subtitle1"
                    className={classes.marginRightText}
                  >
                    Price
                  </Typography>
                  <Form
                    layout={"horizontal"}
                    maxFieldsInRow={2}
                    handleChange={handleChangeProductAttributes}
                    fields={CREATE_PRODUCT_PROFILE_PRODUCT_ATTRIBUTE}
                    updateDefaultValue={false}
                    defaultValues={productProfileProductAttribute}
                    labelWidthSpan={3}
                    fieldTypeWidthSpan={9}
                  ></Form>
                </div>
              </div>
            </Paper>
          </div>
          {/* hide table until proceed is clicked */}
          {displaySelectProductsTable && (
            <SelectProductTableComponent
              selectedArticles={setSelectedProducts}
              preSelectedArticles={
                props.location.state && props.location.state.article
              }
            />
          )}
          {/* hide table until proceed is clicked with few rows selected */}
          {displayStorePriceContributionTable && (
            <StorePriceContributionComponent />
          )}
          <div className={classes.footer}>
            {!displayStorePriceContributionTable ? (
              <Button
                color="primary"
                variant="contained"
                className={classes.setMarginForButtons}
                onClick={onProceed}
              >
                Proceed
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                className={classes.setMarginForButtons}
                onClick={createProductProfile}
              >
                Save
              </Button>
            )}
            <Button
              color="primary"
              variant="outlined"
              onClick={() => props.history.push(PRODUCT_PROFILE)}
            >
              Back
            </Button>
          </div>
        </Loader>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  const { inventorysmartReducer } = store;
  return {
    newProductProfileLoader:
      inventorysmartReducer.createProductProfileReducer.newProductProfileLoader,
    newProductProfileFilterConfiguration:
      inventorysmartReducer.createProductProfileReducer
        .newProductProfileFilterConfiguration,
    dynamicLabels:
      inventorysmartReducer?.inventorySmartCommonService
        ?.inventorysmartScreenConfig?.dynamicLabels,
    inventorysmartScreenConfig:
      inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartScreenConfig,
    inventorySmartPermissionLoader:
      inventorysmartReducer.inventorySmartCommonService
        .inventorySmartPermissionLoader,
    inventorysmartModulesPermission:
      inventorysmartReducer.inventorySmartCommonService
        .inventorysmartModulesPermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    setNewProductProfileLoader: (body) =>
      dispatch(setNewProductProfileLoader(body)),
    setNewProductProfileFilterConfiguration: (body) =>
      dispatch(setNewProductProfileFilterConfiguration(body)),
    getCrossFiltersData: (body) => dispatch(getCrossFiltersData(body)),
    setProductsToSelectForProductProfile: (body) =>
      dispatch(setProductsToSelectForProductProfile(body)),
    getProductsToSelectForProductProfile: (body) =>
      dispatch(getProductsToSelectForProductProfile(body)),
    setProductsStoreSizePenetration: (body) =>
      dispatch(setProductsStoreSizePenetration(body)),
    getProductsStoreSizePenetration: (body) =>
      dispatch(getProductsStoreSizePenetration(body)),
    saveProductProfile: (body) => dispatch(saveProductProfile(body)),
    saveEditedProductProfile: (body) =>
      dispatch(saveEditedProductProfile(body)),
    resetCreateProductProfile: () => dispatch(resetCreateProductProfile()),
    getModuleLevelAccess: (payload) => dispatch(getModuleLevelAccess(payload)),
    setInventorySmartPermissionLoader: (payload) =>
      dispatch(setInventorySmartPermissionLoader(payload)),
    setInventorySmartModulesPermissions: (payload) =>
      dispatch(setInventorySmartModulesPermissions(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductProfileFilters);
