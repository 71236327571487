import { connect } from "react-redux";
import {
  fetchDataForTable,
  filterProductsOrGroupsArray,
  filterSelectionArray,
  getTableAPIFiltersForCreateGroup,
} from "./common-product-group-functions";
import { forwardRef, useEffect } from "react";
import { getColumns, getColumnsAg } from "actions/tableColumnActions";
import {
  ToggleLoader,
  setStyleTableCols,
  fetchStyleLevelData,
  setStyleTableData,
  setSelectedManualStyles,
  setSelectedProducts,
  setDeletedStylesInDefn,
  setDeletedProdsInDefn,
} from "pages/product-grouping/product-grouping-service";
import AgGridComponent from "Utils/agGrid";
import { cloneDeep } from "lodash";
import { addSnack } from "actions/snackbarActions";
import { appendPropertiesToTableInstance } from "Utils/agGrid/table-functions";
const ManualStyleTable = forwardRef((props, ref) => {
  const setStyleLevelIndexes = (dataOutput) => {
    dataOutput.data.forEach((style, idx) => {
      if (props.selectedManualFilterType === "grouping_definitions") {
        if (
          !props.deletedDefnStyles.some((delStyle) => {
            return delStyle.style === style.style;
          })
        ) {
          style.is_selected = true;
        }
      } else {
        if (
          props.selectedStyles.some((selStyle) => {
            return selStyle.style === style.style;
          }) &&
          style.product_codes.some((styleprod) => {
            return props.selectedProducts.some((prods) => {
              return prods.product_code === styleprod;
            });
          })
        ) {
          style.is_selected = true;
        }
      }
    });
    return dataOutput;
  };
  const styleSelectionHandler = (event) => {
    const rows = event.api.getSelectedRows();
    if (
      (event.api.selectedManualFilterType || props.selectedManualFilterType) ===
      "grouping_definitions"
    ) {
      let updatedDefnDelete = filterSelectionArray(
        rows,
        event.api.deletedDefnStyles || props.deletedDefnStyles,
        "style"
      );
      const deleteRows = filterSelectionArray(
        rows,
        event.api.styleLevelData || props.styleLevelData,
        "style"
      );
      updatedDefnDelete.push(...deleteRows);
      const diffStyles = (
        event.api.deletedDefnStyles || props.deletedDefnStyles
      ).filter((style) => {
        return !updatedDefnDelete.some((updStyle) => {
          return updStyle.style === style.style;
        });
      });
      const addedStyles = updatedDefnDelete.filter((style) => {
        return !(event.api.deletedDefnStyles || props.deletedDefnStyles).some(
          (delDefnStyle) => {
            return delDefnStyle.style === style.style;
          }
        );
      });
      let updatedDefnProds = [...props.deletedDefnProds];
      addedStyles.forEach((style) => {
        style.product_codes.forEach((prd) => {
          updatedDefnProds.push({ product_code: prd });
        });
      });
      let diffProds = [];
      diffStyles.forEach((diffStyle) => {
        diffStyle.product_codes.forEach((diffProd) => {
          diffProds.push({ product_code: diffProd });
        });
      });
      updatedDefnProds = updatedDefnProds.filter((updProd) => {
        return !diffProds.some((diff) => {
          return diff.product_code === updProd.product_code;
        });
      });
      props.setDeletedStylesInDefn(updatedDefnDelete);
      props.setDeletedProdsInDefn(updatedDefnProds);
    } else {
      const deletedRows = filterProductsOrGroupsArray(
        rows,
        event.api.styleLevelData || props.styleLevelData,
        "style"
      );
      const updatedDelete = filterSelectionArray(
        deletedRows,
        event.api.selectedStyles || props.selectedStyles,
        "style"
      );

      const updatedStyles = [...updatedDelete, ...rows];
      const deletedStyles = filterSelectionArray(
        deletedRows,
        event.api.selectedStyles || props.selectedStyles,
        "style",
        true
      );
      let style_products = [];
      let deleted_style_products = [];
      updatedStyles.forEach((style) => {
        const style_prods = style.product_codes;
        if (
          !style_prods.some((prod) => {
            return (event.api.selectedProducts || props.selectedProducts).some(
              (selProd) => {
                return prod === selProd.product_code;
              }
            );
          })
        ) {
          style.product_codes.forEach((style_prod) => {
            style_products.push({ product_code: style_prod });
          });
        }
      });
      (event.api.selectedProducts || props.selectedProducts).forEach(
        (selProd) => {
          deletedStyles.forEach((delStyle) => {
            if (
              delStyle.product_codes.some((code) => {
                return code === selProd.product_code;
              })
            ) {
              deleted_style_products.push(selProd);
            }
          });
        }
      );
      let updatedDeleteProds = (
        event.api.selectedProducts || props.selectedProducts
      ).filter((prod) => {
        return !deleted_style_products.some((selection) => {
          return selection.product_code === prod.product_code;
        });
      });
      props.setSelectedManualStyles(updatedStyles);
      props.setSelectedProducts([...updatedDeleteProds, ...style_products]);
    }
  };

  useEffect(() => {
    if (ref.current) {
      const appendPropertyObject = {
        filterDependency: cloneDeep(props.selectedFilters),
        selectedStyles: cloneDeep(props.selectedStyles),
        selectedProducts: cloneDeep(props.selectedProducts),
        selectedGroupType: cloneDeep(props.selectedGroupType),
        selectedManualFilterType: cloneDeep(props.selectedManualFilterType),
        manualDefinitionFilter: cloneDeep(props.manualDefinitionFilter),
        deletedDefnStyles: cloneDeep(props.deletedDefnStyles),
        styleLevelData: cloneDeep(props.styleLevelData),
      };
      ref = appendPropertiesToTableInstance(appendPropertyObject, ref);
    }
  }, [
    props.selectedFilters,
    props.selectedStyles,
    props.selectedProducts,
    props.selectedGroupType,
    props.selectedManualFilterType,
    props.manualDefinitionFilter,
    props.deletedDefnStyles,
    props.styleLevelData,
  ]);
  const fetchDataForStyleTable = async (body, pageIndex, params, type) => {
    const APIFunction = {
      style: [props.fetchStyleLevelData, props.setStyleTableData],
    };
    let manualbody = getTableAPIFiltersForCreateGroup(
      body,
      params.api.filterDependency || props.selectedFilters,
      type,
      params.api.selectedGroupType || props.selectedGroupType,
      params.api.manualDefinitionFilter || props.manualDefinitionFilter,
      params.api.selectedManualFilterType || props.selectedManualFilterType,
      props.selectedCluster
    );
    if (
      (params.api.selectedManualFilterType ||
        props.selectedManualFilterType) !== "grouping_definitions" &&
      manualbody.filters.length === 0
    ) {
      return;
    }
    try {
      props.ToggleLoader(true);
      manualbody = {
        ...manualbody,
        meta: {
          ...manualbody.meta,
          limit: { limit: 10, page: pageIndex + 1 },
        },
        selection: {
          data: params?.api?.checkConfiguration || [],
          unique_columns: ["product_code"],
        },
      };
      let dataOutput = await fetchDataForTable(
        manualbody,
        pageIndex + 1,
        10,
        APIFunction,
        props.isEdit,
        "style",
        props.pathname
      );
      props.ToggleLoader(false);
      return dataOutput;
    } catch (error) {
      //Error handling
      props.ToggleLoader(false);
      props.addSnack({
        message: "Something went wrong",
        options: {
          variant: "error",
        },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      props.ToggleLoader(true);
      const cols = await props.getColumnsAg(
        "table_name=product_group_filter_hierarchy"
      );
      props.setStyleTableCols(cols);
      props.ToggleLoader(false);
    };
    fetchData();
  }, []);
  return (
    <>
      <AgGridComponent
        onRowSelected
        columns={props.styleLevelCols}
        selectAllHeaderComponent={true}
        sizeColumnsToFitFlag
        onGridChanged
        manualCallBack={(body, pageIndex, params) =>
          fetchDataForStyleTable(body, pageIndex, params, "style")
        }
        rowModelType="serverSide"
        serverSideStoreType="partial"
        cacheBlockSize={10}
        uniqueRowId={"style"}
        suppressClickEdit={true}
        loadTableInstance={(gridInstance) => {
          ref.current = gridInstance;
        }}
        onSelectionChanged={styleSelectionHandler}
      />
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    selectedStyles: state.productGroupReducer.selectedStyles,
    selectedProducts: state.productGroupReducer.selectedProducts,
    resetFilterTable: state.productGroupReducer.resetTable,
    selectedGroupType: state.productGroupReducer.selectedGroupType,
    selectedManualFilterType:
      state.productGroupReducer.selectedManualFilterType,
    selectedCluster: state.productGroupReducer.selectedCluster,
    filteredProductsCount:
      state.productGroupReducer.manualFilteredProductsCount,
    styleLevelData: state.productGroupReducer.styleLevelTableData,
    styleLevelDataCount: state.productGroupReducer.styleLevelTableCount,
    styleFiltersTableState: state.tableReducer.tableState["style_filters"],
    deletedDefnStyles: state.productGroupReducer.deletedDefnStyles,
  };
};
const mapActionsToProps = {
  getColumns,
  ToggleLoader,
  setStyleTableCols,
  setStyleTableData,
  fetchStyleLevelData,
  setSelectedManualStyles,
  setSelectedProducts,
  setDeletedStylesInDefn,
  setDeletedProdsInDefn,
  getColumnsAg,
  addSnack,
};
export default connect(mapStateToProps, mapActionsToProps, null, {
  forwardRef: true,
})(ManualStyleTable);
