import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import globalStyles from "Styles/globalStyles";

const useStyles = makeStyles((theme) => ({}));

export const GradingPercentile = (props) => {
  const globalClasses = globalStyles();

  const gotoPreviousStep = () => {}
  const gotoNextStep = () => {}

  return (
    <>
      <div>GradingPercentile</div>
      <div className={`${globalClasses.flexRow} ${globalClasses.gap}`}>
        <Button color="primary" variant="outlined" onClick={gotoPreviousStep}>
          Back
        </Button>
        <Button color="primary" variant="contained" onClick={gotoNextStep}>
          Next
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GradingPercentile);
