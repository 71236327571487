import { forwardRef, useEffect, useState } from "react";
import AgGridTable from "Utils/agGrid";
import { getColumnsAg } from "actions/tableColumnActions";
import { connect } from "react-redux";
import { fetchStoreGroups } from "pages/store-grouping/services-store-grouping/custom-store-group-service";

const ModifyStoreGroupsTable = forwardRef((props, ref) => {
  /**
   * State variables
   */
  const [storeGroupTableCols, setStoreGroupTableCols] = useState([]);
  useEffect(() => {
    const fetchStoreGrpsTableCols = async () => {
      //on mount, get the columns of modify table
      let agGridcols = await getColumnsAg(
        `table_name=product_mapping_modify_table_store_group_level`
      )();
      setStoreGroupTableCols(agGridcols);
    };
    fetchStoreGrpsTableCols();
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.api.filterDependency = props.filterDependency;
      ref.current.api.storeGroupDatesAPIPayload =
        props.storeGroupDatesAPIPayload;
      ref.current.api.startDate = props.defaultStartDate;
      ref.current.api.endDate = props.defaultEndDate;
    }
  }, [
    props.filterDependency,
    props.storeGroupDatesAPIPayload,
    props.defaultStartDate,
    props.defaultEndDate,
  ]);

  const manualCallBack = async (manualbody, pageIndex, params) => {
    let body = {
      filters: params.api.filterDependency || [],
      meta: { ...manualbody },
    };
    try {
      const res = await props.fetchStoreGroups(body, "", pageIndex + 1);
      return {
        data: res.data.data,
        totalCount: res.data.total,
      };
    } catch (error) {
      // displaySnackMessages("Something went wrong", "error");
    }
  };

  /**
   *
   * @param {*} event
   * When we select new rows, this callback is triggered
   */
  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();

    /**
     * We clear the exisiting details, because only single date range is allowed for multiple
     * multiple store groups
     */
    props.clearExisitingStoreGroups(event.api.storeGroupDatesAPIPayload);
    const startDate = event.api.startDate || props.defaultStartDate;
    const endDate = event.api.endDate || props.defaultEndDate;
    let newUpdatedDatesPayload = props.getDefaultStoreGroupPayload(
      startDate,
      endDate
    );
    /**
     * loop over the selected rows, update the time period value with today to end date
     */
    selectedRows.forEach((row) => {
      const rowNode = ref.current.api.getRowNode(row.sg_code + "");
      newUpdatedDatesPayload.store_groups.push(row.sg_code);
      props.updateStoreGroupRowNodeDate(rowNode, startDate, endDate);
    });
    //Update with the updated payload
    props.setStoreGroupDatesAPIPayload(newUpdatedDatesPayload);
    props.setSelectedStoreGroupObjects(selectedRows);
  };
  return (
    <>
      <AgGridTable
        columns={storeGroupTableCols}
        selectAllHeaderComponent={true}
        sizeColumnsToFitFlag
        onGridChanged
        onRowSelected
        manualCallBack={(body, pageIndex, params) =>
          manualCallBack(body, pageIndex, params)
        }
        loadTableInstance={(gridInstance) => {
          ref.current = gridInstance;
        }}
        rowModelType="serverSide"
        serverSideStoreType="partial"
        cacheBlockSize={10}
        uniqueRowId={"sg_code"}
        onSelectionChanged={onSelectionChanged}
      />
    </>
  );
});
const mapStateToProps = (state) => {};

const mapActionsToProps = {
  fetchStoreGroups,
};
export default connect(mapStateToProps, mapActionsToProps, null, {
  forwardRef: true,
})(ModifyStoreGroupsTable);
