import Highcharts from "highcharts";

export const barChartFlatJson = {
  type: "column",
  chartType: "barChart",
  chartTitle: "Monthly Average Rainfall",
  axisLegends: {
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: { title: "Rainfall (mm)" },
  },
  series: [
    {
      name: "Tokyo",
      data: [
        49.9,
        71.5,
        106.4,
        129.2,
        144.0,
        176.0,
        135.6,
        148.5,
        216.4,
        194.1,
        95.6,
        54.4,
      ],
    },
  ],
};

export const stackedbarChartFlatJson = {
  type: "column",
  chartType: "stackedBarChart",
  chartTitle: "Stacked column chart",
  axisLegends: {
    xaxis: {
      categories: ["Apples", "Oranges", "Pears", "Grapes", "Bananas"],
    },
    yaxis: { title: "Total fruit consumption" },
  },
  series: [
    {
      name: "John",
      data: [5, 3, 4, 7, 2],
    },
    {
      name: "Jane",
      data: [2, 2, 3, 2, 1],
    },
    {
      name: "Joe",
      data: [3, 4, 4, 2, 5],
    },
  ],
};

export const bubbleChartFlatJson = {
  type: "bubble",
  chartType: "bubbleChart",
  chartTitle: "Highcharts bubbles with radial gradient fill",
  axisLegends: {
    xaxis: {
      title: "Range: 0 to 100.",
    },
    yaxis: { title: "Range: 0 to 100." },
  },
  series: [
    {
      data: [
        [9, 81, 63],
        [98, 5, 89],
        [51, 50, 73],
        [41, 22, 14],
        [58, 24, 20],
        [78, 37, 34],
        [55, 56, 53],
        [18, 45, 70],
        [42, 44, 28],
        [3, 52, 59],
        [31, 18, 97],
        [79, 91, 63],
        [93, 23, 23],
        [44, 83, 22],
      ],
    },
    {
      data: [
        [42, 38, 20],
        [6, 18, 1],
        [1, 93, 55],
        [57, 2, 90],
        [80, 76, 22],
        [11, 74, 96],
        [88, 56, 10],
        [30, 47, 49],
        [57, 62, 98],
        [4, 16, 16],
        [46, 10, 11],
        [22, 87, 89],
        [57, 91, 82],
        [45, 15, 98],
      ],
    },
  ],
};

export const pieChartFlatJson = {
  type: "pie",
  chartType: "pieChart",
  chartTitle: "Browser market shares in January, 2018",
  series: [
    {
      name: "Brands",
      data: [
        ["Chrome", 61.41],
        ["Internet Explorer", 11.84],
        ["Firefox", 10.85],
        ["Edge", 4.67],
        ["Safari", 4.18],
        ["Sogou Explorer", 1.64],
        ["Opera", 1.6],
        ["QQ", 1.2],
        ["Other", 2.6],
      ],
    },
  ],
};

export const donutChartFlatJson = {
  type: "pie",
  chartType: "donutChart",
  chartTitle: "Donut chart",
  series: [
    {
      name: "Browsers",
      data: [
        ["Firefox", 6],
        ["MSIE", 4],
        ["Chrome", 7],
      ],
    },
  ],
};

export const scatterChartFlatJson = {
  type: "scatter",
  chartType: "scatterChart",
  chartTitle: "Height Versus Weight of 507 Individuals by Gender",
  axisLegends: {
    xaxis: {
      title: "Height (cm)",
    },
    yaxis: { title: "Weight (kg)" },
  },
  series: [
    {
      name: "Female",
      color: "rgba(223, 83, 83, .5)",
      data: [
        [161.2, 51.6],
        [167.5, 59.0],
        [159.5, 49.2],
        [157.0, 63.0],
        [155.8, 53.6],
        [170.0, 59.0],
        [159.1, 47.6],
        [166.0, 69.8],
        [176.2, 66.8],
        [160.2, 75.2],
        [172.5, 55.2],
        [170.9, 54.2],
        [172.9, 62.5],
        [153.4, 42.0],
        [160.0, 50.0],
        [147.2, 49.8],
        [168.2, 49.2],
        [175.0, 73.2],
        [157.0, 47.8],
        [167.6, 68.8],
        [159.5, 50.6],
        [175.0, 82.5],
        [166.8, 57.2],
        [176.5, 87.8],
        [170.2, 72.8],
        [174.0, 54.5],
        [173.0, 59.8],
        [179.9, 67.3],
        [170.5, 67.8],
        [160.0, 47.0],
        [154.4, 46.2],
        [162.0, 55.0],
        [176.5, 83.0],
        [160.0, 54.4],
        [152.0, 45.8],
        [162.1, 53.6],
        [170.0, 73.2],
        [160.2, 52.1],
        [161.3, 67.9],
        [166.4, 56.6],
        [168.9, 62.3],
        [163.8, 58.5],
        [167.6, 54.5],
        [160.0, 50.2],
        [161.3, 60.3],
        [167.6, 58.3],
        [165.1, 56.2],
        [160.0, 50.2],
        [170.0, 72.9],
        [157.5, 59.8],
        [160.0, 48.8],
        [167.1, 62.2],
        [158.0, 55.5],
        [167.6, 57.8],
        [156.0, 54.6],
        [162.1, 59.2],
        [173.4, 52.7],
        [165.1, 72.3],
        [166.4, 55.0],
        [160.0, 55.9],
        [152.4, 60.4],
        [170.2, 69.1],
        [162.6, 84.5],
        [170.2, 55.9],
        [159.2, 51.8],
        [157.5, 56.0],
        [161.3, 63.6],
        [162.6, 63.2],
        [160.0, 59.5],
        [168.9, 56.8],
        [165.1, 64.1],
        [162.6, 50.0],
        [165.1, 72.3],
        [166.4, 55.0],
        [160.0, 55.9],
        [152.4, 60.4],
        [170.2, 69.1],
        [162.6, 84.5],
        [170.2, 55.9],
        [158.8, 55.5],
        [172.7, 69.5],
        [167.6, 76.4],
        [162.6, 61.4],
        [167.6, 65.9],
        [156.2, 58.6],
        [175.2, 66.8],
        [172.1, 56.6],
        [162.6, 58.6],
        [160.0, 55.9],
        [165.1, 59.1],
        [182.9, 81.8],
        [166.4, 70.7],
        [165.1, 56.8],
        [177.8, 60.0],
        [165.1, 58.2],
        [175.3, 72.7],
        [154.9, 54.1],
        [158.8, 49.1],
        [172.7, 75.9],
        [168.9, 55.0],
        [161.3, 57.3],
        [167.6, 55.0],
        [165.1, 65.5],
        [175.3, 65.5],
        [157.5, 48.6],
        [163.8, 58.6],
        [167.6, 63.6],
        [165.1, 55.2],
        [165.1, 62.7],
        [168.9, 56.6],
        [162.6, 53.9],
        [164.5, 63.2],
        [176.5, 73.6],
        [168.9, 62.0],
        [175.3, 63.6],
        [159.4, 53.2],
        [160.0, 53.4],
        [170.2, 55.0],
        [162.6, 70.5],
        [167.6, 54.5],
        [162.6, 54.5],
        [160.7, 55.9],
        [160.0, 59.0],
        [157.5, 63.6],
        [162.6, 54.5],
        [152.4, 47.3],
        [170.2, 67.7],
        [165.1, 80.9],
        [172.7, 70.5],
        [165.1, 60.9],
        [170.2, 63.6],
        [170.2, 54.5],
        [170.2, 59.1],
      ],
    },
    {
      name: "Male",
      color: "rgba(119, 152, 191, .5)",
      data: [
        [174.0, 65.6],
        [175.3, 71.8],
        [193.5, 80.7],
        [186.5, 72.6],
        [187.2, 78.8],
        [181.5, 74.8],
        [184.0, 86.4],
        [184.5, 78.4],
        [175.0, 62.0],
        [184.0, 81.6],
        [180.0, 76.6],
        [177.8, 83.6],
        [192.0, 90.0],
        [176.0, 74.6],
        [174.0, 71.0],
        [184.0, 79.6],
        [192.7, 93.8],
        [171.5, 70.0],
        [173.0, 72.4],
        [176.0, 85.9],
        [176.0, 78.8],
        [180.5, 77.8],
        [172.7, 66.2],
        [176.0, 86.4],
        [173.5, 81.8],
        [178.0, 89.6],
        [180.3, 82.8],
        [180.3, 76.4],
        [164.5, 63.2],
        [173.0, 60.9],
        [183.5, 74.8],
        [175.5, 70.0],
        [188.0, 72.4],
        [189.2, 84.1],
        [172.8, 69.1],
        [170.0, 59.5],
        [182.0, 67.2],
        [170.0, 61.3],
        [177.8, 68.6],
        [184.2, 80.1],
        [186.7, 87.8],
        [171.4, 84.7],
        [172.7, 73.4],
        [175.3, 72.1],
        [180.3, 82.6],
        [182.9, 88.7],
        [188.0, 84.1],
        [177.2, 94.1],
        [172.1, 74.9],
        [167.0, 59.1],
        [169.5, 75.6],
        [174.0, 86.2],
        [172.7, 75.3],
        [182.2, 87.1],
        [164.1, 55.2],
        [163.0, 57.0],
        [171.5, 61.4],
        [184.2, 76.8],
        [174.0, 86.8],
        [174.0, 72.2],
        [177.0, 71.6],
        [186.0, 84.8],
        [167.0, 68.2],
        [171.8, 66.1],
        [182.0, 72.0],
        [167.0, 64.6],
        [177.8, 74.8],
        [164.5, 70.0],
        [192.0, 101.6],
        [175.5, 63.2],
        [171.2, 79.1],
        [181.6, 78.9],
        [167.4, 67.7],
        [181.1, 66.0],
        [177.0, 68.2],
        [174.5, 63.9],
        [177.5, 72.0],
        [170.5, 56.8],
        [182.4, 74.5],
        [197.1, 90.9],
        [180.1, 93.0],
        [175.5, 80.9],
        [180.6, 72.7],
        [184.4, 68.0],
        [175.5, 70.9],
        [180.6, 72.5],
        [177.0, 72.5],
        [177.1, 83.4],
        [181.6, 75.5],
        [176.5, 73.0],
        [175.0, 70.2],
        [174.0, 73.4],
        [165.1, 70.5],
        [177.0, 68.9],
        [192.0, 102.3],
        [176.5, 68.4],
        [169.4, 65.9],
        [182.1, 75.7],
        [179.8, 84.5],
        [175.3, 87.7],
        [184.9, 86.4],
        [177.3, 73.2],
        [167.4, 53.9],
        [178.1, 72.0],
        [168.9, 55.5],
        [157.2, 58.4],
        [180.3, 83.2],
        [170.2, 72.7],
        [177.8, 64.1],
        [177.8, 71.8],
        [177.8, 116.4],
        [163.8, 72.2],
        [188.0, 83.6],
        [198.1, 85.5],
        [175.3, 90.9],
        [166.4, 85.9],
        [190.5, 89.1],
        [166.4, 75.0],
        [177.8, 77.7],
        [179.7, 86.4],
        [172.7, 90.9],
        [190.5, 73.6],
        [185.4, 76.4],
        [167.6, 64.5],
        [170.2, 77.3],
        [167.6, 72.3],
        [188.0, 87.3],
        [174.0, 80.0],
        [176.5, 82.3],
        [180.3, 73.6],
        [167.6, 74.1],
        [188.0, 85.9],
        [180.3, 73.2],
        [167.6, 76.3],
        [183.0, 65.9],
        [183.0, 90.9],
        [179.1, 89.1],
        [170.2, 62.3],
        [177.8, 82.7],
        [179.1, 79.1],
        [190.5, 98.2],
        [177.8, 84.1],
        [180.3, 83.2],
        [180.3, 83.2],
      ],
    },
    {
      name: "Other",
      color: "#fcba03",
      data: [
        [170, 90],
        [168, 68],
        [190, 130],
      ],
    },
  ],
};

export const barLineChartFlatJson = {
  chartType: "barLineChart",
  chartTitle: "Average Monthly Temperature and Rainfall in Tokyo",
  axisLegends: {
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      primaryAxisTitle: "Temperature",
      primaryAxisLable: "{value}°C",
      secondaryAxisTitle: "Rainfall",
      secondaryAxisLable: "{value} mm",
    },
  },
  series: [
    {
      name: "Rainfall",
      type: "column",
      yAxis: 1,
      data: [
        49.9,
        71.5,
        106.4,
        129.2,
        144.0,
        176.0,
        135.6,
        148.5,
        216.4,
        194.1,
        95.6,
        54.4,
      ],
      tooltip: {
        valueSuffix: " mm",
      },
    },
    {
      name: "Temperature",
      type: "spline",
      data: [
        7.0,
        6.9,
        9.5,
        14.5,
        18.2,
        21.5,
        25.2,
        26.5,
        23.3,
        18.3,
        13.9,
        9.6,
      ],
      tooltip: {
        valueSuffix: "°C",
      },
    },
  ],
};

export const doubleLineChartFlatJson = {
  type: "line",
  chartType: "multiLineChart",
  chartTitle: "Monthly Average Temperature",
  axisLegends: {
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: { title: "Temperature (°C)" },
  },
  series: [
    {
      name: "Tokyo",
      data: [
        7.0,
        6.9,
        9.5,
        14.5,
        18.4,
        21.5,
        25.2,
        26.5,
        23.3,
        18.3,
        13.9,
        9.6,
      ],
    },
    {
      name: "London",
      data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8],
    },
  ],
};

export const simpleLineChartFlatJson = {
  type: "spline",
  chartType: "simpleLineChart",
  chartTitle: "My chart",
  axisLegends: {
    xaxis: { categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"] },
    yaxis: { title: "water level (mm)" },
  },

  series: [
    {
      name: "line 1",
      data: [1, 2, 1, 4, 3, 6],
    },
  ],
};

export const waterfallChartFlatJson = {
  type: "waterfall",
  chartType: "waterfallChart",
  chartTitle: "Highcharts Waterfall",
  axisLegends: {
    xaxis: { title: "category" },
    yaxis: { title: "USD" },
  },
  series: [
    {
      upColor: Highcharts.getOptions().colors[2],
      color: Highcharts.getOptions().colors[3],
      data: [
        {
          name: "Start",
          y: 120000,
        },
        {
          name: "Product Revenue",
          y: 569000,
        },
        {
          name: "Service Revenue",
          y: 231000,
        },
        {
          name: "Positive Balance",
          isIntermediateSum: true,
          color: Highcharts.getOptions().colors[1],
        },
        {
          name: "Fixed Costs",
          y: -342000,
        },
        {
          name: "Variable Costs",
          y: -233000,
        },
        {
          name: "Balance",
          isSum: true,
          color: Highcharts.getOptions().colors[1],
        },
      ],
      dataLabels: {
        enabled: true,
        formatter: function () {
          return Highcharts.numberFormat(this.y / 1000, 0, ",") + "k";
        },
        style: {
          fontWeight: "bold",
        },
      },
      pointPadding: 0,
    },
  ],
};

export const GRAPH_MENU_LIST = {
  chartOptions: {
    chart: {
      height: 600,
    },
    legend: {
      navigation: {
        enabled: false,
      },
    },
  },
  buttons: {
    contextButton: {
      menuItems: [
        "printChart",
        "separator",
        "downloadPNG",
        "downloadJPEG",
        "downloadPDF",
        "downloadSVG",
        "separator",
        "downloadCSV",
        "downloadXLS",
      ],
    },
  },
};
