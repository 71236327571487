import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../Utils/axios/index";
import {
  FINALIZE_API,
  GET_PACKAGE_DETAILS,
  GET_PRODUCT_STORE_VIEW,
  GET_STORE_VIEW,
  GET_STORE_VIEW_SUMMARY,
  CHANGE_PLAN_STATUS,
  UPDATE_ALLOCATED_UNITS,
  GET_PACKAGE_DETAILS_FOR_BULK_EDIT,
  BULK_UPDATE_ALLOCATED_UNITS,
  SAVE_ALLOCATION,
} from "modules/inventorysmart/constants-inventorysmart/apiConstants";

export const inventorySmartFinalizeStoreViewService = createSlice({
  name: "inventorySmartFinalizeStoreViewService",
  initialState: {
    productStoreViewSummaryLoader: false,
    storeViewLoader: false,
    selectedFilters: [],
    isFiltersValid: false,
    articleTableLoader: false,
    viewPlanTableConfigLoader: false,
    createAllocationArticles: [],
    viewPlansTableConfig: [],
    viewPlansTableData: [],
    articlesTableData: {},
    storeDcTableLoader: false,
    productStoreTableLoader: false,
    editAllocatedQtyLoader: false,
    allocationCode: null,
    originalAllocationCode: null,
    moveToTiageLoader: false,
    finalized: false,
    planStatus: null,
    planType: null,
  },
  reducers: {
    setProductStoreViewSummaryLoader: (state, action) => {
      state.productStoreViewSummaryLoader = action.payload;
    },
    setStoreViewLoader: (state, action) => {
      state.storeViewLoader = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setIsFiltersValid: (state, action) => {
      state.isFiltersValid = action.payload;
    },
    setArticleTableLoader: (state, action) => {
      state.articleTableLoader = action.payload;
    },
    setViewPlanTableConfigLoader: (state, action) => {
      state.viewPlanTableConfigLoader = action.payload;
    },
    setCreateAllocationArticles: (state, action) => {
      state.createAllocationArticles = action.payload;
    },
    setViewPlanTableConfiguration: (state, action) => {
      state.viewPlansTableConfig = action.payload;
    },
    setViewPlanTableData: (state, action) => {
      state.viewPlansTableData = action.payload;
    },
    setArticlesTableData: (state, action) => {
      state.articlesTableData = action.payload;
    },
    setStoreDcTableLoader: (state, action) => {
      state.storeDcTableLoader = action.payload;
    },
    setProductStoreViewLoader: (state, action) => {
      state.productStoreTableLoader = action.payload;
    },
    setEditAllocatedQtyLoader: (state, action) => {
      state.editAllocatedQtyLoader = action.payload;
    },
    setAllocationCode: (state, action) => {
      state.allocationCode = action.payload;
    },
    setOriginalAllocationCode: (state, action) => {
      state.originalAllocationCode = action.payload;
    },
    setMoveToTiageLoader: (state, action) => {
      state.moveToTiageLoader = action.payload;
    },
    setFinalized: (state, action) => {
      state.finalized = action.payload;
    },
    setPlanStatus: (state, action) => {
      state.planStatus = action.payload;
    },
    setPlanType: (state, action) => {
      state.planType = action.payload;
    },
    resetStoreView: (state, _action) => {
      state.productStoreViewSummaryLoader = false;
      state.storeViewLoader = false;
      state.selectedFilters = [];
      state.isFiltersValid = false;
      state.articleTableLoader = false;
      state.viewPlanTableConfigLoader = false;
      state.viewPlansTableConfig = [];
      state.viewPlansTableData = [];
      state.storeDcTableLoader = false;
      state.productStoreTableLoader = false;
      state.editAllocatedQtyLoader = false;
      state.allocationCode = null;
      state.originalAllocationCode = null;
      state.planStatus = null;
      state.planType = null;
      state.moveToTiageLoader = false;
      state.finalized = false;
    },
  },
});

export const {
  setProductStoreViewSummaryLoader,
  setStoreViewLoader,
  setSelectedFilters,
  setIsFiltersValid,
  setArticleTableLoader,
  setViewPlanTableConfigLoader,
  setCreateAllocationArticles,
  setViewPlanTableConfiguration,
  setViewPlanTableData,
  setArticlesTableData,
  setStoreDcTableLoader,
  setProductStoreViewLoader,
  setEditAllocatedQtyLoader,
  setAllocationCode,
  setOriginalAllocationCode,
  setMoveToTiageLoader,
  setFinalized,
  setPlanStatus,
  setPlanType,
  resetStoreView,
} = inventorySmartFinalizeStoreViewService.actions;

export const getStoreViewSummary = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_VIEW_SUMMARY,
    method: "POST",
    data: postBody,
  });
};

export const getStoreView = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_VIEW,
    method: "POST",
    data: postBody,
  });
};

export const getProductStoreView = (postBody) => () => {
  return axiosInstance({
    url: GET_PRODUCT_STORE_VIEW,
    method: "POST",
    data: postBody,
  });
};

export const getDrafts = (param) => () => {
  return axiosInstance({
    url: `/inventory-smart/plan/draft/${param}`,
    method: "GET",
  });
};

export const getStatus = (param) => () => {
  return axiosInstance({
    url: `/inventory-smart/finalize/get-plan-status?allocation_code=${param}`,
    method: "GET",
  });
};

export const changePlanStatus = (postBody) => () => {
  return axiosInstance({
    url: CHANGE_PLAN_STATUS,
    method: "POST",
    data: postBody,
  });
};

export const saveAllocation = (postBody) => () => {
  return axiosInstance({
    url: SAVE_ALLOCATION,
    method: "POST",
    data: postBody,
  });
};

export const finalizeApi = (postBody) => () => {
  return axiosInstance({
    url: FINALIZE_API,
    method: "POST",
    data: postBody,
  });
};

export const getpackageDetailsForEdit = (postBody) => () => {
  return axiosInstance({
    url: GET_PACKAGE_DETAILS,
    method: "POST",
    data: postBody,
  });
};

export const getpackageDetailsForBulkEdit = (postBody) => () => {
  return axiosInstance({
    url: GET_PACKAGE_DETAILS_FOR_BULK_EDIT,
    method: "POST",
    data: postBody,
  });
};

export const updateAllocatedUnits = (postBody) => () => {
  return axiosInstance({
    url: UPDATE_ALLOCATED_UNITS,
    method: "POST",
    data: postBody,
  });
};

export const bulkUpdateAllocatedUnits = (postBody) => () => {
  return axiosInstance({
    url: BULK_UPDATE_ALLOCATED_UNITS,
    method: "POST",
    data: postBody,
  });
};

export default inventorySmartFinalizeStoreViewService.reducer;
