import { getAllFilters, getFiltersValues } from "actions/filterAction";
import SelectContainer from "commonComponents/filters/SelectContainer";
import { cloneDeep } from "lodash";
import moment from "moment";
import { ADA_DASHBOARD_FILTER_CONFIG } from "../constants-ada/apiConstants";
import { FILTER_DIMENSIONS } from "../constants-ada/stringContants";
import {
  fetchCompareFiscalWeeks,
  fetchFiscalWeeks,
  setFullScreenLoaderCount,
  setHistoricalFiscalData,
  setInventorypreAppliedFilters,
} from "../services-ada/ada-dashboard/ada-dashboard-services";

// util fn

export const configureAttributeOptions = (options) => {
  return options.map((item) => {
    return {
      value: item.attribute || item,
      label: item.attribute || item,
      id: item.attribute || item,
    };
  });
};

export const configureWeekAttributeOptions = (options) => {
  return options.map((item) => {
    return {
      value: item.start_week_id,
      label: item.start_week_id,
      id: item.start_week_id,
    };
  });
};

export const configureMonthAttributeOptions = (options) => {
  return options.map((item) => {
    return {
      value: item.fm_id,
      label: item.fm_id,
      id: item.fm_id,
    };
  });
};

export const filterByDimension = (filters, dimension) => {
  return filters.filter((filter) => filter.dimension === dimension);
};

export const configureYearOptions = (options) => {
  return options.map((item) => {
    return {
      value: item,
      label: item,
      id: item,
    };
  });
};

export const fetchFilterOptions = async (allFilters, appliedFilters) => {
  const filters = appliedFilters?.map((item) => ({
    attribute_name: item.filter_id,
    operator: "in",
    values: Array.isArray(item.values)
      ? item.values.map(
          (selectedValue) => selectedValue?.value || selectedValue
        )
      : item.values,
    filter_type: item.filter_type,
    filter_id: item.filter_id,
  }));
  const filterElements = allFilters?.map(async (key) => {
    const body = {
      attribute_name: key.column_name,
      filter_type: key.type,
      filters,
    };
    const options = await getFiltersValues(key.dimension, body)();
    key.filter_keyword = key.column_name;
    key.levelLabel = "Hierarchy";
    key.component = SelectContainer;
    key.initialData = configureAttributeOptions(options.data.data.attribute);
    return key;
  });
  const response = await Promise.all(filterElements);
  return response;
};

export const fetchFilterConfig = async () => {
  const response = await getAllFilters(ADA_DASHBOARD_FILTER_CONFIG)();
  let result = {};
  for (let elem of FILTER_DIMENSIONS) {
    //if dimension is not available, skip filter
    if (!elem.dimension) continue;
    result[elem.dimension] = filterByDimension(
      response?.data?.data,
      elem.dimension
    );
  }

  return result;
};

export const chartDataPayload = (
  data,
  promoPercentage = null,
  week,
  compareWeek,
  ia_default_flag = false
) => {
  let updatedPromo = null;

  if (promoPercentage === 0) {
    updatedPromo = "0";
  } else {
    updatedPromo = promoPercentage;
  }

  return {
    filters: {
      aggregation_level:
        data?.switchTimeLine?.value || data?.switchTimeLine[0]?.value,
      timeline: {
        start_week_id: week || data?.fiscalDates?.start_fw,
        end_week_id: week || data?.fiscalDates?.end_fw,
      },
      compare_timeline: [
        {
          start_week_id: compareWeek || data?.historicActuals?.start_fw,
          end_week_id: compareWeek || data?.historicActuals?.end_fw,
          year: String(data?.historicActuals?.end_fw)?.slice(0, 4),
        },
      ],
      channel: data?.channel?.map(({ value }) => value) || [],
      product_hierarchy:
        data?.product?.reduce((acc, curr) => {
          acc[curr.filter_id] = curr.values.map(({ value }) => value);
          return acc;
        }, {}) || [],
      store_hierarchy:
        data?.store?.reduce((acc, curr) => {
          acc[curr.filter_id] = curr.values.map(({ value }) => value);
          return acc;
        }, {}) || [],
      product_group: data?.productGroup?.map(({ value }) => value) || [],
      store_group: data?.storeGroup?.map(({ value }) => value) || [],
      hier_code: [],
      graph: true,
      promo_percentage: updatedPromo,
      selected_items: data.inventoryAdaPayload,
      ia_default_flag,
    },
  };
};

export const handleSetExclusion = (data, state) => {
  const currState = [...state.exclusion[data.dimension]];

  const keyIndex = currState.findIndex(
    ({ exclusionKey }) => exclusionKey === data.key
  );

  if (keyIndex === -1) {
    currState.push({ exclusionKey: data.key, values: data.value });
  } else {
    currState[keyIndex] = { exclusionKey: data.key, values: data.value };
  }

  return currState;
};

export const handleCompareBtnClick = (currYear, prevYear) => {
  let currFormattedYear = String(currYear)?.split("-")?.[0];
  let labelYear = String(currFormattedYear - prevYear);

  return {
    value: labelYear,
    label: labelYear,
    id: labelYear,
  };
};

export const handleExclusionDataProps = (exclusions, selectedFilters) => {
  const updatedFilters = cloneDeep(exclusions);

  const getSelectedFilters = (filter_keyword) =>
    selectedFilters.find(({ filter_id }) => filter_id === filter_keyword)
      ?.values || [];

  return updatedFilters.map((filter) => ({
    ...filter,
    initialData: getSelectedFilters(filter.filter_keyword),
  }));
};

export const handleStaticExclusionDataProps = (
  staticExclusions,
  allAppliedFiters
) => {
  const updatedFilters = cloneDeep(staticExclusions);
  const result = [];

  let dropdownPayloadFormat = (
    label,
    filter_keyword,
    initialData,
    levelLabel,
    component,
    api
  ) => ({
    label,
    filter_keyword,
    is_multiple_selection: true,
    initialData,
    levelLabel,
    component,
    api,
    reducerKey: filter_keyword,
  });

  updatedFilters?.forEach(
    ({ label, reducerKey, filterReducerKey, levelLabel, component, api }) => {
      result.push(
        dropdownPayloadFormat(
          label,
          reducerKey,
          allAppliedFiters?.[filterReducerKey],
          levelLabel,
          component,
          api
        )
      );
    }
  );

  return result;
};

export const handleTotalRow = (rowData, columns, activeChildHierarchyKey) => {
  const totalRowData = {
    total_rows: rowData?.length,
    row: "total",
    // first column will contain the hierarcy info
    [columns?.[0].column_name]: `Total ${
      activeChildHierarchyKey ? `- ${activeChildHierarchyKey}` : ""
    }`,
  };

  for (let elem of rowData) {
    for (let key of Object.keys(elem)) {
      if (isNumber(key)) {
        if (!totalRowData[key]) totalRowData[key] = {};
        totalRowData[key]["IA"] =
          (totalRowData[key]?.IA || 0) + (Number(elem[key].IA) || 0);
        totalRowData[key]["adjusted"] =
          (totalRowData[key]?.adjusted || 0) +
          (Number(elem[key].adjusted) || 0);
      }
    }
  }

  return [totalRowData];
};

export const getFiscalCompareWith = async (selectedYear, fiscalDate) => {
  const payload = {
    year: Number(selectedYear),
    start_fw: fiscalDate,
    end_fw: fiscalDate,
    complete_year: false,
  };
  const response = await fetchCompareFiscalWeeks(payload);
  return response?.data?.data;
};

export const handleAppendResponse = (rowData, appendResponse, fiscalWeek) => {
  const updatedData = cloneDeep(rowData);

  updatedData.forEach((rowData, i) => {
    rowData[fiscalWeek] = appendResponse[i][fiscalWeek];
  });

  return updatedData;
};

export const updateRatioBasedValue = (IAValue, newTotal, IATotal) => {
  return Number(IAValue) * Number(newTotal / IATotal);
};

export const calculateHistoricFiscalWeek = (
  historicDataPayload,
  historicalWeekSelected
) => {
  const { fiscalCalendarDetails } = historicDataPayload;

  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  let weekNumber = moment().isoWeek();
  let startFiscalWeekData =
    fiscalCalendarDetails?.[currentYear]?.[weekNumber - historicalWeekSelected];
  let endFiscalWeekData =
    fiscalCalendarDetails?.[currentYear]?.[weekNumber - 1];

  if (weekNumber <= historicalWeekSelected) {
    let weeksInYear = fiscalCalendarDetails?.[currentYear - 1]?.[53] ? 53 : 52;
    startFiscalWeekData =
      fiscalCalendarDetails?.[currentYear - 1]?.[
        weeksInYear + historicalWeekSelected - weekNumber
      ];
    if (weekNumber === 1) {
      endFiscalWeekData =
        fiscalCalendarDetails?.[currentYear - 1]?.[weeksInYear];
    }
  }

  historicDataPayload.fiscalDates = {
    start_fw: startFiscalWeekData.fy + startFiscalWeekData.fw,
    end_fw: endFiscalWeekData.fy + endFiscalWeekData.fw,
  };
  // Revisit historic actuals logic
  historicDataPayload.historicActuals = {
    start_fw: Number(startFiscalWeekData.fy) - 1 + startFiscalWeekData.fw,
    end_fw: Number(endFiscalWeekData.fy) - 1 + endFiscalWeekData.fw,
  };

  return historicDataPayload;
};

export const prepareInventoryPayload = (context, dispatch, setActiveKey) => {
  let inventoryPayload = {
    ...context,
    meta: {
      search: [],
      range: [],
      sort: [],
      limit: {
        limit: 100000000,
        page: 1,
      },
    },
  };
  dispatch(setFullScreenLoaderCount(1));

  const timeline = { ...inventoryPayload?.timeline };

  const payload = {
    product: [
      {
        filter_id: "product_code",
        filter_type: "cascaded",
        dimension: "product",
        values: inventoryPayload?.payload?.product_code?.map((code) => {
          return {
            value: code,
            label: code,
            id: code,
          };
        }),
      },
    ],
    store: [
      {
        filter_id: "store_code",
        filter_type: "cascaded",
        dimension: "store",
        values: inventoryPayload?.payload?.store_code?.map((code) => {
          return {
            value: code,
            label: code,
            id: code,
          };
        }),
      },
    ],
  };

  const getFiscalDate = async () => {
    try {
      const response = await fetchFiscalWeeks(
        timeline.startDate,
        timeline.endDate
      );

      const fiscalDatesInfo = response?.data?.data?.start_date;

      const currFiscalYear = String(fiscalDatesInfo.end_fw)?.slice(0, 4);
      const compareWeeks = await fetchCompareFiscalWeeks({
        year: Number(currFiscalYear) - 1,
        start_fw: fiscalDatesInfo.start_fw,
        end_fw: fiscalDatesInfo.end_fw,
        complete_year: false,
      });
      payload.fiscalDates = fiscalDatesInfo;
      payload.historicActuals = compareWeeks?.data?.data;
      dispatch(setInventorypreAppliedFilters(payload));
      setActiveKey((prevState) => prevState + 1);
    } catch (error) {
    } finally {
      dispatch(setFullScreenLoaderCount(-1));
      localStorage.removeItem("adaPayload");
    }
  };
  getFiscalDate();

  let currDate = moment().subtract(1, "week").format("YYYY/MM/DD");
  let historicDate = moment()
    .subtract(inventoryPayload?.selectedHistoricValue, "week")
    .format("YYYY/MM/DD");

  const getHistoricalData = async () => {
    const response = await fetchFiscalWeeks(historicDate, currDate);

    const fiscalDatesInfo = response?.data?.data?.start_date;

    const lastYear = moment().subtract(1, "year").year();

    const compareWeeks = await fetchCompareFiscalWeeks({
      year: Number(lastYear),
      start_fw: fiscalDatesInfo.start_fw,
      end_fw: fiscalDatesInfo.end_fw,
      complete_year: false,
    });

    let weekCount = [
      {
        label: `${inventoryPayload?.selectedHistoricValue} Weeks`,
        value: inventoryPayload?.selectedHistoricValue,
      },
    ];

    const payload = {
      selectedHistoricValue: weekCount,
      historicalDataFiscalWeek: fiscalDatesInfo,
      historicalDataFiscalWeekCompare: compareWeeks?.data?.data,
    };

    dispatch(setHistoricalFiscalData(payload));
  };

  if (inventoryPayload?.selectedHistoricValue) {
    getHistoricalData();
  }
};

export const isNumber = (value) =>
  typeof Number(value) === "number" && !isNaN(Number(value));
