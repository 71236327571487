import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import {
  loginUser,
  forgotPassword,
  setUser,
  checkUserProvider,
  setUserPreferClient,
  getUserPreference,
  setUserPreferences,
} from "../../../actions/authActions";
import { VALID_EMAIL } from "../regex";
import "../index.scss";
import logo from "../../../assets/header_logo.png";
import {
  getDropdownValues,
  setHelpDesk,
} from "../../../actions/tenantConfigActions";
import { bindActionCreators } from "redux";
import LoadingOverlay from "../../../Utils/Loader/loader";
import { Grid, Link, Typography, Button } from "@mui/material";
import LoginForm from "./login";
import MarketingBanner from "./marketingBanner";
class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      isEmailUser: false,
      uiconfigs: [],
      FirebaseUIConfig: {
        signInOptions: [],
        credentialHelper: "none",
        signInFlow: "popup",
        callbacks: {
          signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            return false;
          },
          signInFailure: (error) => {},
        },
      },
      inputError: {},
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const token = Cookies.get("authToken", {
      domain:
        window.location.hostname === "localhost"
          ? "localhost"
          : "impactsmartsuite.com",
    });
    if (token) {
      const verify = jwt_decode(token);
      if (verify?.email_verified && verify?.hd === "impactanalytics.co") {
        this.props.history.push(
          "/" + window.location.pathname?.split("/")[1] + "/home"
        );
      }
    }
  }

  onChange(e) {
    if (e.target.name == "email") {
      this.setState({ isEmailUser: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  ResetPassword = async () => {
    if (this.state.isEmailUser) {
      this.props.forgotPassword(this.state.email);
      this.clearError();
    } else {
      this.setState({ inputError: { email: "Please input a valid email" } });
    }
  };

  verifyUserData = () => {
    let error = {};
    if (!this.state.email || !VALID_EMAIL.test(this.state.email)) {
      error.email = "Please input valid email id";
    }
    if (!this.state.password) {
      error.password = "Please enter password";
    }
    if (Object.keys(error).length) {
      this.setState({ inputError: error });
      return false;
    } else {
      return true;
    }
  };

  clearError = () => {
    if (Object.keys(this.state.inputError).length) {
      this.setState({ inputError: {} });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const logCred = {
      email: "demo@impactanalytics.co",
      password: "demouser@789",
    };

    if (
      logCred.email === this.state.email &&
      logCred.password === this.state.password
    ) {
      this.clearError();
      const userData = {
        email: this.state.email,
        password: this.state.password,
      };
      Cookies.set(
        "authToken",
        "eyJhbGciOiJSUzI1NiIsImtpZCI6IjY3NmRhOWQzMTJjMzlhNDI5OTMyZjU0M2U2YzFiNmU2NTEyZTQ5ODMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2ODk3NTIxNDUsImF1ZCI6IjEwOTIwODczMjQxNzYtYWsxcWJhY2EycXByZDI0MDFrbjQxN241M25wajhzM20uYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTU2MzkxMjUzNDMzNzIyODk1ODciLCJoZCI6ImltcGFjdGFuYWx5dGljcy5jbyIsImVtYWlsIjoibWFub2hhci5yQGltcGFjdGFuYWx5dGljcy5jbyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhenAiOiIxMDkyMDg3MzI0MTc2LWFrMXFiYWNhMnFwcmQyNDAxa240MTduNTNucGo4czNtLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwibmFtZSI6Ik1hbm9oYXIgUiIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRjTkFMakxrdG94b2Rsa0dhMk01MDJCQWV1RnFSSHVqWVcxaGRXdjlmUnA9czk2LWMiLCJnaXZlbl9uYW1lIjoiTWFub2hhciAiLCJmYW1pbHlfbmFtZSI6IlIiLCJpYXQiOjE2ODk3NTI0NDUsImV4cCI6MTY4OTc1NjA0NSwianRpIjoiMTFiZTk5MDZmNGExNGM0ODg3ODJlZjhmMDA3MGRiNDQzZDcyY2RmZCJ9.TGvPXo--lzYy1QpDndStKAenfnQDnCFCf1UrfCN-faJ68CbNlzTW0fqn-IQby9kV-lfxCyKinPxECh7nFDu9hnMfzT2tyNTwEt3NjOaWMYrp8A5-O3FWjramB7TdYVoA66zy6rRur6mptZd9D4jqnpxWq_6oL_0PXb9w3QZqdIgQt_Yc5j9D9ubBoCXbMzu_Dh5mQM5GVL7iWKJZyvdWWsz_wR_Ts5xDgqsOX2yO0gH60xPqZISoSBnCN4tnruIbbRsgen11UQ2WXQMKQ6iWjG_hfncVUueYH2s69mu2wB4m8zbEfxwJGKog7xD9eKHoO2F-_XZ_uWGcosI8S7oclA",
        {
          domain:
            window.location.hostname === "localhost"
              ? "localhost"
              : "impactsmartsuite.com",
        }
      );
      if (this.props.clientName === undefined) {
        this.props.setUserPreferClient(
          this.props.history?.location?.state?.from?.pathname.split("/")[1]
        );
      }

      if (this.props.history?.location?.state?.from?.pathname === undefined) {
        this.props.history.push("/" + this.props.clientName + "/home");
      } else {
        this.props.history.push(
          this.props.history?.location?.state?.from?.pathname
        );
      }
    } else {
      if (logCred.email !== this.state.email) {
        this.setState({ inputError: { email: "Please input a valid email" } });
      } else {
        this.setState({
          inputError: { password: "Please input a valid password" },
        });
      }
    }
  };

  checkUserProvider = async (e) => {
    if (VALID_EMAIL.test(this.state.email)) {
      try {
        const providers = await this.props.checkUserProvider(this.state.email);
        if (providers[0] == "password") {
          this.setState({ isEmailUser: true });
        } else {
          this.setState({ isEmailUser: false });
        }
      } catch (error) {
        this.setState({ isEmailUser: false });
      }
    }
  };

  onSuccessGoogleLogin = async (authTokenResponse) => {
    const verify = await jwt_decode(authTokenResponse.credential);

    if (verify?.email_verified && verify?.hd === "impactanalytics.co") {
      Cookies.set("authToken", authTokenResponse.credential, {
        domain:
          window.location.hostname === "localhost"
            ? "localhost"
            : "impactsmartsuite.com",
      });

      if (this.props.clientName === undefined) {
        this.props.setUserPreferClient(
          this.props.history?.location?.state?.from?.pathname.split("/")[1]
        );

        // const userPreference = await this.props.getUserPreference();
        // this.props.setUserPreferences(userPreference?.data);
      }

      if (this.props.history?.location?.state?.from?.pathname === undefined) {
        this.props.history.push("/" + this.props.clientName + "/home");
      } else {
        this.props.history.push(
          this.props.history?.location?.state?.from?.pathname
        );
      }
    }
  };

  render() {
    var firebaseSignInOptionsDivClass = this.state.uiconfigs[2]
      ? "firebase-sign-in"
      : "firebase-without-emailLogin";
    return (
      <div style={{ marginBottom: 30 }}>
        <LoadingOverlay
          loader={this.props.overlayLoaderState}
          text={this.props.loaderText}
          spinner
        >
          <Grid container>
            <Grid item xs={12}>
              <div className="signin-form__header">
                <img src={logo} alt="logo" className="header__logo" />
                <Typography
                  component="h1"
                  variant="h2"
                  className="brand__title"
                >
                  Smart Platform
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div id="signInForm" className="h-md-100 signin-form-container">
                <div className="signin-text-div"></div>
                <div className="login-options">
                  <div className="login__wrapper">
                    <Typography
                      id="signInText"
                      className="sign-in-text"
                      variant="h2"
                    >
                      Sign in to your account.
                    </Typography>

                    <LoginForm
                      email={this.state.email}
                      password={this.state.password}
                      onChange={this.onChange}
                      ResetPassword={this.ResetPassword}
                      onSubmit={this.onSubmit}
                      error={this.state.inputError}
                      onSuccessGoogleLogin={this.onSuccessGoogleLogin}
                    />

                    {
                      <Typography
                        variant="body"
                        component="p"
                        className="contact"
                        textAlign="center"
                      >
                        Need any help?{" "}
                        <Link href={this.props.helpDeskUrl} underline="none">
                          {"Contact now"}
                        </Link>
                      </Typography>
                    }
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <MarketingBanner />
            </Grid>
          </Grid>
        </LoadingOverlay>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  // errors: PropTypes.object.isRequired,
  // success: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  isTenantInfoFetched: state.authReducer.isTenantInfoFetched,
  isUsrSessionChecked: state.authReducer.isUsrSessionChecked,
  overlayLoaderState: state.loaderReducer.overlayLoaderState,
  loaderText: state.loaderReducer.loaderText,
  errors: state.errors,
  clientName: state.authReducer.clientName,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginUser,
      setUser,
      forgotPassword,
      checkUserProvider,
      getDropdownValues,
      setHelpDesk,
      getUserPreference,
      setUserPreferClient,
      setUserPreferences,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
