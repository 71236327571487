import React, { useState } from "react";
import { CheckBoxOutlineBlank, CheckBoxTwoTone } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Check from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  selectIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const SelectAllComponent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tenRecordsSelected, setTenRecordsSelected] = useState(true);
  const [allRecordsSelected, setAllRecordsSelected] = useState(true);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const selectTenRecords = () => {
    let isCheckAll;
    if (props.api.getRenderedNodes().every((rowNode) => rowNode.selected)) {
      isCheckAll = false;
      setTenRecordsSelected(true);
    }
    //if few rows are selected or deselected when user selects this options, select all 10 records
    else {
      isCheckAll = true;
      setTenRecordsSelected(false);
    }
    //current page rowNodes
    props.api
      .getRenderedNodes()
      .forEach((rowNode) => rowNode.setSelected(isCheckAll));
    setAnchorEl(null);
    return props;
  };

  const selectAll = () => {
    // BE pagination
    if (props.api.gridOptionsWrapper.gridOptions.manualCallBack) {
      let allRows = props.api.getRenderedNodes();
      let isCheckAll;
      // forEachNode returns - All row nodes present in the table
      // Apply conditions if oly few rows are selected and the user clicks on select all again, using rowNodes select all rows
      if (allRows.every((rowNode) => rowNode.selected)) {
        isCheckAll = false;
        setAllRecordsSelected(true);
      } else {
        isCheckAll = true;
        setAllRecordsSelected(false);
      }
      // all rowNodes rendered
      props.checkAllCallback(props, isCheckAll);
      props.api.forEachNode((node, i) => {
        //3rd param is avoid multiple onselection callback on selectall check
        if (allRows.length - 1 === i) node.setSelected(isCheckAll);
        else node.setSelected(isCheckAll, false, true);
      });
      setAnchorEl(null);
      return props;
    }
    // FE pagination
    else {
      let allRows = props.api.getModel();
      let isCheckAll;
      if (allRows.rowsToDisplay.every((rowNode) => rowNode.selected)) {
        isCheckAll = false;
        setAllRecordsSelected(true);
      }
      //if few rows are selected or deselected when user selects this options, select all records
      else {
        isCheckAll = true;
        setAllRecordsSelected(false);
      }
      /*
          condition used here is to account the issue related to delay in selection when there are multiple records that are selected at once.
          setSelected(newValue, clearSelection, suppressFinishActions)
          when multiple rows/records are to be selected at once, we set the 3rd param in the above function to true,
          so that all the event dispatches are suppressed except that of the last selection, for which we perform the usual selection method 
          that triggers/dispatches events for onRowSelected, onSelectionChanged, etc.
      */
      allRows.rowsToDisplay.forEach((rowNode, i) => {
        if (allRows.rowsToDisplay.length - 1 === i)
          rowNode.setSelected(isCheckAll);
        else rowNode.setSelected(isCheckAll, false, true);
      });
      setAnchorEl(null);
      return props;
    }
  };
  return (
    <>
      <div onClick={handleClick}>
        {!tenRecordsSelected || !allRecordsSelected ? (
          <CheckBoxTwoTone className={classes.selectIcon} fontSize="small"/>
        ) : (
          <CheckBoxOutlineBlank className={classes.selectIcon} fontSize="small"/>
        )}
      </div>
      <Menu
        id="ag-grid-row-selection-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!props.hideSelectCurrentPageRecords && (
          <MenuItem onClick={selectTenRecords} disabled={!allRecordsSelected}>
            {!tenRecordsSelected && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Select current page records
          </MenuItem>
        )}

        {!props.hideSelectAllRecords && (
          <MenuItem onClick={selectAll} disabled={!tenRecordsSelected}>
            {!allRecordsSelected && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Select all records
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
export default SelectAllComponent;
