import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import {
  BsPlusLg,
  BsFillPatchQuestionFill,
  BsReverseLayoutSidebarInsetReverse,
  BsReverseLayoutTextWindowReverse,
} from "react-icons/bs";
import GridViewIcon from "@mui/icons-material/GridView";
import DialpadIcon from "@mui/icons-material/Dialpad";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import TourIcon from "@mui/icons-material/Tour";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import CategoryIcon from "@mui/icons-material/Category";
import {
  CREATE_ALLOCATION,
  DASHBOARD,
  WHATIF,
  CONFIGURATION,
  PRODUCT_PROFILE,
  ALLOCATION_REPORT,
  ORDER_MANAGEMENT,
  ADA_VISUAL,
  ORDER_REPOSITORY,
  CREATE_NEW_ORDER,
  SETTINGS,
  GROUPING,
  MEIO,
} from "../constants-inventorysmart/routesConstants";
import DashboardComponent from "../pages-inventorysmart/Decision-Dashboard";
import WhatIfComponent from "../pages-inventorysmart/WhatIf";
import AdaVisual from "../pages-inventorysmart/ADAVisual";
import OrderRepository from "../pages-inventorysmart/order-repository";
import CreateNewOrder from "../pages-inventorysmart/create-new-order";
import CreateNewAllocationComponent from "../pages-inventorysmart/Create-Allocation";
import Configuration from "../pages-inventorysmart/Configuration";
import ProductProfileComponent from "../pages-inventorysmart/Product-Profile";
import Settings from "../pages-inventorysmart/Settings";
import AllocationReportingComponent from "../pages-inventorysmart/Allocation-Reporting";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";
import { Inventory2Outlined } from "@mui/icons-material";
import OrderManagementPage from "../pages-inventorysmart/Order-Management";
import Grouping from "../pages-inventorysmart/Grouping";
import Meio from "../pages-inventorysmart/Meio";
export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
    title: "Dashboard",
    icon: React.createElement(GridViewIcon),
    component: DashboardComponent,
    order: 1,
    module: "dashboard",
    onclick: "refresh",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CREATE_NEW_ORDER,
    title: "Create new order",
    icon: React.createElement(BsPlusLg),
    component: CreateNewOrder,
    order: 2,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + WHATIF,
    title: "What if",
    icon: React.createElement(BsFillPatchQuestionFill),
    component: WhatIfComponent,
    order: 3,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CREATE_ALLOCATION,
    title: "Create new allocation",
    icon: React.createElement(BubbleChartIcon),
    component: CreateNewAllocationComponent,
    order: 4,
    module: "inventorysmart_create_allocation",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + GROUPING,
    title: "Grouping",
    icon: React.createElement(DialpadIcon),
    component: Grouping,
    order: 5,
    module: "inventorysmart_Grouping",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CONFIGURATION,
    title: "Configuration",
    icon: React.createElement(TungstenOutlinedIcon),
    component: Configuration,
    order: 6,
    module: "inventorysmart_configuration",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + SETTINGS,
    title: "Settings",
    icon: React.createElement(TungstenOutlinedIcon),
    component: Settings,
    order: 7,
    module: "inventorysmart_Settings",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PRODUCT_PROFILE,
    title: "Product profile",
    icon: React.createElement(CategoryIcon),
    component: ProductProfileComponent,
    order: 8,
    module: "inventorysmart_product_profile",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ALLOCATION_REPORT,
    title: "Reports",
    icon: React.createElement(AutoAwesomeMosaicIcon),
    component: AllocationReportingComponent,
    order: 9,
    module: "inventorysmart_allocation_report",
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ORDER_MANAGEMENT,
    title: "Order management",
    icon: React.createElement(BsReverseLayoutSidebarInsetReverse),
    component: OrderManagementPage,
    order: 10,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_VISUAL,
    title: "ADA visual",
    icon: React.createElement(Inventory2Outlined),
    component: AdaVisual,
    order: 11,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ORDER_REPOSITORY,
    title: "Order repository",
    icon: React.createElement(BsReverseLayoutTextWindowReverse),
    component: OrderRepository,
    order: 12,
  }
];

if (window.location.pathname.split("/")[1] === "vs") {
  sideBarOptions.push({
    link: "/" + window.location.pathname.split("/")[1] + MEIO,
    title: "MEIO",
    icon: React.createElement(TourIcon),
    component: Meio,
    order: 13,
  })
}

const viewRender = (value) => {
  return (
    <>
      <value.component {...value} />
    </>
  );
};

const Routes = (props) => {
  const routes = sideBarOptions?.map((value) => ({
    path: value.link,
    component: viewRender(value),
    title: value.title,
  }));

  return (
    <Layout
      routes={routes}
      sideBarOptions={sideBarOptions}
      loading={props.inventorysmartScreenConfigLoader}
      app={"inventorysmartold"}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Routes));
