import React, { useState, useEffect } from "react";

import Paper from "@mui/material/Paper";
import { isEmpty } from "lodash";

import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";

const UserFilterTable = (props) => {
  const [userFilterTableColumn, setUserFilterTableColumns] = useState([]);
  const [userFilterTableData, setUserFilterTableData] = useState([]);
  const [preSelectedRoles, setPreSelectedRoles] = useState([]);

  const {
    assignRoleToUserTableData,
    saveRowSelectionsRoleMappings,
    getTableConfig,
    setAssignRoleUserRoleMgmtLoader,
    formDataOnEdit,
  } = props;

  useEffect(() => {
    const getInitialData = async () => {
      try {
        setAssignRoleUserRoleMgmtLoader(true);
        let configColumns = await getTableConfig();
        let formattedColumns = agGridColumnFormatter(configColumns.data?.data);
        setUserFilterTableColumns(formattedColumns);
        if (!isEmpty(formDataOnEdit)) {
          if (assignRoleToUserTableData.length > 0) {
            fetchEditDataToSetTableRecords(
              formDataOnEdit,
              assignRoleToUserTableData
            );
            setAssignRoleUserRoleMgmtLoader(false);
          } else setUserFilterTableData([]);
        } else {
          if (assignRoleToUserTableData.length > 0) {
            setUserFilterTableData(assignRoleToUserTableData);
            setAssignRoleUserRoleMgmtLoader(false);
          } else setUserFilterTableData([]);
        }
      } catch (err) {
        setAssignRoleUserRoleMgmtLoader(false);
        props.addSnack({
          message: "Something went wrong",
          options: {
            variant: "error",
          },
        });
      }
    };
    getInitialData();
  }, [formDataOnEdit, assignRoleToUserTableData]);

  const fetchEditDataToSetTableRecords = (formEdit, tableData) => {
    let commonDepts = [],
      otherDepts = [];
    if (Array.isArray(formEdit)) {
      let rowDataValue = formEdit
        .map((o) => {
          return o.department_name;
        })
        .flat()
        .filter((obj) => !isEmpty(obj));
      commonDepts = tableData.filter((obj) =>
        rowDataValue.some((item) => item.id === obj.id)
      );
      otherDepts = tableData.filter(
        (obj) => !rowDataValue.some((item) => item.id === obj.id)
      );
    } else {
      commonDepts = tableData.filter((obj) =>
        formEdit.department_name.some((item) => item.id === obj.id)
      );
      otherDepts = tableData.filter(
        (obj) => !formEdit.department_name.some((item) => item.id === obj.id)
      );
    }
    let selections = commonDepts.map((item) => item.id);
    saveRowSelectionsRoleMappings(commonDepts, userFilterTableData);
    setPreSelectedRoles(selections);
    setUserFilterTableData([...commonDepts, ...otherDepts]);
    props.hierarchyTableListOnEdit([...commonDepts, ...otherDepts]);
  };

  // on deletion
  useEffect(() => {
    if (!isEmpty(props.hierarchyTableData)) {
      if (!isEmpty(formDataOnEdit)) {
        // taking out the one with new key set to false
        let revokedAccessList = props.hierarchyTableData.filter(
          (obj) => Object.keys(obj).length === 6
        );
        // From original edit list where depts are selected
        // filter out revokedAccessList and set other selections to true
        let editDataHierarchies;
        if (Array.isArray(formDataOnEdit)) {
          editDataHierarchies = formDataOnEdit
            .map((o) => {
              return o?.department_name;
            })
            .flat();
        } else {
          editDataHierarchies = formDataOnEdit?.department_name;
        }
        let deSelectionsFiltered = editDataHierarchies.filter(
          (item) => !revokedAccessList.some((obj) => obj.id === item.id)
        );
        let selections = deSelectionsFiltered.map((rows) => rows.id);
        setPreSelectedRoles(selections);
        // update table data after selection
        setUserFilterTableData(props.hierarchyTableData);
        // pass the rest of the values to parent to set tableValue state
        saveRowSelectionsRoleMappings(
          deSelectionsFiltered,
          userFilterTableData
        );
      }
    }
  }, [props.hierarchyTableData, formDataOnEdit]);

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows();
    setPreSelectedRoles(selections.map((item) => item.id));
    let allTableRecords = event.api
      .getModel()
      .rowsToDisplay.map((item) => item.data);
    saveRowSelectionsRoleMappings(selections, allTableRecords);
  };

  return (
    <Paper elevation={0}>
      {userFilterTableColumn.length > 0 && (
        <AgGridComponent
          rowdata={userFilterTableData}
          columns={userFilterTableColumn}
          selectAllHeaderComponent={true}
          onSelectionChanged={onSelectionChanged}
          uniqueRowId={"id"}
          rowSelection={"multiple"}
          selectedRows={preSelectedRoles}
          sizeColumnsToFitFlag
        />
      )}
    </Paper>
  );
};

export default UserFilterTable;
