import { common } from "modules/react-demo/constants/stringContants";
import { Month_mapping_list } from "config/constants";

export const formatFileHierarchy = (path, action = null, icon = null) => {
  return {
    id: `${path}_scr`,
    label: path,
    icon: icon,
    action: action,
  };
};

/**
 *
 * @param {first File Name} first
 * @param {second File Name} second
 * @returns order
 *
 * This methods takes in file objects and split the folder name into year and month and sorts based on
 * year and month
 *
 * Format Accepted - File Name should in space seperated format Ex: August 2019
 */
export const compareByYearMonth = (first, second) => {
  let firstYearMonthArray = first.name.split(" ");
  let secondYearMonthArray = second.name.split(" ");
  let firstMonth = Month_mapping_list.indexOf(firstYearMonthArray[0]);
  let secondMonth = Month_mapping_list.indexOf(secondYearMonthArray[0]);
  //If the years are not same, then return the year which is smaller
  if (firstYearMonthArray[1] != secondYearMonthArray[1]) {
    return firstYearMonthArray[1] - secondYearMonthArray[1];
  }
  //If the years are same, then return the difference between month indexes
  return firstMonth - secondMonth;
};
