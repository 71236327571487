import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../Utils/axios";
import {
  OPTIMIZE_SIZE_REVIEW,
  OPTIMIZE_ATTRIBUTE_REVIEW,
  GET_SIZE_REVIEW_CONFIGURATION,
  GET_FINALISE_PLAN_METRICS,
  GET_FINALIZE_CLUSTER_GRADE_DATA,
  GET_FINALIZE_ATTRIBUTE_GRADE_DATA,
  FINALIZE_PLAN,
  DOWNLOAD_PO_SHEET,
  NON_LINEAR_EDIT,
  NON_LINEAR_CHANGES_REPLACE_DATA,
} from "../../../constants/apiConstants";

export const planFinalizeService = createSlice({
  name: "planFinalizeService",
  initialState: {
    loader_2_4: false,
    finalisePlanMetricsData: [],
    reviewBySizeData: {},
    reviewByAttributeGrade: {},
  },
  reducers: {
    set2_4_Loader: (state, action) => {
      state.loader_2_4 = action.payload;
    },
    setFinalisePlanMetricsData: (state, action) => {
      state.finalisePlanMetricsData = action.payload;
    },
    setReviewBySizeData: (state, action) => {
      state.reviewBySizeData = action.payload;
    },
    setReviewByClusterGradeData: (state, action) => {
      state.reviewByAttributeGrade = action.payload;
    },
  },
});

export const {
  set2_4_Loader,
  setFinalisePlanMetricsData,
  setReviewBySizeData,
  setReviewByClusterGradeData,
} = planFinalizeService.actions;

export const getFinalisePlanMetricsData = (postBody) => () => {
  return axiosInstance({
    url: GET_FINALISE_PLAN_METRICS,
    method: "POST",
    data: postBody,
  });
};

export const optimizeReviewSize = (postBody) => () => {
  return axiosInstance({
    url: OPTIMIZE_SIZE_REVIEW,
    method: "POST",
    data: postBody,
  });
};

export const optimizeReviewByAttribute = (postBody) => () => {
  return axiosInstance({
    url: OPTIMIZE_ATTRIBUTE_REVIEW,
    method: "POST",
    data: postBody,
  });
};

export const getReviewBySizeData = (postBody) => () => {
  return axiosInstance({
    url: GET_SIZE_REVIEW_CONFIGURATION,
    method: "POST",
    data: postBody,
  });
};

export const getReviewByClusterGradeData = (postBody) => () => {
  return axiosInstance({
    url: GET_FINALIZE_CLUSTER_GRADE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const getReviewByAttributeGradeData = (postBody) => () => {
  return axiosInstance({
    url: GET_FINALIZE_ATTRIBUTE_GRADE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const finalizePlan = (planCode) => () => {
  return axiosInstance({
    url: `${FINALIZE_PLAN}` + planCode,
    method: "PATCH",
  });
};

export const getDownloadPOSheetData = (postBody) => () => {
  return axiosInstance({
    url: DOWNLOAD_PO_SHEET,
    method: "POST",
    data: postBody,
  });
};

export const nonLinearEdit = (postBody) => () => {
  return axiosInstance({
    url: NON_LINEAR_EDIT,
    method: "POST",
    data: postBody,
  });
};

export const nonLinearReplaceData = (postBody) => () => {
  return axiosInstance({
    url: NON_LINEAR_CHANGES_REPLACE_DATA,
    method: "POST",
    data: postBody,
  });
};

export default planFinalizeService.reducer;
