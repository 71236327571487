//AssortSmart Constants
export const DASHBOARD = "/assort-smart/dashboard";
export const ASSORT_CLUSTER = "/assort-smart/cluster";
export const DASHBOARD_HINDSIGHT = "/assort-smart/hindsight-dashboard";
export const PRESEASON_DASHBOARD = "/assort-smart/preseason-dashboard";
export const STRATEGY = "/assort-smart/strategy";
export const CONFIGURATIONS = "/assort-smart/configurations";
export const ASSORT_IN_SEASON = "/assort-smart/inseason-dashboard";
export const PRESEASON_DASHBOARD_REGIONAL = "/assort-smart/preseason-dashboard-regional";
export const STRATEGY_REGIONAL = "/assort-smart/strategy-regional";
export const CONFIGURATIONS_REGIONAL = "/assort-smart/configurations-regional";
export const IN_SEASON_REGIONAL = "/assort-smart/inseason-dashboard-regional";
export const PRODUCT_ATTRIBUTES = "/assort-smart/product-attributes";
export const LOCATION_ATTRIBUTES = "/assort-smart/location-attributes";
export const PRODUCT_OVERVIEW = "/assort-smart/product-overview";
export const CONSUMER_DECISION_TREE = "/assort-smart/consumer-decision-tree";
export const INSIGHTS = "/assort-smart/insights";
export const DEMAND_TRANSFERENCE = "/assort-smart/demand-transference";
export const CLUSTERING = "/assort-smart/cluster-input";
export const INPUTS = "/assort-smart/inputs";
export const OPTIMISATIONS = "/assort-smart/optimisations";
export const INTEGRATION = "/assort-smart/integration";
export const MASTERS = "/assort-smart/masters";

//PlanSmart Constants
export const PLAN_SMART_PRE_SEASON_DASHBOARD = "/plan-smart/pre-season";
export const PLAN_CREATE_NEW_PLAN = "/plan-smart/create-new-plan";
export const PLAN_SMART_PLAN_DETAILS = "/plan-smart/plan";
export const PLAN_SMART_MASTER_PLAN = "/plan-smart/master-plan";
export const PLAN_SMART_REPORT = "/plan-smart/report";
export const PLAN_SMART_IN_SEASON_DASHBOARD = "/plan-smart/in-season";
export const PRE_SEASON = "/plan-smart/pre-season";
export const REPORTS = "/plan-smart/report";
export const IN_SEASON = "/plan-smart/in-season";
export const BUSINESS_PERFORMANCE = "/plan-smart/business-performance";
export const CLUSTER = "/plan-smart/cluster";
export const ITEM_PLANNING = "/plan-smart/item-planning";

//PriceSmart Constants
export const WORKBENCH = "/price-smart/workbench";
export const PRICE_RULES = "/price-smart/price-rules";
export const PLAN_GROUP_CONFIGURATIONS = "/price-smart/plan-group-configurations";
export const PRICE_LIST_CONFIGURATIONS = "/price-smart/price-list-configurations";
export const MARKET_CONFIGURATIONS = "/price-smart/market-configurations";
export const ALERT_CONFIGURATIONS = "/price-smart/alert-configurations";
export const EXCEPTIONS_CONFIGURATIONS = "/price-smart/exceptions-configurations";
export const DECISION_DASHBOARD = "/price-smart/decision-dashboard";
export const ITEM_GROUP_CONFIG = "/price-smart/item-group-config";
export const STORE_CONFIG = "/price-smart/store-config";
export const CLEARANCE_REVIEW = "/price-smart/clearance-review";
export const MARKETING_CALENDAR = "/price-smart/marketing-calendar";
export const BASE_PRICE_STRATEGY = "/price-smart/base-price-strategies";
export const PROMOTION_STRATEGY = "/price-smart/promotion-strategy";
export const RULES = "/price-smart/rules";

//SpaceSmart Constants
export const SPACE_DASHBOARD = "/space-smart/dashboard";
export const PLANOGRAM = "/space-smart/planogram";
export const FLOORPLANS = "/space-smart/floor-plan";
export const SPACE_RULES = "/space-smart/rules";
export const FIXTUREMANAGEMENT = "/space-smart/fixture-management";

//PromoSmart Constants
export const SPENDS_OVERVIEW = "/promo-smart/spends-overview";
export const POST_PROMO_ANALYTICS = "/promo-smart/post-promo-analytics";
export const SALES_DASHBOARD = "/promo-smart/sales-dashboard";
export const BUDGET_PLANNING = "/promo-smart/budget-planning";
export const PROMO_WORKBENCH = "/promo-smart/workbench";
export const PROMO_CREATION = "/promo-smart/promo-creation";
export const PROMO_DECISION_DASHBOARD = "/promo-smart/decision-dashboard";
export const PROMO_CALENDAR = "/promo-smart/promo-calendar";
export const REPORTING = "/promo-smart/reporting";
export const PROMO_MASTERS = "/promo-smart/masters";

//SpaceSmart Constants
export const TRACKING = "/track-smart/tracking";
export const ALRT_CONFIGURATION = "/track-smart/alrt-config";

//TradeSmart Constants
export const TRADE_SPENDS_OVERVIEW = "/trade-smart/spends-overview";
export const UPLIFT_DECOMPOSITION = "/trade-smart/uplift-decomposition";
export const TRADE_SALES_DASHBOARD = "/trade-smart/sales-dashboard";
export const TRADE_BUDGET_PLANNING = "/trade-smart/budget-planning";
export const TRADE_PROMO_CREATION = "/trade-smart/promo-creation";
export const TRADE_PROMO_CALENDAR = "/trade-smart/promo-calendar";
export const TRADE_REPORTING = "/trade-smart/reporting";
export const TRADE_MASTERS = "/trade-smart/masters";

//ForecastSmart Constants
export const ADA_DASHBOARD = "/ada/dashboard";
export const ADA_REPORTS = "/ada/reports";
export const ADA_CONFIGURATION = "/ada/configuration";

//OOTB Constants
export const OOTB_DASHBOARD = "/ootb-inventory-smart/decision-dashboard";
export const OOTB_WHATIF = "/ootb-inventory-smart/what-if";
export const OOTB_CREATE_ALLOCATION = "/ootb-inventory-smart/create-allocation";
export const OOTB_FINALIZE_ALLOCATION = "/ootb-inventory-smart/finalize-allocation";
export const OOTB_PRODUCT_PROFILE = "/ootb-inventory-smart/product-profile";
export const OOTB_CREATE_PRODUCT_PROFILE =
  "/ootb-inventory-smart/create-product-profile";
export const OOTB_GROUPING = "/ootb-inventory-smart/grouping";
export const OOTB_CONFIGURATION = "/ootb-inventory-smart/configuration";
export const OOTB_CONSTRAINTS = "/ootb-inventory-smart/constraints";
export const OOTB_PRODUCT_CONFIGURATION =
  "/ootb-inventory-smart/product-configuration";
export const OOTB_SETTINGS = "/ootb-inventory-smart/settings";
export const OOTB_ALLOCATION_REPORT = "/ootb-inventory-smart/allocation-reports";
export const OOTB_ORDER_BATCHING = "/ootb-inventory-smart/view-current-allocations";
export const OOTB_VIEW_PAST_ALLOCATION =
  "/ootb-inventory-smart/view-past-allocations";
export const OOTB_ORDER_MANAGEMENT = "/ootb-inventory-smart/order-management";
export const OOTB_ADA_VISUAL = "/ootb-inventory-smart/ada-visuals";
export const OOTB_ORDER_REPOSITORY = "/ootb-inventory-smart/order-repository";
export const OOTB_ORDER_REPOSITORY_WHATIF =
  "/ootb-inventory-smart/order-repository-whatif";
export const OOTB_CREATE_NEW_ORDER = "/ootb-inventory-smart/create-new-order";
export const MEIO = "/ootb-inventory-smart/meio";

//Chatbot Constants
export const CHATBOT_DASHBOARD = "/ada-chatbot/dashboard";
export const ADA_ATTRIBUTE = "/ada-chatbot/product-dashboard";
export const ADA_FILE_SELECTOR = "/ada-chatbot/fileselector";
export const ADA_GENERATE_ATTRIBUTES = "/ada-chatbot/generate-attributes";

//InventorySmart Routes
export const INVENTORY_DASHBOARD = "/generic-inventory-smart/decision-dashboard";
export const WHATIF = "/generic-inventory-smart/what-if";
export const CREATE_ALLOCATION = "/generic-inventory-smart/create-allocation";
export const FINALIZE_ALLOCATION =
  "/generic-inventory-smart/finalize-allocation";
export const PRODUCT_PROFILE = "/generic-inventory-smart/product-profile";
export const CREATE_PRODUCT_PROFILE =
  "/generic-inventory-smart/create-product-profile";
export const GROUPING =
  "/generic-inventory-smart/grouping";
export const CONFIGURATION = "/generic-inventory-smart/configuration";
export const CONSTRAINTS = "/generic-inventory-smart/constraints";
export const PRODUCT_CONFIGURATION =
  "/generic-inventory-smart/product-configuration";
export const SETTINGS = "/generic-inventory-smart/settings";
export const ALLOCATION_REPORT = "/generic-inventory-smart/allocation-reports";
export const ORDER_BATCHING =
  "/generic-inventory-smart/view-current-allocations";
export const VIEW_PAST_ALLOCATION =
  "/generic-inventory-smart/view-past-allocations";
export const ORDER_MANAGEMENT = "/generic-inventory-smart/order-management";
export const ADA_VISUAL = "/generic-inventory-smart/ada-visuals";
export const ORDER_REPOSITORY = "/generic-inventory-smart/order-repository";
export const ORDER_REPOSITORY_WHATIF =
  "/generic-inventory-smart/order-repository-whatif";
export const CREATE_NEW_ORDER = "/generic-inventory-smart/create_new_order";

