import axiosInstance from "../Utils/axios";
import {
  GET_FILTER_KEY,
  ADD_SNACK,
  LOADER,
  SET_FILTER_DIMENSIONS,
  SET_FILTER_MODULES,
  ADD_SELECTED_DIMENSION,
  DELETE_SELECTED_DIMENSION,
  SET_SELECTED_MODULES,
  SET_SELECTED_DIMENSION,
  SHOW_FILTER_MODAL_LOADER,
  SET_MODULE_CONFIG_DATA,
  SET_CONFIG_NAME,
  RESET_CONFIG,
  SET_CONFIGS,
  SET_SELECTED_FILTERS,
  SET_MANDATORY_FILTERS_FOR_SCREEN,
  SET_SELECTED_FILTER_CONFIGURATION,
  SET_SAVED_FILTER_SELECTION,
} from "./types";
import {
  SET_FILTER_ELEMENTS,
  GET_ALL_FILTER,
  CREATE_DIMENSION_FILTER,
  GET_FILTER_OPTIONS,
  GET_TENANT_CONFIG,
  CROSS_DIMENSIONAL_API,
  SAVE_FILTER_SELECTION,
} from "../config/api";
export const getFilterfields = (model, screen) => async (dispatch) => {
  dispatch({
    type: SHOW_FILTER_MODAL_LOADER,
    payload: true,
  });
  try {
    const { data } = await axiosInstance.get(
      "/core/filter?dimension=" + model + "&screen=" + screen
    );
    dispatch({
      type: GET_FILTER_KEY,
      payload: data.data.filters || [],
    });
    dispatch({
      type: SET_MANDATORY_FILTERS_FOR_SCREEN,
      payload: data.data.compulsory || [],
    });
    dispatch({
      type: SHOW_FILTER_MODAL_LOADER,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: GET_FILTER_KEY,
      payload: [],
    });
    dispatch({
      type: SHOW_FILTER_MODAL_LOADER,
      payload: false,
    });
    dispatch({
      type: ADD_SNACK,
      payload: {
        message: `Failed to load fields`,
        options: {
          variant: "error",
        },
      },
    });
  }
};

export const getFilteredFields = (model, screen) => (_dispatch) => {
  return axiosInstance.get(
    "/core/filter?dimension=" + model + "&screen=" + screen
  );
};
export const setFilteredFields = (fields) => (dispatch) => {
  dispatch({
    type: GET_FILTER_KEY,
    payload: fields,
  });
};
export const setFilterElements = (filterobj) => async (dispatch) => {
  try {
    return axiosInstance({
      url: SET_FILTER_ELEMENTS,
      method: "POST",
      data: filterobj,
    });
  } catch {
    dispatch({
      type: ADD_SNACK,
      payload: {
        message: `Failed to create filter`,
        options: {
          variant: "error",
        },
      },
    });
  }
};
export const ToggleLoader = (loadStatus) => (dispatch) => {
  dispatch({
    type: LOADER,
    payload: {
      status: loadStatus,
    },
  });
};
export const getFilterDimensions = (_config_tab_id = "") => () => {
  //We can integrate using API here to fetch the dimensions
  return axiosInstance.get("/core/dimension");
};

export const setFilterDimensions = (dimensions) => (dispatch) => {
  dispatch({
    type: SET_FILTER_DIMENSIONS,
    payload: dimensions,
  });
};
export const getFilterModules = (dimensions) => () => {
  //API to fetch the screens based on dimensions
  return axiosInstance.get("/core/screen?dimension=" + dimensions);
};

export const setFilterModules = (modules) => (dispatch) => {
  dispatch({
    type: SET_FILTER_MODULES,
    payload: modules,
  });
};

export const addToSelDimen = (dimension) => (dispatch) => {
  dispatch({
    type: ADD_SELECTED_DIMENSION,
    payload: dimension,
  });
};

export const delSelDimen = (dimension) => (dispatch) => {
  dispatch({
    type: DELETE_SELECTED_DIMENSION,
    payload: dimension,
  });
};

export const setSelModules = (modules) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_MODULES,
    payload: modules,
  });
};

export const setSelDimens = (dimension) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_DIMENSION,
    payload: dimension,
  });
};

export const setModuleConfigData = (moduleConfig, dimension) => (dispatch) => {
  dispatch({
    type: SET_MODULE_CONFIG_DATA,
    payload: { moduleConfig: moduleConfig, dimension: dimension },
  });
};

export const setModuleConfigName = (configName) => (dispatch) => {
  dispatch({
    type: SET_CONFIG_NAME,
    payload: configName,
  });
};

export const createNewFilterConfig = (_id, reqbody) => () => {
  return axiosInstance({
    url: "/core/filter-configuration",
    method: "POST",
    data: reqbody,
  });
};

export const getFilterConfigsTableData = (
  body,
  pageIndex = 0,
  _pageSize = 10
) => (dispatch) => {
  dispatch({
    type: LOADER,
    payload: { status: true },
  });
  axiosInstance({
    url: `/core/filter-configurations?page=${pageIndex + 1}`,
    method: "POST",
    data: body,
  })
    .then((response) => {
      dispatch({
        type: SET_CONFIGS,
        payload: {
          page: pageIndex + 1,
          pagecount: response.data.count,
          totalCount: response.data.total,
          data: response.data.data,
        },
      });
      dispatch({
        type: LOADER,
        payload: { status: false },
      });
    })
    .catch((_error) => {
      dispatch({
        type: ADD_SNACK,
        payload: {
          message: "Something went wrong",
        },
      });
      dispatch({
        type: LOADER,
        payload: { status: false },
      });
    });
};
export const resetFilterConfigSelections = () => (dispatch) => {
  dispatch({
    type: RESET_CONFIG,
  });
};
export const updateFilterConfig = (_tabId, configId, reqbody) => () => {
  return axiosInstance({
    url: `/core/filter-configuration/${configId}`,
    method: "PUT",
    data: reqbody,
  });
};

export const getFilterConfigById = (id) => () => {
  return axiosInstance({
    url: `/core/filter-configuration/${id}`,
    method: "GET",
  });
};
export const getAllFilters = (screen) => async () => {
  // return axiosInstance({
  //   url: `${GET_ALL_FILTER}/${screen}`,
  //   method: "GET",
  // });
};

export const getAppSpecificFilters = (screen, application) => async () => {
  // return axiosInstance({
  //   url: `${GET_ALL_FILTER}/${screen}/application/${application}`,
  //   method: "GET",
  // });
};
export const getFiltersValues = (screen, postBody) => async () => {
  return axiosInstance({
    url: `${GET_FILTER_OPTIONS}/${screen}`,
    method: "POST",
    data: postBody,
  });
};
export const setSelectedFilters = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FILTERS,
    payload: data,
  });
};
export const setFilterConfiguration = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FILTER_CONFIGURATION,
    payload: data,
  });
};
export const getCreateDimensionFilters = (screen) => {
  return axiosInstance({
    url: `${CREATE_DIMENSION_FILTER}${screen}`,
    method: "GET",
  });
};

export const getPlanningHierarchy = (
  applicationCode = 1,
  queryParam = {}
) => async () => {
  // return axiosInstance({
  //   url: `${GET_TENANT_CONFIG}/${applicationCode}`,
  //   params: queryParam,
  //   method: "GET",
  // });
};

export const setMandatoryFieldsInScreen = (mandatoryFields) => (dispatch) => {
  dispatch({
    type: SET_MANDATORY_FILTERS_FOR_SCREEN,
    payload: mandatoryFields || [],
  });
};

/**
 *
 * @param {dependency for cascading filters} postBody
 * @returns Updates individual dropdown values for the dependency selection
 * This API will have cross dimensionality effect between store and product,
 * i.e if any dropdown related store is selected, product dropdown values also gets filtered
 * based on store dropdown value and vice versa
 */
export const getCrossDimensionFiltersData = (postBody) => async () => {
  return axiosInstance({
    url: CROSS_DIMENSIONAL_API,
    method: "POST",
    data: postBody,
  });
};

export const setSavedFilterData = (data) => (dispatch) => {
  dispatch({
    type: SET_SAVED_FILTER_SELECTION,
    payload: data,
  });
};

export const getSavedFilterSelection = async () => {
  const { data } = await axiosInstance({
    url: `${SAVE_FILTER_SELECTION}`,
    method: "GET",
  });
  return data.data;
};

export const setSavedFilterSelection = (postBody) => async () => {
  return axiosInstance({
    url: `${SAVE_FILTER_SELECTION}`,
    method: "PUT",
    data: postBody,
  });
};
